import { HStack, Tag, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import PointsBadge from 'components/PointsBadge'
import { chain } from 'constants/chains'
import React from 'react'
import { Point, Token } from 'types/pool'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PoolTableNameColumnProps {
  points: Point[]
  token0: Token
  token1: Token
  extraTag?: JSX.Element
  extraTagLabel?: string
  fees?: string
  isPoolMigrated?: boolean
}

const PoolTableNameColumn = ({
  extraTag,
  extraTagLabel,
  fees,
  isPoolMigrated,
  points,
  token0,
  token1
}: PoolTableNameColumnProps) => {
  const { nativeCurrency } = chain

  const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
    token0.address,
    chain.id
  )
  const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
    token1.address,
    chain.id
  )

  return (
    <HStack spacing={2}>
      <DoubleCurrencyLogo
        address0={isToken0WrappedNativeCurrency ? undefined : token0.address}
        symbol0={
          isToken0WrappedNativeCurrency ? nativeCurrency?.symbol : token0.symbol
        }
        address1={isToken1WrappedNativeCurrency ? undefined : token1.address}
        symbol1={
          isToken1WrappedNativeCurrency ? nativeCurrency?.symbol : token1.symbol
        }
        boxSize={6}
        ml="-2"
      />
      <HStack flexShrink={0}>
        <Text fontWeight="semibold" fontSize="sm">{`${
          isToken0WrappedNativeCurrency ? nativeCurrency?.symbol : token0.symbol
        } - ${
          isToken1WrappedNativeCurrency ? nativeCurrency?.symbol : token1.symbol
        }`}</Text>
        {fees ? (
          <Tag
            variant="solid"
            size="md"
            bg="bgSecondary"
            textColor="textPrimary"
          >
            {fees}
          </Tag>
        ) : null}
        {isPoolMigrated ? (
          <Tag variant="solid" size="md" bg="yellowBar" textColor="semibold">
            <Trans>OUTDATED</Trans>
          </Tag>
        ) : null}
        {extraTagLabel ? (
          <Tag
            variant="solid"
            size="md"
            bg="bgSecondary"
            textColor="textPrimary"
          >
            {extraTagLabel}
          </Tag>
        ) : (
          extraTag
        )}
        {points && points.length > 0 && <PointsBadge points={points} />}
      </HStack>
    </HStack>
  )
}

export default PoolTableNameColumn
