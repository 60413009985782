import { Token } from '@traderjoe-xyz/sdk-core'
import { EthenaLPStakingAbi } from 'constants/abis/EthenaLPStaking'
import { ETHENA_LP_STAKING_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseEthenaUserStakeDataProps {
  lpToken: Token
}

const useEthenaUserStakeData = ({ lpToken }: UseEthenaUserStakeDataProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  return useReadContract({
    abi: EthenaLPStakingAbi,
    address: ETHENA_LP_STAKING_ADDRESS[chainId],
    args: account ? [account, getAddress(lpToken.address)] : undefined,
    chainId,
    functionName: 'stakes',
    query: {
      enabled: !!account,
      select: (data) => {
        return {
          cooldownStartTimestamp: data[2] ? Number(data[2]) : undefined,
          coolingDownAmount: {
            formatted: formatUnits(data[1], lpToken.decimals),
            value: data[1]
          },
          stakedAmount: {
            formatted: formatUnits(data[0], lpToken.decimals),
            value: data[0]
          }
        }
      }
    }
  })
}

export default useEthenaUserStakeData
