import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { LBPool as DexbarnLBPool, PoolQueryParam } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnLBPoolToPool } from 'utils/poolV2'

interface UsePoolV2Props {
  orderBy: PoolQueryParam.OrderBy
  pageSize: number
  status: PoolQueryParam.Status
  excludeLowVolumePools?: boolean
}

const usePoolsV2 = ({
  excludeLowVolumePools,
  orderBy,
  pageSize,
  status
}: UsePoolV2Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  // fetch from dexbarn
  const fetchPoolsV2 = useDexbarnGet<DexbarnLBPool[]>(`/v1/lb/pools/${chain}`)
  const result = useInfiniteQuery<DexbarnLBPool[]>({
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length % pageSize === 0 ? allPages.length + 1 : undefined,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam }) =>
      fetchPoolsV2({
        params: {
          excludeLowVolumePools,
          filterBy: '1d',
          orderBy,
          pageNum: pageParam,
          pageSize,
          status
        }
      }),
    queryKey: [
      'LBPairsQuery',
      chain,
      pageSize,
      orderBy,
      status,
      excludeLowVolumePools
    ]
  })

  // convert to common pool interface
  const pools: Pool[] = useMemo(
    () => result.data?.pages.flat().map(convertDexbarnLBPoolToPool) ?? [],
    [result.data]
  )

  return {
    ...result,
    data: pools
  }
}

export default usePoolsV2
