import { HStack, Progress, Text, VStack } from '@chakra-ui/react'
import React from 'react'
import { formattedNum } from 'utils/format'

interface MJTicketsProgressProps {
  amount: number
}

const MJTicketsProgress = ({ amount }: MJTicketsProgressProps) => {
  const amountPerTicket = 25
  const ticketsEarned = Math.floor(amount / amountPerTicket)

  const nextAmountTier = (ticketsEarned + 1) * amountPerTicket
  const amountInCurrentTier = amount % amountPerTicket
  const progress = (amountInCurrentTier / amountPerTicket) * 100

  return (
    <VStack spacing={1} mt={2} w="full">
      <HStack justify="space-between" w="full">
        <Text fontSize="sm" textColor="accent.500" fontWeight="semibold">
          Current: {formattedNum(amount, { places: 2 })} MOE = {ticketsEarned}{' '}
          MJT
        </Text>
        <Text fontSize="sm" textColor="accent.500" fontWeight="semibold">
          {nextAmountTier} MOE
        </Text>
      </HStack>
      <Progress
        value={progress}
        rounded="full"
        w="full"
        sx={{
          '& > div': {
            backgroundColor: 'accent.500'
          }
        }}
      />
    </VStack>
  )
}

export default MJTicketsProgress
