import { DISABLED_VAULTS } from 'constants/vaults'
import { Vault as DexbarnVault } from 'types/dexbarn'
import { Vault, VaultWithUserPosition } from 'types/vault'
import { formatUnits } from 'viem'

import { formattedNum } from './format'

export const convertDexbarnVaultIntoVault = (vault: DexbarnVault): Vault => {
  const usdeAddress = '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'.toLowerCase()
  const isUSDeVault =
    vault.tokenX.address.toLowerCase() === usdeAddress ||
    vault.tokenY.address.toLowerCase() === usdeAddress

  return {
    address: vault.address,
    apr1D: vault.apr1d,
    apt1dPriceChange: vault.apt1dPriceChange,
    aptPrice: vault.aptPrice,
    chainId: vault.chainId,
    feesUsd: vault.feesUsd,
    hodl5050Performance: vault.hodl5050Performance,
    id: vault.address,
    isDisabled: DISABLED_VAULTS[vault.chainId].includes(vault.address),
    isRewarded: false,
    lbPair: {
      baseFeePct: Math.round(vault.pair.baseFeePct * 200) / 200, // round to nearest 0.005%
      binStep: vault.pair.binStep,
      id: vault.pair.address,
      version: 'v22'
    },
    managementFeePct: vault.strategy?.aumAnnualFeePct || 0,
    name: vault.name,
    points: isUSDeVault ? ['ethena'] : [],
    strategy: vault.strategy
      ? {
          id: vault.strategy.address
        }
      : undefined,
    tokenX: vault.tokenX,
    tokenX7DayPerformance: vault.tokenX7DayPerformance,
    tokenY: vault.tokenY,
    tokenY7DayPerformance: vault.tokenY7DayPerformance,
    totalValueLockedUSD: vault.tvlUsd
  }
}

export const getUserVaultBalanceUSD = (
  vault: VaultWithUserPosition
): number | undefined => {
  const userAmountX = vault.userPositions.vaultBalance?.amountRawX
    ? formatUnits(
        vault.userPositions.vaultBalance.amountRawX,
        vault.tokenX.decimals
      )
    : undefined
  const userAmountY = vault.userPositions.vaultBalance?.amountRawY
    ? formatUnits(
        vault.userPositions.vaultBalance.amountRawY,
        vault.tokenY.decimals
      )
    : undefined
  const userBalanceUSD =
    vault.userPositions.vaultBalance?.tokenXUsdPrice &&
    vault.userPositions.vaultBalance.tokenYUsdPrice &&
    userAmountX &&
    userAmountY
      ? Number(userAmountX) * vault.userPositions.vaultBalance.tokenXUsdPrice +
        Number(userAmountY) * vault.userPositions.vaultBalance.tokenYUsdPrice
      : undefined
  return userBalanceUSD
}

export const getVaultRowFormattedValues = (vault: VaultWithUserPosition) => {
  const userBalanceUSD = getUserVaultBalanceUSD(vault)
  return {
    apr1D: `${formattedNum(vault.apr1D * 100, { places: 2 })}%`,
    apt1dPriceChange: `${vault.apt1dPriceChange >= 0 ? '+' : ''}${formattedNum(
      vault.apt1dPriceChange,
      { places: 2 }
    )}%`,
    apt1dPriceChangeColor:
      vault.apt1dPriceChange >= 0 ? 'green.400' : 'red.500',
    aptPrice: `${formattedNum(vault.aptPrice, { places: 2 })} ${
      vault.tokenY.symbol
    }`,
    feesUsd1D: formattedNum(vault.feesUsd, { usd: true }),
    liquidity: formattedNum(vault.totalValueLockedUSD, { usd: true }),
    myFunds: userBalanceUSD ? userBalanceUSD : 0
  }
}

export const isEthenaVault = (vaultAddress: string): boolean => {
  return (
    vaultAddress.toLowerCase() ===
    '0x7bc5cd04dd1211a445444dfda6ae11af27367a2c'.toLowerCase()
  )
}
