import { useMemo } from 'react'
import { Hex } from 'viem'

import useFetchTokensList from './useFetchTokensList'

const useVerifiedTokenAddresses = () => {
  const { data } = useFetchTokensList()
  return useMemo(() => {
    return data?.tokens.map((token) => token.address).filter(Boolean) as
      | Hex[]
      | undefined
  }, [data])
}

export default useVerifiedTokenAddresses
