import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import { Pair } from '@traderjoe-xyz/sdk'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { UsePairUserBalanceData } from 'hooks/pool/v1/usePairUserBalance'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { Pool } from 'types/pool'

import AddLiquidityPanel from './AddLiquidityPanel'
import RemoveLiquidityPanel from './RemoveLiquidityPanel'

interface AddRemoveLiquidityPanelProps {
  onAddLiquiditySuccess: () => void
  onRemoveLiquiditySuccess: () => void
  onToken0Select: (currency: Currency) => void
  onToken1Select: (currency: Currency) => void
  pair: Pair
  pool: Pool
  token0: Currency
  token1: Currency
  userBalanceData: UsePairUserBalanceData
}

const AddRemoveLiquidityPanel = (props: AddRemoveLiquidityPanelProps) => {
  const amountInput0 = useCurrencyInputAmount({ currency: props.token0 })
  const amountInput1 = useCurrencyInputAmount({ currency: props.token1 })

  const isLowLiquidity = props.pool.liquidityUsd < 5000

  return (
    <Tabs isFitted variant="solid-rounded" w="full" p={{ base: 4, md: 8 }}>
      <TabList>
        <Tab data-cy="add-liquidity-tab">Add Liquidity</Tab>
        <Tab data-cy="remove-liquidity-tab">Remove Liquidity</Tab>
      </TabList>
      <TabPanels>
        <TabPanel px={0} pb={0}>
          <AddLiquidityPanel
            amountInput0={amountInput0}
            amountInput1={amountInput1}
            isPoolLowLiquidity={isLowLiquidity}
            {...props}
          />
        </TabPanel>
        <TabPanel px={0} pb={0}>
          <RemoveLiquidityPanel {...props} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

export default AddRemoveLiquidityPanel
