import {
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Farm } from 'types/farm'

import VoteTableRow from './VoteTableRow'

const VoteTable = ({
  farms,
  isLoading,
  userVeMoeBalance
}: {
  farms: Farm[]
  isLoading: boolean
  userVeMoeBalance?: bigint
}) => {
  return farms.length > 0 ? (
    <TableContainer mt={4}>
      <Table
        variant="card"
        style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
      >
        <Thead>
          <Tr>
            <Th>Pool</Th>
            <Th>Votes %</Th>
            <Th>7D change</Th>
            <Th>Emissions %</Th>
            <Th>Emissions / day</Th>
            <Th>Best Reward per 1m votes / day</Th>
            <Th>My votes</Th>
          </Tr>
        </Thead>
        <Tbody>
          {farms.map((farm) => (
            <VoteTableRow
              key={farm.pid}
              farm={farm}
              userVeMoeBalance={userVeMoeBalance}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : isLoading ? (
    <Center my={10}>
      <Spinner />
    </Center>
  ) : (
    <Center h="300px">
      <Text textColor="textSecondary">
        <Trans>No voting pools available</Trans>
      </Text>
    </Center>
  )
}

export default VoteTable
