import { Box, Button, HStack, StackProps, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { getPoolDetailV1Url, getPoolDetailV2Url } from 'utils/poolUrl'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PoolNavigationButtonsProps {
  binStep?: number
  farmPid?: number
  token0?: { address?: string; symbol?: string }
  token1?: { address?: string; symbol?: string }
  vaultAddress?: string
  votePid?: number
}

const PoolNavigationButtons = ({
  binStep,
  farmPid,
  token0,
  token1,
  vaultAddress,
  votePid,
  ...props
}: PoolNavigationButtonsProps & StackProps) => {
  const navigate = useNavigate()
  const chainId = useChainId()

  if (!token0 || !token1) return null

  const isOnFarmPage = window.location.pathname.includes('/farms')
  const hasFarm = farmPid !== undefined

  const isOnVotePage = window.location.pathname.includes('/vote')
  const hasVote = votePid !== undefined

  const isOnVaultPage = window.location.pathname.includes('/vault')

  const nativeCurrency = CNATIVE.onChain(chainId)
  const isToken0WrappedNative = isWrappedNativeCurrency(token0.address, chainId)
  const isToken1WrappedNative = isWrappedNativeCurrency(token1.address, chainId)
  const pairName = `${
    isToken0WrappedNative ? nativeCurrency.symbol : token0.symbol
  } / ${isToken1WrappedNative ? nativeCurrency.symbol : token1.symbol}`

  return (
    <Box maxW="full">
      <HStack overflowX="auto" {...props}>
        <Button
          data-cy="pools-nav-button"
          variant="link"
          size="sm"
          color="textSecondary"
          onClick={() =>
            navigate(isOnVaultPage ? '/pool?tab=auto-pools' : '/pool')
          }
          opacity={0.5}
          flexShrink={0}
        >
          {isOnVaultPage ? <Trans>Vaults</Trans> : <Trans>Pools</Trans>}
        </Button>

        <Text color="textSecondary" mb="2px" opacity={0.5} flexShrink={0}>
          {'>'}
        </Text>

        <Button
          data-cy="pool-detail-nav-button"
          variant="link"
          size="sm"
          color="textSecondary"
          opacity={isOnFarmPage || isOnVotePage ? 0.5 : 1}
          onClick={() =>
            navigate(
              vaultAddress
                ? `/vault/${vaultAddress}`
                : binStep
                  ? getPoolDetailV2Url(
                      'v22',
                      binStep,
                      chainId,
                      token0.address,
                      token1.address
                    )
                  : getPoolDetailV1Url(chainId, token0.address, token1.address)
            )
          }
          flexShrink={0}
        >
          <Trans>
            {pairName} {isOnVaultPage ? 'Vault' : 'Pool'}
          </Trans>
        </Button>

        {hasFarm ? (
          <Text color="textSecondary" mb="2px" opacity={0.5} flexShrink={0}>
            {'>'}
          </Text>
        ) : null}

        {hasFarm ? (
          <Button
            data-cy="farms-nav-button"
            variant="link"
            size="sm"
            color="textSecondary"
            opacity={0.5}
            onClick={() => navigate('/farms')}
            flexShrink={0}
          >
            <Trans>Farms</Trans>
          </Button>
        ) : null}

        {hasFarm ? (
          <Text color="textSecondary" mb="2px" opacity={0.5} flexShrink={0}>
            {'>'}
          </Text>
        ) : null}

        {hasFarm ? (
          <Button
            data-cy="farm-detail-nav-button"
            variant="link"
            size="sm"
            color="textSecondary"
            opacity={isOnFarmPage ? 1 : 0.5}
            onClick={() => navigate(`/farms/${farmPid}`)}
            flexShrink={0}
          >
            <Trans>{pairName} Farm</Trans>
          </Button>
        ) : null}

        {hasVote ? (
          <Text color="textSecondary" mb="2px" opacity={0.5} flexShrink={0}>
            {'>'}
          </Text>
        ) : null}

        {hasVote ? (
          <Button
            data-cy="vote-nav-button"
            variant="link"
            size="sm"
            color="textSecondary"
            opacity={0.5}
            onClick={() => navigate('/vote')}
            flexShrink={0}
          >
            <Trans>Vote</Trans>
          </Button>
        ) : null}

        {hasVote ? (
          <Text color="textSecondary" mb="2px" opacity={0.5} flexShrink={0}>
            {'>'}
          </Text>
        ) : null}

        {hasVote ? (
          <Button
            data-cy="vote-detail-nav-button"
            variant="link"
            size="sm"
            color="textSecondary"
            opacity={isOnVotePage ? 1 : 0.5}
            onClick={() => navigate(`/vote/${votePid}`)}
            flexShrink={0}
          >
            <Trans>{pairName} Vote</Trans>
          </Button>
        ) : null}
      </HStack>
    </Box>
  )
}

export default PoolNavigationButtons
