import { Box, Divider } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import { MOE_MASTERCHEF_ADDRESS } from 'constants/moe'
import useBatchClaimRewards from 'hooks/farms/useBatchClaimRewards'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { Farm } from 'types/farm'
import { getUserPendingRewards, getUserStakedAmounts } from 'utils/farm'

import FarmAnalytics from './FarmAnalytics'

interface FarmUserPositionPanelProps {
  farm: Farm
  isLoadingDexbarnFarm: boolean
  onClaimRewardsSuccess: () => void
}

const FarmUserPositionPanel = ({
  farm,
  isLoadingDexbarnFarm,
  onClaimRewardsSuccess
}: FarmUserPositionPanelProps) => {
  const chainId = useChainId()

  const userStakedAmounts = getUserStakedAmounts(farm)
  const userPendingRewards = getUserPendingRewards(farm, chainId)
  const hasPendingRewards = userPendingRewards.some(
    (reward) => reward.amount > 0
  )

  const { batchClaimRewards, isLoading: isClaimingFarmRewards } =
    useBatchClaimRewards({
      enabled: hasPendingRewards,
      onSuccess: onClaimRewardsSuccess,
      pids: [farm.pid],
      rewarderAddress: MOE_MASTERCHEF_ADDRESS[chainId]
    })

  return (
    <Box
      w="full"
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
    >
      <FarmAnalytics farm={farm} isLoadingDexbarnFarm={isLoadingDexbarnFarm} />

      <Divider my={8} />

      <CurrencyAmountsPanel
        title={t`Staked Balance`}
        tokens={[farm.reserves.token0.token, farm.reserves.token1.token]}
        tokenAmounts={userStakedAmounts}
        tokenSymbols={[
          farm.reserves.token0.symbol,
          farm.reserves.token1.symbol
        ]}
        boxProps={{ px: 0, py: 0 }}
      />

      <Divider my={8} />

      <CurrencyAmountsPanel
        title={t`Rewards`}
        tokens={userPendingRewards.map((reward) => reward.token.address)}
        tokenAmounts={userPendingRewards.map((reward) => reward.amount)}
        tokenSymbols={userPendingRewards.map((reward) => reward.token.symbol)}
        boxProps={{ px: 0, py: 0 }}
        bottomContent={
          <Web3Button
            mt={4}
            size="xl"
            colorScheme="accent"
            variant="outline-bold"
            w="full"
            isDisabled={!hasPendingRewards || !batchClaimRewards}
            isLoading={isClaimingFarmRewards}
            loadingText={t`Claiming`}
            onClick={batchClaimRewards}
          >
            <Trans>Claim Rewards</Trans>
          </Web3Button>
        }
      />
    </Box>
  )
}

export default FarmUserPositionPanel
