import {
  Button,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import SortableTableHeader from 'components/SortableTableHeader'
import useGetFarmsList from 'hooks/farms/useGetFarmsList'
import React, { useCallback, useMemo, useState } from 'react'
import { FarmSortMethod } from 'types/farm'

import FarmTableRow from './FarmTableRow'

interface FarmsTableProps {
  tab: 'all' | 'my_farms'
}

const FarmsTable = ({ tab }: FarmsTableProps) => {
  const [sortMethod, setSortMethod] = useState<FarmSortMethod>('apr')
  const [isSortDescending, setIsSortDescending] = useState(true)

  const { farms, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetFarmsList({ isSortDescending, sortMethod })

  const visibleFarms = useMemo(() => {
    switch (tab) {
      case 'all':
        return farms.filter((farm) => farm.moePerSec > 0 && !farm.isLbRewarder)
      case 'my_farms':
        return farms.filter((farm) => farm.userAmount > 0 && !farm.isLbRewarder)
    }
  }, [farms, tab])

  const onHeaderClick = useCallback(
    (method: FarmSortMethod) => {
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod, setSortMethod]
  )

  return (
    <>
      {visibleFarms && visibleFarms.length > 0 ? (
        <TableContainer mt={4}>
          <Table
            variant="card"
            style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
          >
            <Thead>
              <Tr>
                <Th>
                  <Trans>Farm</Trans>
                </Th>
                <SortableTableHeader
                  name={t`Liquidity`}
                  isSortActive={sortMethod === 'liquidity'}
                  isSortDescending={isSortDescending}
                  onClick={() => onHeaderClick('liquidity')}
                />
                <SortableTableHeader
                  name={t`Farm APR`}
                  isSortActive={sortMethod === 'apr'}
                  isSortDescending={isSortDescending}
                  onClick={() => onHeaderClick('apr')}
                />
                <SortableTableHeader
                  name={t`Rewards`}
                  isSortActive={sortMethod === 'rewards'}
                  isSortDescending={isSortDescending}
                  onClick={() => onHeaderClick('rewards')}
                />
                <Th>
                  <Trans>My Pending Rewards</Trans>
                </Th>
                <Th>
                  <Trans>My Balance</Trans>
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {visibleFarms.map((farm) => (
                <FarmTableRow key={farm.pid} farm={farm} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : isLoading ? (
        <Center my={10}>
          <Spinner />
        </Center>
      ) : (
        <Center h="300px">
          <Text textColor="textSecondary">
            <Trans>No farms available</Trans>
          </Text>
        </Center>
      )}

      {!isLoading && visibleFarms.length > 0 && hasNextPage ? (
        <Center>
          <Button
            mt={8}
            variant="outline-bold"
            w="160px"
            isLoading={isFetchingNextPage}
            loadingText={t`Loading`}
            onClick={() => fetchNextPage()}
          >
            <Trans>Load More</Trans>
          </Button>
        </Center>
      ) : null}
    </>
  )
}

export default FarmsTable
