import { VeMoeRewarderAbi } from 'constants/abis/VeMoeRewarder'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseIsRewarderStoppedProps {
  rewarderAddresses: string[]
}

const useIsRewarderStopped = ({
  rewarderAddresses
}: UseIsRewarderStoppedProps) => {
  const chainId = useChainId()

  return useReadContracts({
    contracts: rewarderAddresses.map((rewarderAddress) => ({
      abi: VeMoeRewarderAbi,
      address: getAddress(rewarderAddress),
      chainId,
      functionName: 'isStopped'
    })),
    query: {
      enabled: rewarderAddresses.length > 0,
      select: (data) => {
        return data.map((result) => result.result as boolean | undefined)
      }
    }
  })
}

export default useIsRewarderStopped
