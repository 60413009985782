import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import { VeMoeAbi } from 'constants/abis/VeMoe'
import { MOE_TOKEN_ADDRESS, VE_MOE_ADDRESS } from 'constants/moe'
import useVerifiedTokenAddresses from 'hooks/tokensList/useVerifiedTokenAddresses'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import { useMemo } from 'react'
import { Farm, FarmSortMethod } from 'types/farm'
import {
  convertLensAndDexbarnFarm,
  getFarmPidFromDexbarnFarmId,
  isBribeActive
} from 'utils/farm'
import { formatUnits, zeroAddress } from 'viem'
import { useBlockNumber, useReadContract } from 'wagmi'

import useGetBribesTotalVotes from './useGetBribesTotalVotes'
import useGetDexbarnFarms from './useGetDexbarnFarms'
import useGetLensFarms from './useGetLensFarms'

interface UseGetFarmsListProps {
  isSortDescending: boolean
  sortMethod: FarmSortMethod
}

const useGetFarmsList = ({
  isSortDescending,
  sortMethod
}: UseGetFarmsListProps) => {
  const chainId = useChainId()
  const verifiedTokens = useVerifiedTokenAddresses()
  const wnativeAddress = WNATIVE[chainId].address

  const { data: currentBlockNumber } = useBlockNumber({ chainId })
  const averageBlockPerSecond = 0.429
  const blockNumber7dAgo = currentBlockNumber
    ? currentBlockNumber -
      BigInt(Math.trunc(60 * 60 * 24 * 7 * averageBlockPerSecond))
    : undefined

  const { data: { farms: lensFarms7dAgo, totalVotes: totalVotes7dAgo } = {} } =
    useGetLensFarms({
      blockNumber: blockNumber7dAgo,
      enabled: !!blockNumber7dAgo
    })

  const {
    data: { farms: lensFarms, totalVotes } = {},
    isLoading: isLoadingLensFarms
  } = useGetLensFarms()

  const {
    data: dexbarnFarmsResult,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingDexbarnFarms
  } = useGetDexbarnFarms({ pageSize: 50, sortMethod })

  const { data: totalWeight } = useReadContract({
    abi: VeMoeAbi,
    address: VE_MOE_ADDRESS[chainId],
    functionName: 'getTotalWeight'
  })

  const rewardTokens = useMemo(() => {
    let tokens: string[] = []

    if (lensFarms) {
      tokens = lensFarms
        .filter((farm) => farm.rewarder.rewardPerSec > 0)
        .map((farm) => farm.rewarder.reward.token.token)
    }

    if (dexbarnFarmsResult) {
      tokens = tokens.concat(
        dexbarnFarmsResult.pages
          .flat()
          .map((farm) => farm.bribes)
          .map((bribes) =>
            bribes
              .filter((bribe) => isBribeActive(bribe, verifiedTokens || []))
              .map((bribe) =>
                bribe.tokenId === zeroAddress ? wnativeAddress : bribe.tokenId
              )
          )
          .flat()
      )
    }

    const moeTokenAddress = MOE_TOKEN_ADDRESS[chainId]
    if (!tokens.includes(moeTokenAddress)) {
      tokens.push(moeTokenAddress)
    }

    return tokens
  }, [lensFarms, dexbarnFarmsResult, verifiedTokens, wnativeAddress, chainId])

  const { data: rewardTokenUsdPrices } = useGetTokensUsdPrice({
    enabled:
      rewardTokens.length > 0 && !isLoadingLensFarms && !isLoadingDexbarnFarms,
    tokenAddresses: rewardTokens
  })

  // fetch total votes for active bribes
  const activeBribes = useMemo(() => {
    return dexbarnFarmsResult?.pages
      .flat()
      .map((farm) =>
        farm.bribes.filter((bribe) =>
          isBribeActive(bribe, verifiedTokens || [])
        )
      )
      .flat()
      .map((bribe) => ({
        contractAddress: bribe.bribeId,
        farmPid: BigInt(getFarmPidFromDexbarnFarmId(bribe.farmId))
      }))
  }, [dexbarnFarmsResult, verifiedTokens])
  const { data: activeBribesTotalVotes } = useGetBribesTotalVotes({
    bribes: activeBribes
  })

  const farms: Farm[] = useMemo(() => {
    if (!lensFarms || !dexbarnFarmsResult) return []
    return (
      dexbarnFarmsResult.pages
        .flat()
        .map((dexbarnFarm) => {
          const lensFarm = lensFarms.find(
            (lensFarm) => dexbarnFarm.farmPid === Number(lensFarm.pid)
          )
          const lensFarm7dAgo = lensFarms7dAgo?.find(
            (lensFarm) => dexbarnFarm.farmPid === Number(lensFarm.pid)
          )
          if (!lensFarm) return undefined

          const totalVotesOnActiveBribes = dexbarnFarm.bribes
            .filter((bribe) => isBribeActive(bribe, verifiedTokens || []))
            .map((dexbarnBribe) => {
              if (!activeBribes) return undefined

              const bribeTotalVotesIndex = activeBribes.findIndex(
                (bribe) =>
                  bribe.contractAddress === dexbarnBribe.bribeId &&
                  bribe.farmPid ===
                    BigInt(getFarmPidFromDexbarnFarmId(dexbarnBribe.farmId))
              )
              let bribeTotalVotes: number | undefined
              if (bribeTotalVotesIndex >= 0 && activeBribesTotalVotes) {
                bribeTotalVotes = Number(
                  formatUnits(
                    activeBribesTotalVotes[bribeTotalVotesIndex] || BigInt(0),
                    18
                  )
                )
              }
              return bribeTotalVotes
            })

          return convertLensAndDexbarnFarm({
            chainId,
            dexbarnFarm,
            lensFarm,
            tokenPrices: rewardTokenUsdPrices,
            totalVotes,
            totalVotes7dAgo,
            totalVotesOnActiveBribes,
            totalWeightForAllFarms: totalWeight,
            verifiedTokens,
            votesOnFarm7dAgo: lensFarm7dAgo?.totalVotesOnFarm
          })
        })
        .filter(Boolean) as Farm[]
    ).sort((a, b) => {
      switch (sortMethod) {
        case 'liquidity':
          return isSortDescending
            ? Number(b.liquidityUsd) - Number(a.liquidityUsd)
            : Number(a.liquidityUsd) - Number(b.liquidityUsd)
        case 'rewards':
          return isSortDescending
            ? b.moePerSecEmittedToFarm > a.moePerSecEmittedToFarm
              ? 1
              : -1
            : a.moePerSecEmittedToFarm > b.moePerSecEmittedToFarm
              ? 1
              : -1
        case 'apr':
          return isSortDescending
            ? b.farmApr - a.farmApr
            : a.farmApr - b.farmApr
      }
    })
  }, [
    lensFarms,
    dexbarnFarmsResult,
    rewardTokenUsdPrices,
    sortMethod,
    isSortDescending,
    totalWeight,
    totalVotes,
    totalVotes7dAgo,
    lensFarms7dAgo,
    verifiedTokens,
    chainId,
    activeBribesTotalVotes,
    activeBribes
  ])

  return {
    farms,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: isLoadingLensFarms || isLoadingDexbarnFarms,
    totalVotes
  }
}

export default useGetFarmsList
