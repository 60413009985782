import { ChevronDownIcon } from '@chakra-ui/icons'
import { Button, ButtonProps, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'

interface CurrencySelectButtonProps {
  onCurrencyPickerOpen: () => void
  currency?: Currency
  currencyAddress?: string
  currencyPickerButtonProps?: ButtonProps
  hideDownIcon?: boolean
  isDisabled?: boolean
}

const CurrencySelectButton = ({
  currency,
  currencyAddress,
  currencyPickerButtonProps,
  hideDownIcon,
  isDisabled,
  onCurrencyPickerOpen
}: CurrencySelectButtonProps) => {
  return (
    <Button
      data-cy="currency-picker-button"
      bg="white"
      boxShadow="element"
      _dark={{ _hover: { bg: 'bgCard' }, bg: 'bgPrimary' }}
      _hover={isDisabled ? {} : { bg: 'hover' }}
      size="md"
      borderRadius={12}
      flexShrink={0}
      leftIcon={
        currency ? (
          <CurrencyLogo
            address={currencyAddress}
            symbol={currency.symbol}
            boxSize={5}
          />
        ) : undefined
      }
      rightIcon={!hideDownIcon ? <ChevronDownIcon boxSize={6} /> : undefined}
      isDisabled={isDisabled}
      onClick={onCurrencyPickerOpen}
      {...currencyPickerButtonProps}
      cursor="pointer"
    >
      <Text>{currency?.symbol ?? t`Select token`}</Text>
    </Button>
  )
}

export default CurrencySelectButton
