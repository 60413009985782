import { VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import CopyableError from 'components/CopyableError'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import WarningOutlined from 'components/WarningOutlined'
import Web3Button from 'components/Web3Button'
import useUnlockTokensFromEthena from 'hooks/ethena/useUnlockTokensFromEthena'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { BaseError } from 'viem'

interface UnlockPanelProps {
  token: Token
  onUnlockSuccess?: () => void
  userStakedAmount?: {
    formatted: string
    value: bigint
  }
}

const UnlockPanel = ({
  onUnlockSuccess,
  token,
  userStakedAmount
}: UnlockPanelProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: token
  })

  const isExceedingBalance = userStakedAmount
    ? Number(userStakedAmount.formatted) < Number(amount)
    : false

  const isUnlockButtonDisabled =
    !userStakedAmount || isExceedingBalance || !amountBN

  const {
    error: unlockError,
    isLoading: isUnlocking,
    unlock
  } = useUnlockTokensFromEthena({
    amount: amountBN,
    enabled: !isUnlockButtonDisabled,
    onSuccess: () => {
      setAmount('')
      onUnlockSuccess?.()
    },
    tokenAddress: token.address
  })

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        currency={token}
        currencyAddress={token.address}
        value={amount}
        onValueChange={setAmount}
        balance={userStakedAmount?.formatted}
        error={isExceedingBalance ? t`Not enough ${token?.symbol}` : undefined}
        rightElement={
          userStakedAmount ? (
            <MaxButton
              borderRadius={12}
              balance={userStakedAmount.formatted}
              onClick={() => setAmount(userStakedAmount.formatted)}
            />
          ) : undefined
        }
      />

      <WarningOutlined
        message="Tokens will be available to withdraw 21 days after unlocking."
        boxProps={{ h: '48px', w: 'full' }}
      />

      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="xl"
        w="full"
        isDisabled={isUnlockButtonDisabled || !unlock}
        onClick={unlock}
        isLoading={isUnlocking}
        loadingText={t`Unlocking APT`}
      >
        Unlock APT
      </Web3Button>

      {unlockError ? (
        <CopyableError
          error={unlockError.message}
          summary={(unlockError as BaseError).shortMessage}
        />
      ) : null}
    </VStack>
  )
}

export default UnlockPanel
