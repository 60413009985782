import { HStack, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect, useState } from 'react'

import { getCurrentJourneyEpochS4 } from './constants'

const MoeJourneyCountdown: React.FC = () => {
  const [timeLeft, setTimeLeft] = useState<
    | { days: number; hours: number; minutes: number; seconds: number }
    | undefined
  >()

  useEffect(() => {
    const interval = setInterval(() => {
      const epoch = getCurrentJourneyEpochS4()
      const now = new Date().getTime()
      const startDate = epoch.epochStartDate.getTime()
      const endDate = epoch.epochEndDate.getTime()

      if (now < startDate) {
        clearInterval(interval)
        setTimeLeft(undefined)
        return
      }

      const distance = endDate - now

      if (distance < 0) {
        clearInterval(interval)
        setTimeLeft(undefined)
        return
      }

      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)

      setTimeLeft({
        days,
        hours,
        minutes,
        seconds
      })
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  if (!timeLeft) {
    return null
  }

  const { epochNumber } = getCurrentJourneyEpochS4()

  return (
    <HStack>
      <Text fontWeight="semibold" textColor="white">
        <Trans>Epoch {epochNumber} ends in:</Trans>
      </Text>

      <HStack spacing={1}>
        <Text fontSize="sm" bg="bgCard" px={2} py={1} borderRadius="5px">
          {timeLeft.days > 0 ? `${timeLeft.days}d` : `${timeLeft.hours}h`}
        </Text>
        <Text fontSize="sm" fontWeight="semibold" textColor="white">
          :
        </Text>
        <Text fontSize="sm" bg="bgCard" px={2} py={1} borderRadius="5px">
          {timeLeft.days > 0 ? `${timeLeft.hours}h` : `${timeLeft.minutes}m`}
        </Text>
        <Text fontSize="sm" fontWeight="semibold" textColor="white">
          :
        </Text>
        <Text fontSize="sm" bg="bgCard" px={2} py={1} borderRadius="5px">
          {timeLeft.days > 0 ? `${timeLeft.minutes}m` : `${timeLeft.seconds}s`}
        </Text>
      </HStack>
    </HStack>
  )
}

export default MoeJourneyCountdown
