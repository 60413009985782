import { t } from '@lingui/macro'
import { EthenaLPStakingAbi } from 'constants/abis/EthenaLPStaking'
import { ETHENA_LP_STAKING_ADDRESS } from 'constants/moe'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseWithdrawTokensFromEthenaProps {
  enabled: boolean
  onSuccess: () => void
  tokenAddress: string
  amount?: bigint
}

const useWithdrawTokensFromEthena = ({
  amount,
  enabled,
  onSuccess,
  tokenAddress
}: UseWithdrawTokensFromEthenaProps) => {
  const chainId = useChainId()
  const { isConnected } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config, error } = useSimulateContract({
    abi: EthenaLPStakingAbi,
    address: ETHENA_LP_STAKING_ADDRESS[chainId],
    args: amount ? [getAddress(tokenAddress), amount] : undefined,
    functionName: 'withdraw',
    query: { enabled: isConnected && !!amount && enabled, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const fmtAmount = amount ? formatUnits(amount, 12) : 0
        const description = t`Withdraw ${formattedNum(
          fmtAmount
        )} APT from Ethena`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const withdraw = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    error,
    isLoading: isPending || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success',
    withdraw
  }
}

export default useWithdrawTokensFromEthena
