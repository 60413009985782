import useVerifiedTokenAddresses from 'hooks/tokensList/useVerifiedTokenAddresses'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import debounce from 'lodash.debounce'
import { useMemo } from 'react'
import { convertLensAndDexbarnFarm } from 'utils/farm'

import useGetDexbarnFarm from './useGetDexbarnFarm'
import useGetLensFarm from './useGetLensFarm'

interface UseGetFarmProps {
  pid?: string
}

const useGetFarm = ({ pid }: UseGetFarmProps) => {
  const verifiedTokens = useVerifiedTokenAddresses()
  const chainId = useChainId()

  const {
    data: lensFarm,
    isLoading: isLoadingLensFarm,
    refetch: refetchFarm
  } = useGetLensFarm({
    pid: pid ? BigInt(pid) : undefined
  })

  const {
    data: dexbarnFarm,
    isLoading: isLoadingDexbarnFarm,
    refetch: refetchDexbarnFarm
  } = useGetDexbarnFarm({
    pid
  })

  const rewardToken =
    lensFarm && lensFarm.rewarder.rewardPerSec > 0
      ? lensFarm.rewarder.reward.token.token
      : undefined
  const { data: tokenPrices } = useGetTokensUsdPrice({
    enabled: !!rewardToken,
    tokenAddresses: rewardToken ? [rewardToken] : []
  })

  const farm = useMemo(() => {
    if (!lensFarm) {
      return undefined
    }
    return convertLensAndDexbarnFarm({
      chainId,
      dexbarnFarm,
      lensFarm,
      tokenPrices,
      verifiedTokens
    })
  }, [lensFarm, dexbarnFarm, tokenPrices, verifiedTokens, chainId])

  const debouncedRefetchFarm = debounce(() => {
    refetchFarm()
    refetchDexbarnFarm()
  }, 2000)

  return {
    farm,
    isLoadingDexbarnFarm,
    isLoadingLensFarm,
    refetch: debouncedRefetchFarm
  }
}

export default useGetFarm
