import { Box, Flex, Heading, HStack, Text, useToken } from '@chakra-ui/react'
import { Currency } from '@traderjoe-xyz/sdk-core'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useMemo, useState } from 'react'
import {
  Bar,
  BarChart,
  Cell,
  Label,
  Rectangle,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from 'recharts'
import { LBPairDistribution } from 'types/poolV2'
import { formattedNum } from 'utils/format'
import { inverseLBPairDistributions } from 'utils/poolV2'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

import LBPairDistributionChartTooltip from './LBPairDistributionChartTooltip'

interface LBPairDistributionChartProps {
  data: LBPairDistribution[]
  title: string
  bottomRightElement?: JSX.Element
  currency0?: Currency
  currency1?: Currency
  footer?: JSX.Element
  headingRightElement?: JSX.Element
  highlightedBins?: number[]
  isPriceRatioInversed?: boolean
  showActiveBinMarker?: boolean
}

const LBPairDistributionChart = ({
  bottomRightElement,
  currency0,
  currency1,
  data: initialData,
  footer,
  headingRightElement,
  highlightedBins,
  isPriceRatioInversed = false,
  showActiveBinMarker = false,
  title
}: LBPairDistributionChartProps) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const [graphPurpleDark, graphGreen, textSecondary, bgHighlight] = useToken(
    'colors',
    ['graphPurpleDark', 'graphGreen', 'textSecondary', 'bgHighlight']
  )
  const [focusBar, setFocusBar] = useState<number | undefined>()

  const [priceDecimals, setPriceDecimals] = useState(10)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 18 ? 10 : 18)),
    targetKey: 'g'
  })

  // inverse data if price ratio is inversed
  const data = useMemo(() => {
    const bars = isPriceRatioInversed
      ? inverseLBPairDistributions(initialData)
      : initialData

    return bars.map((bar) => ({
      ...bar,
      liquidityX: (bar.liquidity * Number(bar.amountYPct)) / 100,
      liquidityY: (bar.liquidity * (100 - Number(bar.amountYPct))) / 100
    }))
  }, [initialData, isPriceRatioInversed])

  const activeBinIndex = data.findIndex((el) => el.isActiveBin)

  // custom ticks to always show active bin
  const xAxisTicks = useMemo(() => {
    const interval = 5 // set interval

    // active bin included in bins to display
    if (activeBinIndex >= 0) {
      const activeBinPrice = data[activeBinIndex]?.price

      const higherBins = data
        .filter(
          (_el, i) =>
            i > activeBinIndex && (i - activeBinIndex) % interval === 0
        )
        .map((el) => el.price)
      const lowerBins = data
        .filter(
          (_el, i) =>
            i < activeBinIndex && (activeBinIndex - i) % interval === 0
        )
        .map((el) => el.price)

      return [...lowerBins, activeBinPrice, ...higherBins]
    }

    // data loaded but active bin not among bins to display
    else if (data[0] && data[0].price !== '0') {
      return data
        .filter((_el, i) => Math.abs(i - activeBinIndex) % interval === 0)
        .map((el) => el.price)
    }

    // data not loaded yet
    return undefined
  }, [data, activeBinIndex])

  const topMargin = highlightedBins && highlightedBins.length > 0 ? 10 : 0

  const getBarOpacity = (index: number, defaultOpacity?: number) => {
    const opacity = focusBar === undefined ? defaultOpacity || 1 : 0.25
    return focusBar !== undefined && focusBar === index ? 1 : opacity
  }

  const activeBinMarker = useMemo(() => {
    const activeBin = data.find((el) => el.isActiveBin)
    if (!activeBin) return undefined

    const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
      activeBin.amountX?.token.address,
      chainId
    )
    const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
      activeBin.amountY?.token.address,
      chainId
    )

    const symbolX = isToken0WrappedNativeCurrency
      ? nativeCurrency.symbol
      : activeBin.amountX?.token.symbol
    const symbolY = isToken1WrappedNativeCurrency
      ? nativeCurrency.symbol
      : activeBin.amountY?.token.symbol

    return `Active Bin: ${formattedNum(
      activeBin.price
    )} ${symbolY} per ${symbolX}`
  }, [data, chainId, nativeCurrency])

  const isActiveBinMarkerVisible = showActiveBinMarker && !!activeBinMarker

  return (
    <Box w="full" pos="relative">
      <Box pos="absolute" bottom={14} right={{ base: 0, md: 6 }} zIndex={1}>
        {bottomRightElement}
      </Box>
      <Flex w="full" justify="space-between">
        <Flex align="center" mb={2} flexWrap="wrap" columnGap={3} rowGap={2}>
          <Heading size="md">{title}</Heading>
          {headingRightElement ? headingRightElement : null}
        </Flex>
        <Flex flexDir="column">
          <HStack>
            {highlightedBins && highlightedBins.length > 0 ? (
              <HStack spacing={1}>
                <Box boxSize="10px" borderRadius="full" bg="bgHighlight" />
                <Text fontSize="sm" fontWeight="semibold">
                  Rewarded bins
                </Text>
              </HStack>
            ) : null}
            <HStack spacing={1}>
              <Box boxSize="10px" borderRadius="full" bg="graphGreen" />
              <Text fontSize="sm" fontWeight="semibold">
                {currency1?.symbol}
              </Text>
            </HStack>
            <HStack spacing={1}>
              <Box boxSize="10px" borderRadius="full" bg="graphPurpleDark" />
              <Text fontSize="sm" fontWeight="semibold">
                {currency0?.symbol}
              </Text>
            </HStack>
          </HStack>
        </Flex>
      </Flex>
      <Box h="160px">
        <ResponsiveContainer width="99%">
          <BarChart
            margin={{
              top: isActiveBinMarkerVisible ? topMargin + 14 : topMargin
            }}
            data={data}
            onMouseMove={(state) => {
              setFocusBar(
                state.isTooltipActive ? state.activeTooltipIndex : undefined
              )
            }}
            onMouseLeave={() => {
              setFocusBar(undefined)
            }}
          >
            <Tooltip
              wrapperStyle={{ outline: 'none' }}
              cursor={{ fill: 'transparent' }}
              content={<LBPairDistributionChartTooltip />}
            />
            <XAxis
              xAxisId="0"
              tickSize={0}
              axisLine={false}
              dataKey="price"
              ticks={xAxisTicks}
              tickFormatter={(val) =>
                formattedNum(val, {
                  allowDecimalsOver1000: true,
                  allowSmallDecimals: true,
                  places: priceDecimals
                })
              }
              fontSize="12px"
              fontWeight="semibold"
              tick={{ fill: textSecondary }}
              tickMargin={12}
            />
            {isActiveBinMarkerVisible && (
              <ReferenceLine
                x={data[activeBinIndex].price}
                stroke={textSecondary}
                strokeDasharray="6 6"
                strokeWidth={1}
              >
                <Label
                  fontSize={14}
                  value={activeBinMarker}
                  position="insideTop"
                  offset={-12}
                  color={textSecondary}
                />
              </ReferenceLine>
            )}
            <Bar
              dataKey="liquidityX"
              stackId="a"
              fill={graphGreen}
              background={(props: any) => {
                return (
                  <Rectangle
                    x={props.x}
                    y={props.y - topMargin}
                    width={props.width}
                    height={props.height + topMargin}
                    opacity={getBarOpacity(props.index)}
                    fill={
                      highlightedBins?.some((id) => id === props.binId)
                        ? bgHighlight
                        : 'transparent'
                    }
                  />
                )
              }}
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fillOpacity={getBarOpacity(index, entry.barOpacity)}
                />
              ))}
            </Bar>
            <Bar dataKey="liquidityY" stackId="a" fill={graphPurpleDark}>
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fillOpacity={getBarOpacity(index, entry.barOpacity)}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
      {footer && footer}
    </Box>
  )
}

export default LBPairDistributionChart
