import { useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { Farm as DexbarnFarm } from 'types/dexbarn'
import { FarmSortMethod } from 'types/farm'
import { getDexbarnChainParam } from 'utils/chains'

type DexbarnFarmsOrderBy = 'votes' | 'deposited_balance_usd'

const useGetDexbarnFarms = ({
  pageSize = 20,
  sortMethod
}: {
  pageSize: number
  sortMethod: FarmSortMethod
}) => {
  const chainId = useChainId()

  const fetchFarms = useDexbarnGet<DexbarnFarm[]>(
    `/v1/farms/${getDexbarnChainParam(chainId)}`
  )

  const orderBy: DexbarnFarmsOrderBy = useMemo(() => {
    switch (sortMethod) {
      case 'liquidity':
        return 'deposited_balance_usd'
      case 'rewards':
      case 'apr':
        return 'votes'
    }
  }, [sortMethod])

  return useInfiniteQuery<DexbarnFarm[]>({
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length > 0 && lastPage.length % pageSize === 0
        ? allPages.length + 1
        : undefined,
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      fetchFarms({ params: { orderBy, pageNum: pageParam, pageSize } }),
    queryKey: ['FarmsQuery', pageSize, orderBy]
  })
}

export default useGetDexbarnFarms
