import { useQuery } from '@tanstack/react-query'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { LBQuoterV21ABI, PoolVersion } from '@traderjoe-xyz/sdk-v2'
import { routerApiClient } from 'constants/dexbarn'
import { LB_QUOTER_V22_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useMemo, useState } from 'react'
import { TradeBestPath } from 'types/trade'
import { getDexbarnChainParam } from 'utils/chains'
import { computePriceImpact } from 'utils/swap'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits, getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface DexbarnRoutes {
  routes: {
    amountIn: string
    amountOut: string
    legs: {
      pair: {
        address: string
        type: 'v1' | 'v2'
        data?: {
          binStep: number
        }
      }
      tokenIn: { address: string; symbol: string }
      tokenOut: { address: string; symbol: string }
    }[]
  }[]
}

interface UseGetBestPathProps {
  isExactIn: boolean
  amount?: string
  currencyIn?: Currency
  currencyOut?: Currency
  refetchInterval?: number
}

const useGetBestPathFromBarn = ({
  amount,
  currencyIn,
  currencyOut,
  isExactIn,
  refetchInterval
}: UseGetBestPathProps) => {
  const chainId = useChainId()
  const client = routerApiClient

  const tokenIn = wrappedCurrency(currencyIn, chainId)
  const tokenOut = wrappedCurrency(currencyOut, chainId)

  const params =
    tokenIn && tokenOut && amount
      ? {
          amountIn: isExactIn ? amount : undefined,
          amountOut: isExactIn ? undefined : amount,
          maxRoutes: 3,
          tokenIn: tokenIn.address.toLowerCase(),
          tokenOut: tokenOut.address.toLowerCase()
        }
      : undefined

  const [lastFetchTime, setLastFetchTime] = useState(Date.now())
  const {
    data: routes,
    isFetching: isFetchingPaths,
    refetch: refetchPaths
  } = useQuery({
    enabled: !!params,
    queryFn: async () => {
      setLastFetchTime(Date.now())
      const response = await client.get<DexbarnRoutes>(
        `/v1/routes/${getDexbarnChainParam(chainId)}`,
        { params }
      )
      return response.data.routes
    },
    queryKey: ['RouterBestPathsQuery', chainId, params],
    refetchInterval
  })

  const quoterCalls = useMemo(() => {
    if (!routes || !amount) return undefined
    const tokenPaths = routes.map(({ legs }) => [
      getAddress(legs[0].tokenIn.address),
      ...legs.map((leg) => getAddress(leg.tokenOut.address))
    ])

    return tokenPaths.map(
      (path) =>
        ({
          abi: LBQuoterV21ABI,
          address: LB_QUOTER_V22_ADDRESS[chainId],
          args: [path, BigInt(amount)],
          chainId,
          functionName: isExactIn
            ? 'findBestPathFromAmountIn'
            : 'findBestPathFromAmountOut'
        }) as const
    )
  }, [routes, amount, chainId, isExactIn])

  const { data: quoterResults, isFetching: isFetchingQuotes } =
    useReadContracts({
      contracts: quoterCalls,
      query: { enabled: !!quoterCalls && quoterCalls.length > 0 }
    })

  const tradeBestPath: TradeBestPath | undefined = useMemo(() => {
    if (
      !quoterResults ||
      !currencyIn ||
      !currencyOut ||
      !tokenIn ||
      !tokenOut
    ) {
      return undefined
    }

    const quotes = quoterResults
      .filter((result) => result.status === 'success')
      .map((result) => result.result)
      .filter(Boolean)
    if (quotes.length === 0) return undefined

    const quote = quotes.reduce((prev, current) => {
      if (!current) return prev
      if (!prev) return current
      return current.amounts[current.amounts.length - 1] >
        prev.amounts[prev.amounts.length - 1]
        ? current
        : prev
    }, quotes[0])

    if (
      !quote ||
      quote.amounts.length === 0 ||
      quote.route.length === 0 ||
      quote.virtualAmountsWithoutSlippage.length === 0
    ) {
      return undefined
    }

    const amountIn = {
      formatted: formatUnits(BigInt(quote.amounts[0]), currencyIn.decimals),
      value: quote.amounts[0]
    }

    const amountOut = {
      formatted: formatUnits(
        BigInt(quote.amounts[quote.amounts.length - 1]),
        currencyOut.decimals
      ),
      value: quote.amounts[quote.amounts.length - 1]
    }

    const matchingPath = routes?.find((route) => {
      const tokenPath = [
        getAddress(route.legs[0].tokenIn.address),
        ...route.legs.map((leg) => getAddress(leg.tokenOut.address))
      ]
      return tokenPath.every((id, index) => id === quote.route[index])
    })?.legs

    if (!matchingPath) return undefined

    return {
      amountIn,
      amountOut,
      currencyIn,
      currencyOut,
      path: matchingPath.map((path) => ({
        binStep: path.pair.data?.binStep
          ? BigInt(path.pair.data.binStep)
          : BigInt(0),
        pairId: path.pair.address,
        pairName: `${path.tokenIn.symbol}/${path.tokenOut.symbol}`,
        tokenInId: path.tokenIn.address,
        tokenInSymbol: path.tokenIn.symbol,
        tokenOutId: path.tokenOut.address,
        tokenOutSymbol: path.tokenOut.symbol,
        version: path.pair.type === 'v2' ? PoolVersion.V2_1 : PoolVersion.V1
      })),
      priceImpact: Number(
        computePriceImpact({
          amounts: quote.amounts,
          isExactIn,
          tokenIn,
          tokenOut,
          virtualAmounts: quote.virtualAmountsWithoutSlippage
        }).toFixed(2)
      )
    }
  }, [
    quoterResults,
    currencyIn,
    currencyOut,
    tokenIn,
    tokenOut,
    routes,
    isExactIn
  ])

  return {
    data: tradeBestPath,
    isFetching: isFetchingPaths || isFetchingQuotes,
    lastFetchTime,
    refetch: refetchPaths
  }
}

export default useGetBestPathFromBarn
