import { Token } from '@traderjoe-xyz/sdk-core'
import { useCallback } from 'react'
import { useWalletClient } from 'wagmi'

const useAddTokenToWallet = () => {
  const { data: walletClient } = useWalletClient()
  return useCallback(
    (token: Token) => {
      walletClient?.watchAsset?.({
        options: {
          address: token.address,
          decimals: token.decimals,
          image: `https://raw.githubusercontent.com/merchant-moe/moe-tokenlists/main/logos/${token.address}/logo.png`,
          symbol: token.symbol ?? ''
        },
        type: 'ERC20'
      })
    },
    [walletClient]
  )
}

export default useAddTokenToWallet
