import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import { useMemo } from 'react'
import { UserVaultBalances, Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { erc20Abi, formatUnits, getAddress, parseAbi } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseUserVaultBalancesProps {
  vaults: Vault[]
  enabled?: boolean
}

const useUserVaultBalances = ({
  enabled = true,
  vaults
}: UseUserVaultBalancesProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  // get amount of shares for all vaults
  const { data: balances, refetch } = useReadContracts({
    contracts: vaults.map((vault) => ({
      abi: erc20Abi,
      address: getAddress(vault.id),
      args: account ? [account] : undefined,
      chainId,
      functionName: 'balanceOf'
    })),
    query: { enabled: !!account && vaults.length > 0 && enabled }
  })

  // get amounts for all vaults
  const reads = useReadContracts({
    contracts: vaults.map((vault, i) => {
      const balance = balances ? balances[i].result : undefined
      return {
        abi: parseAbi([
          'function previewAmounts(uint256 shares) external view returns (uint256 amountX, uint256 amountY)'
        ]),
        address: getAddress(vault.id),
        args: balance ? [balance as bigint] : undefined,
        chainId,
        functionName: 'previewAmounts'
      }
    }),
    query: { enabled: !!balances && balances.length > 0 }
  })

  // get usd prices for all tokens
  const tokenAddresses = useMemo(
    () =>
      vaults
        .map((vault) => [vault.tokenX.address, vault.tokenY.address])
        .flat()
        .filter(Boolean) as string[],
    [vaults]
  )
  const { data: usdPrices } = useGetTokensUsdPrice({
    tokenAddresses
  })

  // convert data
  const data = reads.data
  const userVaultPositions: UserVaultBalances[] | undefined = useMemo(
    () =>
      data?.map((read, i) => {
        const result = read.result
        const amountRawX = result?.[0]
        const amountRawY = result?.[1]

        const amountX =
          amountRawX !== undefined
            ? Number(formatUnits(amountRawX, vaults[i].tokenX.decimals))
            : undefined
        const amountY =
          amountRawY !== undefined
            ? Number(formatUnits(amountRawY, vaults[i].tokenY.decimals))
            : undefined

        const tokenX = vaults[i].tokenX
        const tokenY = vaults[i].tokenY

        return {
          amountRawX,
          amountRawY,
          amountX,
          amountY,
          fmtAmountX: amountX !== undefined ? formattedNum(amountX) : undefined,
          fmtAmountY: amountY !== undefined ? formattedNum(amountY) : undefined,
          tokenXUsdPrice: usdPrices?.[tokenX.address.toLowerCase()],
          tokenYUsdPrice: usdPrices?.[tokenY.address.toLowerCase()]
        }
      }),
    [data, usdPrices, vaults]
  )

  return {
    data: userVaultPositions,
    isLoading: reads.isLoading,
    refetch
  }
}

export default useUserVaultBalances
