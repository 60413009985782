import { Fraction } from '@traderjoe-xyz/sdk-core'
import JSBI from 'jsbi'

export const ZERO_FRACTION = new Fraction(JSBI.BigInt(0), JSBI.BigInt(100))

export function scientificNotationToBigInt(scientificString: string): bigint {
  const num = Number(scientificString)

  if (!isFinite(num)) {
    throw new Error(
      'Number is too large and cannot be represented accurately in JavaScript.'
    )
  }

  const numString = num.toLocaleString('fullwide', { useGrouping: false })
  const bigIntValue = BigInt(numString)

  return bigIntValue
}
