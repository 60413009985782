import {
  Box,
  Flex,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import React from 'react'

interface SimpleCurrencyPickerModalProps {
  currencies: Currency[]
  isOpen: boolean
  onClose: () => void
  onCurrencyChange: (currency: Currency) => void
}

const SimpleCurrencyPickerModal = ({
  currencies,
  isOpen,
  onClose,
  onCurrencyChange
}: SimpleCurrencyPickerModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg">
      <ModalOverlay />
      <ModalContent
        bg="bgPrimary"
        minH="80vh"
        overflow="hidden"
        mb={{ base: 0, sm: 50 }}
      >
        <ModalHeader>
          <Trans>Select a token</Trans>
        </ModalHeader>
        <ModalCloseButton mt={1} />
        <ModalBody p={0}>
          <Box bg="bgPrimary" h="73vh">
            <VStack
              w="full"
              minH="280px"
              bg="bgPrimary"
              maxH="66vh"
              p={4}
              overflow="auto"
            >
              {currencies.map((currency, i) => (
                <Flex
                  key={i}
                  w="full"
                  align="center"
                  justify="space-between"
                  cursor="pointer"
                  px={4}
                  py={3}
                  borderRadius="xl"
                  _hover={{ bg: 'hover' }}
                  onClick={() => {
                    onCurrencyChange(currency)
                    onClose()
                  }}
                >
                  <HStack spacing={4}>
                    <CurrencyLogo
                      address={currency.isToken ? currency.address : undefined}
                      symbol={currency.symbol}
                      boxSize={8}
                    />
                    <VStack align="flex-start" spacing={0.5}>
                      <HStack>
                        <Text fontWeight="semibold">{currency.symbol}</Text>
                        <Text fontSize="sm">{currency.name}</Text>
                      </HStack>
                    </VStack>
                  </HStack>
                </Flex>
              ))}
            </VStack>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default SimpleCurrencyPickerModal
