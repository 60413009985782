import { Box, Flex, Td, Tr } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { VaultWithUserPosition } from 'types/vault'
import { formattedNum } from 'utils/format'
import { getUserVaultBalanceUSD } from 'utils/vault'

import PoolTableNameColumn from '../PoolTableNameColumn'
import UserPositionValueTd from './UserPositionValueTd'

interface VaultPositionRowProps {
  vault: VaultWithUserPosition
}

const VaultPositionRow = ({ vault }: VaultPositionRowProps) => {
  const navigate = useNavigate()

  const userBalanceUSD = getUserVaultBalanceUSD(vault)

  const fmtTotalFundsDeposit =
    userBalanceUSD && userBalanceUSD > 0
      ? formattedNum(userBalanceUSD, {
          usd: true
        })
      : '--'

  return (
    <Tr
      cursor="pointer"
      _hover={{
        bg: 'hover'
      }}
      aria-label={`${vault.tokenX.symbol}-${vault.tokenY.symbol} vault user position`}
      transform="scale(1)"
      role="group"
      onClick={() => navigate(`/vault/${vault.id}`)}
    >
      <Td
        borderTopLeftRadius="2xl"
        borderBottomLeftRadius="2xl"
        borderLeft="1px"
        borderColor="border"
      >
        <PoolTableNameColumn
          token0={vault.tokenX}
          token1={vault.tokenY}
          fees={`${vault.lbPair.baseFeePct}%`}
          points={vault.points}
        />
      </Td>
      <UserPositionValueTd
        symbol0={vault.tokenX.symbol}
        symbol1={vault.tokenY.symbol}
        pooledTokens0={vault.userPositions.vaultBalance?.fmtAmountX}
        pooledTokens1={vault.userPositions.vaultBalance?.fmtAmountY}
      />
      <Td
        textAlign="right"
        borderTopRightRadius="2xl"
        borderBottomRightRadius="2xl"
        borderRight="1px"
        borderColor="border"
      >
        <Flex justifyContent="flex-end" columnGap={4}>
          <Box as="span">{fmtTotalFundsDeposit}</Box>
        </Flex>
      </Td>
    </Tr>
  )
}

export default VaultPositionRow
