import { Box, BoxProps } from '@chakra-ui/react'
import React from 'react'

const PageContainer = (props: BoxProps) => {
  return (
    <Box
      pt={4}
      maxW={{ '2xl': '1600px', base: '1300px' }}
      margin="0 auto"
      minH="calc(100vh - 80px)"
      px={{ '2xl': 4, base: 4, md: 0 }}
      {...props}
    />
  )
}

export default PageContainer
