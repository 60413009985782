import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Center, HStack, Tag, Text } from '@chakra-ui/react'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import { PoolVersion } from '@traderjoe-xyz/sdk-v2'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useLBPairFeeParameters from 'hooks/pool/v2/useLBPairFeeParameters'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { formattedNum } from 'utils/format'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface RouteDetailsStepProps {
  pair: {
    pairId: string
    pairName: string
    tokenInId: string
    tokenInSymbol: string
    tokenOutId: string
    tokenOutSymbol: string
    version: PoolVersion
  }
  showForwardArrow: boolean
  lbPairAddress?: string
}

const RouteDetailsStep = ({
  pair,
  showForwardArrow
}: RouteDetailsStepProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  // get pool fee
  const poolVersion = pair.version
  const {
    data: { baseFee }
  } = useLBPairFeeParameters({
    lbPairAddress: pair.version === PoolVersion.V1 ? undefined : pair.pairId
  })
  const fmtFee =
    poolVersion === PoolVersion.V1
      ? '0.3%'
      : baseFee
        ? `${formattedNum(baseFee / 100, { places: 4 })}%`
        : ''

  return (
    <HStack spacing={1}>
      <DoubleCurrencyLogo
        address0={pair.tokenInId}
        symbol0={
          isWrappedNativeCurrency(pair.tokenInId, chainId)
            ? nativeCurrency.symbol
            : pair.tokenInId
        }
        address1={pair.tokenOutId}
        symbol1={
          isWrappedNativeCurrency(pair.tokenOutId, chainId)
            ? nativeCurrency.symbol
            : pair.tokenOutSymbol
        }
        boxSize={5}
      />
      <Text fontSize="sm" fontWeight="semibold">
        {pair.tokenOutSymbol}
      </Text>
      <Tag size="md" rounded="full" fontWeight="semibold">
        {fmtFee}
      </Tag>
      {showForwardArrow ? (
        <Center>
          <ArrowForwardIcon boxSize="14px" color="textSecondary" ml={1} />
        </Center>
      ) : null}
    </HStack>
  )
}

export default RouteDetailsStep
