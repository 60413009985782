import { MerchantMoeChainId } from 'constants/chains'
import { MOE_EMISSION_TO_FARM_SHARE } from 'constants/farm'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import { ExtraHooksRewarderData, HooksRewarderData } from 'types/lbHooksLens'
import { formatUnits, zeroAddress } from 'viem'

interface UseGetLbPoolsRewardAPRsProps {
  liquidityDepthMinus: number
  liquidityDepthPlus: number
  extraRewarderData?: ExtraHooksRewarderData
  lbPairAddress?: string
  rewarderData?: HooksRewarderData
}

const getLbPoolsRewardAPRs = ({
  extraRewarderData,
  lbPairAddress,
  liquidityDepthMinus,
  liquidityDepthPlus,
  priceUSDPerToken,
  rewarderData
}: UseGetLbPoolsRewardAPRsProps & {
  chainId: MerchantMoeChainId
  priceUSDPerToken?: Record<string, number>
}) => {
  if (
    !lbPairAddress ||
    !rewarderData ||
    !extraRewarderData ||
    !priceUSDPerToken
  ) {
    return undefined
  }

  const hasRewarder = rewarderData.hooksParameters.hooks !== zeroAddress
  const hasExtraRewarder =
    extraRewarderData.hooksParameters.hooks !== zeroAddress

  if (!hasRewarder && !hasExtraRewarder) {
    return undefined
  }

  const rewardTokenPriceUsd =
    priceUSDPerToken[rewarderData.rewardToken.token.toLowerCase()]
  const extraRewardTokenPriceUsd =
    priceUSDPerToken[extraRewarderData.rewardToken.token.toLowerCase()]

  const rewarderAprData = hasRewarder
    ? getRewarderApr({
        liquidityDepthMinus,
        liquidityDepthPlus,
        rewardPerSecond:
          Number(
            formatUnits(
              rewarderData.moePerSecond,
              Number(rewarderData.rewardToken.decimals)
            )
          ) * MOE_EMISSION_TO_FARM_SHARE,
        rewardTokenPriceUsd
      })
    : undefined

  const extraRewarderAprData = hasExtraRewarder
    ? getRewarderApr({
        liquidityDepthMinus,
        liquidityDepthPlus,
        rewardPerSecond: Number(
          formatUnits(
            extraRewarderData.rewardPerSecond,
            Number(extraRewarderData.rewardToken.decimals)
          )
        ),
        rewardTokenPriceUsd: extraRewardTokenPriceUsd
      })
    : undefined

  return {
    extraRewarderAprData,
    lbPairAddress,
    rewarderAprData
  }
}

const getRewarderApr = ({
  liquidityDepthMinus,
  liquidityDepthPlus,
  rewardPerSecond,
  rewardTokenPriceUsd
}: {
  liquidityDepthMinus: number
  liquidityDepthPlus: number
  rewardPerSecond: number
  rewardTokenPriceUsd: number
}) => {
  const rangeLiquidityUsd = liquidityDepthMinus + liquidityDepthPlus

  const nbSecondsInDay = 60 * 60 * 24
  const rewardsUsdPerYear = rewardTokenPriceUsd
    ? rewardPerSecond * rewardTokenPriceUsd * nbSecondsInDay * 365
    : undefined

  const apr =
    rewardsUsdPerYear && rangeLiquidityUsd
      ? rewardsUsdPerYear / rangeLiquidityUsd
      : 0

  return { apr, rangeLiquidityUsd }
}

const useGetLbPoolsRewardAPRs = ({
  calls
}: {
  calls: UseGetLbPoolsRewardAPRsProps[]
}) => {
  const chainId = useChainId()

  const tokens = calls
    .map((call) => [
      call.rewarderData?.rewardToken.token.toLowerCase(),
      call.extraRewarderData?.rewardToken.token.toLowerCase()
    ])
    .flat()
    .filter(Boolean) as string[]
  const uniqueTokens = Array.from(new Set(tokens))

  const { data: priceUSDPerToken, isLoading: isLoadingPrices } =
    useGetTokensUsdPrice({
      enabled: uniqueTokens.length > 0,
      tokenAddresses: uniqueTokens
    })

  return {
    data: calls.map((call) => {
      return getLbPoolsRewardAPRs({
        chainId,
        priceUSDPerToken,
        ...call
      })
    }),
    isLoading: isLoadingPrices
  }
}

export default useGetLbPoolsRewardAPRs
