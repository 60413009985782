import metatagImage from 'assets/metatagimage.webp'
import React from 'react'
import { Helmet } from 'react-helmet'

interface PageHelmetProps {
  url: string
  description?: string
  title?: string
}

const PageHelmet = ({ description, title, url }: PageHelmetProps) => (
  <Helmet>
    <title>
      {title ??
        'Merchant Moe | Leading Decentralized Exchange on Mantle Network'}
    </title>
    <meta
      name="description"
      content={
        description ??
        'Explore DeFi on Mantle Network with Merchant Moe, a leading decentralized exchange. Trade various tokens and earn rewards. Enjoy efficient token swaps, high yield opportunities, and enhanced earning potential for liquidity providers. Merchant Moe makes DeFi easy and accessible.'
      }
    />
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://traderjoexyz.com/${url}`} />
    <meta
      property="og:title"
      content={
        title ??
        'Merchant Moe | Leading Decentralized Exchange on Mantle Network'
      }
    />
    <meta
      property="og:description"
      content={
        description ??
        'Explore DeFi on Mantle Network with Merchant Moe, a leading decentralized exchange. Trade various tokens and earn rewards. Enjoy efficient token swaps, high yield opportunities, and enhanced earning potential for liquidity providers. Merchant Moe makes DeFi easy and accessible.'
      }
    />
    <meta property="og:image" content={metatagImage} />
    <meta property="twitter:card" content={metatagImage} />
    <meta property="twitter:url" content={`https://traderjoexyz.com/${url}`} />
    <meta
      property="twitter:title"
      content={
        title ??
        'Merchant Moe | Leading Decentralized Exchange on Mantle Network'
      }
    />
    <meta
      property="twitter:description"
      content={
        description ??
        'Explore DeFi on Mantle Network with Merchant Moe, a leading decentralized exchange. Trade various tokens and earn rewards. Enjoy efficient token swaps, high yield opportunities, and enhanced earning potential for liquidity providers. Merchant Moe makes DeFi easy and accessible.'
      }
    />
    <meta property="twitter:image" content={metatagImage} />
  </Helmet>
)

export default PageHelmet
