import { Box, HStack, Tag, Td, Text, Tr } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LBPoolVersion, Point, Token } from 'types/pool'
import { FormattedLbPoolReward } from 'types/rewards'
import { formattedNum } from 'utils/format'
import { getPoolDetailV1Url, getPoolDetailV2Url } from 'utils/poolUrl'

import LbRewardsPopover from '../LbRewardsPopover'
import PoolTableNameColumn from '../PoolTableNameColumn'
import UserPositionValueTd from './UserPositionValueTd'

export interface UserPositionRowProps {
  feePct: number
  isPoolMigrated: boolean
  points: Point[]
  poolAddress: string
  tokenX: Token & {
    amount: number
    priceUsd?: number
  }
  tokenY: Token & {
    amount: number
    priceUsd?: number
  }
  farmStakedAmounts?: {
    amountX: number
    amountY: number
  }
  isOutOfPosition?: boolean
  lbBinStep?: number
  lbPoolVersion?: LBPoolVersion
  rewards?: FormattedLbPoolReward[]
  totalAmountUsd?: number
}

const UserPositionRow = ({
  farmStakedAmounts,
  feePct,
  isOutOfPosition,
  isPoolMigrated,
  lbBinStep,
  lbPoolVersion,
  points,
  rewards,
  tokenX,
  tokenY,
  totalAmountUsd
}: UserPositionRowProps) => {
  const chainId = useChainId()
  const navigate = useNavigate()
  const targetUrl =
    lbBinStep && lbPoolVersion
      ? getPoolDetailV2Url(
          lbPoolVersion,
          lbBinStep,
          chainId,
          tokenX.address,
          tokenY.address
        )
      : getPoolDetailV1Url(chainId, tokenX.address, tokenY.address)

  return (
    <Tr
      aria-label={`${tokenX.symbol} - ${tokenY.symbol} liquidity pool user position`}
      cursor="pointer"
      transform="scale(1)"
      role="group"
      onClick={() => navigate(targetUrl)}
    >
      <Td
        borderTopLeftRadius="2xl"
        borderBottomLeftRadius="2xl"
        borderLeft="1px"
        borderColor="border"
      >
        <PoolTableNameColumn
          token0={tokenX}
          token1={tokenY}
          fees={`${feePct}%`}
          isPoolMigrated={isPoolMigrated}
          extraTag={
            <HStack>
              {rewards && rewards.length > 0 ? (
                <LbRewardsPopover rewards={rewards} />
              ) : undefined}
              {isOutOfPosition ? (
                <Tag colorScheme="yellow" borderRadius="full" bg="yellowBar">
                  <HStack>
                    <Text
                      fontSize="sm"
                      textColor="yellow.700"
                      fontWeight="bold"
                    >
                      <Trans>Out of position</Trans>
                    </Text>
                    <Box boxSize={2} borderRadius="full" bg="yellow.700" />
                  </HStack>
                </Tag>
              ) : undefined}
            </HStack>
          }
          points={points}
        />
      </Td>
      <UserPositionValueTd
        symbol0={tokenX.symbol}
        symbol1={tokenY.symbol}
        pooledTokens0={formattedNum(tokenX.amount)}
        pooledTokens1={formattedNum(tokenY.amount)}
      />
      <UserPositionValueTd
        symbol0={tokenX.symbol}
        symbol1={tokenY.symbol}
        pooledTokens0={
          farmStakedAmounts ? formattedNum(farmStakedAmounts.amountX) : '0'
        }
        pooledTokens1={
          farmStakedAmounts ? formattedNum(farmStakedAmounts.amountY) : '0'
        }
      />
      <Td
        textAlign="right"
        borderTopRightRadius="2xl"
        borderBottomRightRadius="2xl"
        borderRight="1px"
        borderColor="border"
      >
        {totalAmountUsd ? formattedNum(totalAmountUsd, { usd: true }) : '--'}
      </Td>
    </Tr>
  )
}

export default UserPositionRow
