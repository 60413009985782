import {
  Box,
  Heading,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { MOE_TOKEN_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import React, { useMemo } from 'react'
import { StableMoeRemittance } from 'types/dexbarn'
import { formattedNum } from 'utils/format'

interface StakeOverviewPanelProps {
  isLoading: boolean
  remittances30d: StableMoeRemittance[]
  totalMoeStaked: number
}

const StakeOverviewPanel = ({
  isLoading,
  remittances30d,
  totalMoeStaked
}: StakeOverviewPanelProps) => {
  const chainId = useChainId()

  const moeToken = MOE_TOKEN_ADDRESS[chainId]
  const { data: tokensUsdPrices } = useGetTokensUsdPrice({
    tokenAddresses: [moeToken]
  })
  const moeUsdPrice = tokensUsdPrices?.[moeToken.toLowerCase()]

  const totalStakedUsd =
    totalMoeStaked && moeUsdPrice ? totalMoeStaked * moeUsdPrice : 0

  const totalRemittedUsd = useMemo(
    () =>
      remittances30d.reduce((acc, remittance) => {
        return acc + Number(remittance.amountOutUsd)
      }, 0),
    [remittances30d]
  )

  const stakingApr30d = totalStakedUsd
    ? (totalRemittedUsd / totalStakedUsd) * (365 / 30) * 100
    : 0

  return (
    <Box
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
      w="full"
    >
      <VStack w="full" align="flex-start" spacing={4}>
        <Heading size="md">
          <Trans>Overview</Trans>
        </Heading>

        <SimpleGrid columns={3} w="full">
          <VStack spacing={1} align="flex-start">
            <Text fontSize="sm" textColor="textSecondary">
              Total Staked USD
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight="semibold" fontSize="lg" minW="100px">
                {formattedNum(totalStakedUsd, { usd: true })}
              </Text>
            </Skeleton>
          </VStack>

          <VStack spacing={1} align="flex-start">
            <Text fontSize="sm" textColor="textSecondary">
              Total Remitted (30D)
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight="semibold" fontSize="lg" minW="100px">
                {formattedNum(totalRemittedUsd, { usd: true })}
              </Text>
            </Skeleton>
          </VStack>

          <VStack spacing={1} align="flex-start">
            <Text fontSize="sm" textColor="textSecondary">
              APR (30D)
            </Text>
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight="semibold" fontSize="lg" minW="60px">
                {formattedNum(stakingApr30d, { places: 2 })}%
              </Text>
            </Skeleton>
          </VStack>
        </SimpleGrid>
      </VStack>
    </Box>
  )
}

export default StakeOverviewPanel
