import { t } from '@lingui/macro'

export interface SubmenuItem {
  name: string
  url: string
  external?: boolean
}

const joeHome: SubmenuItem = {
  external: false,
  name: t`Home`,
  url: ''
}

const joeTrade: SubmenuItem = {
  external: false,
  name: t`Trade`,
  url: 'trade'
}

const joePool: SubmenuItem = {
  external: false,
  name: t`Pool`,
  url: 'pool'
}

const joeAirdrop: SubmenuItem = {
  external: false,
  name: 'Joe',
  url: 'joe-staking'
}

const joeFarms: SubmenuItem = {
  external: false,
  name: t`Farms`,
  url: 'farms'
}

const joeVote: SubmenuItem = {
  external: false,
  name: t`Vote`,
  url: 'vote'
}

const joeStake: SubmenuItem = {
  external: false,
  name: t`Stake`,
  url: 'stake'
}

export { joeAirdrop, joeFarms, joeHome, joePool, joeStake, joeTrade, joeVote }
