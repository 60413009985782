import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import useSendNativeCurrency from 'hooks/useSendNativeCurrency'
import { useTokenBalance } from 'hooks/useTokenBalance'
import useTransferToken from 'hooks/useTransferToken'
import debounce from 'lodash.debounce'
import React from 'react'

interface SendTokensToRewarderProps {
  isOwner: boolean
  rewarderAddress: string
  onTransferSuccess?: () => void
  rewardToken?: Currency
}

const SendTokensToRewarder = ({
  isOwner,
  onTransferSuccess,
  rewarderAddress,
  rewardToken
}: SendTokensToRewarderProps) => {
  const {
    data: userRewardTokenBalance,
    refetch: refetchUserRewardTokenBalance
  } = useTokenBalance({
    token: rewardToken?.isToken ? rewardToken.address : undefined
  })
  const debouncedRefetchUserRewardTokenBalance = debounce(
    refetchUserRewardTokenBalance,
    2000
  )

  const {
    amount: tokenAmount,
    amountBN: tokenAmountBN,
    setAmount: setTokenAmount
  } = useCurrencyInputAmount({
    currency: rewardToken
  })

  const { isLoading: isTransferringNative, transferNativeCurrency } =
    useSendNativeCurrency({
      amount: tokenAmountBN,
      enabled: isOwner && !!rewardToken && rewardToken.isNative,
      onSuccess: () => {
        setTokenAmount('')
        debouncedRefetchUserRewardTokenBalance()
        onTransferSuccess?.()
      },
      recipient: rewarderAddress
    })

  const { isLoading: isTransferring, transferTokens } = useTransferToken({
    amount: tokenAmountBN,
    enabled: isOwner && !!rewardToken && !rewardToken.isNative,
    onSuccess: () => {
      setTokenAmount('')
      debouncedRefetchUserRewardTokenBalance()
      onTransferSuccess?.()
    },
    recipient: rewarderAddress,
    token: rewardToken?.isToken ? rewardToken.address : undefined
  })

  const onSendTokenClick = () => {
    if (!rewardToken) return
    if (rewardToken.isNative) {
      transferNativeCurrency?.()
    } else {
      transferTokens?.()
    }
  }

  return (
    <VStack mt={8} spacing={8}>
      <CurrencyInput
        value={tokenAmount}
        onValueChange={setTokenAmount}
        currency={rewardToken}
        balance={userRewardTokenBalance?.formatted}
      />

      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="xl"
        w="full"
        isLoading={isTransferring || isTransferringNative}
        onClick={onSendTokenClick}
        loadingText={t`Sending tokens`}
        isDisabled={!rewardToken || !tokenAmountBN}
      >
        {isOwner ? (
          <Trans>Send tokens</Trans>
        ) : (
          <Trans>You are not the contract owner</Trans>
        )}
      </Web3Button>
    </VStack>
  )
}

export default SendTokensToRewarder
