import { t } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { formatUnits, Hex, parseAbi } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseStakeTokenProps {
  stakingContractAddress: Hex
  token: Token
  amount?: bigint
  enabled?: boolean
  onSuccess?: () => void
}

const useStakeToken = ({
  amount,
  enabled,
  onSuccess,
  stakingContractAddress,
  token
}: UseStakeTokenProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: parseAbi(['function stake(uint256 amount) external']),
    address: stakingContractAddress,
    args: amount ? [amount] : undefined,
    chainId,
    functionName: 'stake',
    query: {
      enabled: enabled && walletChain?.id === chainId && !!amount,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (amount === undefined) return
        const transactionSummary = t`Staked ${formattedNum(
          formatUnits(amount, token.decimals)
        )} ${token.symbol}`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const stake = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    stake
  }
}

export default useStakeToken
