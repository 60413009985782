import { t } from '@lingui/macro'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress, parseAbi } from 'viem'
import {
  BaseError,
  useAccount,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

interface UseSetRewarderParametersProps {
  enabled: boolean
  rewarderAddress: string
  end?: Date
  onSuccess?: () => void
  rewardPerSecond?: bigint
  start?: Date
}

const useSetRewarderParameters = ({
  enabled,
  end,
  onSuccess,
  rewarderAddress,
  rewardPerSecond,
  start
}: UseSetRewarderParametersProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const startTimestamp = start ? Math.floor(start.getTime() / 1000) : undefined
  const expectedDuration =
    start && end
      ? Math.floor((end.getTime() - start.getTime()) / 1000)
      : undefined

  const { data: config, error } = useSimulateContract({
    abi: parseAbi([
      'function setRewarderParameters(uint256 rewardPerSecond, uint256 startTimestamp, uint256 expectedDuration) external'
    ]),
    address: getAddress(rewarderAddress),
    args:
      rewardPerSecond && startTimestamp && expectedDuration
        ? [rewardPerSecond, BigInt(startTimestamp), BigInt(expectedDuration)]
        : undefined,
    chainId,
    functionName: 'setRewarderParameters',
    query: {
      enabled:
        walletChain?.id === chainId &&
        !!rewardPerSecond &&
        enabled &&
        !!start &&
        !!end,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Set rewarder parameters`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const setRewarderParameters = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    error: error
      ? {
          message: error.message,
          summary: (error as BaseError).shortMessage
        }
      : undefined,
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    setRewarderParameters
  }
}

export default useSetRewarderParameters
