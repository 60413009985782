import useActiveChain from 'hooks/useActiveChain'
import { useCallback, useMemo } from 'react'
import { UserBalancePerVault, Vault } from 'types/vault'
import { getDexbarnChainParam } from 'utils/chains'

import useUserVaultBalances from './useUserVaultBalances'
import useVaults from './useVaults'

interface useVaultWithUserPositionProps {
  enabled: boolean
}

const useVaultWithUserPosition = ({
  enabled
}: useVaultWithUserPositionProps) => {
  const chain = useActiveChain()

  const {
    data: vaults = [],
    isLoading: isLoadingVaults,
    refetch: refetchVaults
  } = useVaults({
    chain: getDexbarnChainParam(chain.id),
    enabled
  })

  const {
    data: userVaultsBalances,
    isLoading: isLoadingUserVaultPositions,
    refetch: refetchUserVaultBalances
  } = useUserVaultBalances({
    enabled,
    vaults
  })

  const userBalancesPerVault = useMemo(
    () =>
      vaults.reduce((acc, { id }, index) => {
        acc[id] = userVaultsBalances?.[index]
        return acc
      }, {} as UserBalancePerVault),
    [vaults, userVaultsBalances]
  )

  const vaultWithUserBalanceAndFarmPositions = useMemo(() => {
    return vaults.map((vault: Vault) => {
      const vaultBalance = userBalancesPerVault[vault?.address]
      const userPositions = { vaultBalance }

      return {
        ...vault,
        userPositions
      }
    })
  }, [userBalancesPerVault, vaults])

  const refetchAll = useCallback(() => {
    refetchVaults()
    refetchUserVaultBalances()
  }, [refetchVaults, refetchUserVaultBalances])

  return {
    data: vaultWithUserBalanceAndFarmPositions,
    isLoadingVaults: isLoadingVaults,
    isLoadingVaultsWithPositions: isLoadingUserVaultPositions,
    refetch: refetchAll
  }
}

export default useVaultWithUserPosition
