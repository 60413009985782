import { Box, HStack, Tag, Text } from '@chakra-ui/react'
import React from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'

const PoolMainAnalyticsChartTooltip = ({
  payload
}: TooltipProps<ValueType, NameType>) => {
  const point = payload && payload.length && payload[0].payload

  return (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      {point?.fmtDate ? (
        <Text color="textPrimary" fontSize="sm" mb={3}>
          {point.fmtDate}
        </Text>
      ) : null}

      <Text color="textSecondary" fontSize="sm">
        {point.title}
      </Text>

      {point?.fmtValue2 ? (
        <HStack mt={1}>
          <Tag
            bg={point.value2Color}
            size="sm"
            color="white"
            fontWeight="semibold"
          >
            v2
          </Tag>
          <Text fontWeight="semibold">{point.fmtValue2}</Text>
        </HStack>
      ) : null}

      {point?.fmtValue ? (
        <HStack mt={1}>
          <Tag
            bg={point.value1Color}
            size="sm"
            color="white"
            fontWeight="semibold"
          >
            v1
          </Tag>
          <Text fontWeight="semibold">{point.fmtValue}</Text>
        </HStack>
      ) : null}
    </Box>
  )
}

export default PoolMainAnalyticsChartTooltip
