export const LiquidityHelperAbi = [
  {
    inputs: [],
    name: 'BinHelper__LiquidityOverflow',
    type: 'error'
  },
  {
    inputs: [],
    name: 'FeesAmounts__LengthMismatch',
    type: 'error'
  },
  {
    inputs: [],
    name: 'SafeCast__Exceeds24Bits',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'x',
        type: 'uint256'
      },
      {
        internalType: 'int256',
        name: 'y',
        type: 'int256'
      }
    ],
    name: 'Uint128x128Math__PowUnderflow',
    type: 'error'
  },
  {
    inputs: [],
    name: 'Uint256x256Math__MulDivOverflow',
    type: 'error'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'previousX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'previousY',
        type: 'uint256[]'
      }
    ],
    name: 'getAmountsAndFeesEarnedOf',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'amountsX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsY',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'feesX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'feesY',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'liquidities',
        type: 'uint256[]'
      }
    ],
    name: 'getAmountsForLiquidities',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'amountsX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsY',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'shares',
        type: 'uint256[]'
      }
    ],
    name: 'getAmountsForShares',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'amountsX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsY',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      }
    ],
    name: 'getAmountsOf',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'amountsX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsY',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'previousLiquidities',
        type: 'uint256[]'
      }
    ],
    name: 'getFeeSharesAndFeesEarnedOf',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'feeShares',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'feesX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'feesY',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsY',
        type: 'uint256[]'
      }
    ],
    name: 'getLiquiditiesForAmounts',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'liquidities',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'shares',
        type: 'uint256[]'
      }
    ],
    name: 'getLiquiditiesForShares',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'liquidities',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      }
    ],
    name: 'getLiquiditiesOf',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'liquidities',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsX',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'amountsY',
        type: 'uint256[]'
      }
    ],
    name: 'getSharesForAmounts',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'shares',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      },
      {
        internalType: 'uint256[]',
        name: 'liquidities',
        type: 'uint256[]'
      }
    ],
    name: 'getSharesForLiquidities',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'shares',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract ILBPair',
        name: 'lbPair',
        type: 'address'
      },
      {
        internalType: 'address',
        name: 'user',
        type: 'address'
      },
      {
        internalType: 'uint256[]',
        name: 'ids',
        type: 'uint256[]'
      }
    ],
    name: 'getSharesOf',
    outputs: [
      {
        internalType: 'uint256[]',
        name: 'balances',
        type: 'uint256[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
] as const
