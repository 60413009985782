import { t } from '@lingui/macro'
import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import { erc721Abi, getAddress } from 'viem'
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWriteContract
} from 'wagmi'

import useChainId from './useChainId'
import useTransactionToast from './useTransactionToast'

interface UseApproveForAllIfNeededProps {
  spender: string
  token?: string
  tokenSymbol?: string
}

const useApproveForAllIfNeeded = ({
  spender,
  token,
  tokenSymbol
}: UseApproveForAllIfNeededProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: isApprovedForAll, isLoading: isLoadingIsApprovedForAll } =
    useReadContract({
      abi: erc721Abi,
      address: token ? getAddress(token) : undefined,
      args: account ? [account, getAddress(spender)] : undefined,
      chainId,
      functionName: 'isApprovedForAll',
      query: { enabled: !!account }
    })

  const { data: config } = useSimulateContract({
    abi: LBPairV21ABI,
    address: token ? getAddress(token) : undefined,
    args: [getAddress(spender), true],
    functionName: 'approveForAll',
    query: { enabled: isApprovedForAll === false, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Approve ${tokenSymbol}`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const approve = config?.request
    ? () => writeContract(config.request)
    : undefined

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransactionReceipt({
    hash
  })

  return {
    approve,
    isApproved: isApprovedForAll === true || receipt?.status === 'success',
    isApproving: isPending || isWaitingForTransaction,
    isLoadingIsApprovedForAll,
    isSuccess
  }
}

export default useApproveForAllIfNeeded
