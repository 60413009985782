import {
  Box,
  Divider,
  Heading,
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import useEthenaStakeParametersByToken from 'hooks/ethena/useEthenaStakeParametersByToken'
import useEthenaUserStakeData from 'hooks/ethena/useEthenaUserStakeData'
import debounce from 'lodash.debounce'
import VaultInfoStats from 'pages/VaultDetail/VaultInfoStats'
import React from 'react'
import { formattedNum } from 'utils/format'
import { GetBalanceData } from 'wagmi/query'

import LockPanel from './LockPanel'
import UnlockPanel from './UnlockPanel'
import WithdrawPanel from './WithdrawPanel'

interface EthenaLPStakingPanelProps {
  token: Token
  userTokenBalance?: GetBalanceData
}

const EthenaLPStakingPanel = (props: EthenaLPStakingPanelProps) => {
  const { data: userStakeData, refetch: refetchUserStakeData } =
    useEthenaUserStakeData({
      lpToken: props.token
    })
  const debouncedRefetchUserStakeData = debounce(refetchUserStakeData, 2000)

  const { data: stakeParameters } = useEthenaStakeParametersByToken({
    lpToken: props.token
  })

  const fmtLockLimit = stakeParameters
    ? `${formattedNum(
        stakeParameters.totalStaked.formatted
      )} APT / ${formattedNum(stakeParameters.stakeLimit.formatted)} APT`
    : `--`

  return (
    <Box py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
      <VStack align="flex-start" spacing={1} pb={4}>
        <Heading size="md">Lock liquidity</Heading>
        <Text fontSize="sm" textColor="textSecondary">
          Earn boosted Ethena Sats by locking your liquidity. Providing
          liquidity to the vault will only earn Sats if locked, and no Sats will
          accrue during unlock period. Locking contract is maintained by Ethena
          Labs.
        </Text>
      </VStack>

      <HStack pb={{ base: 4, md: 6 }} spacing={4}>
        <VaultInfoStats title="Sats" value="30x per day" />
        <VaultInfoStats title="Limit" value={fmtLockLimit} />
      </HStack>

      <Tabs isFitted variant="solid-rounded" w="full">
        <TabList>
          <Tab>
            <Trans>Lock</Trans>
          </Tab>
          <Tab>
            <Trans>Unlock </Trans>
          </Tab>
          <Tab>
            <Trans>Withdraw</Trans>
          </Tab>
        </TabList>
        <Divider />
        <TabPanels mt={4}>
          <TabPanel p={0}>
            <LockPanel
              onLockSuccess={debouncedRefetchUserStakeData}
              stakeLimit={
                stakeParameters
                  ? Number(stakeParameters.stakeLimit.value)
                  : undefined
              }
              totalStaked={
                stakeParameters
                  ? Number(stakeParameters.totalStaked.value)
                  : undefined
              }
              {...props}
            />
          </TabPanel>
          <TabPanel p={0}>
            <UnlockPanel
              userStakedAmount={userStakeData?.stakedAmount}
              onUnlockSuccess={debouncedRefetchUserStakeData}
              {...props}
            />
          </TabPanel>
          <TabPanel p={0}>
            <WithdrawPanel
              userCoolingDownAmount={userStakeData?.coolingDownAmount}
              userCooldownStartTimestamp={userStakeData?.cooldownStartTimestamp}
              onWithdrawSuccess={debouncedRefetchUserStakeData}
              {...props}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default EthenaLPStakingPanel
