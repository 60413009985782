import {
  Box,
  Divider,
  Grid,
  Heading,
  HStack,
  Text,
  VStack
} from '@chakra-ui/react'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import useClaimLbRewards from 'hooks/pool/v2/useClaimLbRewards'
import React from 'react'
import { FormattedLbPoolReward, FormattedReward } from 'types/rewards'
import { formattedNum } from 'utils/format'
import { Hex } from 'viem'

import ClaimRewards from './ClaimRewards'

interface LbRewarderPanelProps {
  currency0: Currency
  currency1: Currency
  hookRewarderAddress: Hex
  inversePriceRatios: boolean
  onClaimSuccess: () => void
  rewards: FormattedLbPoolReward[]
  userClaimableRewards: FormattedReward[]
  userBinIds?: number[]
}

const LbRewarderPanel = ({
  currency0,
  currency1,
  hookRewarderAddress,
  inversePriceRatios,
  onClaimSuccess,
  rewards,
  userBinIds,
  userClaimableRewards
}: LbRewarderPanelProps) => {
  const { claim, isLoading: isClaiming } = useClaimLbRewards({
    enabled: userClaimableRewards && userClaimableRewards.length > 0,
    hookRewarderAddress,
    onSuccess: onClaimSuccess,
    userBinIds: userBinIds || []
  })

  return (
    <Box w="full" bg="bgCard" borderRadius="2xl" boxShadow="element">
      <Box py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
        <Heading size="md">Rewards</Heading>
        <Text fontSize="sm" textColor="textSecondary" mt={1}>
          Earn rewards for providing liquidity within specified price range.{' '}
        </Text>

        <VStack mt={4} align="flex-start" spacing={4}>
          {rewards.map((reward, index) => {
            const isActiveBinOnly = reward.range.start === reward.range.end
            const fmtRewardRange = isActiveBinOnly
              ? '+/- 0% (1 bin)'
              : `-${reward.range.spread.min.toFixed(
                  2
                )}% / +${reward.range.spread.max.toFixed(2)}% (${
                  reward.range.numBins
                } bins)`

            const fmtCurrentPriceRange = inversePriceRatios
              ? `${formattedNum(1 / reward.range.maxPrice)} - ${formattedNum(
                  1 / reward.range.minPrice
                )} ${currency0.symbol} per ${currency1.symbol}`
              : `${formattedNum(reward.range.minPrice)} - ${formattedNum(
                  reward.range.maxPrice
                )} ${currency1.symbol} per ${currency0.symbol}`

            return (
              <Grid
                key={index}
                bg="bgSecondary"
                w="full"
                borderRadius="lg"
                p={4}
                templateColumns={{
                  base: 'minmax(0, 1fr) minmax(0, 1fr)',
                  md: 'minmax(0, 1fr) minmax(0, 2fr)'
                }}
                rowGap={2}
              >
                <Text fontSize="sm" textColor="textSecondary">
                  Token:
                </Text>
                <HStack spacing={1}>
                  <CurrencyLogo
                    address={
                      reward.token.isToken ? reward.token.address : undefined
                    }
                    symbol={reward.token.symbol}
                    boxSize={5}
                  />
                  <Text fontSize="sm" fontWeight="semibold">
                    {reward.token.symbol}
                  </Text>
                </HStack>

                <Text fontSize="sm" textColor="textSecondary">
                  Rewards per day:
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {formattedNum(reward.rewardsPerDay)} {reward.token.symbol}
                </Text>

                <Text fontSize="sm" textColor="textSecondary">
                  Reward range:
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {fmtRewardRange}
                </Text>

                <Text fontSize="sm" textColor="textSecondary">
                  Current range:
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {fmtCurrentPriceRange}
                </Text>

                <Text fontSize="sm" textColor="textSecondary">
                  Range TVL:
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {reward.rangeLiquidityUsd !== undefined
                    ? formattedNum(reward.rangeLiquidityUsd, { usd: true })
                    : '--'}
                </Text>

                <Text fontSize="sm" textColor="textSecondary">
                  Rewards APR:
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {reward.rewardsApr !== undefined
                    ? `${reward.rewardsApr.toFixed(2)}%`
                    : '--'}
                </Text>

                <Text fontSize="sm" textColor="textSecondary">
                  Time remaining:
                </Text>
                <Text fontSize="sm" fontWeight="semibold">
                  {reward.duration || 'N/A'}
                </Text>
              </Grid>
            )
          })}
        </VStack>
      </Box>
      <Divider />
      <ClaimRewards
        claimableRewards={userClaimableRewards}
        isClaiming={isClaiming}
        handleClaimRewards={claim}
      />
    </Box>
  )
}

export default LbRewarderPanel
