import { t } from '@lingui/macro'
import { VeMoeRewarderAbi } from 'constants/abis/VeMoeRewarder'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseStopRewarderProps {
  enabled: boolean
  rewarderAddress: string
  onSuccess?: () => void
}

const useStopRewarder = ({
  enabled,
  onSuccess,
  rewarderAddress
}: UseStopRewarderProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: VeMoeRewarderAbi,
    address: getAddress(rewarderAddress),
    args: [BigInt(0), BigInt(0)],

    chainId,
    functionName: 'setRewardPerSecond',
    query: {
      enabled: enabled && walletChain?.id === chainId,
      gcTime: 0
    }, // stop = setRewardPerSecond(0, 0)
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Stopped rewarder`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const stopRewarder = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    stopRewarder
  }
}

export default useStopRewarder
