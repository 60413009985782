import { HStack, LinkBox, LinkOverlay, Td, Text, Tr } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import { format } from 'date-fns'
import React from 'react'
import { DexbarnUserRewarder } from 'types/dexbarn'
import { shortenAddress } from 'utils/addresses'
import { getFarmPidFromDexbarnFarmId } from 'utils/farm'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface UserRewarderTableRowProps {
  rewarder: DexbarnUserRewarder
}

const UserRewarderTableRow = ({ rewarder }: UserRewarderTableRowProps) => {
  const {
    contractId: rewarderAddress,
    endTimestamp,
    farmId,
    rewardsPerSecond,
    startTimestamp
  } = rewarder
  const farmPid = getFarmPidFromDexbarnFarmId(farmId)

  const fmtStartDate =
    startTimestamp > 0 ? format(startTimestamp * 1000, 'PPpp') : '--'
  const fmtEndDate =
    endTimestamp > 0 ? format(endTimestamp * 1000, 'PPpp') : '--'
  const fmtRewardsPerDay = formattedNum(
    formatUnits(BigInt(rewardsPerSecond) * BigInt(60 * 60 * 24), 18)
  )

  return (
    <LinkBox
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      boxShadow="element"
      borderRadius="10px"
    >
      <Td borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
        <LinkOverlay
          aria-label={`Link to manage rewarder ${rewarderAddress}`}
          href={`/vote/${farmPid}/rewarder/${rewarderAddress}`}
        />
        <HStack>
          <DoubleCurrencyLogo
            address0={rewarder.pairTokenXId}
            address1={rewarder.pairTokenYId}
            symbol0={rewarder.pairTokenXSymbol}
            symbol1={rewarder.pairTokenYSymbol}
            boxSize={6}
          />
          <Text>{`${rewarder.pairTokenXSymbol} / ${rewarder.pairTokenYSymbol}`}</Text>
        </HStack>
      </Td>
      <Td>{shortenAddress(rewarderAddress)}</Td>
      <Td>{fmtStartDate}</Td>
      <Td>{fmtEndDate}</Td>
      <Td
        textAlign="right"
        borderTopRightRadius="10px"
        borderBottomRightRadius="10px"
      >
        <Trans>
          {fmtRewardsPerDay} {rewarder.bribeTokenSymbol} / day
        </Trans>
      </Td>
    </LinkBox>
  )
}

export default UserRewarderTableRow
