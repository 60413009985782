import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import useBalances from 'hooks/useBalances'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import { useMemo } from 'react'
import { useSavedTokens } from 'state/tokens/hooks'
import { TokenInfo, TokenInfoTag } from 'types/tokensList'
import { formatUnits, getAddress } from 'viem'

import useFetchTokensList from './useFetchTokensList'
import useNativeTokenInfo from './useNativeTokenInfo'
import useTokenSearch from './useTokenSearch'

interface UseTokensListProps {
  activeTag: TokenInfoTag
  enabled?: boolean
  includesSavedTokens?: boolean
  query?: string
}

const useTokensList = ({
  activeTag,
  enabled = true,
  includesSavedTokens = true,
  query
}: UseTokensListProps) => {
  const chainId = useChainId()
  const { data, isLoading: isLoadingTokensList } = useFetchTokensList({
    enabled
  })
  const savedTokens = useSavedTokens()
  const tokenFoundByAddress = useTokenSearch({ query })
  const tokens = useMemo(() => {
    const remoteTokens =
      data?.tokens.filter((token) => token.chainId === chainId) ?? []

    if (!includesSavedTokens) {
      return remoteTokens
    }

    const localTokens: TokenInfo[] = savedTokens
      .filter((token) => token.chainId === chainId)
      .map((token) => ({
        ...token,
        address: token.address ? getAddress(token.address) : undefined,
        symbol: token.symbol ?? '',
        tags: [TokenInfoTag.MY_TOKENS]
      }))
    return [...remoteTokens, ...localTokens]
  }, [data, chainId, savedTokens, includesSavedTokens])
  const { data: nativeTokenInfo, isFetching: isLoadingNativeTokenInfo } =
    useNativeTokenInfo({ enabled })
  const { data: balances, isFetching: isLoadingBalances } = useBalances({
    enabled: !isLoadingTokensList && enabled,
    erc20Tokens: tokens
      .map((token) => token.address)
      .filter(Boolean) as `0x${string}`[]
  })
  const tokensWithBalance = useMemo(
    (): TokenInfo[] => [
      nativeTokenInfo,
      ...tokens.map((token, i) => {
        const balance = balances?.[i]
        return {
          ...token,
          balance: balance ? formatUnits(balance, token.decimals) : undefined
        }
      })
    ],
    [balances, tokens, nativeTokenInfo]
  )

  const tokenOwnedAddresses = useMemo(() => {
    const addresses = tokensWithBalance
      .filter((token) => token.balance !== undefined)
      .map((token) => token.address)
      .filter((address) => !!address) as `0x${string}`[]

    const wrappedNativeAddress = getAddress(WNATIVE[chainId].address)
    if (!addresses.includes(wrappedNativeAddress)) {
      addresses.push(wrappedNativeAddress)
    }

    return addresses
  }, [tokensWithBalance, chainId])

  const { data: tokensPrices, isFetching: isLoadingPricesUsd } =
    useGetTokensUsdPrice({
      enabled:
        tokenOwnedAddresses.length > 0 &&
        !isLoadingTokensList &&
        !isLoadingBalances &&
        enabled,
      tokenAddresses: tokenOwnedAddresses
    })

  const filteredTokens = useMemo(() => {
    const lowercasedQuery = query?.toLowerCase() ?? ''
    const results = tokensWithBalance
      .map((token) => {
        const isNative = token.symbol === nativeTokenInfo.symbol
        const tokenAddress = isNative ? WNATIVE[chainId].address : token.address
        const tokenPriceUsd = tokenAddress
          ? tokensPrices?.[tokenAddress.toLowerCase()]
          : undefined
        const totalValueUsd =
          tokenPriceUsd && token.balance
            ? tokenPriceUsd * Number(token.balance)
            : undefined
        return {
          ...token,
          tokenPriceUsd,
          totalValueUsd
        }
      })
      .filter((token) =>
        query && query.length > 0
          ? token.symbol.toLowerCase().includes(lowercasedQuery) ||
            token.name.toLowerCase().includes(lowercasedQuery)
          : token.tags.includes(activeTag) || activeTag === TokenInfoTag.ALL
      )
      .sort((a, b) => {
        if (a.totalValueUsd !== undefined && b.totalValueUsd !== undefined) {
          return b.totalValueUsd - a.totalValueUsd
        }
        if (a.totalValueUsd !== undefined) {
          return -1
        }
        if (b.totalValueUsd !== undefined) {
          return 1
        }
        return a.name.localeCompare(b.name)
      })
    return results
  }, [
    tokensWithBalance,
    activeTag,
    query,
    tokensPrices,
    nativeTokenInfo.symbol,
    chainId
  ])

  return {
    isLoading: isLoadingTokensList || isLoadingNativeTokenInfo,
    isLoadingBalances: isLoadingBalances || isLoadingPricesUsd,
    tokenFoundByAddress,
    tokens: filteredTokens
  }
}

export default useTokensList
