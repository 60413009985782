import { VeMoeAbi } from 'constants/abis/VeMoe'
import { VE_MOE_ADDRESS } from 'constants/moe'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseSetBribesProps {
  enabled: boolean
  onSuccess: () => void
  txSummary: string
  bribes?: {
    contractAddress: string
    pid: bigint
  }[]
}

const useSetBribes = ({
  bribes,
  enabled,
  onSuccess,
  txSummary
}: UseSetBribesProps) => {
  const chainId = useChainId()
  const { isConnected } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: VeMoeAbi,
    address: VE_MOE_ADDRESS[chainId],
    args: bribes
      ? [
          bribes.map(({ pid }) => pid),
          bribes.map(({ contractAddress }) => getAddress(contractAddress))
        ]
      : undefined,
    functionName: 'setBribes',
    query: { enabled: isConnected && !!bribes && enabled, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        addRecentTransaction({
          description: txSummary,
          hash
        })
        addTransactionToast({ description: txSummary, hash })
      }
    }
  })

  const setBribes = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isPending || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success',
    setBribes
  }
}

export default useSetBribes
