import { createReducer } from '@reduxjs/toolkit'

import {
  setAreAirdropTermsAccepted,
  setAreTermsAccepted,
  setIsAirdropCitizenDisclaimerAccepted,
  setIsDedicatedRPC,
  setIsRemoveLiquidityInNativeCurrencyEnabled,
  setIsSafeModeEnabled,
  setIsUKDisclaimerHidden,
  setOriginCountryCode,
  setSlippage,
  setTransactionDeadline
} from './actions'

export enum SlippageType {
  LIQUIDITY_AMOUNT_V2 = 'liquidityAmountV2',
  LIQUIDITY_PRICE_V2 = 'liquidityPriceV2',
  SWAP_V2 = 'swapV2',
  V1 = 'v1'
}

type SlippageSettings = { [type in SlippageType]: number }

interface SettingsState {
  readonly areAirdropTermsAccepted: boolean
  readonly isAirdropCitizenDisclaimerAccepted: boolean
  readonly isDedicatedRPC: boolean
  readonly isRemoveLiquidityInNativeCurrencyEnabled: boolean
  readonly isSafeModeEnabled: boolean
  readonly slippageSettings: SlippageSettings
  readonly areTermsAccepted?: boolean
  readonly isUKDisclaimerHidden?: boolean
  readonly originCountryCode?: string
  readonly transactionDeadline?: number
}

export const initialSlippageSettings: SlippageSettings = {
  [SlippageType.V1]: 0.5,
  [SlippageType.SWAP_V2]: 0.05,
  [SlippageType.LIQUIDITY_AMOUNT_V2]: 0.1,
  [SlippageType.LIQUIDITY_PRICE_V2]: 0.5
}

const initialState: SettingsState = {
  areAirdropTermsAccepted: false,
  areTermsAccepted: false,
  isAirdropCitizenDisclaimerAccepted: false,
  isDedicatedRPC: true,
  isRemoveLiquidityInNativeCurrencyEnabled: true,
  isSafeModeEnabled: true,
  slippageSettings: initialSlippageSettings
}

export default createReducer(initialState, (builder) =>
  builder
    .addCase(setSlippage, (state, action) => {
      state.slippageSettings[action.payload.type] = action.payload.value
    })
    .addCase(setTransactionDeadline, (state, action) => {
      state.transactionDeadline = action.payload
    })
    .addCase(setIsSafeModeEnabled, (state, action) => {
      state.isSafeModeEnabled = action.payload
    })
    .addCase(setIsDedicatedRPC, (state, action) => {
      state.isDedicatedRPC = action.payload
    })
    .addCase(setIsRemoveLiquidityInNativeCurrencyEnabled, (state, action) => {
      state.isRemoveLiquidityInNativeCurrencyEnabled = action.payload
    })
    .addCase(setAreTermsAccepted, (state, action) => {
      state.areTermsAccepted = action.payload
    })
    .addCase(setAreAirdropTermsAccepted, (state, action) => {
      state.areAirdropTermsAccepted = action.payload
    })
    .addCase(setIsAirdropCitizenDisclaimerAccepted, (state, action) => {
      state.isAirdropCitizenDisclaimerAccepted = action.payload
    })
    .addCase(setOriginCountryCode, (state, action) => {
      state.originCountryCode = action.payload
    })
    .addCase(setIsUKDisclaimerHidden, (state, action) => {
      state.isUKDisclaimerHidden = action.payload
    })
    .addDefaultCase((state) => {
      if (state.isRemoveLiquidityInNativeCurrencyEnabled === undefined) {
        state.isRemoveLiquidityInNativeCurrencyEnabled =
          initialState.isRemoveLiquidityInNativeCurrencyEnabled
      }
    })
)
