import { HStack, SimpleGrid, Spacer, Text, VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CurrencyLogo from 'components/CurrencyLogo'
import Web3Button from 'components/Web3Button'
import { VE_MOE_ADDRESS } from 'constants/moe'
import { format } from 'date-fns'
import useBatchClaimRewards from 'hooks/farms/useBatchClaimRewards'
import useEmergencyUnsetBribes from 'hooks/farms/useEmergencyUnsetBribes'
import useSetBribes from 'hooks/farms/useSetBribes'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { FarmBribe, RewarderStatus } from 'types/farm'
import { getFarmPidFromDexbarnFarmId } from 'utils/farm'
import { formattedNum } from 'utils/format'
import { scientificNotationToBigInt } from 'utils/numbers'
import { formatUnits, Hex, zeroAddress } from 'viem'

import FarmBribeStatusTag from './FarmBribeStatusTag'

interface FarmBribeRowProps {
  farmBribe: FarmBribe
  onClaimRewardsSuccess: () => void
  onEmergencyUnsetBribeSuccess: () => void
  onSelectBribeSuccess: () => void
  rewardTokenDecimals: number
  bribeTotalVotes?: bigint
  isStopped?: boolean
  userBribeRewarderAddress?: Hex
  userPendingReward?: string
  userVotesOnFarm?: bigint
}

const FarmBribeRow = ({
  bribeTotalVotes,
  farmBribe,
  isStopped,
  onClaimRewardsSuccess,
  onEmergencyUnsetBribeSuccess,
  onSelectBribeSuccess,
  rewardTokenDecimals,
  userBribeRewarderAddress,
  userPendingReward,
  userVotesOnFarm
}: FarmBribeRowProps) => {
  const chainId = useChainId()

  const isUserBribe =
    userBribeRewarderAddress?.toLowerCase() === farmBribe.bribeId.toLowerCase()
  const hasUserVotesOnFarm = userVotesOnFarm ? userVotesOnFarm > 0 : false

  const totalRewardsPerDay = Number(
    formatUnits(
      scientificNotationToBigInt(farmBribe.rewardsPerSecond) *
        BigInt(60 * 60 * 24),
      rewardTokenDecimals
    )
  )

  const totalVotesIncludingUserVotes = isUserBribe
    ? bribeTotalVotes
    : bribeTotalVotes !== undefined
      ? bribeTotalVotes + (userVotesOnFarm || BigInt(0))
      : undefined

  const rewardsPer1mVotes = totalVotesIncludingUserVotes
    ? (totalRewardsPerDay /
        Number(formatUnits(totalVotesIncludingUserVotes, 18))) *
      1_000_000
    : 0

  const userRewardsShare =
    totalVotesIncludingUserVotes && userVotesOnFarm !== undefined
      ? Number(
          (userVotesOnFarm * BigInt(10_000_000)) / totalVotesIncludingUserVotes
        ) / 10_000_000
      : 0

  const userRewardsPerDay = totalRewardsPerDay * userRewardsShare

  // set bribe
  const canOptIn =
    farmBribe.rewarderStatus === RewarderStatus.LIVE ||
    farmBribe.rewarderStatus === RewarderStatus.PRELAUNCH
  const farmPid = BigInt(getFarmPidFromDexbarnFarmId(farmBribe.farmId))
  const { isLoading: isSettingBribes, setBribes } = useSetBribes({
    bribes: [
      {
        contractAddress: farmBribe.bribeId,
        pid: farmPid
      }
    ],
    enabled: !isUserBribe && hasUserVotesOnFarm && canOptIn,
    onSuccess: onSelectBribeSuccess,
    txSummary: t`Opt-in to voting rewards`
  })

  // claim rewards
  const hasPendingRewards =
    userPendingReward && isUserBribe ? Number(userPendingReward) > 0 : false
  const { batchClaimRewards, isLoading: isClaimingRewards } =
    useBatchClaimRewards({
      enabled: isUserBribe && hasPendingRewards && !isStopped,
      onSuccess: onClaimRewardsSuccess,
      pids: [farmPid],
      rewarderAddress: VE_MOE_ADDRESS[chainId]
    })

  // unset bribe
  const { isLoading: isUnsettingBribes, setBribes: unsetBribes } = useSetBribes(
    {
      bribes: [
        {
          contractAddress: zeroAddress,
          pid: farmPid
        }
      ],
      enabled: isUserBribe,
      onSuccess: onSelectBribeSuccess,
      txSummary: t`Opt-out of voting rewards`
    }
  )

  // emergency unset bribes
  const { emergencyUnsetBribes, isLoading: isEmergencyUnsettingBribes } =
    useEmergencyUnsetBribes({
      enabled: isUserBribe,
      onSuccess: onEmergencyUnsetBribeSuccess,
      pids: [farmPid]
    })

  // opt-out
  const onOptOutClicked = () => {
    if (unsetBribes) {
      unsetBribes()
    } else if (emergencyUnsetBribes) {
      emergencyUnsetBribes()
    }
  }

  return (
    <VStack
      p={4}
      borderRadius="10px"
      align="flex-start"
      border="1px solid"
      borderColor={isUserBribe ? 'bgInteractive' : 'transparent'}
      bg={isUserBribe ? 'bgHighlight' : 'bgElement'}
      w="full"
    >
      <HStack w="full">
        <CurrencyLogo
          address={farmBribe.tokenId}
          symbol={farmBribe.tokenSymbol}
          boxSize={7}
        />
        <Text fontWeight="semibold">{farmBribe.tokenSymbol}</Text>

        <Spacer />

        <FarmBribeStatusTag status={farmBribe.rewarderStatus} />
      </HStack>

      <SimpleGrid columns={2} w="full" gap={2} mt={2}>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Start:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          {format(new Date(farmBribe.startTimestamp * 1000), 'PPpp')}
        </Text>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>End:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          {format(new Date(farmBribe.endTimestamp * 1000), 'PPpp')}
        </Text>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Total rewards per day:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          <Trans>
            {formattedNum(totalRewardsPerDay)} {farmBribe.tokenSymbol} per day
          </Trans>
        </Text>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Rewards per 1m votes:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          {formattedNum(rewardsPer1mVotes)} {farmBribe.tokenSymbol} per day
        </Text>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Your share:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          <Trans>
            {formattedNum(userRewardsPerDay)} {farmBribe.tokenSymbol} per day
          </Trans>
        </Text>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Claimable rewards</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          <Trans>
            {userPendingReward && isUserBribe
              ? formattedNum(userPendingReward)
              : '0'}{' '}
            {farmBribe.tokenSymbol}
          </Trans>
        </Text>
      </SimpleGrid>

      {isUserBribe ? (
        <SimpleGrid
          columns={{ base: 1, md: 2 }}
          w="full"
          gap={{ base: 0, md: 4 }}
        >
          <Web3Button
            mt={4}
            w="full"
            h="40px"
            size="sm"
            variant="outline-bold"
            isDisabled={!batchClaimRewards}
            isLoading={isClaimingRewards}
            loadingText={t`Claiming`}
            onClick={batchClaimRewards}
          >
            <Trans>Claim Rewards</Trans>
          </Web3Button>
          <Web3Button
            mt={4}
            w="full"
            h="40px"
            size="sm"
            colorScheme="accent"
            variant="solid"
            isLoading={isEmergencyUnsettingBribes || isUnsettingBribes}
            loadingText={t`Opting-out`}
            onClick={onOptOutClicked}
            bg="accent.500"
            textColor="white"
            borderRadius="10px"
          >
            <Trans>Opt-out</Trans>
          </Web3Button>
        </SimpleGrid>
      ) : canOptIn ? (
        <Web3Button
          mt={4}
          w="full"
          h="40px"
          size="sm"
          colorScheme="accent"
          variant="solid"
          onClick={setBribes}
          isDisabled={!setBribes}
          isLoading={isSettingBribes}
          loadingText={t`Selecting rewards`}
          bg="accent.500"
          textColor="white"
        >
          <Trans>Opt-in</Trans>
        </Web3Button>
      ) : null}
    </VStack>
  )
}

export default FarmBribeRow
