import { LBFactoryV21ABI } from '@traderjoe-xyz/sdk-v2'
import { LB_FACTORY_V22_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface UseGetAllLBPairsProps {
  tokenXAddress?: string
  tokenYAddress?: string
}

const useGetAllLBPairs = ({
  tokenXAddress,
  tokenYAddress
}: UseGetAllLBPairsProps) => {
  const chainId = useChainId()
  return useReadContract({
    abi: LBFactoryV21ABI,
    address: getAddress(LB_FACTORY_V22_ADDRESS[chainId]),
    args:
      tokenXAddress && tokenYAddress
        ? [getAddress(tokenXAddress), getAddress(tokenYAddress)]
        : undefined,
    chainId,
    functionName: 'getAllLBPairs',
    query: { enabled: !!tokenXAddress && !!tokenYAddress }
  })
}

export default useGetAllLBPairs
