import { Text, VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import Web3Button from 'components/Web3Button'
import useSweepRewarder from 'hooks/rewarder/useSweepRewarder'
import React from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface SweepTokensFromRewarderProps {
  isOwner: boolean
  isSelected: boolean
  rewarderAddress: string
  isStopped?: boolean
  maxWithdrawableAmount?: bigint
  onTransferSuccess?: () => void
  rewardToken?: Currency
}

const SweepTokensFromRewarder = ({
  isOwner,
  isSelected,
  isStopped,
  maxWithdrawableAmount,
  onTransferSuccess,
  rewarderAddress,
  rewardToken
}: SweepTokensFromRewarderProps) => {
  const { isLoading: isSweeping, sweepRewarder } = useSweepRewarder({
    enabled: isOwner && isSelected,
    onSuccess: onTransferSuccess,
    rewardToken,
    rewarderAddress
  })

  const fmtMaxWithdrawableAmount =
    maxWithdrawableAmount && rewardToken
      ? formattedNum(formatUnits(maxWithdrawableAmount, rewardToken.decimals))
      : '0'

  return (
    <VStack mt={8} spacing={8} align="flex-start">
      <Text fontSize="sm" textColor="textSecondary">
        <Trans>
          Withdrawable amount: {fmtMaxWithdrawableAmount} {rewardToken?.symbol}
        </Trans>
      </Text>

      {!isStopped ? (
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>
            You can withdraw the excess amount when rewards are active. To
            withdraw the full amount, you must stop the rewarder first.
          </Trans>
        </Text>
      ) : null}

      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="xl"
        w="full"
        isLoading={isSweeping}
        onClick={sweepRewarder}
        loadingText={t`Withdrawing tokens`}
        isDisabled={!sweepRewarder}
      >
        {isOwner ? (
          <Trans>Withdraw tokens</Trans>
        ) : (
          <Trans>You are not the contract owner</Trans>
        )}
      </Web3Button>
    </VStack>
  )
}

export default SweepTokensFromRewarder
