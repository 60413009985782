import { t } from '@lingui/macro'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'

export const vaultsListHeaders = [
  { name: t`Pool Name` },
  {
    isNumeric: true,
    name: t`Liquidity`,
    sortMethod: PoolSortMethod.LIQUIDITY
  },
  {
    isNumeric: true,
    name: t`My funds`,
    sortMethod: PoolSortMethod.MY_FUNDS
  },
  {
    isNumeric: true,
    name: t`Price`,
    tooltipLabel: t`The price of 1000 APT (Auto Pool Tokens)`
  },
  {
    isNumeric: true,
    name: t`Pct Change (24H)`
  },
  {
    isNumeric: true,
    name: t`Fees (24H)`,
    sortMethod: PoolSortMethod.FEE
  }
]
