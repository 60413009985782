import {
  Box,
  Center,
  Flex,
  Heading,
  HStack,
  Image,
  Progress,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import StakeImage from 'assets/moeJourney/stake.png'
import { MOE_JOURNEY_VE_MOE_TIERS } from 'constants/journey'
import React from 'react'
import { formattedNum } from 'utils/format'

interface MoeJourneyVeMoeTierProgressProps {
  userVeMoeBalance: number
}

const MoeJourneyVeMoeTierProgress = ({
  userVeMoeBalance
}: MoeJourneyVeMoeTierProgressProps) => {
  const tiers = MOE_JOURNEY_VE_MOE_TIERS.map((tier) => tier.veMoeAmount).sort(
    (a, b) => a - b
  )

  const findTiers = (balance: number, tiers: number[]) => {
    let currentTier = 0
    let previousTierAmount = 0
    let nextTierAmount = tiers[0]

    for (let i = 0; i < tiers.length; i++) {
      if (balance >= tiers[i]) {
        currentTier = i + 1
        previousTierAmount = tiers[i]
        nextTierAmount = tiers[i + 1] || tiers[i]
      } else {
        break
      }
    }

    return { currentTier, nextTierAmount, previousTierAmount }
  }

  const userBalanceNum = userVeMoeBalance || 0

  const { currentTier, nextTierAmount, previousTierAmount } = findTiers(
    userBalanceNum,
    tiers
  )
  const isAtHighestTier = currentTier === tiers.length

  const progressPercentage = isAtHighestTier
    ? 100
    : ((userBalanceNum - previousTierAmount) /
        (nextTierAmount - previousTierAmount)) *
      100

  return (
    <VStack
      pos="relative"
      bg="graphPurpleDark"
      borderRadius={{ base: '10px', md: '20px' }}
      boxShadow="element"
      p={{ base: 4, md: 6 }}
    >
      <Image src={StakeImage} />

      <VStack spacing={1}>
        <Heading size="lg" textColor="white" fontWeight="bold">
          veMOE Staking Tiers
        </Heading>
        <Text fontSize="sm" textColor="white" opacity={0.8} textAlign="center">
          Stake MOE to begin accruing veMOE over a 7-day period. Unlock tiers
          based on your accrued veMOE balance. Higher tiers unlock more MJT
        </Text>
      </VStack>

      <Spacer />

      <Box pos="relative" w="full" mt={{ base: 6, md: 4 }}>
        <Center
          left={`${progressPercentage}%`}
          right={`-${progressPercentage}%`}
          transform={
            progressPercentage <= 10
              ? 'translateX(0%)'
              : progressPercentage >= 90
                ? 'translateX(-100%)'
                : 'translateX(-50%)'
          }
          bg="deepPurple"
          w="fit-content"
          rounded="full"
          px={2}
          py={1}
          pos="absolute"
          top={{ base: -2, md: -6 }}
        >
          <Text fontSize="sm" color="white">
            {`${formattedNum(userBalanceNum, { places: 0 })} veMOE`}
          </Text>
        </Center>

        <Box
          ml={`calc(${progressPercentage}% - 4px)`}
          mb="-6px"
          width="2px"
          height="40px"
          bg="deepPurple"
        />

        <Progress
          value={progressPercentage}
          colorScheme="purple"
          borderRadius="full"
          sx={{
            '& > div': {
              backgroundColor: 'deepPurple'
            }
          }}
        />

        <HStack justify="space-between" mt={4}>
          <VStack spacing={0} align="flex-start">
            <Text
              fontWeight="bold"
              textColor="white"
            >{`Tier ${currentTier}`}</Text>
            <Text fontSize="sm" textColor="white">{`${formattedNum(
              previousTierAmount
            )} veMOE`}</Text>
          </VStack>

          {!isAtHighestTier && (
            <VStack spacing={0} align="flex-start">
              <Text fontWeight="bold" textColor="white">{`Tier ${
                currentTier + 1
              }`}</Text>
              <Text fontSize="sm" textColor="white">{`${formattedNum(
                nextTierAmount
              )} veMOE`}</Text>
            </VStack>
          )}
        </HStack>

        <Flex justifyContent="space-between" mt={2.5}></Flex>
      </Box>
    </VStack>
  )
}

export default MoeJourneyVeMoeTierProgress
