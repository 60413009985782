import {
  Box,
  Button,
  Heading,
  HStack,
  Image,
  Spacer,
  Text,
  VStack
} from '@chakra-ui/react'
import React from 'react'
import { WarningIcon } from 'theme/icons'

export interface MoeJourneyCardProps {
  actionUrl: string
  buttonTitle: string
  description: string
  heading: string
  imageFallbackSrc: string
  imageSrcSet: string
  badge?: JSX.Element
  disclaimer?: string
}

const MoeJourneyCard = ({
  actionUrl,
  badge,
  buttonTitle,
  description,
  disclaimer,
  heading,
  imageFallbackSrc,
  imageSrcSet
}: MoeJourneyCardProps) => {
  const navigateToActionUrl = () => {
    window.open(actionUrl, '_blank')
  }

  return (
    <Box
      borderRadius={{ base: '10px', md: '20px' }}
      bg="bgCard"
      boxShadow="element"
      p={6}
    >
      <VStack spacing={4} align="stretch" height="full">
        <Image
          srcSet={imageSrcSet}
          fallbackSrc={imageFallbackSrc}
          minH="200px"
          objectFit="cover"
          borderRadius="10px"
          w="full"
        />
        <HStack>
          <Heading size="md">{heading}</Heading>
          {badge && badge}
        </HStack>
        <Text fontSize="sm">{description}</Text>
        {disclaimer ? (
          <HStack
            border="1px solid"
            borderRadius="10px"
            py={2}
            px={3}
            borderColor="red.500"
            spacing={3}
          >
            <WarningIcon boxSize="16px" fill="red.500" />
            <Text textColor="red.500" fontSize="sm">
              {disclaimer}
            </Text>
          </HStack>
        ) : null}
        <Spacer />
        <Button
          alignSelf="stretch"
          variant="primary"
          colorScheme="accent"
          onClick={navigateToActionUrl}
          w="full"
        >
          {buttonTitle}
        </Button>
      </VStack>
    </Box>
  )
}

export default MoeJourneyCard
