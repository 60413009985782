import {
  Box,
  Heading,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import usePoolV1ByTokens from 'hooks/pool/v1/usePoolV1ByTokens'
import React from 'react'
import { Farm } from 'types/farm'
import { formattedNum } from 'utils/format'

interface FarmAnalyticsProps {
  farm: Farm
  isLoadingDexbarnFarm: boolean
}

const FarmAnalytics = ({ farm, isLoadingDexbarnFarm }: FarmAnalyticsProps) => {
  const { data: pool, isLoading: isLoadingPool } = usePoolV1ByTokens({
    tokens: farm
      ? [farm.reserves.token0.token, farm.reserves.token1.token]
      : undefined
  })

  return (
    <Box>
      <Heading size="md" mb={4}>
        <Trans>Farm Analytics</Trans>
      </Heading>

      <SimpleGrid columns={3}>
        <FarmAnalyticsValue
          title={t`Liquidity`}
          value={formattedNum(farm.liquidityUsd, { usd: true })}
          isLoading={isLoadingDexbarnFarm}
        />

        <FarmAnalyticsValue
          title={t`Pool APR`}
          value={
            pool ? `${formattedNum(pool.apr * 100, { places: 2 })}%` : '--'
          }
          isLoading={isLoadingPool}
        />

        <FarmAnalyticsValue
          title={t`Farm APR`}
          value={`${formattedNum(farm.farmApr, { places: 2 })}%`}
          isLoading={isLoadingDexbarnFarm}
        />
      </SimpleGrid>
    </Box>
  )
}

export default FarmAnalytics

const FarmAnalyticsValue = ({
  isLoading,
  title,
  value
}: {
  isLoading: boolean
  title: string
  value: string
}) => {
  return (
    <VStack align="flex-start" spacing={0}>
      <Text textColor="textSecondary" fontSize="sm">
        {title}
      </Text>
      <Skeleton isLoaded={!isLoading}>
        <Text fontSize="sm" fontWeight="semibold">
          {value}
        </Text>
      </Skeleton>
    </VStack>
  )
}
