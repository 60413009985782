import { MerchantMoeChainId } from '../constants/chains'

const getMaxGasLimit = (chainId: number): bigint | undefined => {
  switch (chainId) {
    case MerchantMoeChainId.MANTLE:
      return BigInt(30_000_000)
    case MerchantMoeChainId.FUJI:
      return BigInt(15_000_000)
    default:
      return undefined
  }
}

export const getConfigWithGasLimitIncreased = ({
  config,
  percentageIncrease
}: {
  config: any | undefined
  percentageIncrease: number
}) => {
  if (percentageIncrease < 1) {
    throw Error('Percentage increase must be >= 1%')
  }

  if (config && config.request) {
    let increasedGasLimit =
      config.request.gas !== undefined
        ? (config.request.gas * (BigInt(percentageIncrease) + BigInt(100))) /
          BigInt(100)
        : undefined

    if (config.request.chain?.id) {
      const maxGasLimit = getMaxGasLimit(config.request.chain.id)
      if (maxGasLimit && increasedGasLimit && increasedGasLimit > maxGasLimit) {
        increasedGasLimit = maxGasLimit
      }
    }

    return {
      ...config,
      request: {
        ...config.request,
        gas: increasedGasLimit
      }
    }
  }

  return config
}
