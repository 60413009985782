import { t } from '@lingui/macro'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import { getAddress } from 'viem'
import {
  useAccount,
  useReadContract,
  useSimulateContract,
  useWriteContract
} from 'wagmi'

interface UseVaultQueueWithdrawalProps {
  vaultAddress: string
  basisPointToWithdraw?: number
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultQueueWithdrawal = ({
  basisPointToWithdraw,
  enabled = true,
  onSuccess,
  vaultAddress
}: UseVaultQueueWithdrawalProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const contract = { abi: VaultABI, address: getAddress(vaultAddress) }
  const { data: balance } = useReadContract({
    ...contract,
    args: account ? [account] : undefined,
    chainId,
    functionName: 'balanceOf',
    query: { enabled: !!account }
  })

  const amount = useMemo(() => {
    if (!balance || !basisPointToWithdraw) return undefined
    return (balance * BigInt(basisPointToWithdraw)) / BigInt(10000)
  }, [basisPointToWithdraw, balance])

  const { data: previewAmounts } = useReadContract({
    ...contract,
    args: amount ? [amount] : undefined,
    chainId,
    functionName: 'previewAmounts',
    query: { enabled: enabled && !!amount }
  })

  const { data: config } = useSimulateContract({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: amount && account ? [amount, account] : undefined,
    functionName: 'queueWithdrawal',
    query: {
      enabled:
        enabled && !!account && !!balance && !!amount && amount > BigInt(0),
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Request withdrawal from vault`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const queueWithdrawal = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    balance,
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    previewAmounts,
    queueWithdrawal
  }
}

export default useVaultQueueWithdrawal
