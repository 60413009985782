import { useQueries } from '@tanstack/react-query'
import {
  LBPool as DexbarnLBPool,
  Pool as DexbarnPool,
  PoolQueryParam
} from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV1'
import { convertDexbarnLBPoolToPool } from 'utils/poolV2'

import useChainId from './useChainId'
import useDebounce from './useDebounce'
import { useDexbarnGet } from './useDexbarn'

interface UsePairsSearchProps {
  query: string
  enabled?: boolean
  filterBy?: PoolQueryParam.FilterBy
  orderBy?: PoolQueryParam.OrderBy
  poolVersion?: 'v1' | 'v2' | 'all'
  status?: PoolQueryParam.Status
}

const usePairsSearch = ({
  enabled = true,
  filterBy = '1d',
  orderBy = 'liquidity',
  poolVersion = 'all',
  query,
  status = 'all'
}: UsePairsSearchProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const debouncedQuery = useDebounce(query, 400)
  const terms = debouncedQuery.split(' ')
  let _query = terms[0].trim()

  const isQueryTwoCharacters = _query.length === 2
  if (isQueryTwoCharacters) {
    _query = _query + '-'
  }

  const _enabled = _query.length >= 3 && enabled

  const fetchV1Pairs = useDexbarnGet<DexbarnPool[]>(
    `/v1/pools/search?query=${_query}&chain=${chain}&status=${status}&filterBy=${filterBy}&orderBy=${orderBy}&excludeLowVolumePools=false`
  )

  const fetchLbPairs = useDexbarnGet<DexbarnLBPool[]>(
    `/v1/lb/pools/search?query=${_query}&chain=${chain}&status=${status}&filterBy=${filterBy}&orderBy=${orderBy}&excludeLowVolumePools=false`
  )

  return useQueries({
    combine: (results) => {
      const v1Pools = results[0].data?.map(convertDexbarnPoolToPool) ?? []
      const lbPools = results[1].data?.map(convertDexbarnLBPoolToPool) ?? []

      let pools = [...lbPools, ...v1Pools]

      const term2 = terms.length > 1 ? terms[1].trim().toLowerCase() : undefined
      if (term2) {
        pools = pools.filter(
          (pool) =>
            pool.name.toLowerCase().includes(term2) ||
            pool.name.toLowerCase().includes(term2)
        )
      }

      return {
        data: pools,
        isLoading: results.some((result) => result.isLoading)
      }
    },
    queries: [
      {
        enabled: _enabled && (poolVersion === 'all' || poolVersion === 'v1'),
        queryFn: () => fetchV1Pairs(),
        queryKey: [
          'V1PairsSearchQuery',
          _query,
          filterBy,
          orderBy,
          status,
          poolVersion
        ]
      },
      {
        enabled: _enabled && (poolVersion === 'all' || poolVersion === 'v2'),
        queryFn: () => fetchLbPairs(),
        queryKey: [
          'LBPairsSearchQuery',
          _query,
          filterBy,
          orderBy,
          status,
          poolVersion
        ]
      }
    ]
  })
}

export default usePairsSearch
