import { useCallback } from 'react'
import { Transaction, Transactions } from 'types/transactions'
import { Hex } from 'viem'
import { useAccount, usePublicClient } from 'wagmi'

import useChainId from './useChainId'

const RAINBOW_KIT_RECENT_TRANSACTIONS = 'rk-transactions'

interface RecentTransaction {
  [address: string]: Transactions
}

const useAddRecentTransaction = () => {
  const client = usePublicClient()
  const { address: walletAddress } = useAccount()
  const chainId = useChainId()

  const addTransaction = useCallback(
    (transaction: Omit<Transaction, 'status'>) => {
      if (!walletAddress) {
        return
      }

      const recentTransactions: RecentTransaction = JSON.parse(
        localStorage.getItem(RAINBOW_KIT_RECENT_TRANSACTIONS) || '{}'
      )

      if (!recentTransactions[walletAddress]) {
        recentTransactions[walletAddress] = {}
      }

      if (!recentTransactions[walletAddress][chainId]) {
        recentTransactions[walletAddress][chainId] = []
      }

      recentTransactions[walletAddress][chainId].unshift({
        ...transaction,
        status: 'pending'
      })

      localStorage.setItem(
        RAINBOW_KIT_RECENT_TRANSACTIONS,
        JSON.stringify(recentTransactions)
      )

      client
        ?.waitForTransactionReceipt({ hash: transaction.hash as Hex })
        .then((receipt) => {
          const recentTransactions: RecentTransaction = JSON.parse(
            localStorage.getItem(RAINBOW_KIT_RECENT_TRANSACTIONS) || '{}'
          )

          switch (receipt.status) {
            case 'success': {
              const tx = recentTransactions[walletAddress][chainId].find(
                (tx) => tx.hash === transaction.hash
              )
              if (tx) {
                tx.status = 'confirmed'
                const updatedRecentTransactions = recentTransactions[
                  walletAddress
                ][chainId].filter((tx) => tx.hash !== transaction.hash)
                updatedRecentTransactions.unshift(tx)
                recentTransactions[walletAddress][chainId] =
                  updatedRecentTransactions
              }
              break
            }
            case 'reverted': {
              const tx = recentTransactions[walletAddress][chainId].find(
                (tx) => tx.hash === transaction.hash
              )
              if (tx) {
                tx.status = 'failed'
                const updatedRecentTransactions = recentTransactions[
                  walletAddress
                ][chainId].filter((tx) => tx.hash !== transaction.hash)
                updatedRecentTransactions.unshift(tx)
                recentTransactions[walletAddress][chainId] =
                  updatedRecentTransactions
              }
              break
            }
          }

          localStorage.setItem(
            RAINBOW_KIT_RECENT_TRANSACTIONS,
            JSON.stringify(recentTransactions)
          )
        })
    },
    [walletAddress, chainId, client]
  )

  return addTransaction
}

export default useAddRecentTransaction
