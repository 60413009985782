import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import React from 'react'

import SendTokensToRewarder from './SendTokensToRewarder'
import SweepTokensFromRewarder from './SweepTokensFromRewarder'

interface TransferTokensPanelProps {
  isOwner: boolean
  rewarderAddress: string
  isStopped?: boolean
  maxWithdrawableAmount?: bigint
  onTransferSuccess?: () => void
  rewardToken?: Currency
}

const TransferTokensPanel = ({
  isOwner,
  isStopped,
  maxWithdrawableAmount,
  onTransferSuccess,
  rewarderAddress,
  rewardToken
}: TransferTokensPanelProps) => {
  const [tabIndex, setTabIndex] = React.useState(0)

  return (
    <Flex
      bg="bgCard"
      border="1px solid"
      borderColor="border"
      w="full"
      flexDir="column"
      gap={8}
      p={{ base: 4, md: 8 }}
      borderRadius="2xl"
      boxShadow="element"
    >
      <Heading size="md">Transfer tokens</Heading>

      <Tabs
        variant="solid-rounded"
        isFitted
        w="full"
        onChange={(index) => setTabIndex(index)}
        index={tabIndex}
      >
        <TabList>
          <Tab>
            <Trans>Send</Trans>
          </Tab>
          <Tab>
            <Trans>Withdraw</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <SendTokensToRewarder
              isOwner={isOwner}
              onTransferSuccess={onTransferSuccess}
              rewarderAddress={rewarderAddress}
              rewardToken={rewardToken}
            />
          </TabPanel>

          <TabPanel p={0}>
            <SweepTokensFromRewarder
              isOwner={isOwner}
              onTransferSuccess={onTransferSuccess}
              rewarderAddress={rewarderAddress}
              rewardToken={rewardToken}
              isStopped={isStopped}
              maxWithdrawableAmount={maxWithdrawableAmount}
              isSelected={tabIndex === 1}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  )
}

export default TransferTokensPanel
