import {
  Box,
  Flex,
  Heading,
  Hide,
  HStack,
  Show,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import PageContainer from 'components/PageContainer'
import PageHelmet from 'components/PageHelmet'
import SearchBar from 'components/SearchBar'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePoolSearchParams from 'hooks/pool/usePoolSearchParams'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import usePoolsV1 from 'hooks/pool/v1/usePoolsV1'
import useLbPoolsWithRewards from 'hooks/pool/v2/useLbPoolsWithRewards'
import usePoolsV2 from 'hooks/pool/v2/usePoolsV2'
import useChainId from 'hooks/useChainId'
import usePairsSearch from 'hooks/usePairsSearch'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { LBPool } from 'types/pool'

import CreateNewPoolMenu from './CreateNewPoolMenu'
import PoolMainAnalytics from './PoolMainAnalytics'
import PoolsMobileList from './PoolsMobileList'
import PoolsTable from './PoolsTable'
import UserPositionsTable from './UserPositions/UserPositionsTable'
import Vault from './Vault'

const Pool = () => {
  const location = useLocation()
  const { selectedTab, setSelectedTab } = usePoolSearchParams()
  const chainId = useChainId()

  const [isLargerThanMd] = useMediaQuery(['(min-width: 48em)'])

  const [sortMethod, setSortMethod] = useState<PoolSortMethod>(
    PoolSortMethod.VOLUME
  )

  // fetch pools v1
  const pageSize = 25
  const {
    data: poolsV1,
    fetchNextPage: fetchNextPools,
    hasNextPage: hasNextPagePools,
    isFetchingNextPage: isFetchingNextPoolsPage,
    isLoading: isLoadingPools
  } = usePoolsV1({
    orderBy: sortMethod === PoolSortMethod.LIQUIDITY ? 'liquidity' : 'volume',
    pageSize,
    status: 'all'
  })

  // fetch lb pools
  const {
    data: lbPools,
    fetchNextPage: fetchNextLBPools,
    hasNextPage: hasNextPageLBPools,
    isFetchingNextPage: isFetchingNextLBPoolsPage,
    isLoading: isLoadingLBPools
  } = usePoolsV2({
    excludeLowVolumePools: selectedTab !== 1,
    orderBy: sortMethod === PoolSortMethod.LIQUIDITY ? 'liquidity' : 'volume',
    pageSize,
    status: 'all'
  })

  // load more callback
  const onLoadMoreClick = useCallback(() => {
    fetchNextLBPools()
    fetchNextPools()
  }, [fetchNextLBPools, fetchNextPools])

  // search
  const [query, setQuery] = useState<string>('')
  const { data: searchedPools = [], isLoading: isLoadingSearch } =
    usePairsSearch({
      poolVersion: selectedTab === 1 ? 'v2' : 'all',
      query,
      status: 'all'
    })

  // get lb pools with rewards
  const nonFilteredPools = useMemo(() => {
    if (query.length > 0) {
      return searchedPools
    }
    return lbPools as LBPool[]
  }, [lbPools, searchedPools, query])
  const poolsWithRewards = useLbPoolsWithRewards({ pools: nonFilteredPools })

  // calculate pools to display
  const pools = useMemo(() => {
    if (query.length > 0) {
      return poolsWithRewards
    }

    if (selectedTab === 1) {
      return poolsWithRewards
    }

    return [...poolsWithRewards, ...poolsV1]
  }, [poolsV1, poolsWithRewards, query, selectedTab])

  // loading state
  const isLoadingOrSearchingLB = isLoadingLBPools || isLoadingSearch
  const isLoadingOrSearchingAll = isLoadingPools || isLoadingOrSearchingLB

  // reset query when chain changes
  useEffect(() => {
    setQuery('')
  }, [chainId])

  return (
    <PageContainer>
      <Box>
        <PageHelmet
          title={POOL_HELMET_TITLE}
          description={POOL_HELMET_DESCRIPTION}
          url={location.pathname}
        />
        <Flex align="center" justify="space-between">
          <VStack pb={6} spacing={0} align="flex-start">
            <Heading>
              <Trans>Pool</Trans>
            </Heading>
            <Text color="textSecondary" fontSize="sm">
              <Trans>Provide liquidity and earn fees.</Trans>
            </Text>
          </VStack>
        </Flex>
        <PoolMainAnalytics />
      </Box>
      <Tabs
        variant="solid-rounded"
        index={selectedTab}
        onChange={(index) => setSelectedTab(index)}
        pt="50px"
        isFitted={!isLargerThanMd}
      >
        <Flex
          flexDir={{ base: 'column', md: 'row' }}
          gap={4}
          align={{ base: 'flex-start', md: 'center' }}
          pb={4}
        >
          <HStack
            justify="space-between"
            flexShrink={0}
            w={{ base: 'full', md: 'auto' }}
          >
            <TabList
              bg="bgCard"
              boxShadow="element"
              overflowX="auto"
              whiteSpace="nowrap"
              w={{ base: 'full', md: 'auto' }}
            >
              <Tab>
                <Trans>All Pools</Trans>
              </Tab>
              <Tab>
                <Trans>LB Pools</Trans>
              </Tab>
              <Tab>
                <Trans>Vaults</Trans>
              </Tab>
              <Tab>
                <Trans>My Pools</Trans>
              </Tab>
            </TabList>
          </HStack>
          <Flex w="full" gap={4}>
            <SearchBar
              placeholder={t`Search by name, symbol or address`}
              value={query}
              onValueChange={setQuery}
              size="md"
            />
            {selectedTab === 0 || selectedTab === 1 ? (
              <CreateNewPoolMenu />
            ) : null}
          </Flex>
        </Flex>

        <TabPanels minH="60vh" pb={{ base: 4, md: 24 }}>
          <TabPanel p={0}>
            <Hide below="md">
              <PoolsTable
                isFetchingPage={
                  isFetchingNextPoolsPage || isFetchingNextLBPoolsPage
                }
                pools={pools}
                isLoadingPools={isLoadingOrSearchingAll}
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPagePools || hasNextPageLBPools}
                query={query}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            </Hide>
            <Show below="md">
              <PoolsMobileList
                pools={pools}
                isLoadingPools={isLoadingOrSearchingAll}
                isFetchingPage={
                  isFetchingNextPoolsPage || isFetchingNextLBPoolsPage
                }
                onLoadMoreClick={onLoadMoreClick}
                hasNextPage={hasNextPagePools || hasNextPageLBPools}
                query={query}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            </Show>
          </TabPanel>
          <TabPanel p={0}>
            <Hide below="md">
              <PoolsTable
                isFetchingPage={isFetchingNextLBPoolsPage}
                pools={pools}
                isLoadingPools={isLoadingOrSearchingLB}
                onLoadMoreClick={fetchNextLBPools}
                hasNextPage={hasNextPageLBPools}
                query={query}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            </Hide>
            <Show below="md">
              <PoolsMobileList
                pools={pools}
                isLoadingPools={isLoadingOrSearchingLB}
                isFetchingPage={isFetchingNextLBPoolsPage}
                onLoadMoreClick={fetchNextLBPools}
                hasNextPage={hasNextPageLBPools}
                query={query}
                sortMethod={sortMethod}
                setSortMethod={setSortMethod}
              />
            </Show>
          </TabPanel>
          <TabPanel p={0}>
            <Vault isTabSelected={selectedTab === 2} query={query} />
          </TabPanel>
          <TabPanel p={0}>
            <UserPositionsTable
              isTabSelected={selectedTab === 3}
              query={query}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  )
}

export default Pool
