import { CNATIVE, Currency } from '@traderjoe-xyz/sdk-core'
import { MerchantMoeChainId } from 'constants/chains'
import { mETH, USDCe, USDTe } from 'utils/swap'

export const CREATE_POOL_BIN_STEPS = [
  { baseFee: 25, binStep: 25, isDisabled: false },
  { baseFee: 40, binStep: 50, isDisabled: false },
  { baseFee: 80, binStep: 100, isDisabled: false }
]

export const CREATE_POOL_QUOTE_TOKENS_PER_CHAIN: {
  [chainId in MerchantMoeChainId]: Currency[]
} = {
  [MerchantMoeChainId.MANTLE]: [
    CNATIVE.onChain(MerchantMoeChainId.MANTLE),
    mETH[MerchantMoeChainId.MANTLE],
    USDTe[MerchantMoeChainId.MANTLE]
  ],
  [MerchantMoeChainId.FUJI]: [
    CNATIVE.onChain(MerchantMoeChainId.FUJI),
    USDCe[MerchantMoeChainId.FUJI],
    USDTe[MerchantMoeChainId.FUJI]
  ]
}
