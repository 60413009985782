import { useQuery } from '@tanstack/react-query'
import { MoeHelperAbi } from 'constants/abis/MoeHelper'
import { MOE_HELPER_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserPoolPosition as DexbarnUserPoolPosition } from 'types/dexbarn'
import { UserPoolPosition } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { getUserStakedAmounts } from 'utils/farm'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

import useUserFarmPositions from './useUserFarmPositions'

interface UseUserPoolV1PositionsProps {
  enabled: boolean
}

const useUserPoolV1Positions = ({ enabled }: UseUserPoolV1PositionsProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  // fetch user pool positons
  const fetchPoolPositions = useDexbarnGet<DexbarnUserPoolPosition[]>(
    `/v1/user/pool-positions/${account}/${getDexbarnChainParam(chainId)}`
  )
  const { data } = useQuery({
    enabled: !!account && enabled,
    queryFn: () => fetchPoolPositions(),
    queryKey: ['UserPoolPositionsQuery', account, chainId]
  })

  // fetch user farm positions
  const { data: userLensFarms } = useUserFarmPositions({
    enabled
  })

  // get user pool positions amounts
  return useReadContract({
    abi: MoeHelperAbi,
    address: MOE_HELPER_ADDRESS[chainId],
    args:
      data && data.length > 0 && account
        ? [data.map((position) => getAddress(position.pairId)), account]
        : undefined,
    chainId,
    functionName: 'getPositionsOf',
    query: {
      enabled: !!account && enabled && data && data.length > 0,
      select: (positionsOf) => {
        if (!data) return []

        const positions: UserPoolPosition[] = data.map((position, i) => {
          const userFarm = userLensFarms?.find(
            (farm) =>
              farm?.lpToken.token.toLowerCase() ===
              position.pairId.toLowerCase()
          )
          const userFarmStakedAmounts = userFarm
            ? getUserStakedAmounts(userFarm)
            : undefined

          const amountX = positionsOf?.[i]?.amount0 ?? BigInt(0)
          const amountY = positionsOf?.[i]?.amount1 ?? BigInt(0)

          return {
            ...position,
            amountX: {
              formatted: formatUnits(amountX, position.tokenX.decimals),
              value: amountX
            },
            amountY: {
              formatted: formatUnits(amountY, position.tokenY.decimals),
              value: amountY
            },
            farmStakedAmounts: userFarmStakedAmounts
              ? {
                  amountX: userFarmStakedAmounts[0],
                  amountY: userFarmStakedAmounts[1]
                }
              : {
                  amountX: 0,
                  amountY: 0
                }
          }
        })
        return positions
      }
    }
  })
}

export default useUserPoolV1Positions
