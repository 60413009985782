import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import {
  joeAirdrop,
  joeFarms,
  joeHome,
  joePool,
  joeStake,
  joeTrade,
  joeVote,
  SubmenuItem
} from 'constants/urls'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { HamburgerIcon } from 'theme/icons'

const MobileNavigationMenu = () => {
  const { isOpen, onClose, onOpen } = useDisclosure()
  const navigate = useNavigate()
  const submenuItems: SubmenuItem[] = [
    joeHome,
    joeTrade,
    joePool,
    joeFarms,
    joeStake,
    joeVote,
    joeAirdrop
  ]

  return (
    <>
      <Button
        aria-label="Links to other pages"
        variant="ghost"
        color="textPrimary"
        _hover={{ bg: 'bgSecondary' }}
        _active={{ bg: 'bgSecondary', color: 'textPrimary' }}
        onClick={onOpen}
      >
        <HamburgerIcon boxSize={6} fill="textPrimary" />
      </Button>
      <Drawer placement="bottom" onClose={onClose} isOpen={isOpen}>
        <DrawerOverlay />
        <DrawerContent
          borderTopLeftRadius={24}
          borderTopRightRadius={24}
          bg="bgPrimary"
        >
          <DrawerCloseButton />
          <DrawerBody>
            <Flex
              gap={4}
              w="full"
              align="center"
              flexDir="column"
              justifyContent="center"
              p={4}
            >
              {submenuItems.map((item, i) => {
                return item.external ? (
                  <Flex
                    _hover={{ cursor: 'pointer' }}
                    key={i}
                    align="center"
                    justifyContent="center"
                    w="full"
                    h={10}
                    onClick={() => window.open(item.url, '_blank')}
                  >
                    <Flex
                      w="full"
                      gap={2}
                      justifyContent="center"
                      align="center"
                    >
                      <Text textAlign="center" fontWeight="semibold">
                        {item.name}
                      </Text>
                      <ExternalLinkIcon />
                    </Flex>
                  </Flex>
                ) : (
                  <Flex
                    key={i}
                    w="full"
                    h={10}
                    justifyContent="center"
                    align="center"
                    _hover={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate(`/${item.url}`)
                      onClose()
                    }}
                  >
                    <Text textAlign="center" w="full" fontWeight="semibold">
                      {item.name}
                    </Text>
                  </Flex>
                )
              })}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default MobileNavigationMenu
