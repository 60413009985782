import { SimpleGrid, Tag } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import MoeJourneyCard1_1x from 'assets/moeJourney/moe-journey-card-1_1x.webp'
import MoeJourneyCard1_2x from 'assets/moeJourney/moe-journey-card-1_2x.webp'
import MoeJourneyCard1_3x from 'assets/moeJourney/moe-journey-card-1_3x.webp'
import MoeJourneyCard2_1x from 'assets/moeJourney/moe-journey-card-2_1x.webp'
import MoeJourneyCard2_2x from 'assets/moeJourney/moe-journey-card-2_2x.webp'
import MoeJourneyCard2_3x from 'assets/moeJourney/moe-journey-card-2_3x.webp'
import MoeJourneyCard3_1x from 'assets/moeJourney/moe-journey-card-3_1x.webp'
import MoeJourneyCard3_2x from 'assets/moeJourney/moe-journey-card-3_2x.webp'
import MoeJourneyCard3_3x from 'assets/moeJourney/moe-journey-card-3_3x.webp'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'

import MoeJourneyCard, { MoeJourneyCardProps } from './MoeJourneyCard'

const MoeJourneyCards = ({ hasDiscordRole }: { hasDiscordRole: boolean }) => {
  const moeJourneyCards: MoeJourneyCardProps[] = [
    {
      actionUrl: 'https://merchantmoe.com/stake',
      buttonTitle: t`Stake MOE`,
      description: t`Stake MOE and collect Moe Journey Tickets based on your veMOE balance. The more veMOE you have, the more tickets you will unlock. Boost your tickets further with MJT Bonuses.`,
      heading: t`1. Stake MOE`,
      imageFallbackSrc: MoeJourneyCard1_3x,
      imageSrcSet: `${MoeJourneyCard1_1x} 1x, ${MoeJourneyCard1_2x} 2x, ${MoeJourneyCard1_3x} 3x`
    },
    {
      actionUrl: 'https://discord.com/invite/merchantmoe',
      buttonTitle: t`Join Our Discord`,
      description: t`Earn bonus Moe Journey Tickets by engaging with the community and participating in hosted events.`,
      heading: t`2. Join our Community`,
      imageFallbackSrc: MoeJourneyCard2_3x,
      imageSrcSet: `${MoeJourneyCard2_1x} 1x, ${MoeJourneyCard2_2x} 2x, ${MoeJourneyCard2_3x} 3x`
    },
    {
      actionUrl: 'https://guild.xyz/merchant-moe/moe-journey-tickets#!',
      badge: hasDiscordRole ? (
        <Tag fontWeight="semibold" colorScheme="green">
          Role Claimed
        </Tag>
      ) : (
        <TouchFriendlyTooltip label="Users must  claim a role on Guild to participate and be elibigle for rewards.">
          <Tag fontWeight="semibold" colorScheme="red">
            Not Claimed
          </Tag>
        </TouchFriendlyTooltip>
      ),
      buttonTitle: t`Connect to Guild.xyz`,
      description: t`Connect to the Merchant Moe Guild Community to gain access to this campaign. You MUST be connected on Guild to be included in reward draws.`,
      heading: t`3. Unlock Community Roles`,
      imageFallbackSrc: MoeJourneyCard3_3x,
      imageSrcSet: `${MoeJourneyCard3_1x} 1x, ${MoeJourneyCard3_2x} 2x, ${MoeJourneyCard3_3x} 3x`
    }
  ]

  return (
    <SimpleGrid columns={{ base: 1, lg: 3, md: 2 }} gap={6} mt={2}>
      {moeJourneyCards.map((card, index) => (
        <MoeJourneyCard key={index} {...card} />
      ))}
    </SimpleGrid>
  )
}

export default MoeJourneyCards
