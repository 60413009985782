import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseLBPairFeeParametersProps {
  lbPairAddress?: string
}

const useLBPairFeeParameters = ({
  lbPairAddress
}: UseLBPairFeeParametersProps) => {
  const chainId = useChainId()

  const contractV21 = {
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId
  }
  const { data: reads, isLoading } = useReadContracts({
    contracts: [
      {
        ...contractV21,
        functionName: 'getStaticFeeParameters'
      },
      { ...contractV21, functionName: 'getBinStep' }
    ],
    query: { enabled: !!lbPairAddress }
  })
  const binStep = reads?.[1].result as number | undefined
  const feeParameters = reads?.[0]?.result as unknown[] | undefined
  const baseFactor = feeParameters?.[0] as number | undefined
  const baseFee =
    binStep && baseFactor ? (binStep * baseFactor) / 10000 : undefined

  return {
    data: {
      baseFactor,
      baseFee,
      binStep
    },
    isLoading
  }
}

export default useLBPairFeeParameters
