import { MoeLensAbi } from 'constants/abis/MoeLens'
import { MOE_LENS_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { zeroAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseGetLensFarmsProps {
  blockNumber?: bigint
  enabled?: boolean
}

const useGetLensFarms = ({
  blockNumber,
  enabled = true
}: UseGetLensFarmsProps = {}) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  const start = BigInt(0)
  const pageSize = BigInt(100)

  return useReadContract({
    abi: MoeLensAbi,
    address: MOE_LENS_ADDRESS[chainId],
    args: [start, pageSize, account || zeroAddress],
    blockNumber,
    chainId,
    functionName: 'getFarmData',
    query: { enabled }
  })
}

export default useGetLensFarms
