import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import { MOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import React from 'react'

interface StakedMoeBalancePanelProps {
  moeDepositedAmount: string
}

const StakedMoeBalancePanel = ({
  moeDepositedAmount
}: StakedMoeBalancePanelProps) => {
  const chainId = useChainId()
  const moeToken = MOE_TOKEN[chainId]

  return (
    <CurrencyAmountsPanel
      boxProps={{
        bg: 'bgCard',
        borderRadius: { base: '10px', md: '20px' },
        boxShadow: 'element'
      }}
      title={t`Staked Balance`}
      tokens={[moeToken.address]}
      tokenAmounts={[Number(moeDepositedAmount)]}
      tokenSymbols={[moeToken.symbol]}
    />
  )
}

export default StakedMoeBalancePanel
