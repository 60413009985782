import {
  Box,
  HStack,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import BinanceWalletIcon from 'assets/binanceWallet.svg'
import CoinbaseWalletIcon from 'assets/coinbaseWallet.svg'
import InjectedIcon from 'assets/injectedWallet.svg'
import WalletConnectIcon from 'assets/walletConnectWallet.svg'
import React from 'react'
import { useAreTermsAccepted } from 'state/settings/hooks'
import { useAccount, useConnect } from 'wagmi'

import Disclaimer from './Disclaimer'

interface ConnectModalProps {
  isOpen: boolean
  onClose: () => void
  onOpen: () => void
}

const ConnectModal = ({ isOpen, onClose }: ConnectModalProps) => {
  const account = useAccount()

  const [selectedConnectorUid, setSelectedConnectorUid] = React.useState<
    string | undefined
  >(undefined)
  const { connect, connectors } = useConnect({
    mutation: { onSuccess: onClose }
  })

  const { areTermsAccepted } = useAreTermsAccepted()

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay zIndex="calc(var(--wcm-z-index) - 1)" />
      <ModalContent
        containerProps={{
          zIndex: 'calc(var(--wcm-z-index) - 1)'
        }}
        position="fixed"
        bottom={{ base: '0', md: 'auto' }}
        margin={{ base: 0, md: 'auto' }}
        width="100%"
        borderBottomRadius={{ base: 0, md: '2xl' }}
      >
        <ModalHeader>Connect Wallet</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack>
            {connectors.toReversed().map((connector) => {
              const isCoinbase = connector.type === 'coinbaseWallet'
              const isWalletConnect = connector.type === 'walletConnect'
              const isBinance = connector.type === 'binanceWallet'
              const isInjected = connector.type === 'injected'

              const iconFallbackSrc = isCoinbase
                ? CoinbaseWalletIcon
                : isBinance
                  ? BinanceWalletIcon
                  : isWalletConnect
                    ? WalletConnectIcon
                    : isInjected
                      ? InjectedIcon
                      : undefined

              const isDisabled =
                !areTermsAccepted ||
                (account.isConnecting && selectedConnectorUid !== connector.uid)
              const isConnecting =
                account.isConnecting && selectedConnectorUid === connector.uid

              return (
                <Box
                  key={connector.uid}
                  p={4}
                  w="full"
                  bg="bgCard"
                  borderRadius="lg"
                  cursor="pointer"
                  pointerEvents={isDisabled && !isConnecting ? 'none' : 'auto'}
                  opacity={!isDisabled ? 1 : 0.5}
                  _hover={{ bg: 'bgHighlight' }}
                  onClick={() => {
                    connect({ connector })
                    setSelectedConnectorUid(connector.uid)

                    if (connector.id === 'walletConnect') {
                      onClose()
                    }
                  }}
                >
                  <HStack justify="flex-start" w="full" spacing={4}>
                    <Image
                      src={connector.icon || iconFallbackSrc}
                      boxSize={8}
                      borderRadius="6px"
                    />
                    <Text fontWeight="semibold">{connector.name}</Text>

                    <Spacer />

                    {isConnecting ? <Spinner size="sm" /> : null}
                  </HStack>
                </Box>
              )
            })}
          </VStack>
        </ModalBody>

        <ModalFooter>
          <Disclaimer />
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConnectModal
