import { MOE_STAKING } from 'constants/moe'
import { MOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import { formatUnits, parseAbi } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

const useGetUserMoeDepositedAmount = () => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const moeToken = MOE_TOKEN[chainId]

  return useReadContract({
    abi: parseAbi([
      'function getDeposit(address account) external view returns (uint256)'
    ]),
    address: MOE_STAKING[chainId],
    args: account ? [account] : undefined,
    chainId,
    functionName: 'getDeposit',
    query: {
      enabled: !!account,
      select: (data) => {
        return {
          formatted: formatUnits(data, moeToken.decimals),
          value: data
        }
      }
    }
  })
}

export default useGetUserMoeDepositedAmount
