import { Center, Flex, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { RemoveLiquidityV2Option } from 'hooks/pool/v2/useRemoveLiquidityV2'
import React, { useEffect, useState } from 'react'
import { LBPairUserBalances } from 'types/poolV2'

import RemoveLiquidityDetails from './RemoveLiquidityDetails'
import RemoveLiquidityOptions from './RemoveLiquidityOptions'

interface RemoveLiquidityPanelV2Props {
  currency0: Currency
  currency1: Currency
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  lbPairAddress: string
  onRemoveLiquidityConfigError: () => void
  onRemoveLiquiditySuccess: () => void
  activeBinId?: number
  binStep?: string
  inversePriceRatios?: boolean
  userBalances?: LBPairUserBalances
}

const RemoveLiquidityPanelV2 = (props: RemoveLiquidityPanelV2Props) => {
  const {
    binStep,
    currency0,
    currency1,
    currencyPrice0,
    currencyPrice1,
    inversePriceRatios,
    userBalances
  } = props
  const userPositions = userBalances?.positions

  const [range, setRange] = useState<number[]>([])

  const [removeLiquidityOption, setRemoveLiquidityOption] =
    useState<RemoveLiquidityV2Option>(RemoveLiquidityV2Option.BOTH_TOKENS)

  useEffect(() => {
    if (range.length > 0 || !userPositions || userPositions.length === 0) return
    const fullRange = [
      userPositions[0],
      userPositions[userPositions.length - 1]
    ]
    setRange(fullRange)
  }, [range, userPositions])

  const hasUserLiquidityInPool = userPositions && userPositions.length > 0
  if (!hasUserLiquidityInPool || range.length < 2) {
    return (
      <Center h="160px">
        <Text color="textSecondary" fontSize="sm">
          <Trans>You have no liquidity in this pool</Trans>
        </Text>
      </Center>
    )
  }

  if (!binStep) {
    return null
  }

  return (
    <Flex flexDir="column" gap={4}>
      <RemoveLiquidityOptions
        currencyPrice0={currencyPrice0}
        currencyPrice1={currencyPrice1}
        currency0={currency0}
        currency1={currency1}
        binStep={Number(binStep)}
        userPositions={userPositions}
        range={range}
        onRangeChange={setRange}
        onRemoveLiquidityOptionChange={setRemoveLiquidityOption}
        inversePriceRatios={inversePriceRatios}
      />
      <RemoveLiquidityDetails
        range={range}
        removeOption={removeLiquidityOption}
        {...props}
      />
    </Flex>
  )
}

export default RemoveLiquidityPanelV2
