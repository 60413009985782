import {
  ChainId,
  Currency,
  CurrencyAmount,
  Percent,
  Token,
  TokenAmount,
  WNATIVE
} from '@traderjoe-xyz/sdk-core'
import { PoolVersion, RouterPathParameters } from '@traderjoe-xyz/sdk-v2'
import JSBI from 'jsbi'
import { TradeBestPath } from 'types/trade'
import { getAddress, parseUnits } from 'viem'

import { MerchantMoeChainId } from '../constants/chains'

type ChainTokenList = {
  readonly [chainId in MerchantMoeChainId]: Token[]
}

export const USDTe = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xAb231A5744C8E6c45481754928cCfFFFD4aa0732',
    6,
    'USDT.e',
    'Bridged USDT'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
    6,
    'USDT',
    'Tether USD'
  )
}

export const USDCe = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0x3b3A66124Db1d4dFaEbD1A537740dbC0bb9A5181',
    6,
    'USDC.e',
    'Bridgec USDC'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    6,
    'USDC',
    'USD Coin'
  )
}

export const WBTC = {
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2',
    8,
    'WBTC',
    'Wrapped BTC'
  )
}

export const WETH = {
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
    18,
    'WETH',
    'Wrapped ETH'
  )
}

export const mETH = {
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0xcDA86A272531e8640cD7F1a92c01839911B90bb0',
    18,
    'mETH',
    'mETH'
  )
}

export const WNATIVE_ONLY: ChainTokenList = {
  [MerchantMoeChainId.FUJI]: [WNATIVE[MerchantMoeChainId.FUJI]],
  [MerchantMoeChainId.MANTLE]: [WNATIVE[MerchantMoeChainId.MANTLE]]
}

export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  [MerchantMoeChainId.MANTLE]: [
    ...WNATIVE_ONLY[MerchantMoeChainId.MANTLE],
    USDCe[MerchantMoeChainId.MANTLE],
    USDTe[MerchantMoeChainId.MANTLE],
    WETH[MerchantMoeChainId.MANTLE]
  ],
  [MerchantMoeChainId.FUJI]: [
    ...WNATIVE_ONLY[MerchantMoeChainId.FUJI],
    USDCe[MerchantMoeChainId.FUJI],
    USDTe[MerchantMoeChainId.FUJI]
  ]
}

export const tryParseAmount = (
  value?: string,
  currency?: Currency,
  parseZero?: boolean
): CurrencyAmount | undefined => {
  if (!value || !currency) {
    return undefined
  }
  try {
    const typedValueParsed = parseUnits(
      value as `${number}`,
      currency.decimals
    ).toString()
    if (typedValueParsed !== '0' || parseZero) {
      return currency instanceof Token
        ? new TokenAmount(currency, JSBI.BigInt(typedValueParsed))
        : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(typedValueParsed))
    }
  } catch (error) {
    // should fail if the user specifies too many decimal places of precision (or maybe exceed max uint?)
    console.error(`Failed to parse input amount: "${value}"`, error)
  }
  // necessary for all paths to return a value
  return undefined
}

export const getCurrencyAmount = (currency?: Currency, amount?: bigint) => {
  if (amount === undefined || !currency) return undefined
  return currency instanceof Token
    ? new TokenAmount(currency, JSBI.BigInt(amount.toString()))
    : CurrencyAmount.ether(currency.chainId, JSBI.BigInt(amount.toString()))
}

export const getSwapCallParameters = ({
  allowedSlippage,
  currencyIn,
  currencyOut,
  deadline,
  isExactIn,
  recipient,
  trade,
  useFeeOnTransfer
}: {
  allowedSlippage: number
  currencyIn: Currency
  currencyOut: Currency
  deadline: number
  isExactIn: boolean
  recipient: string
  trade: TradeBestPath
  useFeeOnTransfer: boolean
}) => {
  const isNativeIn = currencyIn.isNative
  const isNativeOut = currencyOut.isNative

  if (isNativeIn && isNativeOut) {
    throw new Error('ETHER_IN_OUT')
  }

  const to = getAddress(recipient)
  const deadlineHex = `0x${deadline.toString(16)}`

  const tokenPath: string[] = [
    getAddress(trade.path[0].tokenInId),
    getAddress(trade.path[0].tokenOutId)
  ]
  const versions: PoolVersion[] = [trade.path[0].version]
  trade.path.slice(1).forEach((pair) => {
    versions.push(pair.version)
    tokenPath.push(
      tokenPath[tokenPath.length - 1] === getAddress(pair.tokenInId)
        ? getAddress(pair.tokenOutId)
        : getAddress(pair.tokenInId)
    )
  })

  const pairBinSteps = trade.path.map(
    ({ binStep }) => '0x' + binStep.toString(16)
  )

  const path: RouterPathParameters = {
    pairBinSteps,
    tokenPath,
    versions
  }

  let amountIn: bigint
  let amountOut: bigint

  if (isExactIn) {
    amountIn = trade.amountIn.value
    amountOut =
      trade.amountOut.value -
      (trade.amountOut.value * BigInt(allowedSlippage)) / BigInt(10_000)
  } else {
    amountIn =
      trade.amountIn.value +
      (trade.amountIn.value * BigInt(allowedSlippage)) / BigInt(10_000)
    amountOut = trade.amountOut.value
  }

  let methodName: string
  let args: (string | string[] | bigint | RouterPathParameters)[]
  let value: bigint

  if (isExactIn) {
    if (isNativeIn) {
      methodName = useFeeOnTransfer
        ? 'swapExactNATIVEForTokensSupportingFeeOnTransferTokens'
        : 'swapExactNATIVEForTokens'
      args = [amountOut.toString(), path, to, deadlineHex]
      value = amountIn
    } else if (isNativeOut) {
      methodName = useFeeOnTransfer
        ? 'swapExactTokensForNATIVESupportingFeeOnTransferTokens'
        : 'swapExactTokensForNATIVE'
      args = [amountIn, amountOut, path, to, deadlineHex]
      value = BigInt(0)
    } else {
      methodName = useFeeOnTransfer
        ? 'swapExactTokensForTokensSupportingFeeOnTransferTokens'
        : 'swapExactTokensForTokens'
      args = [amountIn, amountOut, path, to, deadlineHex]
      value = BigInt(0)
    }
  } else {
    if (isNativeIn) {
      methodName = 'swapNATIVEForExactTokens'
      args = [amountOut, path, to, deadlineHex]
      value = amountIn
    } else if (isNativeOut) {
      methodName = 'swapTokensForExactNATIVE'
      args = [amountOut, amountIn, path, to, deadlineHex]
      value = BigInt(0)
    } else {
      methodName = 'swapTokensForExactTokens'
      args = [amountOut, amountIn, path, to, deadlineHex]
      value = BigInt(0)
    }
  }

  return {
    args,
    methodName,
    value
  }
}

export const computePriceImpact = ({
  amounts,
  isExactIn,
  tokenIn,
  tokenOut,
  virtualAmounts
}: {
  amounts: readonly bigint[]
  isExactIn: boolean
  tokenIn: Token
  tokenOut: Token
  virtualAmounts: readonly bigint[]
}) => {
  if (isExactIn) {
    const outputAmount = new TokenAmount(
      tokenOut,
      JSBI.BigInt(amounts[amounts.length - 1].toString())
    )
    const exactQuoteStr = virtualAmounts[virtualAmounts.length - 1].toString()
    const exactQuote = new TokenAmount(tokenOut, JSBI.BigInt(exactQuoteStr))
    const slippage = exactQuote.subtract(outputAmount).divide(exactQuote)
    return new Percent(slippage.numerator, slippage.denominator)
  } else {
    const inputAmount = new TokenAmount(
      tokenIn,
      JSBI.BigInt(amounts[0].toString())
    )
    const exactQuoteStr = virtualAmounts[0].toString()
    const exactQuote = new TokenAmount(tokenIn, JSBI.BigInt(exactQuoteStr))
    const slippage = inputAmount.subtract(exactQuote).divide(inputAmount)
    return new Percent(slippage.numerator, slippage.denominator)
  }
}
