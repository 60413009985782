import {
  HStack,
  IconButton,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Skeleton,
  Text
} from '@chakra-ui/react'
import { CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import { MerchantMoeChainId } from 'constants/chains'
import useTokensList from 'hooks/tokensList/useTokensList'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { TokenInfoTag } from 'types/tokensList'
import { USDCe, USDTe, WBTC, WETH } from 'utils/swap'

interface TradeCurrencyShortcutsProps {
  onCurrencyClick: (currency: Currency) => void
  otherSelectedCurrency?: Currency
}

const TradeCurrencyShortcuts = ({
  onCurrencyClick,
  otherSelectedCurrency
}: TradeCurrencyShortcutsProps) => {
  const chainId = useChainId()

  const {
    isLoading: isLoadingTokens,
    isLoadingBalances,
    tokens: allTokens
  } = useTokensList({
    activeTag: TokenInfoTag.ALL
  })

  const topTokensPerChain = {
    [MerchantMoeChainId.MANTLE]: [
      CNATIVE.onChain(chainId),
      USDCe[chainId],
      USDTe[chainId],
      WETH[MerchantMoeChainId.MANTLE],
      WBTC[MerchantMoeChainId.MANTLE]
    ],
    [MerchantMoeChainId.FUJI]: [
      CNATIVE.onChain(chainId),
      USDCe[chainId],
      USDTe[chainId]
    ]
  }

  const tokens = allTokens
    .filter(
      (token) =>
        (token.balance ? Number(token.balance) > 0 : false) &&
        otherSelectedCurrency?.symbol !== token.symbol
    )
    .slice(0, 3)
    .map((token) => {
      if (token.address) {
        return new Token(
          token.chainId,
          token.address,
          token.decimals,
          token.symbol,
          token.name
        )
      }
      return CNATIVE.onChain(chainId)
    })

  if (tokens.length < 3) {
    const defaultTokens = topTokensPerChain[chainId]
    defaultTokens.forEach((token) => {
      if (
        !token ||
        token.symbol === otherSelectedCurrency?.symbol ||
        tokens.length >= 3
      )
        return

      if (token.isNative && !tokens.some((t) => t.isNative)) {
        tokens.push(token)
      }
      if (
        token &&
        token.isToken &&
        !tokens.some(
          (t) =>
            t.isToken && t.address.toLowerCase() === token.address.toLowerCase()
        )
      ) {
        tokens.push(token)
      }
    })
  }

  if (isLoadingTokens || isLoadingBalances) {
    return (
      <HStack>
        <Skeleton boxSize={6} borderRadius="full" />
        <Skeleton boxSize={6} borderRadius="full" />
        <Skeleton boxSize={6} borderRadius="full" />
      </HStack>
    )
  }

  return (
    <HStack data-cy="trade-currency-shortcuts" spacing={0}>
      {tokens.map((token) => {
        return (
          <Popover
            key={token.symbol}
            placement="top"
            trigger="hover"
            variant="responsive"
          >
            <PopoverTrigger>
              <IconButton
                data-cy={`trade-currency-shortcut-${token.symbol}`}
                borderRadius="full"
                size="sm"
                bg="transparent"
                p={0}
                aria-label={`select ${token.symbol}`}
                icon={
                  <CurrencyLogo
                    key={token.symbol}
                    address={token.isToken ? token.address : undefined}
                    symbol={token.symbol}
                    boxSize={6}
                  />
                }
                onClick={() => onCurrencyClick(token)}
              />
            </PopoverTrigger>
            <PopoverContent p={0}>
              <PopoverBody px={2} py={1}>
                <HStack spacing={1}>
                  <Text fontSize="sm">{token.name}</Text>
                  <Text fontSize="sm" textColor="textSecondary">
                    {token.symbol}
                  </Text>
                </HStack>
              </PopoverBody>
            </PopoverContent>
          </Popover>
        )
      })}
    </HStack>
  )
}

export default TradeCurrencyShortcuts
