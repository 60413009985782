import { Box, HStack, Td, Tr } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { LBPool, Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

import LbRewardsPopover from './LbRewardsPopover'
import PoolTableNameColumn from './PoolTableNameColumn'

interface PoolTableRow {
  pool: Pool | LBPool
}

const PoolTableRow = ({ pool }: PoolTableRow) => {
  const chainId = useChainId()
  const navigate = useNavigate()

  const lbRewards = 'lbRewards' in pool ? pool.lbRewards : undefined
  const rewardsApr =
    lbRewards
      ?.map((reward) => reward.rewardsApr || 0)
      .reduce((a, b) => a + b, 0) || 0
  const totalApr = pool.apr + rewardsApr

  const aprTooltipLabel = (
    <Box>
      <Trans>
        Fees APR: {`${(pool.apr * 100).toFixed(2)}%`} <br />
        Rewards APR: {rewardsApr ? `${(rewardsApr * 100).toFixed(2)}%` : '--'}
      </Trans>
    </Box>
  )

  return (
    <Tr
      data-cy={`pool-row-${pool.pairAddress}`}
      key={pool.pairAddress}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      boxShadow="element"
      borderRadius="10px"
      aria-label={`${pool.tokenX.symbol}-${pool.tokenY.symbol} liquidity pool`}
      onClick={() => navigate(getPoolDetailUrl(pool, chainId))}
    >
      <Td borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
        <HStack>
          <PoolTableNameColumn
            token0={pool.tokenX}
            token1={pool.tokenY}
            fees={`${pool.feePct}%`}
            extraTag={
              lbRewards && lbRewards.length > 0 ? (
                <LbRewardsPopover rewards={lbRewards} />
              ) : undefined
            }
            points={pool.points}
          />
        </HStack>
      </Td>
      <Td textAlign="right">{formattedNum(pool.volumeUsd, { usd: true })}</Td>
      <Td textAlign="right">
        {formattedNum(pool.liquidityUsd, { usd: true })}
      </Td>
      <Td textAlign="right">
        {'liquidityUsdDepthPlus' in pool
          ? formattedNum(pool.liquidityUsdDepthPlus, { usd: true })
          : '--'}
      </Td>
      <Td textAlign="right">
        {'liquidityUsdDepthMinus' in pool
          ? formattedNum(pool.liquidityUsdDepthMinus, { usd: true })
          : '--'}
      </Td>
      <Td textAlign="right">{formattedNum(pool.feesUsd, { usd: true })}</Td>
      <Td
        textAlign="right"
        borderTopRightRadius="10px"
        borderBottomRightRadius="10px"
      >
        {rewardsApr > 0 ? (
          <Box textDecoration="dotted underline">
            <TouchFriendlyTooltip label={aprTooltipLabel}>
              {formattedNum(totalApr * 100, { places: 2 })}%
            </TouchFriendlyTooltip>
          </Box>
        ) : (
          `${formattedNum(totalApr * 100, { places: 2 })}%`
        )}
      </Td>
    </Tr>
  )
}

export default PoolTableRow
