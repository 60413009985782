import { Checkbox, Text, useDisclosure, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import ScrollLockModal from 'components/ScrollLockModal'
import StakeTokenPanel from 'components/StakeTokenPanel'
import { JOE_STAKING } from 'constants/moe'
import { JOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import React, { useEffect, useState } from 'react'
import {
  useAreAirdropTermsAccepted,
  useIsAirdropCitizenDisclaimerAccepted
} from 'state/settings/hooks'
import { useAccount } from 'wagmi'

interface StakeJoePanelProps {
  onStakeSuccess: () => void
}

const StakeJoePanel = ({ onStakeSuccess }: StakeJoePanelProps) => {
  const { isConnected } = useAccount()

  const chainId = useChainId()
  const joeToken = JOE_TOKEN[chainId]

  // t&c modal
  const {
    isOpen: isTermsModalOpen,
    onClose: onTermsModalClose,
    onOpen: onTermsModalOpen
  } = useDisclosure()
  const { areAirdropTermsAccepted, updateAreAirdropTermsAccepted } =
    useAreAirdropTermsAccepted()

  // citizen modal
  const {
    isOpen: isCitizenModalOpen,
    onClose: onCitizenModalClose,
    onOpen: onCitizenModalOpen
  } = useDisclosure()
  const {
    isAirdropCitizenDisclaimerAccepted,
    updateIsAirdropCitizenDisclaimerAccepted
  } = useIsAirdropCitizenDisclaimerAccepted()

  // load html content
  const [termsHtmlContent, setTermsHtmlContent] = useState('')
  const [citizenHtmlContent, setCitizenHtmlContent] = useState('')
  useEffect(() => {
    fetch('moe_airdrop_tc.html')
      .then((response) => response.text())
      .then((data) => {
        setTermsHtmlContent(data)
      })

    fetch('moe_airdrop_citizen_disclaimer.html')
      .then((response) => response.text())
      .then((data) => {
        setCitizenHtmlContent(data)
      })
  }, [])

  return (
    <StakeTokenPanel
      onStakeSuccess={onStakeSuccess}
      isPanelEnabled={
        areAirdropTermsAccepted && isAirdropCitizenDisclaimerAccepted
      }
      token={joeToken}
      stakingContractAddress={JOE_STAKING[chainId]}
      bottomStackViewTopElement={
        isConnected ? (
          <>
            <VStack align="flex-start" pb={2} spacing={4}>
              <Checkbox
                data-cy="airdrop-terms-checkbox"
                colorScheme="accent"
                onChange={() => {
                  areAirdropTermsAccepted
                    ? updateAreAirdropTermsAccepted(false)
                    : onTermsModalOpen()
                }}
                isChecked={areAirdropTermsAccepted}
              >
                <Text fontSize="sm">
                  <Trans>I agree to MOE&#39;s Terms & Conditions</Trans>
                </Text>
              </Checkbox>

              <Checkbox
                data-cy="airdrop-citizen-checkbox"
                colorScheme="accent"
                onChange={() => {
                  isAirdropCitizenDisclaimerAccepted
                    ? updateIsAirdropCitizenDisclaimerAccepted(false)
                    : onCitizenModalOpen()
                }}
                isChecked={isAirdropCitizenDisclaimerAccepted}
              >
                <Text fontSize="sm">
                  <Trans>
                    I&#39;m not a citizen of USA, Canada, China, DPRK, Cuba,
                    Syria, Iran, Sudan, Crimea
                  </Trans>
                </Text>
              </Checkbox>
            </VStack>

            <ScrollLockModal
              isOpen={isTermsModalOpen}
              onClose={onTermsModalClose}
              htmlContent={termsHtmlContent}
              onClickAccept={() => updateAreAirdropTermsAccepted(true)}
            />

            <ScrollLockModal
              isOpen={isCitizenModalOpen}
              onClose={onCitizenModalClose}
              htmlContent={citizenHtmlContent}
              onClickAccept={() =>
                updateIsAirdropCitizenDisclaimerAccepted(true)
              }
            />
          </>
        ) : undefined
      }
    />
  )
}

export default StakeJoePanel
