export const MoeHelperAbi = [
  {
    inputs: [
      {
        internalType: 'contract IMoePair[]',
        name: 'pairs',
        type: 'address[]'
      },
      { internalType: 'address', name: 'user', type: 'address' }
    ],
    name: 'getPositionsOf',
    outputs: [
      {
        components: [
          { internalType: 'uint256', name: 'lpBalance', type: 'uint256' },
          { internalType: 'address', name: 'token0', type: 'address' },
          { internalType: 'address', name: 'token1', type: 'address' },
          { internalType: 'uint256', name: 'amount0', type: 'uint256' },
          { internalType: 'uint256', name: 'amount1', type: 'uint256' }
        ],
        internalType: 'struct MoeHelper.LiquidityPosition[]',
        name: 'lps',
        type: 'tuple[]'
      }
    ],
    stateMutability: 'view',
    type: 'function'
  }
] as const
