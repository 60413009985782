import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { LiquidityHelperAbi } from 'constants/abis/LiquidityHelper'
import { LIQUIDITY_HELPER_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { UserLBPosition } from 'types/poolV2'
import { getDexbarnChainParam } from 'utils/chains'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseUserLBPositionsProps {
  enabled: boolean
}

const useUserLBPositions = ({
  enabled
}: UseUserLBPositionsProps): {
  data: UserLBPosition[]
  isLoading: boolean
} => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchUserPoolIds = useDexbarnGet<UserLBPosition[]>(
    `/v1/lb/user/pool-ids/${account?.toLowerCase()}/${chain}?pageSize=50`
  )
  const result = useQuery<UserLBPosition[]>({
    enabled: !!account && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchUserPoolIds(),
    queryKey: ['UserLBPositionsWithBinIds', chain, account]
  })

  // fetch total amounts X/Y for each position
  const positions = result.data ?? []
  const contract = {
    abi: LiquidityHelperAbi,
    address: LIQUIDITY_HELPER_ADDRESS[chainId]
  } as const
  const { data: totalAmounts, isLoading } = useReadContracts({
    contracts: positions.map((position) => ({
      ...contract,
      args: account
        ? [getAddress(position.poolAddress), account, position.binIds]
        : undefined,
      chainId,
      functionName: 'getAmountsOf'
    })),
    query: { enabled: !!account && enabled && positions.length > 0 }
  })

  const userLBPositions = positions.map((position, i) => {
    const amounts = totalAmounts?.[i]?.result as
      | [bigint[], bigint[]]
      | undefined

    const amountsX = amounts?.[0] || []
    const amountsY = amounts?.[1] || []

    const totalAmountX = amountsX.reduce(
      (acc, amount) => acc + amount,
      BigInt(0)
    )
    const totalAmountY = amountsY.reduce(
      (acc, amount) => acc + amount,
      BigInt(0)
    )

    return {
      ...position,
      totalAmountX: {
        formatted: formatUnits(totalAmountX, position.tokenX.decimals),
        value: totalAmountX
      },
      totalAmountY: {
        formatted: formatUnits(totalAmountY, position.tokenY.decimals),
        value: totalAmountY
      }
    }
  })

  return {
    data: userLBPositions,
    isLoading: isLoading || result.isLoading
  }
}

export default useUserLBPositions
