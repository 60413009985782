import {
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CopyableError from 'components/CopyableError'
import NumericalInput from 'components/NumericalInput'
import Web3Button from 'components/Web3Button'
import useSetRewarderParameters from 'hooks/rewarder/useSetRewarderParameters'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React, { useState } from 'react'
import { formatUnits } from 'viem'

interface SetRewarderParametersPanelProps {
  isOwner: boolean
  rewarderAddress: string
  rewardToken: Currency
  onSetParametersSuccess?: () => void
  remainingAmountInRewarder?: bigint
}

const SetRewarderParametersPanel = ({
  isOwner,
  onSetParametersSuccess,
  remainingAmountInRewarder,
  rewarderAddress,
  rewardToken
}: SetRewarderParametersPanelProps) => {
  // inputs
  const [startDate, setStartDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()
  const {
    amount: rewardPerSecond,
    amountBN: rewardPerSecondBN,
    setAmount: setRewardPerSecond
  } = useCurrencyInputAmount({
    currency: rewardToken
  })

  const now = Date.now()
  const isFormValid =
    !!rewardToken &&
    !!startDate &&
    !!endDate &&
    startDate.getTime() > now &&
    startDate.getTime() < endDate.getTime()

  // calculate reward per second for remaining amount of tokens in rewarder
  const durationInSeconds =
    endDate && startDate ? (endDate.getTime() - startDate.getTime()) / 1000 : 0
  const rewardPerSecondWithRemainingAmountInRewarder =
    durationInSeconds && remainingAmountInRewarder
      ? remainingAmountInRewarder / BigInt(durationInSeconds)
      : BigInt(0)

  // set rewarder parameters
  const {
    error: setRewarderParametersError,
    isLoading: isSettingRewarderParameters,
    setRewarderParameters
  } = useSetRewarderParameters({
    enabled: isOwner,
    end: endDate,
    onSuccess: onSetParametersSuccess,
    rewardPerSecond: rewardPerSecondBN,
    rewarderAddress,
    start: startDate
  })

  return (
    <Flex
      bg="bgCard"
      border="1px solid"
      borderColor="border"
      w="full"
      flexDir="column"
      gap={8}
      p={{ base: 4, md: 8 }}
      borderRadius="2xl"
      boxShadow="element"
    >
      <VStack align="flex-start">
        <Heading size="md">
          <Trans>Set rewarder parameters</Trans>
        </Heading>
      </VStack>

      <SimpleGrid columns={2} spacing={4}>
        <VStack spacing={1} align="flex-start">
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>Start date:</Trans>
          </Text>
          <Input
            size="lg"
            type="datetime-local"
            borderRadius="10px"
            bg="bgSecondary"
            border={0}
            onChange={(e) => {
              const date = new Date(e.target.value)
              setStartDate(date)
            }}
          />
        </VStack>
        <VStack spacing={1} align="flex-start">
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>End date:</Trans>
          </Text>
          <Input
            size="lg"
            type="datetime-local"
            borderRadius="10px"
            bg="bgSecondary"
            border={0}
            onChange={(e) => {
              const date = new Date(e.target.value)
              setEndDate(date)
            }}
          />
        </VStack>
      </SimpleGrid>

      <VStack align="flex-start">
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Reward per second:</Trans>
        </Text>
        <InputGroup>
          <NumericalInput
            inputType="decimal"
            placeholder="0.0"
            size="lg"
            bg="bgSecondary"
            border={0}
            value={rewardPerSecond}
            onValueChange={setRewardPerSecond}
          />
          <InputRightElement h="full" mr={2}>
            {startDate && endDate ? (
              <Button
                flexShrink={0}
                bg="transparent"
                _hover={{
                  bg: 'bgCard'
                }}
                borderRadius="full"
                px={2}
                py={1}
                textTransform="uppercase"
                onClick={() => {
                  setRewardPerSecond(
                    formatUnits(
                      rewardPerSecondWithRemainingAmountInRewarder,
                      rewardToken.decimals
                    )
                  )
                }}
                size="xs"
                fontWeight="semibold"
              >
                Auto
              </Button>
            ) : null}
          </InputRightElement>
        </InputGroup>
      </VStack>

      <VStack>
        <Web3Button
          variant="primary"
          colorScheme="accent"
          size="xl"
          w="full"
          isDisabled={
            !rewardToken || !isFormValid || !setRewarderParameters || !isOwner
          }
          isLoading={isSettingRewarderParameters}
          onClick={setRewarderParameters}
          loadingText={t`Setting parameters`}
        >
          {isOwner ? (
            <Trans>Set parameters</Trans>
          ) : (
            <Trans>You are not the contract owner</Trans>
          )}
        </Web3Button>

        {setRewarderParametersError ? (
          <CopyableError
            textProps={{ w: 'full' }}
            summary={setRewarderParametersError.summary}
            error={setRewarderParametersError.message}
          />
        ) : null}
      </VStack>
    </Flex>
  )
}

export default SetRewarderParametersPanel
