import {
  Currency,
  CurrencyAmount,
  Token,
  TokenAmount,
  WNATIVE
} from '@traderjoe-xyz/sdk-core'
import { getAddress } from 'viem'

import { MerchantMoeChainId } from '../constants/chains'

export function wrappedCurrency(
  currency?: Currency,
  chainId?: MerchantMoeChainId
): Token | undefined {
  return currency && currency.isNative && chainId
    ? WNATIVE[chainId]
    : currency instanceof Token
      ? currency
      : undefined
}

export function wrappedCurrencyAmount(
  currencyAmount: CurrencyAmount | undefined,
  chainId: MerchantMoeChainId | undefined
): TokenAmount | undefined {
  const token =
    chainId && currencyAmount && currencyAmount.currency.isNative
      ? WNATIVE[chainId]
      : currencyAmount && chainId
        ? wrappedCurrency(currencyAmount.currency, chainId)
        : undefined
  return token && currencyAmount
    ? new TokenAmount(token, currencyAmount.raw)
    : undefined
}

export const getCurrencyAddress = (
  currency?: Currency
): `0x${string}` | undefined => {
  return currency && currency.isToken ? getAddress(currency.address) : undefined
}

export function isWrappedNativeCurrency(
  address?: string,
  chainId?: MerchantMoeChainId
): boolean {
  return chainId
    ? address?.toLowerCase() === WNATIVE[chainId].address.toLowerCase()
    : false
}
