import { MoeLensAbi } from 'constants/abis/MoeLens'
import { VeMoeAbi } from 'constants/abis/VeMoe'
import { MOE_LENS_ADDRESS, VE_MOE_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { formatUnits } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseGetUserVoteBribeDataProps {
  pid?: bigint
}

const useGetUserVoteBribeData = ({ pid }: UseGetUserVoteBribeDataProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  return useReadContracts({
    contracts: [
      {
        abi: MoeLensAbi,
        address: MOE_LENS_ADDRESS[chainId],
        args: pid !== undefined && account ? [BigInt(pid), account] : undefined,
        chainId,
        functionName: 'getVeMoeDataAt'
      },
      {
        abi: VeMoeAbi,
        address: VE_MOE_ADDRESS[chainId],
        args: pid !== undefined && account ? [account, BigInt(pid)] : undefined,
        chainId,
        functionName: 'getBribesOf'
      }
    ],
    query: {
      enabled: pid !== undefined && !!account,
      select: (data) => {
        const [veMoeData, bribeContractAddress] = data

        const userPendingAmount = veMoeData.result
          ? {
              formatted: formatUnits(
                veMoeData.result.rewarder.reward.userPendingAmount,
                Number(veMoeData.result.rewarder.reward.token.decimals)
              ),
              value: veMoeData.result.rewarder.reward.userPendingAmount
            }
          : undefined

        return {
          bribeContractAddress: bribeContractAddress.result,
          rewarder: veMoeData.result?.rewarder,
          userPendingAmount
        }
      }
    }
  })
}

export default useGetUserVoteBribeData
