import { t } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress, Hex, parseAbi } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseUnstakeTokenProps {
  stakingContractAddress: Hex
  token: Token
  amount?: bigint
  enabled?: boolean
  onSuccess?: () => void
}

const useUnstakeToken = ({
  amount,
  enabled,
  onSuccess,
  stakingContractAddress,
  token
}: UseUnstakeTokenProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config, error: prepareContractWriteError } =
    useSimulateContract({
      abi: parseAbi(['function unstake(uint256 amount) external']),
      address: getAddress(stakingContractAddress),
      args: amount ? [amount] : undefined,
      chainId,
      functionName: 'unstake',
      query: {
        enabled: enabled && walletChain?.id === chainId && !!amount,
        gcTime: 0
      },
      value: BigInt(0) as any // workaround for safe app
    })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (amount === undefined) return
        const transactionSummary = t`Unstaked ${formattedNum(
          formatUnits(amount, token.decimals)
        )} ${token.symbol}`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const unstake = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    prepareContractWriteError,
    unstake
  }
}

export default useUnstakeToken
