import { Text, VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import { VE_MOE_TOKEN } from 'constants/tokens'
import useVoteForFarm from 'hooks/farms/useVote'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { Farm } from 'types/dexlens'
import { formatUnits } from 'viem'

interface RemoveVoteFromFarmPanelProps {
  farm: Farm
  isUserRequiredToEmergencyUnsetBribe: boolean
  onRemoveVoteSuccess?: () => void
}

const RemoveVoteFromFarmPanel = ({
  farm,
  isUserRequiredToEmergencyUnsetBribe,
  onRemoveVoteSuccess
}: RemoveVoteFromFarmPanelProps) => {
  const chainId = useChainId()

  const veMoeToken = VE_MOE_TOKEN[chainId]
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: veMoeToken
  })

  const votesOfUserForFarm = formatUnits(farm.userVotesOnFarm, 18)
  const isExceedingBalance = votesOfUserForFarm
    ? Number(amount) > Number(votesOfUserForFarm)
    : false

  const isAmountInvalid = isExceedingBalance || Number(amount) === 0

  const { isLoading: isVoting, voteForFarm } = useVoteForFarm({
    deltaAmount: amountBN ? -amountBN : undefined,
    enabled: !isAmountInvalid && !isUserRequiredToEmergencyUnsetBribe,
    onSuccess: () => {
      setAmount('')
      onRemoveVoteSuccess?.()
    },
    pid: farm.pid
  })

  return (
    <VStack spacing={10}>
      <CurrencyInput
        data-cy="remove-votes-input"
        heading={t`Amount to remove:`}
        headingProps={{ fontSize: 'sm' }}
        currency={veMoeToken}
        value={amount}
        onValueChange={setAmount}
        h="60px"
        balance={votesOfUserForFarm}
        error={
          isExceedingBalance
            ? t`The amount exceeds your votes for this farm`
            : undefined
        }
      />

      <VStack w="full">
        <Web3Button
          data-cy="remove-votes-button"
          size="xl"
          colorScheme="accent"
          variant="primary"
          w="full"
          isDisabled={isAmountInvalid || !voteForFarm}
          onClick={voteForFarm}
          isLoading={isVoting}
          loadingText={t`Removing Votes`}
        >
          <Trans>Remove Votes</Trans>
        </Web3Button>

        {isUserRequiredToEmergencyUnsetBribe ? (
          <Text fontSize="sm" textColor="red.500">
            You must opt-out from the voting rewards first
          </Text>
        ) : null}
      </VStack>
    </VStack>
  )
}

export default RemoveVoteFromFarmPanel
