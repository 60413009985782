import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show,
  Spinner,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import useSortedPools, { PoolSortMethod } from 'hooks/pool/useSortedPools'
import React, { useCallback, useState } from 'react'

import PoolMobileCard from './PoolMobileCard'
import { PoolsTableProps } from './PoolsTable'

enum PoolsMobileSort {
  LeastAPR,
  LeastLiquidity,
  LeastVolume,
  MostAPR,
  MostLiquidity,
  MostVolume
}

const getPoolsSortTitle = (sort: PoolsMobileSort) => {
  switch (sort) {
    case PoolsMobileSort.MostLiquidity:
      return t`Highest Liquidity`
    case PoolsMobileSort.LeastLiquidity:
      return t`Lowest Liquidity`
    case PoolsMobileSort.MostVolume:
      return t`Highest Volume`
    case PoolsMobileSort.LeastVolume:
      return t`Lowest Volume`
    case PoolsMobileSort.MostAPR:
      return t`Highest APR`
    case PoolsMobileSort.LeastAPR:
      return t`Lowest APR`
  }
}

const PoolsMobileList = ({
  hasNextPage,
  isFetchingPage,
  isLoadingPools,
  onLoadMoreClick,
  pools,
  query,
  setSortMethod,
  sortMethod
}: PoolsTableProps): JSX.Element => {
  // Sorting
  const sorts = [
    PoolsMobileSort.MostVolume,
    PoolsMobileSort.LeastVolume,
    PoolsMobileSort.MostLiquidity,
    PoolsMobileSort.LeastLiquidity,
    PoolsMobileSort.MostAPR,
    PoolsMobileSort.LeastAPR
  ]
  const [sort, setSort] = useState(PoolsMobileSort.MostVolume)
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)

  const handleChangeSort = useCallback(
    (s: PoolsMobileSort) => {
      setSort(s)
      switch (s) {
        case PoolsMobileSort.MostLiquidity:
        case PoolsMobileSort.LeastLiquidity:
          setSortMethod(PoolSortMethod.LIQUIDITY)
          break
        case PoolsMobileSort.MostVolume:
        case PoolsMobileSort.LeastVolume:
          setSortMethod(PoolSortMethod.VOLUME)
          break
        case PoolsMobileSort.MostAPR:
        case PoolsMobileSort.LeastAPR:
          setSortMethod(PoolSortMethod.APR)
          break
      }

      switch (s) {
        case PoolsMobileSort.MostLiquidity:
        case PoolsMobileSort.MostVolume:
        case PoolsMobileSort.MostAPR:
          setIsSortDescending(true)
          break
        case PoolsMobileSort.LeastLiquidity:
        case PoolsMobileSort.LeastVolume:
        case PoolsMobileSort.LeastAPR:
          setIsSortDescending(false)
          break
      }
    },
    [setSortMethod]
  )

  const sortedPools = useSortedPools({
    isSortDescending,
    pools,
    sortMethod
  })

  return (
    <Flex flexDir="column" gap={4}>
      <Menu>
        <MenuButton
          w="full"
          as={Button}
          rightIcon={<ChevronDownIcon />}
          boxShadow="element"
          bg="white"
          borderRadius="10px"
        >
          <HStack>
            <Show above="md">
              <Text color="textSecondary" fontWeight="normal">
                <Trans>Sorting:</Trans>
              </Text>
            </Show>
            <Text color="textPrimary" fontWeight="semibold">
              {getPoolsSortTitle(sort)}
            </Text>
          </HStack>
        </MenuButton>
        <MenuList>
          {sorts.map((s, i) => (
            <MenuItem key={i} h="48px" onClick={() => handleChangeSort(s)}>
              {getPoolsSortTitle(s)}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
      {isLoadingPools ? (
        <Center mt={8}>
          <Spinner />
        </Center>
      ) : (
        <>
          <Flex flexDir="column" gap={2} mb={4}>
            {sortedPools.map((pool, idx) => (
              <PoolMobileCard key={idx} pool={pool} />
            ))}
          </Flex>
          {!isLoadingPools &&
          query === '' &&
          sortedPools.length > 0 &&
          hasNextPage ? (
            <Button
              variant="outline-bold"
              alignSelf="center"
              w="160px"
              isLoading={isFetchingPage}
              loadingText={t`Loading`}
              onClick={onLoadMoreClick}
            >
              <Trans>Load More</Trans>
            </Button>
          ) : null}
        </>
      )}
    </Flex>
  )
}

export default PoolsMobileList
