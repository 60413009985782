import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import WarningOutlined from 'components/WarningOutlined'
import Web3Button from 'components/Web3Button'
import { ETHENA_LP_STAKING_ADDRESS } from 'constants/moe'
import useLockTokensIntoEthena from 'hooks/ethena/useLockTokensIntoEthena'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { BaseError } from 'wagmi'
import { GetBalanceData } from 'wagmi/query'

interface LockPanelProps {
  token: Token
  onLockSuccess?: () => void
  stakeLimit?: number
  totalStaked?: number
  userTokenBalance?: GetBalanceData
}

const LockPanel = ({
  onLockSuccess,
  stakeLimit,
  token,
  totalStaked,
  userTokenBalance
}: LockPanelProps) => {
  const chainId = useChainId()

  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: token
  })

  const isExceedingBalance = userTokenBalance
    ? Number(userTokenBalance.formatted) < Number(amount)
    : false

  const isExceedingStakeLimit =
    stakeLimit && totalStaked && amount
      ? totalStaked + Number(amount) > stakeLimit
      : false

  const isLockButtonDisabled =
    !userTokenBalance ||
    isExceedingBalance ||
    !amountBN ||
    isExceedingStakeLimit

  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    isLoadingAllowance,
    reset: resetApprove,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountBN,
    spender: ETHENA_LP_STAKING_ADDRESS[chainId],
    token: token.address,
    tokenSymbol: token.symbol
  })

  const {
    error: lockError,
    isLoading: isLocking,
    lock
  } = useLockTokensIntoEthena({
    amount: amountBN,
    enabled: !isLockButtonDisabled && isApproved,
    onSuccess: () => {
      setAmount('')
      resetApprove()
      onLockSuccess?.()
    },
    tokenAddress: token.address
  })

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        currency={token}
        currencyAddress={token.address}
        value={amount}
        onValueChange={setAmount}
        balance={userTokenBalance?.formatted}
        error={isExceedingBalance ? t`Not enough ${token?.symbol}` : undefined}
        rightElement={
          userTokenBalance ? (
            <MaxButton
              borderRadius={12}
              balance={userTokenBalance.formatted}
              onClick={() => setAmount(userTokenBalance.formatted)}
            />
          ) : undefined
        }
      />

      <WarningOutlined
        message="Tokens will be available to withdraw 21 days after unlocking."
        boxProps={{ h: '48px', w: 'full' }}
      />

      {!isApproved &&
      !isLockButtonDisabled &&
      !isLoadingAllowance &&
      !!approve ? (
        <ApproveTokenButton
          data-cy="lock-token-approve-button"
          amount={amount}
          currencySymbol={token.symbol}
          approvalType={approvalType}
          onApprovalTypeSelect={setApprovalType}
          isLoading={isApproving}
          onClick={approve}
        >
          <Trans>Approve {token.symbol}</Trans>
        </ApproveTokenButton>
      ) : null}

      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="xl"
        w="full"
        isDisabled={isLockButtonDisabled || !lock}
        onClick={lock}
        isLoading={isLocking}
        loadingText={t`Locking APT`}
      >
        {isExceedingBalance ? (
          <Trans>Insufficient balance</Trans>
        ) : isExceedingStakeLimit ? (
          <Trans>Stake limit exceeded</Trans>
        ) : (
          <Trans>Lock APT</Trans>
        )}
      </Web3Button>

      {lockError ? (
        <CopyableError
          error={lockError.message}
          summary={(lockError as BaseError).shortMessage}
          textProps={{ textAlign: 'center', w: 'full' }}
        />
      ) : null}
    </VStack>
  )
}

export default LockPanel
