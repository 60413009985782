import {
  Box,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Spinner,
  useToken
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import AnalyticsChart, { ChartRange } from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import useVaultBalances from 'hooks/vault/useVaultBalances'
import useVaultSharePriceData from 'hooks/vault/useVaultSharePriceData'
import useVaultTvlHistory from 'hooks/vault/useVaultTvlHistory'
import React, { useMemo } from 'react'
import { Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

import VaultRecentActivityTable from './VaultRecentActivityTable'

interface VaultAnalyticsProps {
  isTabSelected: boolean
  vault: Vault
  currency0?: Currency
  currency1?: Currency
}

const VaultAnalytics = ({
  currency0,
  currency1,
  isTabSelected,
  vault
}: VaultAnalyticsProps) => {
  const [graphPurple] = useToken('colors', ['graphPurpleDark'])

  const { data: tvlHistory } = useVaultTvlHistory({
    enabled: isTabSelected,
    vaultAddress: vault.id
  })
  const tvlChartData = useMemo(() => {
    return tvlHistory
      ? tvlHistory.map((x) => ({
          date: x.timestamp,
          value: x.tvlUsd
        }))
      : []
  }, [tvlHistory])

  const { data: sharePriceData } = useVaultSharePriceData({
    enabled: isTabSelected,
    vaultAddress: vault.id
  })
  const sharePriceChartData = useMemo(() => {
    return sharePriceData
      ? sharePriceData.map((x) => ({
          date: new Date(x.timestamp).getTime() / 1000,
          value: parseFloat(x.sharePrice)
        }))
      : []
  }, [sharePriceData])

  const {
    isLoading: isLoadingBalances,
    tokenBalancesX,
    tokenBalancesY
  } = useVaultBalances({ vault })

  if (isLoadingBalances) {
    return (
      <Center pt={8}>
        <Spinner />
      </Center>
    )
  }

  const fmtBalancesX = formatBalances(tokenBalancesX, vault.tokenX.decimals)
  const fmtBalancesY = formatBalances(tokenBalancesY, vault.tokenY.decimals)

  const formatPerformanceMetric = (value: number) => {
    return `${parseFloat((value * 100).toFixed(5))} %`
  }

  return (
    <Flex flexDir="column" gap={8}>
      <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
        <AnalyticsStat
          title={t`Token X deployed in pool`}
          value={`${fmtBalancesX.inPool} ${vault.tokenX.symbol}`}
        />

        <AnalyticsStat
          title={t`Token X idle balance`}
          value={`${fmtBalancesX.inVault} ${vault.tokenX.symbol}`}
        />
        <AnalyticsStat
          title={t`Token X queued for withdrawal`}
          value={`${fmtBalancesX.queued} ${vault.tokenX.symbol}`}
        />
        <AnalyticsStat
          title={t`Token Y deployed in pool`}
          value={`${fmtBalancesY.inPool} ${vault.tokenY.symbol}`}
        />
        <AnalyticsStat
          title={t`Token Y idle balance`}
          value={`${fmtBalancesY.inVault} ${vault.tokenY.symbol}`}
        />
        <AnalyticsStat
          title={t`Token Y queued for withdrawal`}
          value={`${fmtBalancesY.queued} ${vault.tokenY.symbol}`}
        />
        <AnalyticsStat
          title={t`Vault vs 50/50 hold (7D)`}
          value={formatPerformanceMetric(vault.hodl5050Performance)}
        />
        <AnalyticsStat
          title={t`Vault vs 100% ${vault.tokenX.symbol} (7D)`}
          value={formatPerformanceMetric(vault.tokenX7DayPerformance)}
        />
        <AnalyticsStat
          title={t`Vault vs 100% ${vault.tokenY.symbol} (7D)`}
          value={formatPerformanceMetric(vault.tokenY7DayPerformance)}
        />
      </SimpleGrid>
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="share-price"
          subtitle={t`The price of 1000 APT (Auto Pool Tokens)`}
          data={sharePriceChartData}
          header={
            <Heading size="md">
              <Trans>Price</Trans>
            </Heading>
          }
          h="220px"
          tooltipTitle={t`Price`}
          fill={graphPurple}
          stroke={graphPurple}
          showXAxis
          showYAxis
          usdValue={false}
          valueSymbol={`${vault.tokenY.symbol}`}
          chartRanges={[
            ChartRange.D1,
            ChartRange.D7,
            ChartRange.D30,
            ChartRange.D90
          ]}
          yAxisMinValue="auto"
        />
      </Box>
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="tvl"
          data={tvlChartData}
          header={
            <Heading size="md">
              <Trans>TVL (Total Locked Value)</Trans>
            </Heading>
          }
          h="220px"
          tooltipTitle={t`TVL`}
          fill={graphPurple}
          stroke={graphPurple}
          showXAxis
          showYAxis
          hideTab
        />
      </Box>
      <Box
        p={{ base: 4, md: 8 }}
        bg="bgCard"
        border="1px"
        borderColor="border"
        borderRadius="2xl"
      >
        <VaultRecentActivityTable
          vault={vault}
          currency0={currency0}
          currency1={currency1}
        />
      </Box>
    </Flex>
  )
}

export default VaultAnalytics

const formatBalances = (
  tokenBalances: {
    inPool: bigint
    inVault: bigint
    queued: bigint
  },
  tokenDecimals: number
) => {
  const inPool = formattedNum(
    formatUnits(tokenBalances.inPool, tokenDecimals),
    { places: 2 }
  )
  const queued = formattedNum(
    formatUnits(tokenBalances.queued, tokenDecimals),
    { places: 2 }
  )
  const inVault = formattedNum(
    formatUnits(tokenBalances.inVault, tokenDecimals),
    { places: 2 }
  )
  return {
    inPool,
    inVault,
    queued
  }
}
