import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import StakeMoePanel from './StakeMoePanel'
import UnstakeMoePanel from './UnstakeMoePanel'

interface StakeMoePanelsProps {
  moeDepositedAmount?: string
  onStakeSuccess?: () => void
  onUnstakeSuccess?: () => void
}

const StakeMoePanels = (props: StakeMoePanelsProps) => {
  return (
    <VStack
      w="full"
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
      spacing={{ base: 4, md: 10 }}
      align="flex-start"
    >
      <Tabs w="full" variant="solid-rounded" isFitted>
        <TabList>
          <Tab data-cy="stake-moe-tab">
            <Trans>Stake</Trans>
          </Tab>
          <Tab data-cy="unstake-moe-tab">
            <Trans>Unstake</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt={{ base: 4, md: 10 }}>
            <StakeMoePanel {...props} />
          </TabPanel>
          <TabPanel p={0} pt={{ base: 4, md: 10 }}>
            <UnstakeMoePanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

export default StakeMoePanels
