import {
  Box,
  Center,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import useUserPositionSearch from 'hooks/pool/useUserPositionSearch'
import useUserPositionsRows from 'hooks/pool/useUserPositionsRows'
import useUserPoolV1Positions from 'hooks/pool/v1/useUserPoolV1Positions'
import useUserLBPositions from 'hooks/pool/v2/useUserLBPositions'
import useUserVaultsWithPosition from 'hooks/vault/useUserVaultsWithPositions'
import React from 'react'
import { useAccount } from 'wagmi'

import UserPositionRow from './UserPositionRow'
import VaultPositionRow from './VaultPositionRow'

interface SectionProps {
  rows: JSX.Element[]
  title?: string
}

const Section = ({ rows, title }: SectionProps) => (
  <Box>
    {title ? (
      <Heading size="sm" ml={{ base: 2, md: 6 }} mb={1}>
        {title}
      </Heading>
    ) : null}
    <TableContainer>
      <Table
        variant="card"
        style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
      >
        <Thead>
          <Tr>
            <Th>
              <Trans>Pool name</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>Pool balance</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>Farm balance</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>USD value</Trans>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  </Box>
)

const VaultFarmSection = ({ rows, title }: SectionProps) => (
  <Box>
    <Heading size="sm" ml={{ base: 2, md: 6 }} mb={1}>
      {title}
    </Heading>
    <TableContainer>
      <Table
        variant="card"
        style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
      >
        <Thead>
          <Tr>
            <Th>
              <Trans>Pool name</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>Pool balance</Trans>
            </Th>
            <Th textAlign="right">
              <Trans>USD value</Trans>
            </Th>
          </Tr>
        </Thead>
        <Tbody>{rows}</Tbody>
      </Table>
    </TableContainer>
  </Box>
)

interface UserPositionsTableProps {
  isTabSelected: boolean
  query: string
}

const UserPositionsTable = ({
  isTabSelected,
  query
}: UserPositionsTableProps) => {
  const { isConnected } = useAccount()
  // const { openConnectModal } = useConnectModal()

  const { data: lbPositions, isLoading: isLoadingLbPositions } =
    useUserLBPositions({ enabled: isTabSelected })
  const { data: poolV1Positions = [], isLoading: isLoadingPoolV1Positions } =
    useUserPoolV1Positions({ enabled: isTabSelected })

  const {
    data: userVaultsWithPosition,
    isLoading: isLoadingUserVaultWithPosition
  } = useUserVaultsWithPosition({ enabled: isTabSelected })

  const {
    lbPositions: filteredLbPositions,
    poolV1Positions: filteredPoolV1Positions,
    vaults: filteredUserVaults
  } = useUserPositionSearch({
    lbPositions,
    poolV1Positions,
    query,
    userVaults: userVaultsWithPosition
  })

  const poolPositionsRowsProps = useUserPositionsRows({
    lbPositions: filteredLbPositions,
    positions: filteredPoolV1Positions
  })

  if (!isConnected) {
    return (
      <Center flexDir="column" gap={4} h="200px">
        <Text>
          <Trans>Connect your wallet to see your pools.</Trans>
        </Text>
        {/* <Button
          variant="primary"
          colorScheme="accent"
          onClick={openConnectModal}
        >
          <Trans>Connect Wallet</Trans>
        </Button> */}
      </Center>
    )
  }

  if (
    isLoadingLbPositions ||
    isLoadingPoolV1Positions ||
    isLoadingUserVaultWithPosition
  ) {
    return (
      <Center pt={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={4} mt={4}>
      {filteredUserVaults.length > 0 ? (
        <Box mb={{ base: 0, md: 4 }}>
          <VaultFarmSection
            title={t`My Vaults`}
            rows={filteredUserVaults.map((vault, i) => (
              <VaultPositionRow key={`vault-${i}`} vault={vault} />
            ))}
          />
        </Box>
      ) : null}
      {poolPositionsRowsProps.length > 0 ? (
        <Section
          title={t`My Pools`}
          rows={poolPositionsRowsProps.map((rowProps, i) => (
            <UserPositionRow key={i} {...rowProps} />
          ))}
        />
      ) : null}
      {poolPositionsRowsProps.length === 0 &&
      filteredUserVaults.length === 0 ? (
        <Center py={8}>
          <Text size="sm" color="textSecondary">
            <Trans>You don&#39;t have any positions.</Trans>
          </Text>
        </Center>
      ) : null}
    </Flex>
  )
}

export default UserPositionsTable
