import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Hide,
  Tab,
  TabList,
  Tabs,
  Text,
  useToken,
  VStack
} from '@chakra-ui/react'
import { format } from 'date-fns'
import React, { useMemo, useState } from 'react'
import { Area, AreaChart, ResponsiveContainer, Tooltip } from 'recharts'
import {
  ninetyDays,
  oneHundredEightyDays,
  sevenDays,
  thirtyDays
} from 'utils/date'
import { formattedNum } from 'utils/format'

import PoolMainAnalyticsChartTooltip from './PoolMainAnalyticsChartTooltip'

enum ChartRange {
  D7 = '7D',
  D30 = '30D',
  D90 = '90D',
  D180 = '180D'
}

const defaultChartRanges = [
  ChartRange.D7,
  ChartRange.D30,
  ChartRange.D90,
  ChartRange.D180
]

const skeleton = [
  { date: 1628467200, value: 0 },
  { date: 1628467300, value: 5 },
  { date: 1628467400, value: 4 },
  { date: 1628467500, value: 7 }
]

interface AnalyticsChartData {
  date: number
  value: number
  value2: readonly [number, number]
}

interface AnalyticsChartProps {
  data: AnalyticsChartData[]
  header: React.ReactNode
  headerFlexProps?: FlexProps
  isLoading?: boolean
  tagline?: string
  tooltipTitle?: string
}

const PoolMainAnalyticsChart = ({
  data,
  header,
  headerFlexProps,
  isLoading,
  tagline,
  tooltipTitle,
  ...props
}: AnalyticsChartProps & BoxProps) => {
  const loading = data.length === 0 || isLoading
  const [bgSecondary, bgInteractive, graphPurpleDark, graphPurpleOutline] =
    useToken('colors', [
      'bgSecondary',
      'bgInteractive',
      'graphPurpleDark',
      'graphPurpleOutline'
    ])

  const [chartRange, setChartRange] = useState<ChartRange>(ChartRange.D30)
  const timespan = useMemo(() => {
    switch (chartRange) {
      case ChartRange.D7:
        return sevenDays()
      case ChartRange.D30:
        return thirtyDays()
      case ChartRange.D90:
        return ninetyDays()
      case ChartRange.D180:
        return oneHundredEightyDays()
    }
  }, [chartRange])

  const filteredData = data
    .sort((a, b) => (a.date > b.date ? 1 : -1))
    .filter((d: AnalyticsChartData) => timespan <= d.date)
    .map((d: AnalyticsChartData) => ({
      ...d,
      fmtDate: format(new Date(d.date * 1000), 'LLL d, y'),
      fmtValue: formattedNum(d.value, {
        places: 10,
        usd: true
      }),
      fmtValue2: formattedNum(d.value2[1] - d.value2[0], {
        places: 10,
        usd: true
      }),
      title: tooltipTitle ?? tagline,
      value1Color: graphPurpleDark,
      value2Color: bgInteractive
    }))

  const gradients = [
    {
      color: graphPurpleDark,
      id: 'value'
    },
    {
      color: bgInteractive,
      id: 'value2'
    }
  ]

  return (
    <Box pos="relative">
      <Flex
        justify="space-between"
        align="center"
        mb={{ base: 0, sm: 8 }}
        {...headerFlexProps}
      >
        <VStack align="flex-start" spacing={0.25}>
          {tagline ? (
            <Text color="textSecondary" fontSize="sm">
              {tagline}
            </Text>
          ) : null}
          {header}
        </VStack>
        <Hide below="md">
          <Tabs
            variant="solid-rounded"
            colorScheme="accent"
            index={defaultChartRanges.indexOf(chartRange)}
            onChange={(index) => setChartRange(defaultChartRanges[index])}
          >
            <TabList>
              {defaultChartRanges.map((range, i) => (
                <Tab key={i}>{range}</Tab>
              ))}
            </TabList>
          </Tabs>
        </Hide>
      </Flex>
      <Box {...props}>
        <ResponsiveContainer>
          <AreaChart
            data={loading ? skeleton : filteredData}
            margin={{ bottom: 0, left: 0, right: 0, top: 0 }}
          >
            <defs>
              {gradients.map((g) => (
                <linearGradient
                  key={g.id}
                  id={`colorGradient-${g.id}`}
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop
                    offset="5%"
                    stopColor={loading ? bgSecondary : g.color}
                    stopOpacity={1}
                    style={{
                      transition: loading ? undefined : 'all 3s ease-out'
                    }}
                  />
                  <stop
                    offset="95%"
                    stopColor={loading ? bgSecondary : g.color}
                    stopOpacity={0.05}
                    style={{
                      transition: loading ? undefined : 'all 3s ease-out'
                    }}
                  />
                </linearGradient>
              ))}
            </defs>
            <Area
              dataKey="value2"
              stroke={loading ? bgSecondary : bgInteractive}
              fill={'url(#colorGradient-value2)'}
            />
            <Area
              dataKey="value"
              stroke={loading ? bgSecondary : graphPurpleOutline}
              fill={'url(#colorGradient-value)'}
            />
            {!loading ? (
              <Tooltip
                wrapperStyle={{ outline: 'none' }}
                content={<PoolMainAnalyticsChartTooltip />}
              />
            ) : null}
          </AreaChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  )
}

export default PoolMainAnalyticsChart
