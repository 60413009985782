import { Box, Flex, Heading, Image, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import MoeBanner1x from 'assets/home/moe-banner_1x.webp'
import MoeBanner2x from 'assets/home/moe-banner_2x.webp'
import MoeBanner3x from 'assets/home/moe-banner_3x.webp'
import PageHelmet from 'components/PageHelmet'
import React from 'react'
import { useLocation } from 'react-router-dom'

import HomeHighlightedCards from './HomeHighlightedCards'
import HomeTiles from './HomeTiles'

const Home = () => {
  const location = useLocation()

  return (
    <>
      <PageHelmet url={location.pathname} />
      <Flex
        flexDir="column"
        gap={10}
        maxW="1300px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        pb={{ base: 8, md: 16 }}
        align="center"
      >
        <Box w="full" pos="relative" overflow="hidden">
          <Heading
            left={0}
            right={0}
            margin="0 auto"
            pos="absolute"
            fontSize={{ base: '44px', lg: '120px', md: '100px' }}
            bottom={0}
            mb={{ base: '-15px', lg: '-35px', md: '-25px' }}
            color={'white'}
            _dark={{ color: 'black' }}
            fontWeight="black"
            zIndex={2}
            textAlign="center"
          >
            Merchant Moe
          </Heading>

          <Image
            objectFit="cover"
            fallbackSrc={MoeBanner2x}
            srcSet={`${MoeBanner1x} 1x, ${MoeBanner2x} 2x, ${MoeBanner3x} 3x`}
            w="full"
            h={{ base: '350px', md: '450px' }}
          />
        </Box>

        <HomeHighlightedCards />

        <VStack spacing="40px" w="full">
          <VStack spacing={0}>
            <Heading
              textAlign="center"
              size="3xl"
              color="textPrimary"
              fontWeight="black"
            >
              <Trans>Decentralized Trading</Trans>
            </Heading>
            <Text fontSize="20px" fontWeight="semibold">
              <Trans>powered by Mantle</Trans>
            </Text>
          </VStack>

          <HomeTiles />
        </VStack>
      </Flex>
    </>
  )
}

export default Home
