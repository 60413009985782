import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { RewarderFactoryAbi } from 'constants/abis/RewarderFactory'
import { REWARDER_FACTORY_ADDRESS } from 'constants/moe'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

export enum RewarderType {
  InvalidRewarder,
  MasterChefRewarder,
  VeMoeRewarder,
  JoeStakingRewarder
}

interface UseCreateRewarderProps {
  rewarderType: RewarderType
  currency?: Currency
  farmPid?: bigint
  onSuccess?: (rewarderAddress?: string) => void
}

const useCreateRewarder = ({
  currency,
  farmPid,
  onSuccess,
  rewarderType
}: UseCreateRewarderProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const currencyId = currency
    ? currency.isToken
      ? getAddress(currency.address)
      : zeroAddress
    : undefined

  const { data: config } = useSimulateContract({
    abi: RewarderFactoryAbi,
    address: REWARDER_FACTORY_ADDRESS[chainId],
    args:
      currencyId && farmPid !== undefined
        ? [rewarderType, currencyId, farmPid]
        : undefined,
    chainId,
    functionName: 'createRewarder',
    query: {
      enabled:
        walletChain?.id === chainId && !!currencyId && farmPid !== undefined,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Created rewarder`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const createRewarder = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: () => {
        onSuccess?.(config?.result)
      }
    })

  return {
    createRewarder,
    isLoading: isWaitingForTransaction || isPending,
    isSuccess
  }
}

export default useCreateRewarder
