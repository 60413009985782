import { tabsAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  tabsAnatomy.keys
)

const baseStyle = {
  tab: {
    fontWeight: 'semibold'
  }
}

const line = {
  tab: {
    _selected: {
      color: 'bgInteractive'
    }
  }
}

const solidRounded = {
  tab: {
    _dark: {
      _selected: { bg: 'bgInteractive', color: 'textPrimary' },
      color: 'textPrimary'
    },
    _selected: {
      bg: 'bgInteractive',
      color: 'white'
    },
    borderRadius: '10px',
    color: 'black',
    fontSize: 'md',
    fontWeight: 'regular',
    paddingX: 4,
    paddingY: 1
  },
  tablist: {
    bg: 'bgSecondary',
    borderRadius: '10px',
    height: '40px',
    size: { base: 'sm', md: 'md' }
  }
}

export const tabsTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    line,
    'solid-rounded': solidRounded
  }
})
