import { Box } from '@chakra-ui/react'
import PageHelmet from 'components/PageHelmet'
import React, { useEffect, useState } from 'react'

const TermsOfService = () => {
  const [htmlContent, setHtmlContent] = useState('')
  useEffect(() => {
    fetch('moe_tos.html')
      .then((response) => response.text())
      .then((data) => {
        setHtmlContent(data)
      })
  }, [])

  return (
    <>
      <PageHelmet title="Merchant Moe | Terms of Service" url="tos" />
      <Box
        pt={4}
        maxW="960px"
        margin="0 auto"
        minH="calc(100vh - 80px)"
        px={{ base: 0, md: 4 }}
        pb={{ base: 8, md: 16 }}
        dangerouslySetInnerHTML={{ __html: htmlContent }}
      />
    </>
  )
}

export default TermsOfService
