import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useMemo } from 'react'
import { DexAnalyticsData } from 'types/analytics'
import { getDexbarnChainParam } from 'utils/chains'

dayjs.extend(utc)

interface UsePoolHomeAnalyticsProps {
  version: 'v1' | 'v2'
}

const usePoolHomeAnalytics = ({ version }: UsePoolHomeAnalyticsProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  let urlPrefix: string
  switch (version) {
    case 'v1':
      urlPrefix = '/v1'
      break
    case 'v2':
      urlPrefix = '/v1/lb'
      break
  }

  const DATA_LENGTH = 180

  const utcDataLengthDaysBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(DATA_LENGTH - 1, 'day')
    .unix()

  const now = dayjs()
  const yesterday = now.subtract(1, 'day')

  const fetchDexAnalyticsDayData = useDexbarnGet<DexAnalyticsData[]>(
    `${urlPrefix}/dex/analytics/${chain}?startTime=${utcDataLengthDaysBack}&aggregateBy=daily`
  )

  const { data, isLoading } = useQuery<DexAnalyticsData[]>({
    gcTime: 0,
    queryFn: () => fetchDexAnalyticsDayData(),
    queryKey: ['DexAnalytics180D', chain, urlPrefix]
  })

  const fetchDexAnalyticsHourlyData = useDexbarnGet<DexAnalyticsData[]>(
    `${urlPrefix}/dex/analytics/${chain}?startTime=${yesterday.unix()}&endTime=${now.unix()}&aggregateBy=hourly`
  )

  const { data: todayData, isLoading: isLoadingTodayData } = useQuery<
    DexAnalyticsData[]
  >({
    gcTime: 0,
    queryFn: () => fetchDexAnalyticsHourlyData(),
    queryKey: ['DexAnalyticsPast24H', chain, urlPrefix]
  })
  const volume24H = useMemo(() => {
    const data = todayData ?? []
    return data.reduce((previousValue, currentValue) => {
      return previousValue + currentValue.volumeUsd
    }, 0)
  }, [todayData])

  return { data, isLoading: isLoading || isLoadingTodayData, volume24H }
}

export default usePoolHomeAnalytics
