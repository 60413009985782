import { useQuery } from '@tanstack/react-query'
import { MOE_MASTERCHEF_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { DexbarnFarmBribe } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseGetFarmBribesProps {
  pid: number
}

const useGetFarmBribes = ({ pid }: UseGetFarmBribesProps) => {
  const chainId = useChainId()

  const dexbarnFarmId = `${MOE_MASTERCHEF_ADDRESS[
    chainId
  ].toLowerCase()}-${pid}`
  const fetchBribes = useDexbarnGet<DexbarnFarmBribe[]>(
    `/v1/farms/${getDexbarnChainParam(chainId)}/${dexbarnFarmId}/bribes`
  )

  return useQuery({
    queryFn: () => fetchBribes(),
    queryKey: ['FarmBribesQuery', chainId, pid]
  })
}

export default useGetFarmBribes
