import { Tag } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import PointsBadge from 'components/PointsBadge'
import PoolCard from 'components/PoolCard'
import React from 'react'
import { VaultWithUserPosition } from 'types/vault'
import { formattedNum } from 'utils/format'
import { getVaultRowFormattedValues } from 'utils/vault'

interface VaultMobileRowProps {
  vault: VaultWithUserPosition
}

const VaultMobileRow = ({ vault }: VaultMobileRowProps) => {
  const {
    apt1dPriceChange,
    apt1dPriceChangeColor,
    aptPrice,
    feesUsd1D,
    liquidity,
    myFunds
  } = getVaultRowFormattedValues(vault)

  const fmtTotalFundsDeposit =
    myFunds > 0
      ? formattedNum(myFunds, {
          usd: true
        })
      : '--'

  const tags = [
    <Tag
      key="fees-tag"
      variant="solid"
      size="md"
      bg="bgPrimary"
      textColor="textPrimary"
    >
      {`${vault.lbPair.baseFeePct}%`}
    </Tag>,
    vault.points.length > 0 ? (
      <PointsBadge key="points-badge" points={vault.points} />
    ) : null
  ]
  if (vault.isRewarded) {
    tags.push(
      <Tag variant="solid" size="md" bg="green.400" textColor="white">
        <Trans>REWARDS</Trans>
      </Tag>
    )
  }

  return (
    <PoolCard
      w="full"
      href={`/vault/${vault.id}`}
      token0={vault.tokenX}
      token1={vault.tokenY}
      tags={tags}
      isLabelsColumn
      columns={[
        {
          title: t`Liquidity`,
          value: liquidity
        },
        {
          title: t`My Funds`,
          value: fmtTotalFundsDeposit
        },
        {
          title: t`Price`,
          value: aptPrice
        },
        {
          title: t`Pct Change (24H)`,
          value: apt1dPriceChange,
          valueTextColor: apt1dPriceChangeColor
        },
        {
          title: t`Fees (24H)`,
          value: feesUsd1D
        }
      ]}
    />
  )
}

export default VaultMobileRow
