import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { VaultSharePriceData } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseVaultSharePriceDataProps {
  vaultAddress: string
  enabled?: boolean
}

const useVaultSharePriceData = ({
  enabled,
  vaultAddress
}: UseVaultSharePriceDataProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const now = dayjs()
  const ninetyDaysAgo = now.subtract(90, 'day')

  const fromTimestamp = ninetyDaysAgo.unix()
  const toTimestamp = now.unix()

  const fetchSharePriceData = useDexbarnGet<VaultSharePriceData[]>(
    `/v1/lb/vaults/${chain}/${vaultAddress.toLowerCase()}/share-price`
  )
  return useQuery({
    enabled,
    queryFn: () =>
      fetchSharePriceData({ params: { fromTimestamp, toTimestamp } }),
    queryKey: ['UseVaultSharePriceData', vaultAddress]
  })
}

export default useVaultSharePriceData
