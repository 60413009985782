import {
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { DexbarnUserRewarder } from 'types/dexbarn'

import UserRewarderTableRow from './UserRewarderTableRow'

interface UserRewardersTableProps {
  isLoading: boolean
  userRewarders?: DexbarnUserRewarder[]
}

const UserRewardersTable = ({
  isLoading,
  userRewarders = []
}: UserRewardersTableProps) => {
  return userRewarders.length > 0 ? (
    <TableContainer mt={4}>
      <Table
        variant="card"
        style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
      >
        <Thead>
          <Tr>
            <Th>Pool</Th>
            <Th>Rewarder</Th>
            <Th>Start</Th>
            <Th>End</Th>
            <Th textAlign="right">Emissions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {userRewarders.map((rewarder) => (
            <UserRewarderTableRow
              key={rewarder.contractId}
              rewarder={rewarder}
            />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : isLoading ? (
    <Center my={10}>
      <Spinner />
    </Center>
  ) : (
    <Center h="300px">
      <Text textColor="textSecondary">
        <Trans>No rewarders found</Trans>
      </Text>
    </Center>
  )
}

export default UserRewardersTable
