import { LBFactoryV21ABI, LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import { LB_FACTORY_V22_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { getAddress } from 'viem'
import { useReadContract, useReadContracts } from 'wagmi'

import useLBPairFeeParameters from './useLBPairFeeParameters'

interface UseLBPairDataProps {
  binStep?: string
  token0Address?: string
  token1Address?: string
}

const useLBPairData = ({
  binStep,
  token0Address,
  token1Address
}: UseLBPairDataProps) => {
  const chainId = useChainId()

  const { data: lbPairInfo, isLoading: isLoadingPairInfo } = useReadContract({
    abi: LBFactoryV21ABI,
    address: getAddress(LB_FACTORY_V22_ADDRESS[chainId]),
    args:
      token0Address && token1Address && binStep
        ? [
            getAddress(token0Address),
            getAddress(token1Address),
            BigInt(binStep)
          ]
        : undefined,
    chainId,
    functionName: 'getLBPairInformation',
    query: { enabled: !!token0Address && !!token1Address && !!binStep }
  })

  const lbPairContract = {
    abi: LBPairV21ABI,
    address: lbPairInfo?.LBPair,
    chainId
  }
  const {
    data: reservesAndId,
    isLoading: isLoadingReservesAndId,
    refetch: refetchReservesAndActiveBinId
  } = useReadContracts({
    contracts: [
      {
        ...lbPairContract,
        functionName: 'getReserves'
      },
      {
        ...lbPairContract,
        functionName: 'getActiveId'
      }
    ],
    query: { enabled: !!lbPairInfo }
  })
  const reserves = reservesAndId?.[0].result as [bigint, bigint] | undefined

  const { data: feeParameters, isLoading: isLoadingFeeParameters } =
    useLBPairFeeParameters({ lbPairAddress: lbPairInfo?.LBPair })

  const activeBinId: number | undefined =
    reservesAndId && Number(reservesAndId[1].result)

  return {
    activeBinId,
    feeParameters,
    isLoading:
      isLoadingPairInfo || isLoadingReservesAndId || isLoadingFeeParameters,
    lbPairInfo,
    refetchReservesAndActiveBinId,
    reserveX: reserves?.[0],
    reserveY: reserves?.[1]
  }
}

export default useLBPairData
