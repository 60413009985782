import { Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import WithdrawSharesPanel from 'components/WithdrawSharesPanel'
import { CLOSED_AUTO_POOLS } from 'constants/vaults'
import useChainId from 'hooks/useChainId'
import useEmergencyWithdraw from 'hooks/vault/useEmergencyWithdraw'
import useVaultQueueWithdrawal from 'hooks/vault/useVaultQueueWithdrawal'
import React, { useCallback, useState } from 'react'
import { formatUnits } from 'viem'

interface VaultRemoveLiquidityPanelProps {
  vaultAddress: string
  currency0?: Currency
  currency1?: Currency
  onRemoveLiquiditySuccess?: () => void
}

const VaultRemoveLiquidityPanel = ({
  currency0,
  currency1,
  onRemoveLiquiditySuccess,
  vaultAddress
}: VaultRemoveLiquidityPanelProps) => {
  const chainId = useChainId()

  const [percentageToWithdraw, setPercentageToWithdraw] = useState<string>('')
  const [isPercentageInvalid, setIsPercentageInvalid] = useState(false)

  const isAutoPoolClosed = CLOSED_AUTO_POOLS[chainId]
    .map((address) => address.toLowerCase())
    .includes(vaultAddress.toLowerCase())

  const onWithdrawSuccess = () => {
    setPercentageToWithdraw('')
    onRemoveLiquiditySuccess?.()
  }

  const {
    isLoading: isWithdrawing,
    previewAmounts,
    queueWithdrawal
  } = useVaultQueueWithdrawal({
    basisPointToWithdraw: percentageToWithdraw
      ? Number(percentageToWithdraw) * 100
      : undefined,
    enabled: !isPercentageInvalid && !isAutoPoolClosed,
    onSuccess: onWithdrawSuccess,
    vaultAddress
  })

  const { isLoading: isEmergencyWithdrawLoading, withdraw: emergencyWithdraw } =
    useEmergencyWithdraw({
      onSuccess: onWithdrawSuccess,
      vaultAddress
    })

  const formatPreviewAmount = useCallback(
    (amount?: bigint, decimals?: number) => {
      if (!amount || isPercentageInvalid || !decimals) return undefined
      return Number(formatUnits(amount, decimals))
    },
    [isPercentageInvalid]
  )

  return (
    <WithdrawSharesPanel
      isRemoving={isWithdrawing || isEmergencyWithdrawLoading}
      onRemoveClick={isAutoPoolClosed ? emergencyWithdraw : queueWithdrawal}
      amountDesired0={formatPreviewAmount(
        previewAmounts?.[0],
        currency0?.decimals
      )}
      amountDesired1={formatPreviewAmount(
        previewAmounts?.[1],
        currency1?.decimals
      )}
      tokenSymbol0={currency0?.symbol}
      tokenSymbol1={currency1?.symbol}
      percentage={percentageToWithdraw}
      onPercentageChange={(pct, isInvalid) => {
        setPercentageToWithdraw(pct)
        setIsPercentageInvalid(isInvalid)
      }}
      footer={
        percentageToWithdraw ? (
          <Text textAlign="center" fontSize="sm" textColor="textSecondary">
            <Trans>
              You will be able to redeem your tokens the next time the vault
              rebalances its position.
            </Trans>
          </Text>
        ) : undefined
      }
    />
  )
}

export default VaultRemoveLiquidityPanel
