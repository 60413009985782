import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import { MOE_MASTERCHEF_ADDRESS } from 'constants/moe'
import useDepositToFarm from 'hooks/farms/useDepositToFarm'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useMemo } from 'react'
import { Farm } from 'types/dexlens'
import { toSdkChainId } from 'utils/chains'

interface FarmStakePanelProps {
  farm: Farm
  onStakeSuccess: () => void
}

const FarmStakePanel = ({ farm, onStakeSuccess }: FarmStakePanelProps) => {
  const chainId = useChainId()

  const lpToken = useMemo(
    () =>
      new Token(
        toSdkChainId(chainId),
        farm.lpToken.token,
        Number(farm.lpToken.decimals),
        farm.lpToken.symbol
      ),
    [chainId, farm]
  )
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: lpToken
  })

  const { data: lpTokenUserBalance, refetch: refetchLpTokenUserBalance } =
    useTokenBalance({
      token: lpToken.address
    })
  const debounceRefetchLpTokenUserBalance = debounce(
    refetchLpTokenUserBalance,
    2000
  )

  const isExceedingBalance = lpTokenUserBalance
    ? Number(amount) > Number(lpTokenUserBalance.formatted)
    : false

  const isAmountInvalid = isExceedingBalance || Number(amount) === 0

  const { approvalType, approve, isApproved, isApproving, setApprovalType } =
    useApproveSpenderIfNeeded({
      amount: amountBN,
      spender: MOE_MASTERCHEF_ADDRESS[chainId],
      token: lpToken.address,
      tokenSymbol: lpToken.symbol
    })

  const { deposit, isLoading: isDepositing } = useDepositToFarm({
    amount: amountBN,
    enabled: !isAmountInvalid && isApproved,
    farmName: `${farm.reserves.token0.symbol} / ${farm.reserves.token1.symbol}`,
    onSuccess: () => {
      setAmount('')
      debounceRefetchLpTokenUserBalance()
      onStakeSuccess()
    },
    pid: farm.pid
  })

  return (
    <VStack spacing={10}>
      <CurrencyInput
        data-cy="farm-stake-input"
        heading={t`Amount to stake:`}
        headingProps={{ fontSize: 'sm' }}
        currency={lpToken}
        value={amount}
        onValueChange={setAmount}
        h="60px"
        balance={lpTokenUserBalance?.formatted}
        error={
          isExceedingBalance ? t`Not enough ${lpToken?.symbol}` : undefined
        }
        rightElement={
          lpTokenUserBalance ? (
            <MaxButton
              data-cy="farm-stake-max-button"
              rounded="full"
              balance={lpTokenUserBalance.formatted}
              onClick={() => setAmount(lpTokenUserBalance.formatted)}
              bg="bgCard"
              mr={-2}
            />
          ) : undefined
        }
      />

      <VStack w="full" spacing={4}>
        {approve && !isApproved && !isAmountInvalid ? (
          <ApproveTokenButton
            data-cy="farm-stake-approve-button"
            amount={amount}
            currencySymbol={lpToken.symbol}
            approvalType={approvalType}
            onApprovalTypeSelect={setApprovalType}
            isLoading={isApproving}
            onClick={approve}
          >
            {t`Approve ${lpToken.symbol}`}
          </ApproveTokenButton>
        ) : null}

        <Web3Button
          data-cy="farm-stake-button"
          size="xl"
          colorScheme="accent"
          variant="primary"
          w="full"
          isDisabled={isAmountInvalid || !deposit}
          loadingText={t`Staking`}
          onClick={deposit}
          isLoading={isDepositing}
        >
          <Trans>Stake</Trans>
        </Web3Button>
      </VStack>
    </VStack>
  )
}

export default FarmStakePanel
