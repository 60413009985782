export const FEE_RATE = 0.0025 // 0.25%
export const POOL_SHARE_PRECISION = 100000000

export const POOL_HELMET_TITLE =
  'Merchant Moe | Leading Decentralized Exchange on Mantle Network | Pool'
export const POOL_HELMET_DESCRIPTION =
  'Join Trader Joe, your go-to DEX, for a seamless DeFi experience. Trade or explore thousands of tokens, provide liquidity to earn fees. Trader Joe makes DeFi easy.'

export const POOL_DISTRIBUTION_CHART_RADIUS = {
  initial: 60,
  max: 400,
  min: 20,
  step: 40
}
