import { Tag, Text } from '@chakra-ui/react'
import React from 'react'
import { RewarderStatus } from 'types/farm'

interface FarmBribeStatusTagProps {
  status: RewarderStatus
}

const FarmBribeStatusTag = ({ status }: FarmBribeStatusTagProps) => {
  let colorScheme = ''
  switch (status) {
    case RewarderStatus.PRELAUNCH:
      colorScheme = 'white'
      break
    case RewarderStatus.LIVE:
      colorScheme = 'green'
      break
    case RewarderStatus.ENDED:
    case RewarderStatus.STOPPED:
      colorScheme = 'red'
      break
  }

  return (
    <Tag colorScheme={colorScheme}>
      <Text>{status}</Text>
    </Tag>
  )
}

export default FarmBribeStatusTag
