import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  FlexProps,
  Grid,
  Heading,
  HStack,
  Tag,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CurrencyLogo from 'components/CurrencyLogo'
import PointsBadge from 'components/PointsBadge'
import PoolCard from 'components/PoolCard'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { LBPool, Pool } from 'types/pool'
import { formattedNum } from 'utils/format'
import { getPoolDetailUrl } from 'utils/poolUrl'

interface PoolMobileCardProps {
  pool: Pool | LBPool
}

const PoolMobileCard = ({
  pool,
  ...props
}: PoolMobileCardProps & FlexProps): JSX.Element => {
  const chainId = useChainId()

  const [isExpanded, setIsExpanded] = React.useState(false)

  const lbRewards = 'lbRewards' in pool ? pool.lbRewards : undefined
  const rewardsApr =
    lbRewards
      ?.map((reward) => reward.rewardsApr || 0)
      .reduce((a, b) => a + b, 0) || 0
  const totalApr = pool.apr + rewardsApr

  return (
    <PoolCard
      token0={pool.tokenX}
      token1={pool.tokenY}
      href={getPoolDetailUrl(pool, chainId)}
      columns={[
        {
          title: t`Liquidity`,
          value: formattedNum(pool.liquidityUsd, { usd: true })
        },
        {
          title: t`Volume (24H)`,
          value: formattedNum(pool.volumeUsd, { usd: true })
        },
        {
          title: t`APR (24H)`,
          value: `${formattedNum(totalApr * 100, { places: 2 })}%`
        }
      ]}
      tags={[
        <Tag
          key="fees-tag"
          variant="solid"
          size="md"
          bg="bgSecondary"
          textColor="textPrimary"
        >
          {`${pool.feePct}%`}
        </Tag>,
        pool.points.length > 0 ? (
          <PointsBadge key="points-badge" points={pool.points} />
        ) : null
      ]}
      bottomContent={
        lbRewards && lbRewards.length > 0 ? (
          <Box mt={4} mb={-2}>
            {isExpanded ? (
              <Box
                mb={4}
                fontSize="sm"
                borderTop="1px solid"
                borderTopColor="border"
              >
                <VStack spacing={4} align="flex-start" mt={4}>
                  <Heading size="sm">
                    <Trans>Rewards</Trans>
                  </Heading>

                  <Grid
                    templateColumns="minmax(0, 1.25fr) minmax(0, 2fr) minmax(0, 2fr)"
                    fontSize="sm"
                    rowGap={2}
                    columnGap={4}
                  >
                    <Text textColor="textSecondary">
                      <Trans>Token</Trans>
                    </Text>

                    <Text textColor="textSecondary">
                      <Trans>Rewards per day</Trans>
                    </Text>

                    <Text textColor="textSecondary">
                      <Trans>Time remaining</Trans>
                    </Text>

                    {lbRewards.map((reward, index) => {
                      return (
                        <React.Fragment key={index}>
                          <HStack alignSelf="flex-start">
                            <CurrencyLogo
                              boxSize={4}
                              address={
                                reward.token.isToken
                                  ? reward.token.address
                                  : undefined
                              }
                              symbol={reward.token.symbol}
                            />
                            <Text fontWeight="semibold">
                              {reward.token.symbol}
                            </Text>
                          </HStack>
                          <Text fontWeight="semibold">
                            {formattedNum(reward.rewardsPerDay)}{' '}
                            {reward.token.symbol}
                          </Text>
                          <Text fontWeight="semibold">
                            {reward.duration || 'N/A'}
                          </Text>
                        </React.Fragment>
                      )
                    })}
                  </Grid>
                </VStack>
              </Box>
            ) : null}
            <Button
              w="full"
              variant="ghost"
              bg="bgCard"
              h={12}
              borderTop="1px solid"
              borderColor="border"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                setIsExpanded(!isExpanded)
              }}
              color="textSecondary"
              rightIcon={
                isExpanded ? (
                  <ChevronUpIcon boxSize={5} color="textSecondary" />
                ) : (
                  <ChevronDownIcon boxSize={5} color="textSecondary" />
                )
              }
            >
              {isExpanded ? (
                <Trans>Hide Rewards</Trans>
              ) : (
                <Trans>Show Rewards</Trans>
              )}
            </Button>
          </Box>
        ) : undefined
      }
      {...props}
    />
  )
}

export default PoolMobileCard
