import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import { VE_MOE_TOKEN } from 'constants/tokens'
import useVeMoeUserBalance from 'hooks/farms/useVeMoeUserBalance'
import useVoteForFarm from 'hooks/farms/useVote'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import debounce from 'lodash.debounce'
import React from 'react'
import { Farm } from 'types/dexlens'

interface AddVoteToFarmPanelProps {
  farm: Farm
  onAddVoteSuccess?: () => void
}

const AddVoteToFarmPanel = ({
  farm,
  onAddVoteSuccess
}: AddVoteToFarmPanelProps) => {
  const chainId = useChainId()

  const veMoeToken = VE_MOE_TOKEN[chainId]
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: veMoeToken
  })

  const {
    data: { userAvailableVeMoeBalance } = {},
    refetch: refetchVeMoeUserBalance
  } = useVeMoeUserBalance()
  const debouncedRefetchVeMoeBalance = debounce(
    () => refetchVeMoeUserBalance(),
    2000
  )

  const isExceedingBalance = userAvailableVeMoeBalance
    ? Number(amount) > Number(userAvailableVeMoeBalance.formatted)
    : false

  const isAmountInvalid = isExceedingBalance || Number(amount) === 0

  const { isLoading: isVoting, voteForFarm } = useVoteForFarm({
    deltaAmount: amountBN,
    enabled: !isAmountInvalid,
    onSuccess: () => {
      setAmount('')
      debouncedRefetchVeMoeBalance()
      onAddVoteSuccess?.()
    },
    pid: farm.pid
  })

  return (
    <VStack spacing={10}>
      <CurrencyInput
        data-cy="add-votes-input"
        heading={t`Amount to add:`}
        headingProps={{ fontSize: 'sm' }}
        currency={veMoeToken}
        value={amount}
        onValueChange={setAmount}
        h="60px"
        balance={userAvailableVeMoeBalance?.formatted}
        error={
          isExceedingBalance ? t`Not enough ${veMoeToken?.symbol}` : undefined
        }
      />

      <Web3Button
        data-cy="add-votes-button"
        size="xl"
        colorScheme="accent"
        variant="primary"
        w="full"
        isDisabled={isAmountInvalid || !voteForFarm}
        isLoading={isVoting}
        loadingText={t`Adding Votes`}
        onClick={voteForFarm}
      >
        <Trans>Add Votes</Trans>
      </Web3Button>
    </VStack>
  )
}

export default AddVoteToFarmPanel
