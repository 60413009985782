type LevelInfo = {
  moeJourneyTickets: number
  tier: number
  veMoeAmount: number
}

export const MOE_JOURNEY_VE_MOE_TIERS: LevelInfo[] = [
  { moeJourneyTickets: 20, tier: 1, veMoeAmount: 500000 },
  { moeJourneyTickets: 40, tier: 2, veMoeAmount: 1000000 },
  { moeJourneyTickets: 80, tier: 3, veMoeAmount: 2000000 },
  { moeJourneyTickets: 120, tier: 4, veMoeAmount: 4000000 },
  { moeJourneyTickets: 200, tier: 5, veMoeAmount: 7500000 },
  { moeJourneyTickets: 250, tier: 6, veMoeAmount: 10000000 },
  { moeJourneyTickets: 300, tier: 7, veMoeAmount: 12500000 },
  { moeJourneyTickets: 375, tier: 8, veMoeAmount: 15000000 },
  { moeJourneyTickets: 450, tier: 9, veMoeAmount: 17500000 },
  { moeJourneyTickets: 500, tier: 10, veMoeAmount: 20000000 },
  { moeJourneyTickets: 550, tier: 11, veMoeAmount: 25000000 },
  { moeJourneyTickets: 600, tier: 12, veMoeAmount: 35000000 },
  { moeJourneyTickets: 650, tier: 13, veMoeAmount: 50000000 },
  { moeJourneyTickets: 700, tier: 14, veMoeAmount: 65000000 },
  { moeJourneyTickets: 750, tier: 15, veMoeAmount: 80000000 },
  { moeJourneyTickets: 800, tier: 16, veMoeAmount: 100000000 },
  { moeJourneyTickets: 900, tier: 17, veMoeAmount: 150000000 },
  { moeJourneyTickets: 1000, tier: 18, veMoeAmount: 200000000 },
  { moeJourneyTickets: 1500, tier: 19, veMoeAmount: 400000000 },
  { moeJourneyTickets: 2000, tier: 20, veMoeAmount: 600000000 }
]

export function findLevelByVeMoeAmount(
  veMoeAmount: number
): LevelInfo | undefined {
  for (let i = 0; i < MOE_JOURNEY_VE_MOE_TIERS.length; i++) {
    if (
      veMoeAmount >= MOE_JOURNEY_VE_MOE_TIERS[i].veMoeAmount &&
      (i === MOE_JOURNEY_VE_MOE_TIERS.length - 1 ||
        veMoeAmount < MOE_JOURNEY_VE_MOE_TIERS[i + 1].veMoeAmount)
    ) {
      return MOE_JOURNEY_VE_MOE_TIERS[i]
    }
  }
  return {
    moeJourneyTickets: 0,
    tier: 0,
    veMoeAmount: 0
  }
}
