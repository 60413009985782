import { ChainId, Token } from '@traderjoe-xyz/sdk-core'
import { zeroAddress } from 'viem'

import { MerchantMoeChainId } from './chains'
import { MOE_TOKEN_ADDRESS } from './moe'

export const JOE_TOKEN: { [chainId in MerchantMoeChainId]: Token } = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    '0xB5Bd280567C5A62df1A5570c88e63a5670cBA22d',
    18,
    'JOE',
    'JoeToken'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.FUJI,
    '0x371c7ec6D8039ff7933a2AA28EB827Ffe1F52f07',
    18,
    'JOE',
    'JoeToken'
  )
}

export const MOE_TOKEN: { [chainId in MerchantMoeChainId]: Token } = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    MOE_TOKEN_ADDRESS[MerchantMoeChainId.FUJI],
    18,
    'MOE',
    'MoeToken'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    MOE_TOKEN_ADDRESS[MerchantMoeChainId.MANTLE],
    18,
    'MOE',
    'MoeToken'
  )
}

// veMOE token does not exist but we need to create a token object to use in some places
export const VE_MOE_TOKEN: { [chainId in MerchantMoeChainId]: Token } = {
  [MerchantMoeChainId.FUJI]: new Token(
    ChainId.FUJI,
    zeroAddress,
    18,
    'veMOE',
    'VeMoeToken'
  ),
  [MerchantMoeChainId.MANTLE]: new Token(
    ChainId.MANTLE,
    '0x55160b0f39848A7B844f3a562210489dF301dee7',
    18,
    'veMOE',
    'VeMoeToken'
  )
}
