export const MOE_JOURNEY_REWARDER_ADDRESS =
  '0xaf38d5664ede982516aad35de91f9bfd2d161be0'

export const MOE_JOURNEY_S4_START_DATE = new Date(1720458000000)

// epochs are 2 weeks long starting from the start date
export const getCurrentJourneyEpochS4 = () => {
  const now = new Date().getTime()
  const startDate = MOE_JOURNEY_S4_START_DATE.getTime()
  const epochDuration = 14 * 24 * 60 * 60 * 1000

  if (now < startDate) {
    return {
      epochEndDate: new Date(startDate + epochDuration),
      epochStartDate: new Date(startDate)
    }
  }

  const diff = now - startDate
  const epochNumber = Math.floor(diff / epochDuration)
  const epochStartDate = new Date(startDate + epochNumber * epochDuration)
  const epochEndDate = new Date(startDate + (epochNumber + 1) * epochDuration)

  return {
    epochEndDate,
    epochNumber: epochNumber + 1,
    epochStartDate
  }
}
