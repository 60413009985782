import { MoeLensAbi } from 'constants/abis/MoeLens'
import { MOE_LENS_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { zeroAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

interface UseGetLensFarmProps {
  pid?: bigint
}

const useGetLensFarm = ({ pid }: UseGetLensFarmProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  return useReadContract({
    abi: MoeLensAbi,
    address: MOE_LENS_ADDRESS[chainId],
    args: pid !== undefined ? [pid, account || zeroAddress] : undefined,
    chainId,
    functionName: 'getFarmDataAt',
    query: { enabled: pid !== undefined }
  })
}

export default useGetLensFarm
