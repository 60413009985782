import { useQuery } from '@tanstack/react-query'
import { Currency, Token, TokenAmount } from '@traderjoe-xyz/sdk-core'
import { PairV2, RouteV2, TradeV2 } from '@traderjoe-xyz/sdk-v2'
import useChainId from 'hooks/useChainId'
import { TradeBestPath } from 'types/trade'
import { toSdkChainId } from 'utils/chains'
import { convertStringToBN } from 'utils/format'
import { BASES_TO_CHECK_TRADES_AGAINST } from 'utils/swap'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'
import { usePublicClient } from 'wagmi'

interface UseGetOnChainBestTradeProps {
  isExactIn: boolean
  inputCurrency?: Currency
  outputCurrency?: Currency
  refetchInterval?: number
  typedTokenAmount?: TokenAmount
}

const useGetOnChainBestTrade = ({
  inputCurrency,
  isExactIn,
  outputCurrency,
  refetchInterval,
  typedTokenAmount
}: UseGetOnChainBestTradeProps) => {
  const chainId = useChainId()
  const publicClient = usePublicClient({ chainId })

  const [inputToken, outputToken] = [
    wrappedCurrency(inputCurrency, chainId),
    wrappedCurrency(outputCurrency, chainId)
  ]

  return useQuery({
    enabled: !!typedTokenAmount && !!inputToken && !!outputToken,
    gcTime: 0,
    queryFn: async () => {
      try {
        if (
          !typedTokenAmount ||
          !inputCurrency ||
          !outputCurrency ||
          !inputToken ||
          !outputToken ||
          !publicClient
        ) {
          return null
        }

        const bases: Token[] = BASES_TO_CHECK_TRADES_AGAINST[chainId]
        const allTokenPairs = PairV2.createAllTokenPairs(
          inputToken,
          outputToken,
          bases
        )
        const allPairs = PairV2.initPairs(allTokenPairs)
        const allRoutes = RouteV2.createAllRoutes(
          allPairs,
          inputToken,
          outputToken
        )

        const isNativeIn = inputCurrency?.isNative ?? false
        const isNativeOut = outputCurrency?.isNative ?? false

        const trades = isExactIn
          ? await TradeV2.getTradesExactIn(
              allRoutes,
              typedTokenAmount,
              outputToken,
              isNativeIn,
              isNativeOut,
              publicClient,
              toSdkChainId(chainId)
            )
          : await TradeV2.getTradesExactOut(
              allRoutes,
              typedTokenAmount,
              inputToken,
              isNativeIn,
              isNativeOut,
              publicClient,
              toSdkChainId(chainId)
            )

        const validTrades = trades.filter(Boolean) as TradeV2[]
        const bestTrade = TradeV2.chooseBestTrade(validTrades, isExactIn)

        if (!bestTrade) {
          return null
        }

        const amountIn = convertStringToBN(
          bestTrade.inputAmount.toFixed(),
          bestTrade.inputAmount.token.decimals
        )

        const amountOut = convertStringToBN(
          bestTrade.outputAmount.toFixed(),
          bestTrade.outputAmount.token.decimals
        )

        if (!amountIn || !amountOut) {
          return null
        }

        const { binSteps, pairs: pairsAddr, versions } = bestTrade.quote
        const { path } = bestTrade.route
        const pairs = path.slice(1).map((token, i) => [path[i], token])

        const tradeBestPath: TradeBestPath = {
          amountIn: {
            formatted: formatUnits(
              amountIn,
              bestTrade.inputAmount.token.decimals
            ),
            value: amountIn
          },
          amountOut: {
            formatted: formatUnits(
              amountOut,
              bestTrade.outputAmount.token.decimals
            ),
            value: amountOut
          },
          currencyIn: inputCurrency,
          currencyOut: outputCurrency,
          path: pairs.map(([tokenIn, tokenOut], i) => {
            return {
              binStep: binSteps[i],
              pairId: pairsAddr[i],
              pairName: `${tokenIn.symbol}/${tokenOut.symbol}`,
              tokenInId: tokenIn.address,
              tokenInSymbol: tokenIn.symbol || '',
              tokenOutId: tokenOut.address,
              tokenOutSymbol: tokenOut.symbol || '',
              version: versions[i]
            }
          }),
          priceImpact: Number(bestTrade.priceImpact.toFixed(2))
        }

        return tradeBestPath
      } catch (e) {
        console.error('Error fetchAllPossibleTrades', e)
        return null
      }
    },
    queryKey: [
      'trades',
      chainId,
      inputCurrency,
      outputCurrency,
      typedTokenAmount
    ],
    refetchInterval
  })
}

export default useGetOnChainBestTrade
