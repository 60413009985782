import { useQuery } from '@tanstack/react-query'
import dayjs from 'dayjs'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface PoolV1ChartData {
  date: number
  value: number
}

interface PoolAnalyticsData {
  date: string
  reserveNative: number
  reserveUsd: number
  reserveX: number
  reserveY: number
  timestamp: number
  volumeNative: number
  volumeUsd: number
  volumeX: number
  volumeY: number
}

interface UsePoolDetailV1AnalyticsProps {
  poolAddress?: string
}

const usePoolDetailV1Analytics = ({
  poolAddress
}: UsePoolDetailV1AnalyticsProps) => {
  const chainId = useChainId()

  const DATA_LENGTH = 180

  const utcDataLengthDaysBack = dayjs()
    .utc()
    .startOf('day')
    .subtract(DATA_LENGTH - 1, 'day')
    .unix()

  const fetchPoolAnalytics = useDexbarnGet<PoolAnalyticsData[]>(
    `v1/pools/analytics/${getDexbarnChainParam(chainId)}/${poolAddress}`
  )

  return useQuery({
    enabled: !!poolAddress,
    queryFn: () =>
      fetchPoolAnalytics({
        params: { aggregateBy: 'daily', startTime: utcDataLengthDaysBack }
      }),
    queryKey: ['PoolAnalyticsV1', chainId, poolAddress, utcDataLengthDaysBack],
    select: (data) => {
      const chartData = data.reduce<{
        liquidity: PoolV1ChartData[]
        volume: PoolV1ChartData[]
      }>(
        (previousValue, currentValue) => {
          const date = new Date(currentValue.date)
          previousValue['liquidity'].unshift({
            date: date.getTime() / 1000,
            value: currentValue.reserveUsd
          })
          previousValue['volume'].unshift({
            date: date.getTime() / 1000,
            value: currentValue.volumeUsd
          })
          return previousValue
        },
        { liquidity: [], volume: [] }
      )
      return {
        chartData,
        pairDayDatas: data
      }
    }
  })
}

export default usePoolDetailV1Analytics
