import { ChevronDownIcon } from '@chakra-ui/icons'
import { Collapse, Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import ContractLink from 'components/ContractLink'
import Warning from 'components/Warning'
import useIsTrustedTrade from 'hooks/swap/useIsTrustedTrade'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { useState } from 'react'
import { TradeBestPath } from 'types/trade'
import { formattedNum } from 'utils/format'
import { tradeExpandSummary } from 'utils/measure'
import { formatUnits } from 'viem'

import RouteDetails from './RouteDetails'

interface TradeDetailsRowProps {
  title: string
  value: string
}

const getWarningMessage = (symbol: string): string => {
  return `${symbol} is not on the verified token list`
}

const TradeDetailsRow = ({ title, value }: TradeDetailsRowProps) => (
  <HStack w="full" justify="space-between">
    <Text fontSize="sm" color="textSecondary">
      {title}
    </Text>
    <Text fontSize="sm" fontWeight="semibold">
      {value}
    </Text>
  </HStack>
)

interface TradeDetailsProps {
  allowedSlippage: number
  isExactIn: boolean
  trade: TradeBestPath
}

const TradeDetails = ({
  allowedSlippage,
  isExactIn,
  trade
}: TradeDetailsProps) => {
  const { nativeCurrency } = useActiveChain()
  const [isOpen, setIsOpen] = useState(false)
  const [isInverted, setIsInverted] = useState(false)

  const isNativeIn = trade.currencyIn.isNative
  const isNativeOut = trade.currencyOut.isNative

  const formattedPrice = isInverted
    ? formattedNum(
        Number(trade.amountIn.formatted) / Number(trade.amountOut.formatted),
        { places: 8 }
      )
    : formattedNum(
        Number(trade.amountOut.formatted) / Number(trade.amountIn.formatted),
        { places: 8 }
      )

  const inputTokenSymbol = isNativeIn
    ? nativeCurrency?.symbol
    : trade.currencyIn.symbol
  const outputTokenSymbol = isNativeOut
    ? nativeCurrency?.symbol
    : trade.currencyOut.symbol

  const symbolA = isInverted ? outputTokenSymbol : inputTokenSymbol
  const symbolB = isInverted ? inputTokenSymbol : outputTokenSymbol

  // Expected output
  const formattedExpectedOutput = `${formattedNum(trade.amountOut.formatted)} ${
    isNativeOut ? nativeCurrency?.symbol : trade.currencyOut.symbol
  }`

  // Minimum received
  const slippageAdjustedAmount = isExactIn
    ? trade.amountOut.value -
      (trade.amountOut.value * BigInt(allowedSlippage)) / BigInt(10000)
    : trade.amountIn.value +
      (trade.amountIn.value * BigInt(allowedSlippage)) / BigInt(10000)
  const fmtSlippageAdjustedAmount = isExactIn
    ? `${formattedNum(
        formatUnits(slippageAdjustedAmount, trade.currencyOut.decimals)
      )} ${trade.currencyOut.symbol}`
    : `${formattedNum(
        formatUnits(slippageAdjustedAmount, trade.currencyIn.decimals)
      )} ${trade.currencyIn.symbol}`

  // Price impact
  const priceImpact = Number(trade.priceImpact.toFixed(2))
  const formattedPriceImpact = priceImpact < 0.01 ? '<0.01%' : `${priceImpact}%`
  const showPriceImpactWarning = priceImpact > 2

  // TODO: trade fee

  // Trusted
  const { inputTrusted, outputTrusted } = useIsTrustedTrade(trade)

  return (
    <Flex
      flexDir="column"
      w="full"
      borderRadius="10px"
      border="1px solid"
      borderColor="bgPrimary"
      bg={isOpen ? 'bgCard' : 'bgPrimary'}
    >
      <Flex
        align="center"
        justify="space-between"
        w="full"
        minH="40px"
        px={4}
        cursor="pointer"
        bg="bgPrimary"
        borderTopRadius="10px"
        onClick={() => {
          setIsOpen((previous) => !previous)
          if (!isOpen) {
            tradeExpandSummary(symbolA, symbolB)
          }
        }}
      >
        <Text
          fontSize="sm"
          fontWeight="semibold"
          onClick={(e) => {
            e.stopPropagation()
            setIsInverted((previous) => !previous)
          }}
        >
          {`1 ${symbolA} = ${formattedPrice} ${symbolB}`}
        </Text>
        <ChevronDownIcon />
      </Flex>
      <Collapse in={isOpen} animateOpacity>
        <VStack
          px={4}
          py={6}
          pt={4}
          w="full"
          align="flex-start"
          spacing={0.5}
          bg="bgCard"
        >
          <TradeDetailsRow
            title={t`Expected Output:`}
            value={formattedExpectedOutput}
          />
          <TradeDetailsRow
            title={isExactIn ? t`Minimum Received:` : t`Maximum Sold:`}
            value={fmtSlippageAdjustedAmount}
          />
          <TradeDetailsRow
            title={t`Price Impact:`}
            value={formattedPriceImpact}
          />
        </VStack>
        <RouteDetails trade={trade} />
      </Collapse>
      {!inputTrusted && inputTokenSymbol && trade.currencyIn.isToken && (
        <Warning
          message={getWarningMessage(inputTokenSymbol)}
          rightContent={
            <ContractLink
              address={trade.currencyIn.address}
              showAddress={false}
              color="white"
            />
          }
          m={2}
          mt={0}
        />
      )}
      {!outputTrusted && outputTokenSymbol && trade.currencyOut.isToken && (
        <Warning
          message={getWarningMessage(outputTokenSymbol)}
          rightContent={
            <ContractLink
              address={trade.currencyOut.address}
              showAddress={false}
              color="white"
            />
          }
          m={2}
          mt={0}
        />
      )}
      {showPriceImpactWarning && (
        <Warning
          data-cy="price-impact-warning"
          message="Price Impact Warning"
          rightContent={
            <Text fontSize="sm" fontWeight="semibold" color="white">
              {formattedPriceImpact}
            </Text>
          }
          m={2}
          mt={0}
        />
      )}
    </Flex>
  )
}

export default TradeDetails
