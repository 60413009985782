import { useQuery } from '@tanstack/react-query'
import { MOE_STAKING } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { formatUnits } from 'viem'

interface DexbarnData {
  date: string
  deltaAmountStaked: number
  timestamp: number
}

const useGetMoeStakingAnalytics = () => {
  const chainId = useChainId()

  const fetchMoeStakingAnalytics = useDexbarnGet<DexbarnData[]>(
    `/v1/staking/analytics/${getDexbarnChainParam(chainId)}/${
      MOE_STAKING[chainId]
    }`
  )

  return useQuery({
    queryFn: () =>
      fetchMoeStakingAnalytics({ params: { startTime: 1704733200 } }),
    queryKey: ['MoeStakingAnalyticsQuery', chainId],
    select: (data) => {
      let totalStaked = BigInt(0)
      const processedData = data.map((dexbarnData) => {
        totalStaked += BigInt(dexbarnData.deltaAmountStaked)
        return {
          amountStaked: Number(formatUnits(totalStaked, 18)),
          timestamp: dexbarnData.timestamp
        }
      })

      return processedData
    }
  })
}

export default useGetMoeStakingAnalytics
