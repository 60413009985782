import { CNATIVE, Token as TokenSdk } from '@traderjoe-xyz/sdk-core'
import { MerchantMoeChainId } from 'constants/chains'
import { MOE_EMISSION_TO_FARM_SHARE } from 'constants/farm'
import { MOE_TOKEN } from 'constants/tokens'
import { formatDuration, intervalToDuration } from 'date-fns'
import { ExtraHooksRewarderData, HooksRewarderData } from 'types/lbHooksLens'
import { FormattedLbPoolReward, FormattedReward } from 'types/rewards'
import { formatUnits, zeroAddress } from 'viem'

import { getPriceFromBinId } from './bin'
import { toSdkChainId } from './chains'

export const getLbPoolRewards = ({
  chainId,
  currency0Decimals,
  currency1Decimals,
  extraRewarderData,
  lbBinStep,
  rewarderData
}: {
  chainId: MerchantMoeChainId
  currency0Decimals: number
  currency1Decimals: number
  extraRewarderData: ExtraHooksRewarderData
  lbBinStep: number
  rewarderData: HooksRewarderData
}) => {
  const rewards: FormattedLbPoolReward[] = []

  if (rewarderData.hooksParameters.hooks !== zeroAddress) {
    const moeToken = MOE_TOKEN[chainId]

    const rewardsPerSecond =
      Number(formatUnits(rewarderData.moePerSecond, moeToken.decimals)) *
      MOE_EMISSION_TO_FARM_SHARE
    const rewardsPerDay = rewardsPerSecond * 60 * 60 * 24

    rewards.push({
      range: getRewardRange({
        activeBinId: rewarderData.activeId,
        currency0Decimals,
        currency1Decimals,
        lbBinStep,
        rangeEnd: rewarderData.rangeEnd,
        rangeStart: rewarderData.rangeStart
      }),
      rewardsPerDay,
      token: moeToken,
      type: 'base'
    })
  }

  if (
    extraRewarderData.hooksParameters.hooks !== zeroAddress &&
    !extraRewarderData.isEnded
  ) {
    const rewardToken =
      extraRewarderData.rewardToken.token === zeroAddress
        ? CNATIVE.onChain(chainId)
        : new TokenSdk(
            toSdkChainId(chainId),
            extraRewarderData.rewardToken.token,
            Number(extraRewarderData.rewardToken.decimals),
            extraRewarderData.rewardToken.symbol
          )

    const rewardsPerSecond = Number(
      formatUnits(extraRewarderData.rewardPerSecond, rewardToken.decimals)
    )
    const rewardsPerDay = rewardsPerSecond * 60 * 60 * 24

    const duration = extraRewarderData.isStarted
      ? intervalToDuration({
          end: new Date(Number(extraRewarderData.endTimestamp) * 1000),
          start: new Date(Number(extraRewarderData.lastUpdateTimestamp) * 1000)
        })
      : intervalToDuration({
          end: new Date(Number(extraRewarderData.lastUpdateTimestamp) * 1000),
          start: new Date()
        })

    rewards.push({
      duration: extraRewarderData.isEnded
        ? 'Ended'
        : extraRewarderData.isStarted
          ? formatDuration(duration, { format: ['months', 'days', 'hours'] })
          : `Starts in ${formatDuration(duration, {
              format: ['days', 'hours', 'minutes']
            })}`,
      range: getRewardRange({
        activeBinId: extraRewarderData.activeId,
        currency0Decimals,
        currency1Decimals,
        lbBinStep,
        rangeEnd: extraRewarderData.rangeEnd,
        rangeStart: extraRewarderData.rangeStart
      }),
      rewardsPerDay,
      token: rewardToken,
      type: 'extra'
    })
  }

  return rewards
}

const getRewardRange = ({
  activeBinId,
  currency0Decimals,
  currency1Decimals,
  lbBinStep,
  rangeEnd: _rangeEnd,
  rangeStart: _rangeStart
}: {
  activeBinId: bigint
  currency0Decimals: number
  currency1Decimals: number
  lbBinStep: number
  rangeEnd: bigint
  rangeStart: bigint
}) => {
  const activeId = Number(activeBinId)
  const rangeStart = Number(_rangeStart)
  const rangeEnd = Number(_rangeEnd) - 1 // right range is not inclusive

  const activePrice = Number(
    getPriceFromBinId(
      activeId,
      lbBinStep,
      currency0Decimals,
      currency1Decimals,
      10
    )
  )
  const minPrice = Number(
    getPriceFromBinId(
      rangeStart,
      lbBinStep,
      currency0Decimals,
      currency1Decimals,
      10
    )
  )
  const maxPrice = Number(
    getPriceFromBinId(
      rangeEnd,
      lbBinStep,
      currency0Decimals,
      currency1Decimals,
      10
    )
  )

  return {
    end: rangeEnd,
    maxPrice,
    minPrice,
    numBins: rangeEnd - rangeStart + 1,
    spread: {
      max: ((maxPrice - activePrice) / activePrice) * 100,
      min: ((activePrice - minPrice) / activePrice) * 100
    },
    start: rangeStart
  }
}

export const getUserClaimableRewards = ({
  chainId,
  extraRewarderData,
  rewarderData
}: {
  chainId: MerchantMoeChainId
  extraRewarderData: ExtraHooksRewarderData
  rewarderData: HooksRewarderData
}) => {
  const userClaimableRewards: FormattedReward[] = []

  if (rewarderData.hooksParameters.hooks !== zeroAddress) {
    if (rewarderData.pendingRewards > 0) {
      const moeToken = MOE_TOKEN[chainId]
      userClaimableRewards.push({
        token: moeToken,
        tokenAddress: rewarderData.rewardToken.token,
        tokenAmount: Number(
          formatUnits(rewarderData.pendingRewards, moeToken.decimals)
        )
      })
    }
  }

  if (extraRewarderData.hooksParameters.hooks !== zeroAddress) {
    if (extraRewarderData.pendingRewards > 0) {
      const rewardToken =
        extraRewarderData.rewardToken.token === zeroAddress
          ? CNATIVE.onChain(chainId)
          : new TokenSdk(
              toSdkChainId(chainId),
              extraRewarderData.rewardToken.token,
              Number(extraRewarderData.rewardToken.decimals),
              extraRewarderData.rewardToken.symbol
            )
      userClaimableRewards.push({
        token: rewardToken,
        tokenAddress: extraRewarderData.rewardToken.token,
        tokenAmount: Number(
          formatUnits(extraRewarderData.pendingRewards, rewardToken.decimals)
        )
      })
    }
  }

  return userClaimableRewards
}
