import { Currency } from '@traderjoe-xyz/sdk-core'
import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import useChainId from 'hooks/useChainId'
import { useMemo } from 'react'
import { convertLBPositionToLiquidityChartData } from 'utils/poolV2'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface UseLBPairUserDistributionProps {
  activeBinId?: number
  binStep?: string
  currency0?: Currency
  currency1?: Currency
  enabled?: boolean
  lbPairAddress?: string
  owner?: string
}

// hook used in the leaderboard to get the user's liquidity distribution
const useLBPairUserDistribution = ({
  activeBinId: _activeBinId,
  binStep,
  currency0,
  currency1,
  enabled,
  lbPairAddress,
  owner
}: UseLBPairUserDistributionProps) => {
  const chainId = useChainId()

  const { data: activeId, isLoading: isLoadingActiveId } = useReadContract({
    abi: LBPairV21ABI,
    address: lbPairAddress ? getAddress(lbPairAddress) : undefined,
    chainId,
    functionName: 'getActiveId',
    query: { enabled: !_activeBinId && enabled }
  })
  const activeBinId = useMemo(() => {
    if (_activeBinId) {
      return _activeBinId
    }
    return activeId
  }, [activeId, _activeBinId])

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)
  const { isLoadingLBPosition, userBalances } = useLBPosition({
    enabled,
    lbBinStep: Number(binStep),
    lbPairAddress: lbPairAddress,
    owner,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const chartData = useMemo(() => {
    if (!activeBinId || !binStep) {
      return []
    }
    return convertLBPositionToLiquidityChartData({
      activeBinId: activeBinId,
      binStep,
      token0: wrappedCurrency0,
      token1: wrappedCurrency1,
      userBalances
    })
  }, [wrappedCurrency0, wrappedCurrency1, binStep, userBalances, activeBinId])

  const { numBins, totalTokensX, totalTokensY } = useMemo(() => {
    if (!userBalances) {
      return {}
    }
    return {
      numBins: userBalances.positions.length,
      totalTokensX: userBalances.amounts.reduce((acc, balance) => {
        return acc + balance.amountX
      }, 0),
      totalTokensY: userBalances.amounts.reduce((acc, balance) => {
        return acc + balance.amountY
      }, 0)
    }
  }, [userBalances])

  return {
    activeBinId,
    chartData,
    isLoading: isLoadingActiveId || isLoadingLBPosition,
    numBins,
    totalTokensX,
    totalTokensY,
    userBalances
  }
}

export default useLBPairUserDistribution
