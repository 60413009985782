import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Divider,
  Flex,
  Hide,
  HStack,
  IconButton,
  Text,
  useDisclosure
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import ConnectModal from 'components/ConnectModal'
import MobileAccountMenu from 'components/MobileAccountMenu'
import WalletMenu from 'components/WalletMenu'
import PopoverSettingsMenu from 'components/WalletMenu/SettingScreen/PopoverSettingsScreen'
import { chain } from 'constants/chains'
import useSwitchNetwork from 'hooks/useSwitchNetwork'
import React from 'react'
import { MySettingsIcon, WalletIcon, WarningIcon } from 'theme/icons'
import { e2eTestsConfig, isRunningE2ETests } from 'utils/e2e'
import { useAccount, useConnect } from 'wagmi'

const ConnectButton = () => {
  const account = useAccount()
  const { connect, connectors } = useConnect()

  const { switchChain } = useSwitchNetwork()
  const targetChain = chain

  const { isOpen, onClose, onOpen } = useDisclosure()
  const onConnectWalletClick = isRunningE2ETests
    ? () =>
        connect({
          chainId: e2eTestsConfig.chain.id,
          connector: connectors[0]
        })
    : onOpen

  return (
    <div>
      <ConnectModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

      {(() => {
        return (
          <Flex alignItems="center" justifyContent="center" gap={4}>
            {account.address && account.isConnected ? (
              <>
                <Hide below="lg">
                  <WalletMenu
                    accountDisplayName={`${account.address.substring(
                      0,
                      4
                    )}...${account.address.substring(
                      account.address.length - 4
                    )}`}
                    walletAddress={account.address}
                  />
                </Hide>
                <Hide above="lg">
                  <MobileAccountMenu walletAddress={account.address} />
                </Hide>
              </>
            ) : chain.id !== targetChain.id ? (
              <>
                <Hide below="md">
                  <Button
                    flexShrink={0}
                    data-cy="wrong-network-button"
                    colorScheme="red"
                    rightIcon={<ChevronDownIcon />}
                    onClick={() => switchChain?.({ chainId: targetChain.id })}
                  >
                    <Trans>Wrong network</Trans>
                  </Button>
                </Hide>
                <Hide above="md">
                  <Button
                    data-cy="wrong-network-button"
                    colorScheme="yellow"
                    onClick={() => switchChain?.({ chainId: targetChain.id })}
                  >
                    <WarningIcon boxSize={5} fill="white" />
                  </Button>
                </Hide>
              </>
            ) : (
              <>
                <Hide below="lg">
                  <HStack
                    spacing={0}
                    flexShrink={0}
                    boxShadow="element"
                    borderRadius="10px"
                  >
                    <Button
                      data-cy="connect-wallet-button"
                      h="40px"
                      leftIcon={<WalletIcon w="20px" />}
                      onClick={onConnectWalletClick}
                      borderRight={0}
                      borderRadius={0}
                      borderLeftRadius="10px"
                      bg="bgCard"
                    >
                      <Text>
                        <Trans>Connect Wallet</Trans>
                      </Text>
                    </Button>
                    <Divider orientation="vertical" h="24px" />
                    <PopoverSettingsMenu
                      popoverTrigger={
                        <IconButton
                          aria-label="settings menu"
                          icon={<MySettingsIcon />}
                          bg="bgCard"
                          boxSize={10}
                          borderLeft={0}
                          borderRadius={0}
                          borderRightRadius="10px"
                        />
                      }
                    />
                  </HStack>
                </Hide>
                <Hide above="lg">
                  <IconButton
                    bg="transparent"
                    aria-label="connect-wallet"
                    w="full"
                    onClick={onConnectWalletClick}
                  >
                    <Flex alignItems="center" pos="relative">
                      <WalletIcon w="21px" h="18px" />
                      <Box
                        pos="absolute"
                        top={-1}
                        boxSize={2}
                        right={-3}
                        borderRadius="full"
                        bgColor="red.500"
                      />
                    </Flex>
                  </IconButton>
                </Hide>
              </>
            )}
          </Flex>
        )
      })()}
    </div>
  )
}

export default ConnectButton
