import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import { UsePairUserBalanceData } from 'hooks/pool/v1/usePairUserBalance'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { wrappedCurrency } from 'utils/wrappedCurrency'

interface PoolDetailBalancesPanelV1Props {
  isLoadingUserBalance: boolean
  userBalanceData: UsePairUserBalanceData
}

const PoolDetailBalancesPanelV1 = ({
  isLoadingUserBalance,
  userBalanceData
}: PoolDetailBalancesPanelV1Props) => {
  const chainId = useChainId()
  const { pooledToken0, pooledToken1, token0, token1 } = userBalanceData

  return (
    <CurrencyAmountsPanel
      title={t`Deposit Balance`}
      isLoading={isLoadingUserBalance}
      tokens={[
        wrappedCurrency(token0, chainId)?.address || '',
        wrappedCurrency(token1, chainId)?.address || ''
      ]}
      tokenAmounts={[pooledToken0, pooledToken1]}
      tokenSymbols={[token0?.symbol, token1?.symbol]}
    />
  )
}

export default PoolDetailBalancesPanelV1
