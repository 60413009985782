import { Center, SimpleGrid, Spinner, Text } from '@chakra-ui/react'
import PageContainer from 'components/PageContainer'
import PairNameHeader from 'components/PairNameHeader'
import PoolNavigationButtons from 'components/PoolNavigationButtons'
import useGetFarm from 'hooks/farms/useGetFarm'
import React from 'react'
import { Navigate, useParams } from 'react-router-dom'

import FarmStakePanels from './FarmStakePanels'
import FarmUserPositionPanel from './FarmUserPositionPanel'

const FarmDetail = () => {
  const { pid } = useParams()

  const {
    farm,
    isLoadingDexbarnFarm,
    isLoadingLensFarm,
    refetch: refetchFarm
  } = useGetFarm({ pid })

  const token0 = farm?.reserves.token0
  const token1 = farm?.reserves.token1

  if (farm && farm.isLbRewarder) {
    return <Navigate to="/farms" />
  }

  return (
    <PageContainer>
      {isLoadingDexbarnFarm || isLoadingLensFarm ? (
        <Center minH="70vh">
          <Spinner />
        </Center>
      ) : farm ? (
        <>
          <PoolNavigationButtons
            token0={{ address: token0?.token, symbol: token0?.symbol }}
            token1={{ address: token1?.token, symbol: token1?.symbol }}
            farmPid={Number(farm.pid)}
            mb={2}
          />
          {token0 && token1 ? (
            <PairNameHeader
              token0={{ address: token0.token, symbol: token0.symbol }}
              token1={{ address: token1.token, symbol: token1.symbol }}
            />
          ) : null}

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            mt="50px"
            spacing={{ base: '50px', xl: '125px' }}
            alignItems="flex-start"
          >
            <FarmStakePanels
              farm={farm}
              onStakeSuccess={refetchFarm}
              onUnstakeSuccess={refetchFarm}
            />

            <FarmUserPositionPanel
              farm={farm}
              isLoadingDexbarnFarm={isLoadingDexbarnFarm}
              onClaimRewardsSuccess={refetchFarm}
            />
          </SimpleGrid>
        </>
      ) : (
        <Center minH="70vh">
          <Text textColor="textSecondary">Farm not found</Text>
        </Center>
      )}
    </PageContainer>
  )
}

export default FarmDetail
