import { Box, Heading, ListItem, UnorderedList } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const StakeInfoPanel = () => {
  return (
    <Box
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
    >
      <Heading size="md">
        <Trans>Stake Information</Trans>
      </Heading>

      <UnorderedList spacing={4} pt={6} fontSize="sm">
        <ListItem>
          <Trans>Claim your share of protocol revenue generated.</Trans>
        </ListItem>
        <ListItem>
          <Trans>
            A % fee is deducted from every swap and used to buyback MOE which is
            distributed to all stakers.
          </Trans>
        </ListItem>
        <ListItem>
          <Trans>
            Rewards are distributed every few days, and you can Harvest at any
            time.
          </Trans>
        </ListItem>
        <ListItem>
          <Trans>
            The APR (30D) metric shows an annualized return that is forecasted,
            based on the revenue collected over the previous thirty days.
          </Trans>
        </ListItem>
        <ListItem>
          <Trans>
            There is currently no deposit fee for staking your MOE tokens. The
            deposit fee may be modified at any time.
          </Trans>
        </ListItem>
      </UnorderedList>
    </Box>
  )
}

export default StakeInfoPanel
