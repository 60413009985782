import { Box, SimpleGrid, Skeleton, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import PoolMainAnalyticsChart from 'components/PoolMainAnalyticsChart'
import usePoolHomeAnalytics from 'hooks/analytics/usePoolHomeAnalytics'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'

const PoolMainAnalytics = () => {
  const {
    data: dataV1 = [],
    isLoading: isLoadingV1,
    volume24H: volume24HV1
  } = usePoolHomeAnalytics({ version: 'v1' })

  const {
    data: dataV2 = [],
    isLoading: isLoadingV2,
    volume24H: volume24HV2
  } = usePoolHomeAnalytics({ version: 'v2' })

  const isLoading = isLoadingV1 || isLoadingV2

  // calculate total TVL
  const totalTVLV1 =
    dataV1.length > 0 ? dataV1[dataV1.length - 1].reserveUsd : 0
  const totalTVLV2 =
    dataV2.length > 0 ? dataV2[dataV2.length - 1].reserveUsd : 0
  const fmtTVL = formattedNum(totalTVLV1 + totalTVLV2, { usd: true })

  // calculate total volume 24H
  const fmtVolume24H = formattedNum(volume24HV1 + volume24HV2, { usd: true })

  // combine data
  const tvlData = useMemo(() => {
    return dataV1.map((d) => {
      const d2 = dataV2.find((d2) => d2.timestamp === d.timestamp)
      const reserveUsdV2 = d2 ? d2.reserveUsd : 0
      return {
        date: d.timestamp,
        value: d.reserveUsd,
        value2: [d.reserveUsd, d.reserveUsd + reserveUsdV2] as const
      }
    })
  }, [dataV1, dataV2])

  const volumeData = useMemo(() => {
    return dataV1.map((d) => {
      const d2 = dataV2.find((d2) => d2.timestamp === d.timestamp)
      const volumeUsdV2 = d2 ? d2.volumeUsd : 0
      return {
        date: d.timestamp,
        value: d.volumeUsd,
        value2: [d.volumeUsd, d.volumeUsd + volumeUsdV2] as const
      }
    })
  }, [dataV1, dataV2])

  return (
    <SimpleGrid columns={{ base: 1, md: 2 }} pt={4} gap={{ base: 4, md: 8 }}>
      <Box bg="bgCard" boxShadow="element" borderRadius="20px">
        <PoolMainAnalyticsChart
          id="tvl"
          header={
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight="semibold" fontSize="2xl" minW="100px">
                {fmtTVL}
              </Text>
            </Skeleton>
          }
          tagline={t`TVL (Total Value Locked)`}
          tooltipTitle={t`TVL`}
          data={tvlData}
          h={{ base: '100px', md: '200px' }}
          headerFlexProps={{ pt: 4, px: 4 }}
        />
      </Box>
      <Box bg="bgCard" boxShadow="element" borderRadius="20px">
        <PoolMainAnalyticsChart
          id="volume"
          header={
            <Skeleton isLoaded={!isLoading}>
              <Text fontWeight="semibold" fontSize="2xl" minW="100px">
                {fmtVolume24H}
              </Text>
            </Skeleton>
          }
          tagline={t`Volume (24H)`}
          tooltipTitle={t`Volume`}
          data={volumeData}
          h={{ base: '100px', md: '200px' }}
          headerFlexProps={{ pt: 4, px: 4 }}
        />
      </Box>
    </SimpleGrid>
  )
}

export default PoolMainAnalytics
