import { Center, Flex, Spinner, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import useSortedVaults from 'hooks/vault/useSortedVaults'
import useVaultSearch from 'hooks/vault/useVaultSearch'
import useVaultWithUserPositions from 'hooks/vault/useVaultWithUserPositions'
import React, { useState } from 'react'

import VaultTable from './VaultsTable/VaultTable'

interface VaultsTableProps {
  isTabSelected: boolean
  query: string
}

const Vault = ({ isTabSelected, query }: VaultsTableProps) => {
  const { data: vaultsWithUserPositions, isLoadingVaults } =
    useVaultWithUserPositions({ enabled: isTabSelected })

  const [sortMethodVaults, setSortMethodVaults] = useState<PoolSortMethod>(
    PoolSortMethod.LIQUIDITY
  )
  const [isSortDescendingVaults, setIsSortDescendingVaults] =
    useState<boolean>(true)

  const filteredVaults = useVaultSearch({
    query,
    vaults: vaultsWithUserPositions
  })

  const sortedVaults = useSortedVaults({
    isSortDescending: isSortDescendingVaults,
    sortMethod: sortMethodVaults,
    vaults: filteredVaults
  })

  if (isLoadingVaults) {
    return (
      <Center mt={8}>
        <Spinner />
      </Center>
    )
  }

  return (
    <Flex flexDir="column" gap={4}>
      {vaultsWithUserPositions.length === 0 ? (
        <Center py={8}>
          <Text size="sm" color="textSecondary">
            <Trans>There is no automated pools yet.</Trans>
          </Text>
        </Center>
      ) : (
        <>
          <VaultTable
            isSortDescending={isSortDescendingVaults}
            onHeaderClick={(method) => {
              if (!method) return
              sortMethodVaults !== method
                ? setSortMethodVaults(method)
                : setIsSortDescendingVaults((previous) => !previous)
            }}
            sortMethod={sortMethodVaults}
            vaults={sortedVaults}
            filteredVaults={filteredVaults}
          />
        </>
      )}
    </Flex>
  )
}

export default Vault
