import { Button, Grid, Heading, useDisclosure, VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { LiquidityDistribution } from '@traderjoe-xyz/sdk-v2'
import ApproveTokenButton from 'components/ApproveTokenButton'
import BatchAddLiquidityModal from 'components/BatchAddLiquidityModal'
import CopyableError from 'components/CopyableError'
import CurrencyInput from 'components/CurrencyInput'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import MaxButton from 'components/MaxButton'
import WarningOutlined from 'components/WarningOutlined'
import { LB_ROUTER_V22_ADDRESS } from 'constants/moe'
import useAddLiquidityV2 from 'hooks/pool/v2/useAddLiquidityV2'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import { convertInputAmountToBigInt } from 'hooks/useCurrencyInputAmount'
import useDebounce from 'hooks/useDebounce'
import React, { useCallback, useMemo, useState } from 'react'
import { getAddUniformLiquidityBatches } from 'utils/getAddUniformLiquidityBatches'
import { getMaxBinPerAddLiquidityBatch } from 'utils/getMaxBinPerBatch'
import { poolSelectDistributionShape } from 'utils/measure'
import {
  convertDistributionParamsToLiquidityDistribution,
  getAddLiquidityDistributionParams
} from 'utils/poolV2'
import { getCurrencyAddress, wrappedCurrency } from 'utils/wrappedCurrency'
import { BaseError } from 'viem'
import { usePublicClient } from 'wagmi'

import CurveShapeAlphaSlider from './CurveShapeAlphaSlider'
import LiquidityShapePicker from './LiquidityShapePicker'
import PriceRangeSelection from './PriceRangeSelection'
import {
  useAddLiquidityDispatchContext,
  useAddLiquidityStateContext,
  useLBPairDataContext,
  usePoolDetailV2Context
} from './state'

interface AddLiquidityPanelV2Props {
  currencyPrice0: number | undefined
  currencyPrice1: number | undefined
  onAddLiquiditySuccess: () => void
  poolLiquidityUsd?: number
  rewardedRange?: number[]
}

const AddLiquidityPanelV2 = ({
  currencyPrice0,
  currencyPrice1,
  onAddLiquiditySuccess,
  poolLiquidityUsd,
  rewardedRange
}: AddLiquidityPanelV2Props) => {
  const chainId = useChainId()
  const publicClient = usePublicClient()

  const {
    isOpen: isBatchAddLiquidityModalOpen,
    onClose: onCloseBatchAddLiquidityModal,
    onOpen: onOpenBatchAddLiquidityModal
  } = useDisclosure()

  const {
    balance0,
    balance1,
    currency0,
    currency1,
    inversePriceRatios,
    togglePriceRatios
  } = usePoolDetailV2Context()
  const { activeBinId, binStep } = useLBPairDataContext()
  const { amount0, amount1, binRange, distribution } =
    useAddLiquidityStateContext()
  const dispatchAddLiquidityAction = useAddLiquidityDispatchContext()
  const debouncedBinRange = useDebounce(binRange, 1000)

  const isToken0Disabled =
    activeBinId && debouncedBinRange
      ? debouncedBinRange[1] < activeBinId
      : false
  const isToken1Disabled =
    activeBinId && debouncedBinRange
      ? activeBinId < debouncedBinRange[0]
      : false

  const tokenAddress0 = getCurrencyAddress(currency0)
  const tokenAddress1 = getCurrencyAddress(currency1)

  const isExceedingBalance0 = balance0
    ? Number(balance0.formatted) < Number(amount0)
    : false
  const isExceedingBalance1 = balance1
    ? Number(balance1.formatted) < Number(amount1)
    : false

  const isPriceRangeEnabled =
    activeBinId && currency0 && currency1 && binStep && binRange

  const lbRouterAddress = LB_ROUTER_V22_ADDRESS[chainId]
  const isPoolLowLiquidity =
    poolLiquidityUsd !== undefined && poolLiquidityUsd < 5000

  const amountValue0 = useMemo(() => {
    return convertInputAmountToBigInt(amount0, currency0?.decimals)
  }, [amount0, currency0?.decimals])

  const amountValue1 = useMemo(() => {
    return convertInputAmountToBigInt(amount1, currency1?.decimals)
  }, [amount1, currency1?.decimals])

  const {
    approvalType: approvalType0,
    approve: approveToken0,
    isApproved: isToken0Approved,
    isApproving: isApprovingToken0,
    reset: resetApproveToken0,
    setApprovalType: setApprovalType0
  } = useApproveSpenderIfNeeded({
    amount: amountValue0,
    spender: lbRouterAddress,
    token: tokenAddress0,
    tokenSymbol: currency0?.symbol
  })

  const {
    approvalType: approvalType1,
    approve: approveToken1,
    isApproved: isToken1Approved,
    isApproving: isApprovingToken1,
    reset: resetApproveToken1,
    setApprovalType: setApprovalType1
  } = useApproveSpenderIfNeeded({
    amount: amountValue1,
    spender: lbRouterAddress,
    token: tokenAddress1,
    tokenSymbol: currency1?.symbol
  })

  const areTokensApproved =
    (isToken0Approved === true || !tokenAddress0) &&
    (isToken1Approved === true || !tokenAddress1)

  // reset state
  const resetState = useCallback(() => {
    resetApproveToken0()
    resetApproveToken1()
    onAddLiquiditySuccess()
  }, [onAddLiquiditySuccess, resetApproveToken0, resetApproveToken1])

  const [alpha, setAlpha] = useState<number>(1 / 10)
  const distributionParams = useMemo(() => {
    return getAddLiquidityDistributionParams({
      activeBinId,
      alpha,
      amount0: amountValue0,
      amount1: amountValue1,
      binRange: debouncedBinRange,
      currency0,
      currency1,
      liquidityDistribution: distribution
    })
  }, [
    activeBinId,
    amountValue0,
    amountValue1,
    currency0,
    currency1,
    debouncedBinRange,
    distribution,
    alpha
  ])

  const isActiveBinTargetAdd =
    debouncedBinRange?.length === 2 &&
    debouncedBinRange[0] === debouncedBinRange[1] &&
    debouncedBinRange[0] === activeBinId

  const isSingleBinDepositWithToken0 =
    amountValue0 !== undefined && activeBinId && debouncedBinRange
      ? debouncedBinRange[1] <= activeBinId && amountValue0 >= 0
      : false
  const isSingleBinDepositWithToken1 =
    amountValue1 !== undefined && activeBinId && debouncedBinRange
      ? activeBinId <= debouncedBinRange[0] && amountValue1 >= 0
      : false

  const validAmount0 =
    !!amountValue0 ||
    (amountValue0 !== undefined && isActiveBinTargetAdd) ||
    isToken0Disabled ||
    isSingleBinDepositWithToken0
  const validAmount1 =
    !!amountValue1 ||
    (amountValue1 !== undefined && isActiveBinTargetAdd) ||
    isToken1Disabled ||
    isSingleBinDepositWithToken1
  const validAmounts = validAmount0 && validAmount1

  const simulatedLiquidityDistribution = useMemo(() => {
    return convertDistributionParamsToLiquidityDistribution({
      activeBinId,
      binStep: binStep?.toString(),
      distributionParams,
      token0: wrappedCurrency(currency0, chainId),
      token1: wrappedCurrency(currency1, chainId),
      totalAmount0: amountValue0,
      totalAmount1: amountValue1
    })
  }, [
    activeBinId,
    amountValue0,
    amountValue1,
    binStep,
    chainId,
    currency0,
    currency1,
    distributionParams
  ])

  const isAddLiquidityDisabled =
    isExceedingBalance0 ||
    isExceedingBalance1 ||
    !validAmounts ||
    !simulatedLiquidityDistribution

  const getHintText = () => {
    if (!validAmount0) {
      return t`Enter ${currency0?.symbol} amount`
    }
    if (!validAmount1) {
      return t`Enter ${currency1?.symbol} amount`
    }
    if (isExceedingBalance0 || isExceedingBalance1) {
      return t`Not enough balance`
    }
    return undefined
  }

  const batches = useMemo(() => {
    const binPerBatch = getMaxBinPerAddLiquidityBatch(chainId)
    return getAddUniformLiquidityBatches(
      binPerBatch,
      debouncedBinRange,
      activeBinId,
      currency0,
      currency1,
      amountValue0,
      amountValue1,
      distributionParams
    )
  }, [
    chainId,
    debouncedBinRange,
    activeBinId,
    currency0,
    currency1,
    amountValue0,
    amountValue1,
    distributionParams
  ])

  const batch = batches && batches.length === 1 ? batches[0] : undefined
  const {
    addLiquidity,
    isLoading: isAddingLiquidity,
    resetAddLiquidity,
    writeError: addLiquidityError
  } = useAddLiquidityV2({
    activeBinId: activeBinId ? BigInt(activeBinId) : undefined,
    amount0: batch?.amount0,
    amount1: batch?.amount1,
    binStep,
    currency0,
    currency1,
    distributionParams: batch?.distributionParams
  })

  const onAddLiquidityClick = async () => {
    if (!batches) return
    if (batches.length === 1) {
      if (!addLiquidity || !publicClient) return
      const hash = await addLiquidity()
      const receipt = await publicClient.waitForTransactionReceipt({ hash })
      if (receipt.status === 'success') {
        dispatchAddLiquidityAction({
          type: 'reset'
        })
        resetState()
      }
    } else {
      onOpenBatchAddLiquidityModal()
    }
  }

  return (
    <>
      {debouncedBinRange &&
      activeBinId &&
      currency0 &&
      currency1 &&
      amountValue0 !== undefined &&
      amountValue1 !== undefined &&
      distributionParams &&
      binStep &&
      simulatedLiquidityDistribution &&
      batches &&
      batches.length > 1 ? (
        <BatchAddLiquidityModal
          batches={batches}
          isOpen={isBatchAddLiquidityModalOpen}
          onClose={() => {
            onCloseBatchAddLiquidityModal()
            resetState()
          }}
          debouncedBinRange={debouncedBinRange}
          activeBinId={activeBinId}
          currency0={currency0}
          currency1={currency1}
          amount0={amountValue0}
          amount1={amountValue1}
          distributionParams={distributionParams}
          binStep={binStep}
          isPoolLowLiquidity={isPoolLowLiquidity}
          inversePriceRatios={inversePriceRatios}
          simulatedLiquidityDistribution={simulatedLiquidityDistribution}
        />
      ) : null}

      <VStack align="flex-start" spacing={12}>
        <VStack align="flex-start" w="full">
          <Heading size="md">
            <Trans>Deposit Liquidity</Trans>
          </Heading>
          <Grid w="full" templateColumns="1fr" gap={2} alignItems="flex-start">
            <CurrencyInput
              data-cy="add-liquidity-currency-input-0"
              currency={currency0}
              currencyAddress={tokenAddress0}
              value={amount0}
              onValueChange={(amount) => {
                dispatchAddLiquidityAction({
                  payload: amount,
                  type: 'set_amount0'
                })
                resetAddLiquidity()
              }}
              balance={balance0?.formatted}
              error={
                isExceedingBalance0
                  ? t`Not enough ${currency0?.symbol}`
                  : undefined
              }
              isDisabled={isToken0Disabled}
              rightElement={
                balance0 ? (
                  <MaxButton
                    balance={balance0.formatted}
                    isDisabled={isToken0Disabled}
                    onClick={() => {
                      dispatchAddLiquidityAction({
                        payload: balance0.formatted,
                        type: 'set_amount0'
                      })
                      resetAddLiquidity()
                    }}
                  />
                ) : undefined
              }
            />
            <CurrencyInput
              data-cy="add-liquidity-currency-input-1"
              currency={currency1}
              currencyAddress={tokenAddress1}
              value={amount1}
              onValueChange={(amount) => {
                dispatchAddLiquidityAction({
                  payload: amount,
                  type: 'set_amount1'
                })
                resetAddLiquidity()
              }}
              balance={balance1?.formatted}
              error={
                isExceedingBalance1
                  ? t`Not enough ${currency1?.symbol}`
                  : undefined
              }
              isDisabled={isToken1Disabled}
              rightElement={
                balance1 ? (
                  <MaxButton
                    balance={balance1.formatted}
                    isDisabled={isToken1Disabled}
                    onClick={() => {
                      dispatchAddLiquidityAction({
                        payload: balance1.formatted,
                        type: 'set_amount1'
                      })
                      resetAddLiquidity()
                    }}
                  />
                ) : undefined
              }
            />
          </Grid>
        </VStack>
        <LiquidityShapePicker
          distribution={distribution}
          onDistributionChange={(dist) => {
            dispatchAddLiquidityAction({
              payload: dist,
              type: 'set_distribution'
            })
            resetAddLiquidity()
            poolSelectDistributionShape(dist.toString())
          }}
        />
        {isPriceRangeEnabled ? (
          <PriceRangeSelection
            showActiveBinIndicator
            currencyPrice0={currencyPrice0}
            currencyPrice1={currencyPrice1}
            currency0={currency0}
            currency1={currency1}
            binStep={Number(binStep)}
            activeBinId={activeBinId}
            binRange={binRange}
            onSelectRewardedRange={
              rewardedRange
                ? () => {
                    dispatchAddLiquidityAction({
                      payload: rewardedRange,
                      type: 'set_bin_range'
                    })
                    resetAddLiquidity()
                  }
                : undefined
            }
            onBinRangeChange={(range) => {
              dispatchAddLiquidityAction({
                payload: range,
                type: 'set_bin_range'
              })
              resetAddLiquidity()
            }}
            inversePriceRatios={inversePriceRatios}
            togglePriceRatiosClick={togglePriceRatios}
            resetBinRange={() => {
              dispatchAddLiquidityAction({
                type: 'set_default_bin_range'
              })
              resetAddLiquidity()
            }}
          />
        ) : null}
        {simulatedLiquidityDistribution &&
        !isAddLiquidityDisabled &&
        validAmounts ? (
          <VStack w="full" spacing={4}>
            <LBPairDistributionChart
              currency0={currency0}
              currency1={currency1}
              data={simulatedLiquidityDistribution}
              isPriceRatioInversed={inversePriceRatios}
              title={t`Simulated Liquidity Distribution`}
            />
            {distribution === LiquidityDistribution.CURVE ? (
              <CurveShapeAlphaSlider
                alpha={alpha}
                setAlpha={(alpha) => {
                  setAlpha(alpha)
                  resetAddLiquidity()
                }}
              />
            ) : null}
          </VStack>
        ) : null}

        <VStack w="full" spacing={4}>
          {isPoolLowLiquidity && (
            <WarningOutlined
              message={t`This pool has low liquidity and may not have accurate market pricing. Please check prices carefully before adding liquidity`}
            />
          )}

          {!isToken0Approved &&
          !isToken0Disabled &&
          approveToken0 &&
          currency0 &&
          !isAddLiquidityDisabled ? (
            <ApproveTokenButton
              data-cy="add-liquidity-approve-button-0"
              amount={amount0}
              currencySymbol={currency0.symbol}
              approvalType={approvalType0}
              onApprovalTypeSelect={setApprovalType0}
              isLoading={isApprovingToken0}
              onClick={() => approveToken0()}
            >
              {t`Approve ${currency0.symbol}`}
            </ApproveTokenButton>
          ) : null}

          {!isToken1Approved &&
          !isToken1Disabled &&
          approveToken1 &&
          currency1 &&
          !isAddLiquidityDisabled ? (
            <ApproveTokenButton
              data-cy="add-liquidity-approve-button-1"
              amount={amount1}
              currencySymbol={currency1.symbol}
              approvalType={approvalType1}
              onApprovalTypeSelect={setApprovalType1}
              isLoading={isApprovingToken1}
              onClick={() => approveToken1()}
            >
              {t`Approve ${currency1.symbol}`}
            </ApproveTokenButton>
          ) : null}

          <Button
            data-cy="add-liquidity-button"
            variant="primary"
            colorScheme={isPoolLowLiquidity ? 'red' : 'accent'}
            size="xl"
            w="full"
            isDisabled={isAddLiquidityDisabled || !areTokensApproved}
            isLoading={isAddingLiquidity}
            loadingText={t`Adding Liquidity`}
            onClick={onAddLiquidityClick}
          >
            {getHintText() ??
              (isPoolLowLiquidity ? t`Add Liquidity Anyway` : t`Add Liquidity`)}
          </Button>

          {addLiquidityError ? (
            <CopyableError
              error={addLiquidityError.message}
              summary={(addLiquidityError as BaseError).shortMessage}
            />
          ) : null}
        </VStack>
      </VStack>
    </>
  )
}

export default AddLiquidityPanelV2
