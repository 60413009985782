import {
  Button,
  Center,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import PageContainer from 'components/PageContainer'
import useGetFarmsList from 'hooks/farms/useGetFarmsList'
import useVeMoeUserBalance from 'hooks/farms/useVeMoeUserBalance'
import useGetUserRewarders from 'hooks/rewarder/useGetUserRewarders'
import React, { useMemo, useState } from 'react'

import UserRewardersTable from './UserRewardersTable'
import VoteTable from './VoteTable'
import VoteWeightInfoGrid from './VoteWeightInfoGrid'

enum VoteTableTab {
  ALL,
  MY_POOLS,
  MY_REWARDERS
}

const Vote = () => {
  const { farms, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useGetFarmsList({
      isSortDescending: true,
      sortMethod: 'rewards'
    })
  const {
    data: {
      balance: userVeMoeBalance,
      platformTotalVotes,
      userAvailableVeMoeBalance
    } = {}
  } = useVeMoeUserBalance()

  const [tab, setTab] = useState<VoteTableTab>(VoteTableTab.ALL)
  const visibleFarms = useMemo(() => {
    if (!farms) return []
    switch (tab) {
      case VoteTableTab.ALL:
        return farms
          .filter((farm) => farm.isRewardable)
          .sort((b, a) => (b.totalVotesOnFarm > a.totalVotesOnFarm ? -1 : 1))
      case VoteTableTab.MY_POOLS:
        return farms
          .filter((farm) => farm.userVotesOnFarm > 0)
          .sort((b, a) => (b.totalVotesOnFarm > a.totalVotesOnFarm ? -1 : 1))
      case VoteTableTab.MY_REWARDERS:
        return []
    }
  }, [tab, farms])

  const { data: userRewarders, isLoading: isLoadingUserRewarders } =
    useGetUserRewarders({ enabled: true })
  const hasUserRewarders = userRewarders && userRewarders.length > 0

  return (
    <PageContainer mb="100px">
      <VStack align="flex-start">
        <Heading>
          <Trans>Vote</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>
            Vote with veMOE to boost rewards for Classic Farms and Liquidity
            Book pools
          </Trans>
        </Text>
        <Text fontSize="sm" textColor="textSecondary" mt={-2}>
          <Trans>
            The share of rewards is based on the current distribution of veMOE
            voting. Vote weighting is non-linear. Voting for pools with a large
            share of votes will have a diminishing boost effect compared to
            pools with fewer votes.
          </Trans>
        </Text>
      </VStack>

      <VoteWeightInfoGrid
        userAvailableVeMoeBalance={userAvailableVeMoeBalance?.formatted}
        userVeMoeBalance={userVeMoeBalance?.formatted}
        platformTotalVotes={platformTotalVotes?.formatted}
      />

      <Tabs
        variant="solid-rounded"
        w="full"
        mt={10}
        index={tab}
        onChange={(index) => setTab(index)}
      >
        <TabList bg="bgCard" boxShadow="element" w="fit-content">
          <Tab>
            <Trans>All Pools</Trans>
          </Tab>
          <Tab>
            <Trans>My Pools</Trans>
          </Tab>
          {hasUserRewarders ? (
            <Tab>
              <Trans>My Rewarders</Trans>
            </Tab>
          ) : null}
        </TabList>

        <TabPanels>
          <TabPanel p={0}>
            <VoteTable
              farms={visibleFarms}
              userVeMoeBalance={userVeMoeBalance?.value}
              isLoading={isLoading}
            />
          </TabPanel>

          <TabPanel p={0}>
            <VoteTable
              farms={visibleFarms}
              userVeMoeBalance={userVeMoeBalance?.value}
              isLoading={isLoading}
            />
          </TabPanel>

          {hasUserRewarders ? (
            <TabPanel p={0}>
              <UserRewardersTable
                userRewarders={userRewarders}
                isLoading={isLoadingUserRewarders}
              />
            </TabPanel>
          ) : null}
        </TabPanels>
      </Tabs>

      {!isLoading && hasNextPage && (
        <Center mt={4}>
          <Button
            variant="outline-bold"
            alignSelf="center"
            w="160px"
            isLoading={isFetchingNextPage}
            loadingText={t`Loading`}
            onClick={() => fetchNextPage()}
          >
            <Trans>Load More</Trans>
          </Button>
        </Center>
      )}
    </PageContainer>
  )
}

export default Vote
