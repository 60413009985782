import {
  Box,
  Flex,
  FlexProps,
  Hide,
  HStack,
  Tab,
  TabList,
  Tabs,
  Tag,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import ContractLink from 'components/ContractLink'
import HotkeyTooltipButton from 'components/HotkeyTooltipButton'
import PairNameHeader from 'components/PairNameHeader'
import PointsBadge from 'components/PointsBadge'
import PoolNavigationButtons from 'components/PoolNavigationButtons'
import SlippageSettingsPicker, {
  SlippageSettingsPickerType
} from 'components/SlippageSettingsPicker'
import useKeyPress from 'hooks/useKeyPress'
import React, { useState } from 'react'
import { Point } from 'types/pool'
import { formattedNum } from 'utils/format'

interface PoolDetailHeaderProps {
  points: Point[]
  activeBinPrice?: string
  address?: string
  binStep?: number
  farmPid?: number
  inversePriceRatios?: boolean
  onInversePriceRatiosClick?: () => void
  settingsBottom?: number
  showHotkeyTooltip?: boolean
  slippageSettingsPickerType?: SlippageSettingsPickerType
  tag?: string
  token0?: { address?: string; symbol?: string }
  token1?: { address?: string; symbol?: string }
  vaultAddress?: string
  votePid?: number
}

const PoolDetailHeader = ({
  activeBinPrice,
  address,
  binStep,
  farmPid,
  inversePriceRatios,
  onInversePriceRatiosClick,
  points,
  settingsBottom = 0,
  showHotkeyTooltip,
  slippageSettingsPickerType,
  tag,
  token0,
  token1,
  vaultAddress,
  votePid,
  ...props
}: PoolDetailHeaderProps & FlexProps) => {
  const [priceDecimals, setPriceDecimals] = useState(10)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 18 ? 10 : 18)),
    targetKey: 'g'
  })

  return (
    <Flex
      maxW={{ '2xl': '1600px', base: '1300px' }}
      margin="0 auto"
      flexDir="column"
      align="flex-start"
      gap={2}
      pos="relative"
      h={{ base: 'fit-content', md: '150px' }}
      {...props}
    >
      <PoolNavigationButtons
        token0={token0}
        token1={token1}
        farmPid={farmPid}
        votePid={votePid}
        binStep={binStep}
        vaultAddress={vaultAddress}
      />
      <Flex
        zIndex={1}
        gap={2}
        flexDir={{ base: 'column', md: 'row' }}
        align={{ base: 'flex-start', md: 'center' }}
      >
        <PairNameHeader
          token0={{ address: token0?.address, symbol: token0?.symbol || '' }}
          token1={{ address: token1?.address, symbol: token1?.symbol || '' }}
        />
        <Flex flexWrap="wrap" gap={2} pl={{ base: 0, md: 4 }}>
          {tag ? <Tag variant="outline">{tag}</Tag> : null}
          <Tag variant="outline">
            <ContractLink color="textPrimary" address={address} />
          </Tag>
        </Flex>
        {points.length > 0 ? <PointsBadge points={points} /> : null}
      </Flex>
      <Flex
        pos={{ base: 'relative', md: 'absolute' }}
        bottom={{ base: 0, md: settingsBottom }}
        mt={{ base: 2, md: 0 }}
        alignSelf={{ base: 'flex-start', md: 'flex-end' }}
        justifyContent={{ base: 'space-between', md: 'flex-start' }}
        alignItems="center"
        zIndex={4}
        gap={{ base: 2, md: 4 }}
        w={{ base: 'full', md: 'auto' }}
        pt={{ base: 0, md: 4 }}
      >
        {activeBinPrice ? (
          <Box>
            <Text fontSize="sm" textColor="textSecondary">
              <Trans>Current Price:</Trans>
            </Text>
            <Text fontWeight="semibold">{`1 ${
              inversePriceRatios ? token1?.symbol : token0?.symbol
            } = ${formattedNum(activeBinPrice, { places: priceDecimals })} ${
              inversePriceRatios ? token0?.symbol : token1?.symbol
            }`}</Text>
          </Box>
        ) : null}
        <HStack spacing={{ base: 2, md: 4 }}>
          {onInversePriceRatiosClick ? (
            <Tabs
              isManual
              variant="solid-rounded"
              index={inversePriceRatios ? 1 : 0}
              onChange={onInversePriceRatiosClick}
            >
              <TabList bg="bgCard" boxShadow="element">
                <Tab>{token0?.symbol}</Tab>
                <Tab>{token1?.symbol}</Tab>
              </TabList>
            </Tabs>
          ) : null}
          {slippageSettingsPickerType ? (
            <SlippageSettingsPicker
              type={slippageSettingsPickerType}
              iconButtonProps={{
                'aria-label': 'open settings',
                bg: 'bgCard'
              }}
            />
          ) : null}
          {showHotkeyTooltip ? (
            <Hide below="md">
              <HotkeyTooltipButton />
            </Hide>
          ) : null}
        </HStack>
      </Flex>
    </Flex>
  )
}

export default PoolDetailHeader
