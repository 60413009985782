import { createAction } from '@reduxjs/toolkit'

import { SlippageType } from './reducer'

export const setSlippage = createAction<{ type: SlippageType; value: number }>(
  'settings/slippage'
)

export const setTransactionDeadline = createAction<number | undefined>(
  'settings/transactionDeadline'
)

export const setIsSafeModeEnabled = createAction<boolean>(
  'settings/isSafeModeEnabled'
)

export const setIsDedicatedRPC = createAction<boolean>(
  'settings/isDedicatedRPC'
)

export const setAreTermsAccepted = createAction<boolean>(
  'settings/areTermsAccepted'
)

export const setAreAirdropTermsAccepted = createAction<boolean>(
  'settings/areAirdropTermsAccepted'
)

export const setIsAirdropCitizenDisclaimerAccepted = createAction<boolean>(
  'settings/isAirdropCitizenDisclaimerAccepted'
)

export const setIsRemoveLiquidityInNativeCurrencyEnabled =
  createAction<boolean>('settings/isRemoveLiquidityInNativeCurrencyEnabled')

export const setOriginCountryCode = createAction<string>(
  'settings/originCountryCode'
)

export const setIsUKDisclaimerHidden = createAction<boolean>(
  'settings/isUKDisclaimerHidden'
)
