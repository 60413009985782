import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import { STABLE_MOE_ADDRESS } from 'constants/moe'
import useClaim from 'hooks/stake/useClaim'
import useGetStableMoePendingRewards from 'hooks/stake/useGetStableMoePendingRewards'
import useChainId from 'hooks/useChainId'
import debounce from 'lodash.debounce'
import React from 'react'

const ClaimMoeStakingRewardsPanel = () => {
  const chainId = useChainId()

  const { data: pendingRewards = [], refetch: refetchPendingRewards } =
    useGetStableMoePendingRewards()
  const debouncedRefetchPendingRewards = debounce(
    () => refetchPendingRewards(),
    2000
  )

  const hasPendingRewards =
    pendingRewards.length > 0 &&
    pendingRewards.some(({ userPendingAmount }) => userPendingAmount.value > 0)

  const panelProps = {
    tokenAmounts: pendingRewards.map(({ userPendingAmount }) =>
      Number(userPendingAmount.formatted)
    ),
    tokenSymbols: pendingRewards.map(({ token }) => token.symbol),
    tokens: pendingRewards.map(({ token }) => token.token)
  }

  const { claim, isLoading: isClaiming } = useClaim({
    enabled: hasPendingRewards,
    onSuccess: debouncedRefetchPendingRewards,
    stakingContractAddress: STABLE_MOE_ADDRESS[chainId]
  })

  return (
    <CurrencyAmountsPanel
      boxProps={{
        bg: 'bgCard',
        borderRadius: { base: '10px', md: '20px' },
        boxShadow: 'element'
      }}
      title={t`Staking Rewards`}
      {...panelProps}
      bottomContent={
        <Web3Button
          mt={4}
          size="xl"
          colorScheme="accent"
          variant="outline-bold"
          w="full"
          isDisabled={!hasPendingRewards}
          isLoading={isClaiming}
          onClick={claim}
          loadingText={t`Claiming`}
        >
          Claim Rewards
        </Web3Button>
      }
    />
  )
}

export default ClaimMoeStakingRewardsPanel
