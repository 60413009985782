import { Show } from '@chakra-ui/react'
import Footer from 'components/Footer'
import Header from 'components/Header'
import MobileBottomTabBar from 'components/MobileBottomTabBar'
import ScrollToTop from 'components/ScrollToTop'
import useLoadOriginCountry from 'hooks/useLoadOriginCountry'
import useOnWalletConnectListeners from 'hooks/useOnWalletConnectListeners'
import NotFound from 'pages/404'
import Airdrop from 'pages/Airdrop'
import CreatePool from 'pages/CreatePool'
import CreatePoolV2 from 'pages/CreatePoolV2'
import FarmDetail from 'pages/FarmDetail'
import Farms from 'pages/Farms'
import Home from 'pages/Home'
import MoeJourney from 'pages/MoeJourney'
import Pool from 'pages/Pool'
import PoolDetail from 'pages/PoolDetail'
import PoolDetailV2 from 'pages/PoolDetailV2'
import { PoolDetailV2ContextProvider } from 'pages/PoolDetailV2/state'
import Stake from 'pages/Stake'
import VaultDetail from 'pages/VaultDetail'
import Vote from 'pages/Vote'
import VoteDetail from 'pages/VoteDetail'
import CreateVoteRewards from 'pages/VoteRewards/CreateVoteRewards'
import EditVoteRewards from 'pages/VoteRewards/EditVoteRewards'
import React from 'react'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import Privacy from './pages/Legal/Privacy'
import TermsOfService from './pages/Legal/TermsOfService'
import Trade from './pages/Trade'

const ElementWithHeader = ({ element }: { element: JSX.Element }) => {
  return (
    <>
      <Header />
      {element}
    </>
  )
}

export const App = () => {
  useOnWalletConnectListeners()
  useLoadOriginCountry()

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="" element={<ElementWithHeader element={<Home />} />} />
        <Route
          path="/trade"
          element={<ElementWithHeader element={<Trade />} />}
        />
        <Route
          path="/pool"
          element={<ElementWithHeader element={<Pool />} />}
        />
        <Route
          path="/pool/v1/:token0Address/:token1Address/"
          element={<ElementWithHeader element={<PoolDetail />} />}
        />
        <Route
          path="/pool/v1/create"
          element={<ElementWithHeader element={<CreatePool />} />}
        />
        <Route
          path="/pool/v1/create/:token0Address/:token1Address"
          element={<ElementWithHeader element={<CreatePool />} />}
        />
        <Route
          path="/pool/v2/create"
          element={<ElementWithHeader element={<CreatePoolV2 />} />}
        />
        <Route
          path="/pool/:poolVersion/:currencyId0/:currencyId1/:binStep"
          element={
            <ElementWithHeader
              element={
                <PoolDetailV2ContextProvider>
                  <PoolDetailV2 />
                </PoolDetailV2ContextProvider>
              }
            />
          }
        />
        <Route
          path="/stake"
          element={<ElementWithHeader element={<Stake />} />}
        />
        <Route
          path="/vote"
          element={<ElementWithHeader element={<Vote />} />}
        />
        <Route
          path="/vote/:pid"
          element={<ElementWithHeader element={<VoteDetail />} />}
        />
        <Route
          path="/vote/:pid/rewarder/create"
          element={<ElementWithHeader element={<CreateVoteRewards />} />}
        />
        <Route
          path="/vote/:pid/rewarder/:rewarderAddress"
          element={<ElementWithHeader element={<EditVoteRewards />} />}
        />
        <Route
          path="/farms"
          element={<ElementWithHeader element={<Farms />} />}
        />
        <Route
          path="/farms/:pid"
          element={<ElementWithHeader element={<FarmDetail />} />}
        />
        <Route
          path="/vault/:vaultAddress"
          element={<ElementWithHeader element={<VaultDetail />} />}
        />
        <Route
          path="/joe-staking"
          element={<ElementWithHeader element={<Airdrop />} />}
        />
        <Route
          path="/airdrop"
          element={<Navigate to="/joe-staking" replace />}
        />
        <Route
          path="/journey"
          element={<ElementWithHeader element={<MoeJourney />} />}
        />
        <Route
          path="/privacy"
          element={<ElementWithHeader element={<Privacy />} />}
        />
        <Route
          path="/tos"
          element={<ElementWithHeader element={<TermsOfService />} />}
        />
        <Route
          path="/404"
          element={<ElementWithHeader element={<NotFound />} />}
        />
        {/* Unknown paths */}
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
      <Footer />
      <Show below="lg">
        <MobileBottomTabBar />
      </Show>
    </BrowserRouter>
  )
}
