import { MerchantMoeChainId } from '../constants/chains'

export const getMaxBinPerAddLiquidityBatch = (chainId: MerchantMoeChainId) => {
  switch (chainId) {
    case MerchantMoeChainId.MANTLE:
    case MerchantMoeChainId.FUJI:
      return 100
  }
}

export const getMaxBinPerRemoveLiquidityBatch = (
  chainId: MerchantMoeChainId
) => {
  switch (chainId) {
    case MerchantMoeChainId.MANTLE:
    case MerchantMoeChainId.FUJI:
      return 200
  }
}
