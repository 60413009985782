import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import Web3Button from 'components/Web3Button'
import useWithdrawFromFarm from 'hooks/farms/useWithdrawFromFarm'
import useChainId from 'hooks/useChainId'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React, { useMemo } from 'react'
import { Farm } from 'types/dexlens'
import { toSdkChainId } from 'utils/chains'
import { formatUnits } from 'viem'
import { useAccount } from 'wagmi'

interface FarmUnstakePanelProps {
  farm: Farm
  onUnstakeSuccess: () => void
}

const FarmUnstakePanel = ({
  farm,
  onUnstakeSuccess
}: FarmUnstakePanelProps) => {
  const { isConnected } = useAccount()
  const chainId = useChainId()

  const lpToken = useMemo(
    () =>
      new Token(
        toSdkChainId(chainId),
        farm.lpToken.token,
        Number(farm.lpToken.decimals),
        farm.lpToken.symbol
      ),
    [chainId, farm]
  )
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: lpToken
  })

  const farmUserAmount = isConnected
    ? formatUnits(farm.userAmount, lpToken.decimals)
    : undefined
  const isExceedingBalance = Number(amount) > Number(farmUserAmount)

  const isAmountInvalid = isExceedingBalance || Number(amount) === 0

  const { isLoading: isWithdrawing, withdraw } = useWithdrawFromFarm({
    amount: amountBN,
    enabled: !isAmountInvalid,
    farmName: `${farm.reserves.token0.symbol} / ${farm.reserves.token1.symbol}`,
    onSuccess: () => {
      setAmount('')
      onUnstakeSuccess()
    },
    pid: farm.pid
  })

  return (
    <VStack spacing={10}>
      <CurrencyInput
        data-cy="farm-unstake-input"
        heading={t`Amount to unstake:`}
        headingProps={{ fontSize: 'sm' }}
        currency={lpToken}
        value={amount}
        onValueChange={setAmount}
        h="60px"
        balance={farmUserAmount}
        error={
          isExceedingBalance ? t`Not enough ${lpToken?.symbol}` : undefined
        }
        rightElement={
          farmUserAmount ? (
            <MaxButton
              data-cy="farm-unstake-max-button"
              rounded="full"
              balance={farmUserAmount}
              onClick={() => setAmount(farmUserAmount)}
              bg="bgCard"
              mr={-2}
            />
          ) : undefined
        }
      />
      <Web3Button
        data-cy="farm-unstake-button"
        size="xl"
        colorScheme="accent"
        variant="primary"
        w="full"
        isDisabled={isAmountInvalid || !withdraw}
        isLoading={isWithdrawing}
        loadingText={t`Unstaking`}
        onClick={withdraw}
      >
        <Trans>Unstake</Trans>
      </Web3Button>
    </VStack>
  )
}

export default FarmUnstakePanel
