import { keepPreviousData, useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Pool as DexbarnPool, PoolQueryParam } from 'types/dexbarn'
import { Pool } from 'types/pool'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnPoolToPool } from 'utils/poolV1'

interface UsePoolV1Props {
  orderBy: PoolQueryParam.OrderBy
  pageSize: number
  status: PoolQueryParam.Status
}

const usePoolsV1 = ({ orderBy, pageSize, status }: UsePoolV1Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchPools = useDexbarnGet<DexbarnPool[]>(`/v1/pools/${chain}`)
  const result = useInfiniteQuery<DexbarnPool[]>({
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length % pageSize === 0 ? allPages.length + 1 : undefined,
    initialPageParam: 1,
    placeholderData: keepPreviousData,
    queryFn: ({ pageParam }) =>
      fetchPools({
        params: {
          filterBy: '1d',
          orderBy,
          pageNum: pageParam,
          pageSize,
          status
        }
      }),
    queryKey: ['PoolsQuery', chain, pageSize, orderBy, status]
  })

  // convert to common pool interface
  const pools: Pool[] =
    result.data?.pages.flat().map((pool) => convertDexbarnPoolToPool(pool)) ??
    []

  return {
    ...result,
    data: pools
  }
}

export default usePoolsV1
