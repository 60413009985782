import { Flex, Heading, Spacer, Tag, Text, VStack } from '@chakra-ui/react'
import React from 'react'

interface MJPMultiplierViewProps {
  description: string
  subtitle: string
  title: string
  bottomElement?: JSX.Element
}

const MJPMultiplierView = ({
  bottomElement,
  description,
  subtitle,
  title
}: MJPMultiplierViewProps) => {
  return (
    <VStack
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      boxShadow="element"
      p={{ base: 4, md: 6 }}
      h="full"
      align="flex-start"
      justify="space-between"
    >
      <Flex flexWrap="wrap" gap={2} w="full">
        <Heading size="md">{title}</Heading>

        <Spacer />

        <Tag bg="accent.500" rounded="full" textColor="white">
          {subtitle}
        </Tag>
      </Flex>

      <Text mt={1} fontSize="sm">
        {description}
      </Text>

      {bottomElement && bottomElement}
    </VStack>
  )
}

export default MJPMultiplierView
