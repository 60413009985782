import { t } from '@lingui/macro'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useTransactionToast from 'hooks/useTransactionToast'
import { useEffect } from 'react'
import { getAddress } from 'viem'
import {
  useSimulateContract,
  useWaitForTransactionReceipt,
  useWriteContract
} from 'wagmi'

interface UseVaultCancelQueuedWithdrawalProps {
  shares: bigint
  vaultAddress: string
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultCancelQueuedWithdrawal = ({
  enabled = true,
  onSuccess,
  shares,
  vaultAddress
}: UseVaultCancelQueuedWithdrawalProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args: shares ? [shares] : undefined,
    functionName: 'cancelQueuedWithdrawal',
    query: { enabled, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Cancel vault withdrawal`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const cancelQueuedWithdrawal = config?.request
    ? () => writeContract(config.request)
    : undefined

  const {
    data: receipt,
    isLoading: isWaitingForTransaction,
    isSuccess
  } = useWaitForTransactionReceipt({
    hash: data
  })

  useEffect(() => {
    if (receipt) {
      onSuccess?.()
    }
  }, [receipt, onSuccess])

  return {
    cancelQueuedWithdrawal,
    isLoading: isWaitingForTransaction || isPending,
    isSuccess
  }
}

export default useVaultCancelQueuedWithdrawal
