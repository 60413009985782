import {
  Box,
  Button,
  Center,
  Flex,
  Heading,
  HStack,
  Spinner,
  Tab,
  TabList,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import useGetFarmBribes from 'hooks/farms/useGetFarmBribes'
import useSortedFarmBribes from 'hooks/farms/useSortedFarmBribes'
import useVerifiedTokenAddresses from 'hooks/tokensList/useVerifiedTokenAddresses'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { Farm } from 'types/dexlens'
import { Hex, zeroAddress } from 'viem'

import FarmBribeRow from './FarmBribeRow'

interface BribesPanelProps {
  farm: Farm
  isLoadingUserBribe: boolean
  onUserBribeChanged: () => void
  userBribeContractAddress?: Hex
  userBribePendingReward?: string
}

const BribesPanel = ({
  farm,
  isLoadingUserBribe,
  onUserBribeChanged,
  userBribeContractAddress,
  userBribePendingReward
}: BribesPanelProps) => {
  const navigate = useNavigate()

  // get verified tokens
  const verifiedTokens = useVerifiedTokenAddresses()

  // fetch all bribes
  const { data: allFarmBribes = [], isLoading: isLoadingBribes } =
    useGetFarmBribes({
      pid: Number(farm.pid)
    })

  // filter out unverified tokens
  const validFarmBribes = useMemo(() => {
    const allowedTokens = [...(verifiedTokens || []), zeroAddress].map(
      (token) => token.toLowerCase()
    )

    return allFarmBribes.filter((bribe) => {
      return (
        (bribe.startTimestamp > 0 &&
          bribe.endTimestamp > 0 &&
          allowedTokens.includes(bribe.tokenId.toLowerCase())) ||
        bribe.bribeId.toLowerCase() === userBribeContractAddress?.toLowerCase()
      )
    })
  }, [allFarmBribes, userBribeContractAddress, verifiedTokens])

  const {
    debouncedRefetchBribesTotalVotes,
    farmBribes,
    isLoading: isPreparingBribes,
    selectedTab,
    setSelectedTab
  } = useSortedFarmBribes({
    dexbarnFarmBribes: validFarmBribes
  })

  return (
    <Box
      w="full"
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
    >
      <HStack align="flex-start" justify="space-between" spacing={4}>
        <VStack spacing={0} align="flex-start">
          <Heading size="md">
            <Trans>Voting rewards</Trans>
          </Heading>

          <Text fontSize="sm">
            <Trans>
              Opt-in to bonus rewards for this farm. You may only select one.
            </Trans>
          </Text>
        </VStack>

        <Button
          fontSize="sm"
          variant="solid"
          colorScheme="accent"
          bg="accent.500"
          textColor="white"
          onClick={() => navigate(`/vote/${farm.pid}/rewarder/create`)}
        >
          <Trans>Create Rewarder</Trans>
        </Button>
      </HStack>

      <Flex
        gap={4}
        my={4}
        justify="space-between"
        flexDir={{ base: 'column', md: 'row' }}
      >
        <Tabs
          index={selectedTab}
          onChange={setSelectedTab}
          variant="solid-rounded"
          isFitted={true}
        >
          <TabList>
            <Tab>
              <Trans>Active</Trans>
            </Tab>
            <Tab>
              <Trans>Ended</Trans>
            </Tab>
          </TabList>
        </Tabs>

        {/* <Menu>
          <MenuButton
            as={Button}
            rightIcon={<ChevronDownIcon />}
            fontWeight="regular"
            bg="bgSecondary"
          >
            <HStack spacing={2}>
              <Text textColor="textSecondary" fontWeight="regular">
                Sort by:
              </Text>
              <Text fontWeight="semibold">{sortType}</Text>
            </HStack>
          </MenuButton>
          <MenuList>
            <MenuItem
              h="48px"
              onClick={() => setSortType(FarmBribeSortType.REWARDS)}
            >
              Rewards
            </MenuItem>
            <MenuItem
              h="48px"
              onClick={() => setSortType(FarmBribeSortType.CREATED_AT)}
            >
              Start date
            </MenuItem>
          </MenuList>
        </Menu> */}
      </Flex>

      {isLoadingBribes || isLoadingUserBribe || isPreparingBribes ? (
        <Center py="80px">
          <Spinner size="sm" />
        </Center>
      ) : farmBribes && farmBribes.length > 0 ? (
        <VStack pt={4} spacing={4}>
          {farmBribes.map((farmBribe) => (
            <FarmBribeRow
              key={farmBribe.bribeId}
              farmBribe={farmBribe}
              rewardTokenDecimals={farmBribe.tokenDecimals}
              userBribeRewarderAddress={userBribeContractAddress}
              userPendingReward={userBribePendingReward}
              bribeTotalVotes={farmBribe.bribeTotalVotes}
              userVotesOnFarm={farm.userVotesOnFarm}
              onSelectBribeSuccess={() => {
                onUserBribeChanged()
                debouncedRefetchBribesTotalVotes()
              }}
              onClaimRewardsSuccess={onUserBribeChanged}
              onEmergencyUnsetBribeSuccess={() => {
                onUserBribeChanged()
                debouncedRefetchBribesTotalVotes()
              }}
              isStopped={farmBribe.isStopped}
            />
          ))}
        </VStack>
      ) : (
        <Center py="80px">
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>No voting rewards for this farm</Trans>
          </Text>
        </Center>
      )}
    </Box>
  )
}

export default BribesPanel
