import { useQuery } from '@tanstack/react-query'
import { HIDDEN_VAULTS } from 'constants/vaults'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Chain, Vault as DexbarnVault } from 'types/dexbarn'
import { convertDexbarnVaultIntoVault } from 'utils/vault'

interface FetchVaultsParameters {
  userAddress?: string
}

interface UseVaultsProps {
  chain: Chain
  enabled?: boolean
  userAddress?: string
}

const useVaults = ({ chain, enabled = true, userAddress }: UseVaultsProps) => {
  const chainId = useChainId()
  const fetchVaults = useDexbarnGet<DexbarnVault[], FetchVaultsParameters>(
    `/v1/lb/vaults/${chain}`
  )
  return useQuery({
    enabled,
    queryFn: () => fetchVaults({ params: { userAddress } }),
    queryKey: ['VaultsQuery', chain, userAddress],
    select: (vaults) =>
      vaults
        .filter(
          (vault) =>
            !HIDDEN_VAULTS[chainId].includes(vault.address.toLowerCase())
        )
        .map((vault) => convertDexbarnVaultIntoVault(vault))
  })
}

export default useVaults
