import { useQuery } from '@tanstack/react-query'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useMemo } from 'react'
import {
  ClaimableRewardsEpoch,
  FormattedReward,
  RewardsHistoryEpoch
} from 'types/rewards'
import { getDexbarnChainParam } from 'utils/chains'
import {
  getAggregatedClaimableRewards,
  getClaimableTokenAddresses,
  getFormattedRewards,
  getTokensMap
} from 'utils/merkleProofRewards'
import { zeroAddress } from 'viem'
import { useAccount } from 'wagmi'

import useLBPairClaimRewards from './useMerkleProofClaimRewards'

type UseLBPairClaimRewardsArg = {
  amount: string
  epoch: number
  market: string
  tokenAddress: string
}

interface UseMerkleProofRewardsProps {
  enabled: boolean
  rewarderAddress: string
  market?: string
}

const useMerkleProofRewards = ({
  enabled,
  market,
  rewarderAddress
}: UseMerkleProofRewardsProps) => {
  const { address: account } = useAccount()
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const { nativeCurrency } = useActiveChain()

  const fetchUserClaimableRewards = useDexbarnGet<ClaimableRewardsEpoch[]>(
    `/v1/lb/rewards/claimable/${chain}/${account}?market=${market}`
  )
  const { data: rewardEpochs, isLoading: isClaimableLoading } = useQuery<
    ClaimableRewardsEpoch[]
  >({
    enabled: enabled && !!market && !!account,
    queryFn: () => fetchUserClaimableRewards(),
    queryKey: ['ClaimableRewardsEpoch', account, market, rewarderAddress],
    retry: 0
  })

  const fetchUserRewardsHistory = useDexbarnGet<RewardsHistoryEpoch[]>(
    `/v1/lb/rewards/history/${chain}/${account}?market=${market}`
  )
  const { data: rewardsHistoryData, isLoading: isHistoryLoading } = useQuery<
    RewardsHistoryEpoch[]
  >({
    enabled: enabled && !!market && !!account,
    queryFn: () => fetchUserRewardsHistory(),
    queryKey: ['RewardsHistoryEpoch', account, market, rewarderAddress],
    retry: 0
  })

  const tokenAddresses = useMemo(
    () => getClaimableTokenAddresses(rewardEpochs),
    [rewardEpochs]
  )
  const { tokens: rewardTokens } = useSdkCurrencies({
    addresses: tokenAddresses.map((address) =>
      address === zeroAddress ? nativeCurrency.symbol : address
    )
  })

  const claimableRewards = useMemo(() => {
    if (!rewardEpochs) {
      return undefined
    }
    const tokensMap = getTokensMap(tokenAddresses, rewardTokens)

    const allEpochsFmtRewards: FormattedReward[][] = rewardEpochs.map(
      (epochData) => getFormattedRewards(epochData.claimableRewards, tokensMap)
    )

    return getAggregatedClaimableRewards(allEpochsFmtRewards)
  }, [tokenAddresses, rewardTokens, rewardEpochs])

  const claimRewardsArgs = useMemo(() => {
    if (!market || !rewardEpochs || !rewardsHistoryData) {
      return undefined
    }

    const rewards: UseLBPairClaimRewardsArg[] = []

    rewardEpochs.forEach((epochData) => {
      epochData.claimableRewards.forEach((reward) => {
        const rewardHistory = rewardsHistoryData.find(
          (history) => history.epoch === epochData.epoch
        )
        const claimableAmount = rewardHistory?.rewards.find(
          (r) => reward.tokenAddress === r.tokenAddress
        )?.amount

        if (claimableAmount) {
          rewards.push({
            amount: claimableAmount,
            epoch: epochData.epoch,
            market: market,
            tokenAddress: reward.tokenAddress
          })
        }
      })
    })

    return rewards
  }, [market, rewardEpochs, rewardsHistoryData])

  const { batchClaimRewards, isLoading: isClaiming } = useLBPairClaimRewards({
    claimableRewards: claimRewardsArgs,
    rewarderAddress
  })

  return {
    batchClaimRewards,
    claimableRewards,
    isClaiming,
    isLoading: isClaimableLoading || isHistoryLoading,
    rewardsHistoryData
  }
}

export default useMerkleProofRewards
