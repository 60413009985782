/* eslint-disable @typescript-eslint/naming-convention */
import { PoolVersion } from '@traderjoe-xyz/sdk-v2'
import { isMobile } from 'react-device-detect'
import ReactGA from 'react-ga4'

export const initializeAnalytics = () => {
  const GOOGLE_ANALYTICS_ID: string | undefined =
    process.env.REACT_APP_GA_MEASUREMENT_ID
  if (typeof GOOGLE_ANALYTICS_ID === 'string' && GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(GOOGLE_ANALYTICS_ID)
    ReactGA.set({
      customBrowserType: !isMobile
        ? 'desktop'
        : 'web3' in window || 'ethereum' in window
          ? 'mobileWeb3'
          : 'mobileRegular'
    })
    ReactGA.send('pageview')
  } else {
    ReactGA.initialize('test', { testMode: true })
  }
}

export const walletConnect = (walletName?: string): void => {
  ReactGA.event('connectWallet', {
    category: 'wallet',
    label: walletName
  })
}

/**
 * Pool Events Tracking
 *
 */
export const poolAddLiquidity = (poolName: string): void => {
  ReactGA.event('addLiquidity', {
    category: 'pool',
    label: poolName
  })
}

export const poolRemoveLiquidity = (poolName: string): void => {
  ReactGA.event('addLiquidity', {
    category: 'pool',
    label: poolName
  })
}

export const poolSelectDistributionShape = (distributionName: string): void => {
  ReactGA.event('selectDistributionShape', {
    category: 'pool',
    label: distributionName
  })
}

/**
 * Trade Events Tracking
 */

export const tradeSwap = (
  symbolX?: string,
  symbolY?: string,
  paths?: {
    binStep: bigint
    tokenInId: string
    tokenOutId: string
    version: PoolVersion
  }[],
  router?: 'barn' | 'frontend' | 'aligned'
): void => {
  const serializedPaths = JSON.stringify(
    paths?.map((path) => ({
      ...path,
      binStep: Number(path.binStep),
      poolVersion: path.version === PoolVersion.V1 ? 'v1' : 'v2'
    }))
  )

  ReactGA.event('swap', {
    category: 'trade',
    label: `${symbolX}/${symbolY}`,
    paths: serializedPaths,
    router: router
  })
}

export const tradeExpandSummary = (
  symbolX?: string,
  symbolY?: string
): void => {
  ReactGA.event('expand', {
    category: 'trade',
    label: `${symbolX}/${symbolY}`
  })
}

export const tradeAddToWallet = (symbol?: string): void => {
  ReactGA.event('addCurrencyToWallet', {
    category: 'trade',
    label: symbol
  })
}

/**
 * Home Events Tracking
 */
export const homeClickTile = (tileName: string): void => {
  ReactGA.event('clickTile', {
    category: 'home',
    label: tileName
  })
}

/**
 * Search Events Tracking
 */

export const searchEnterSearch = (): void => {
  ReactGA.event('enterSEarch', {
    category: 'search'
  })
}

export const searchClickMarket = (pairName: string): void => {
  ReactGA.event('clickMarket', {
    category: 'search',
    label: pairName
  })
}
