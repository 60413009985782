import { ChainId } from '@traderjoe-xyz/sdk-core'
import { Chain } from 'types/dexbarn'

import { MerchantMoeChainId } from '../constants/chains'

export const getDexbarnChainParam = (chainId: MerchantMoeChainId): Chain => {
  switch (chainId) {
    case MerchantMoeChainId.MANTLE:
      return 'mantle'
    case MerchantMoeChainId.FUJI:
      return 'avalanche'
  }
}

export const toSdkChainId = (chainId: MerchantMoeChainId): ChainId => {
  switch (chainId) {
    case MerchantMoeChainId.MANTLE:
      return ChainId.MANTLE
    case MerchantMoeChainId.FUJI:
      return ChainId.FUJI
  }
}
