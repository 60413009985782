import { MoeLensAbi } from 'constants/abis/MoeLens'
import { MOE_LENS_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { formatUnits, zeroAddress } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

const useGetStableMoePendingRewards = () => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  return useReadContract({
    abi: MoeLensAbi,
    address: MOE_LENS_ADDRESS[chainId],
    args: [account || zeroAddress],
    chainId,
    functionName: 'getStakingData',
    query: {
      select: (data) => {
        return data.userRewards
          .map((reward) => ({
            ...reward,
            userPendingAmount: {
              formatted: formatUnits(
                reward.userPendingAmount,
                Number(reward.token.decimals)
              ),
              value: reward.userPendingAmount
            }
          }))
          .filter(
            (reward) =>
              reward.userPendingAmount.value > 0 ||
              reward.token.token !==
                '0xcDA86A272531e8640cD7F1a92c01839911B90bb0'
          )
      }
    }
  })
}

export default useGetStableMoePendingRewards
