import { Box, Flex, Heading, SimpleGrid, useToken } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import AnalyticsChart from 'components/AnalyticsChart'
import AnalyticsStat from 'components/AnalyticsStat'
import usePoolAnalyticsChange from 'hooks/analytics/usePoolAnalyticsChange'
import usePoolDetailV1Analytics from 'hooks/analytics/usePoolDetailV1Analytics'
import React from 'react'
import { Pool } from 'types/pool'
import { formattedNum } from 'utils/format'

interface PoolDetailOverviewProps {
  pool: Pool
  reserveX: string
  reserveY: string
}

const PoolDetailOverview = ({
  pool,
  reserveX,
  reserveY
}: PoolDetailOverviewProps) => {
  const { data: { chartData, pairDayDatas } = {}, isLoading } =
    usePoolDetailV1Analytics({
      poolAddress: pool.pairAddress
    })
  const poolChange = usePoolAnalyticsChange({ pairDayDatas })

  const [graphPurple] = useToken('colors', ['graphPurpleDark'])

  const fmtLiquidity = formattedNum(pool.liquidityUsd, { usd: true })
  const fmtVolume = formattedNum(pool.volumeUsd, { usd: true })
  const fmtFee = formattedNum(pool.feesUsd, { usd: true })
  const fmtReservesX = `${formattedNum(reserveX)} ${pool.tokenX.symbol}`
  const fmtReservesY = `${formattedNum(reserveY)} ${pool.tokenY.symbol}`

  return (
    <Flex flexDir="column" gap={4}>
      <SimpleGrid gap={4} columns={2}>
        <AnalyticsStat
          title={t`Liquidity`}
          value={fmtLiquidity}
          change={poolChange?.liquidityChange}
        />
        <AnalyticsStat
          title={t`Volume (24h)`}
          value={fmtVolume}
          change={poolChange?.volumeChange}
        />
        <AnalyticsStat
          title={t`Fees (24h)`}
          value={fmtFee}
          change={poolChange?.feeChange}
        />
        <AnalyticsStat
          title={t`APR`}
          value={`${Number(pool.apr * 100).toFixed(2)}%`}
        />
        <AnalyticsStat title={t`TokenX`} value={fmtReservesX} />
        <AnalyticsStat title={t`TokenY`} value={fmtReservesY} />
      </SimpleGrid>
      <Box
        py={{ base: 4, md: 6 }}
        px={{ base: 4, md: 8 }}
        bg="bgCard"
        boxShadow="element"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="tvl"
          data={chartData?.liquidity ?? []}
          tagline={t`TVL (Total Locked Value)`}
          header={<Heading size="md">{fmtLiquidity}</Heading>}
          fill={graphPurple}
          stroke={graphPurple}
          h={{ base: '100px', md: '250px' }}
          tooltipTitle={t`TVL`}
          isLoading={isLoading}
        />
      </Box>
      <Box
        py={{ base: 4, md: 6 }}
        px={{ base: 4, md: 8 }}
        bg="bgCard"
        boxShadow="element"
        borderRadius="2xl"
      >
        <AnalyticsChart
          id="volume"
          data={chartData?.volume ?? []}
          tagline={t`Volume (24h)`}
          header={<Heading size="md">{fmtVolume}</Heading>}
          fill={graphPurple}
          stroke={graphPurple}
          h={{ base: '100px', md: '250px' }}
          tooltipTitle={t`Volume (24h)`}
          isLoading={isLoading}
        />
      </Box>
    </Flex>
  )
}

export default PoolDetailOverview
