import {
  Box,
  Heading,
  HStack,
  Skeleton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import { StableMoeRemittance } from 'types/dexbarn'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

interface StakeRemittancesTableProps {
  isLoading: boolean
  remittances: StableMoeRemittance[]
}

const StakeRemittancesTable: React.FC<StakeRemittancesTableProps> = ({
  isLoading,
  remittances
}) => {
  const skeletonRows = Array(15).fill(0)

  return (
    <Box
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      boxShadow="element"
      w="full"
      px={{ base: 4, md: 8 }}
      pt={{ base: 4, md: 8 }}
      pb={{ base: 0, md: 4 }}
    >
      <Heading size="md" mb={4}>
        Remittances
      </Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th px={0} pr={4}>
                Fee Collected
              </Th>
              <Th>Amount Remitted</Th>
              <Th px={0} pl={4} isNumeric>
                Date
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {isLoading
              ? skeletonRows.map((_, index) => (
                  <Tr key={index}>
                    <Td px={0} pr={4} py={2}>
                      <Skeleton height="16px" width="100px" />
                    </Td>
                    <Td py={2}>
                      <HStack>
                        <Skeleton height="16px" width="80px" />
                        <Skeleton height="16px" width="60px" />
                      </HStack>
                    </Td>
                    <Td px={0} pl={4} py={2} isNumeric>
                      <Skeleton height="16px" width="80px" ml="auto" />
                    </Td>
                  </Tr>
                ))
              : remittances.map((remittance, index) => (
                  <Tr key={index}>
                    <Td px={0} pr={4} py={2}>{`${formattedNum(
                      formatUnits(
                        BigInt(remittance.amountIn),
                        remittance.tokenIn.decimals
                      )
                    )} ${remittance.tokenIn.symbol}`}</Td>
                    <Td isNumeric py={2}>
                      <HStack>
                        <Text>{`${formattedNum(
                          formatUnits(
                            BigInt(remittance.amountOut),
                            remittance.tokenOut.decimals
                          )
                        )} ${remittance.tokenOut.symbol}`}</Text>
                        <Text fontWeight="normal" textColor="textSecondary">
                          {formattedNum(remittance.amountOutUsd, {
                            usd: true
                          })}
                        </Text>
                      </HStack>
                    </Td>
                    <Td px={0} pl={4} py={2} isNumeric fontWeight="normal">
                      <TouchFriendlyTooltip
                        label={new Date(remittance.timestamp).toLocaleString()}
                      >
                        {formatDistanceToNow(new Date(remittance.timestamp), {
                          addSuffix: true
                        })}
                      </TouchFriendlyTooltip>
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  )
}

export default StakeRemittancesTable
