import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { VeMoeRewarderAbi } from 'constants/abis/VeMoeRewarder'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress, zeroAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseSweepRewarderProps {
  enabled: boolean
  rewarderAddress: string
  onSuccess?: () => void
  rewardToken?: Currency
}

const useSweepRewarder = ({
  enabled,
  onSuccess,
  rewarderAddress,
  rewardToken
}: UseSweepRewarderProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const currencyId = rewardToken?.isNative ? zeroAddress : rewardToken?.address
  const { data: config } = useSimulateContract({
    abi: VeMoeRewarderAbi,
    address: getAddress(rewarderAddress),
    args: account && currencyId ? [getAddress(currencyId), account] : undefined,
    chainId,
    functionName: 'sweep',
    query: {
      enabled: enabled && walletChain?.id === chainId && !!account,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Withdrew tokens from rewarder`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const sweepRewarder = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    sweepRewarder
  }
}

export default useSweepRewarder
