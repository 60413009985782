import { ArrowForwardIcon } from '@chakra-ui/icons'
import { Flex, HStack, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { TradeBestPath } from 'types/trade'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

import RouteDetailsStep from './RouteDetailsStep'

interface RouteDetailsProps {
  trade: TradeBestPath
}

const RouteDetails = ({ trade }: RouteDetailsProps) => {
  const chainId = useChainId()
  const nativeCurrency = CNATIVE.onChain(chainId)

  const { path } = trade

  return (
    <VStack align="flex-start" px={4} pb={4} bg="bgCard">
      <Text fontSize="sm" fontWeight="semibold">
        <Trans>Route</Trans>
      </Text>
      <Flex align="center" flexWrap="wrap" gap={2}>
        <HStack>
          <CurrencyLogo
            address={path[0].tokenInId}
            symbol={
              isWrappedNativeCurrency(path[0].tokenInId, chainId)
                ? nativeCurrency.symbol
                : path[0].tokenInSymbol
            }
            boxSize={5}
          />
        </HStack>
        <ArrowForwardIcon boxSize="14px" color="textSecondary" />
        {path.map((pair, i) => {
          return (
            <RouteDetailsStep
              key={i}
              pair={pair}
              showForwardArrow={path.length - 1 > i}
            />
          )
        })}
      </Flex>
    </VStack>
  )
}

export default RouteDetails
