import { t } from '@lingui/macro'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { getAddress, isAddress } from 'viem'
import { useAccount, useEstimateGas, useSendTransaction } from 'wagmi'

import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

interface UseSendNativeCurrencyProps {
  enabled: boolean
  recipient: string
  amount?: bigint
  onSuccess?: () => void
}

const useSendNativeCurrency = ({
  amount,
  enabled,
  onSuccess,
  recipient
}: UseSendNativeCurrencyProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const args = isAddress(recipient)
    ? {
        to: getAddress(recipient),
        value: amount
      }
    : undefined

  const { data: gas } = useEstimateGas({
    ...args,
    chainId,
    query: {
      enabled: enabled && walletChain?.id === chainId && !!amount,
      gcTime: 0
    }
  })

  const {
    data: hash,
    isPending,
    sendTransaction
  } = useSendTransaction({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Transferred tokens`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const transferNativeCurrency =
    gas && args
      ? () =>
          sendTransaction({
            ...args,
            gas
          })
      : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    transferNativeCurrency
  }
}

export default useSendNativeCurrency
