import { ChevronRightIcon } from '@chakra-ui/icons'
import { Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { MyActivityIcon, MyPoolIcon } from 'theme/icons'
import { WalletMenuEnum } from 'types/walletMenu'

import WalletMenuItem from './WalletMenuItem'
import WalletSettings from './WalletSettings'

interface WalletMyInfoProps {
  setWalletMenuDisplay: (menuDisplay: WalletMenuEnum) => void
}

const WalletMyInfo = ({ setWalletMenuDisplay }: WalletMyInfoProps) => {
  const navigate = useNavigate()

  return (
    <VStack w="full" alignItems="flex-start" py={4} px={3} rowGap={2}>
      <WalletMenuItem onClick={() => navigate(`/pool?tab=my-pools`)}>
        <MyPoolIcon boxSize="20px" mr={4} fill="textSecondary" />
        <Text fontWeight="semibold">
          <Trans>My Pools</Trans>
        </Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletMenuItem
        onClick={() => setWalletMenuDisplay(WalletMenuEnum.Activity)}
      >
        <MyActivityIcon boxSize="20px" mr={4} fill="textSecondary" />
        <Text fontWeight="semibold">
          <Trans>Activity</Trans>
        </Text>
        <ChevronRightIcon textColor="textSecondary" ml="auto" boxSize="20px" />
      </WalletMenuItem>
      <WalletSettings setWalletMenuDisplay={setWalletMenuDisplay} />
    </VStack>
  )
}

export default WalletMyInfo
