import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Flex,
  Hide,
  HStack,
  IconButton,
  Image,
  Link,
  Spacer,
  StackProps,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import Jazzicon from 'assets/jazzicon.svg'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { DisconnectIcon } from 'theme/icons'
import { shortenAddress } from 'utils/addresses'
import { useDisconnect } from 'wagmi'

interface ConnnectedAccountProps {
  stackProps?: StackProps
  walletAddress?: string
}

const ConnnectedAccount = ({
  stackProps,
  walletAddress
}: ConnnectedAccountProps) => {
  const { disconnect } = useDisconnect()
  const { blockExplorers } = useActiveChain()

  return (
    <HStack align="center" gap={4} w="full" {...stackProps}>
      <Image
        src={Jazzicon}
        borderRadius="50%"
        alt="pfp"
        bgColor="rgb(243, 184, 0)"
        boxSize="40px"
      />
      <Flex flexDir="column" alignItems="flex-start">
        <Text fontSize="xs" textColor="textSecondary">
          <Trans>Connected Account:</Trans>
        </Text>
        <Link
          aria-label="Link to user address on explorer"
          isExternal
          href={`${blockExplorers?.default.url}/address/${walletAddress}`}
        >
          <HStack>
            <Text fontSize="md" fontWeight="semibold">
              {shortenAddress(walletAddress)}
            </Text>
            <ExternalLinkIcon />
          </HStack>
        </Link>
      </Flex>
      <Spacer />
      <Hide below="lg">
        <IconButton
          aria-label="disconnect wallet"
          onClick={() => disconnect()}
          bg="bgSecondary"
          icon={<DisconnectIcon />}
          boxSize={10}
        />
      </Hide>
    </HStack>
  )
}

export default ConnnectedAccount
