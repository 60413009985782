import { Button, Flex, HStack, Skeleton, Text } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import CurrencyLogo from 'components/CurrencyLogo'
import { MOE_MASTERCHEF_ADDRESS } from 'constants/moe'
import { MOE_TOKEN } from 'constants/tokens'
import useBatchClaimRewards from 'hooks/farms/useBatchClaimRewards'
import useUserFarmPositions from 'hooks/pool/v1/useUserFarmPositions'
import useChainId from 'hooks/useChainId'
import debounce from 'lodash.debounce'
import React, { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'

const FarmPendingRewards = () => {
  const chainId = useChainId()

  const {
    data: userFarms,
    isFetching: isFetchingUserFarms,
    refetch: refetchUserFarms
  } = useUserFarmPositions()
  const debouncedRefetchUserFarms = debounce(refetchUserFarms, 2000)

  // sum up all pending rewards into a single array
  const userRewards = useMemo(() => {
    if (!userFarms) return []

    const moeToken = MOE_TOKEN[chainId]

    const rewardMap = new Map<
      string,
      { amount: number; token: { address: string; symbol?: string } }
    >()

    userFarms.forEach((farm) => {
      if (!farm) return

      // moe rewards
      const userPendingMoeReward = Number(
        formatUnits(farm.userPendingMoeReward, moeToken.decimals)
      )
      const currentMoeEntry = rewardMap.get(moeToken.address)
      if (currentMoeEntry) {
        rewardMap.set(moeToken.address, {
          amount: currentMoeEntry.amount + userPendingMoeReward,
          token: {
            address: moeToken.address,
            symbol: moeToken.symbol
          }
        })
      } else {
        rewardMap.set(moeToken.address, {
          amount: userPendingMoeReward,
          token: {
            address: moeToken.address,
            symbol: moeToken.symbol
          }
        })
      }

      // extra rewards
      const rewardToken = farm.rewarder.reward.token
      const userRewardAmount = Number(
        formatUnits(
          farm.rewarder.reward.userPendingAmount,
          Number(rewardToken.decimals)
        )
      )
      if (userRewardAmount > 0) {
        const currentEntry = rewardMap.get(rewardToken.token)
        if (currentEntry) {
          rewardMap.set(rewardToken.token, {
            amount: currentEntry.amount + userRewardAmount,
            token: {
              address: rewardToken.token,
              symbol: rewardToken.symbol
            }
          })
        } else {
          rewardMap.set(rewardToken.token, {
            amount: userRewardAmount,
            token: {
              address: rewardToken.token,
              symbol: rewardToken.symbol
            }
          })
        }
      }
    })

    return Array.from(rewardMap.values()).map(({ amount, token }) => ({
      amount,
      token
    }))
  }, [userFarms, chainId])

  // claim all
  const farmPids = userFarms?.map((farm) => farm.pid) || []
  const { batchClaimRewards, isLoading: isClaimingFarmRewards } =
    useBatchClaimRewards({
      enabled: farmPids.length > 0,
      onSuccess: debouncedRefetchUserFarms,
      pids: farmPids,
      rewarderAddress: MOE_MASTERCHEF_ADDRESS[chainId]
    })

  if (userRewards.length === 0 && !isFetchingUserFarms) return null

  return (
    <Flex
      gap={4}
      bg="bgCard"
      flexDir={{ base: 'column', md: 'row' }}
      w={{ base: 'full', md: 'fit-content' }}
      pl={{ base: 4, md: 3 }}
      pr={{ base: 4, md: 0 }}
      py={{ base: 4, md: 0 }}
      borderRadius="10px"
      mb={{ base: 4, md: 0 }}
      boxShadow="element"
    >
      <Flex gap={2} flexWrap="wrap" align="center">
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Pending rewards:</Trans>
        </Text>
        <Skeleton isLoaded={!isFetchingUserFarms} minW="80px">
          <HStack>
            {userRewards.map((userReward, i) => {
              return (
                <HStack key={userReward.token.address} spacing={1}>
                  <CurrencyLogo
                    address={userReward.token.address}
                    symbol={userReward.token.symbol}
                    boxSize={5}
                  />
                  <Text fontWeight="semibold" fontSize="sm">
                    {formattedNum(userReward.amount)} {userReward.token.symbol}{' '}
                    {i < userRewards.length - 1 && '+'}
                  </Text>
                </HStack>
              )
            })}
          </HStack>
        </Skeleton>
      </Flex>

      <Button
        variant="solid"
        colorScheme="accent"
        minW="80px"
        borderRadius="10px"
        isDisabled={!batchClaimRewards}
        isLoading={isClaimingFarmRewards}
        loadingText={t`Claiming`}
        onClick={() => batchClaimRewards?.()}
      >
        <Trans>Claim</Trans>
      </Button>
    </Flex>
  )
}

export default FarmPendingRewards
