import {
  extendTheme,
  SystemStyleObject,
  ThemeComponents
} from '@chakra-ui/react'

import { buttonTheme } from './button'
import { fontStyle } from './fonts'
import { inputTheme } from './input'
import { menuTheme } from './menu'
import { modalTheme } from './modal'
import { popoverTheme } from './popover'
import { slidersTheme } from './slider'
import { statTheme } from './stat'
import { switchTheme } from './switch'
import { tableTheme } from './table'
import { tabsTheme } from './tabs'
import { tagTheme } from './tag'

const styles: { global: { [key: string]: SystemStyleObject } } = {
  global: {
    '#chakra-toast-manager-top-right': {
      top: 'calc(env(safe-area-inset-top, 0px) + var(--toast-top-offset)) !important'
    },
    ':root': {
      '--toast-top-offset': '76px'
    },
    body: {
      bg: 'bgPrimary',
      transitionDuration: 'fast !important', // 150ms
      transitionProperty: 'background !important',
      transitionTimingFunction: 'ease-in-out !important'
    }
  }
}

export function withFallback(
  displayP3Color: string,
  fallbackHex: string
): string {
  if (CSS.supports('color', displayP3Color)) {
    return displayP3Color
  } else {
    return fallbackHex
  }
}

const colors = {
  accent: {
    100: withFallback('color(display-p3 0.9804 0.9216 0.9412)', '#faebf0'),
    200: withFallback('color(display-p3 0.9804 0.9216 0.9412)', '#faebf0'),
    300: withFallback('color(display-p3 0.898 0.7647 0.8078)', '#f4bfce'),
    400: withFallback('color(display-p3 0.949 0.6275 0.7412)', '#ff95bf'),
    500: withFallback('color(display-p3 0.9294 0.502 0.6549)', '#ff6da9'),
    600: withFallback('color(display-p3 0.85 0.425 0.5771)', '#e96494'),
    700: withFallback('color(display-p3 0.85 0.425 0.5771)', '#e96494'),
    800: withFallback('color(display-p3 0.85 0.425 0.5771)', '#e96494'),
    900: withFallback('color(display-p3 0.85 0.425 0.5771)', '#e96494')
  },
  deepPurple: withFallback('color(display-p3 0.4121 0.2643 0.6042)', '#70419f'),
  deepRed: {
    300: withFallback('color(display-p3 0.7529 0.3529 0.4980)', '#d15880'),
    400: withFallback('color(display-p3 0.6804 0.2784 0.4235)', '#bc486d'),
    500: withFallback('color(display-p3 0.6078 0.2039 0.3490)', '#a82959'),
    600: withFallback('color(display-p3 0.5353 0.1294 0.2745)', '#941845'),
    700: withFallback('color(display-p3 0.4627 0.0549 0.2000)', '#800e32')
  },
  green: {
    100: '#d5f5c9',
    200: '#93ee73',
    300: '#6fd24c',
    400: '#5eb53f',
    500: '#54903e'
  },
  textSecondaryDark: withFallback(
    'color(display-p3 0.6196 0.6392 0.6941)',
    '#9ca3b3'
  ),
  textSecondaryLight: withFallback(
    'color(display-p3 0.4306 0.4436 0.4784)',
    '#6c717c'
  ),
  yellowBar: withFallback('color(display-p3 1 0.7843 0.3528)', '#ffc85a')
}

const semanticTokens = {
  colors: {
    bgAlternative: {
      _dark: withFallback('color(display-p3 0.0847 0.0847 0.09)', '#161617'),
      _light: withFallback('color(display-p3 0.9144 0.9025 0.95)', '#ebe6f4')
    },
    bgCard: {
      _dark: withFallback('color(display-p3 0.126 0.126 0.14)', '#202024'),
      _light: withFallback('color(display-p3 0.9882 0.9882 1)', '#fcfcff')
    },
    bgChart2: withFallback('color(display-p3 0.6784 0.5216 0.8824)', '#ad85e1'),
    bgElement: {
      _dark: withFallback('color(display-p3 0.1584 0.1584 0.18)', '#28282f'),
      _light: withFallback('color(display-p3 0.9555 0.9506 0.97)', '#f4f2f9')
    },
    bgHighlight: {
      _dark: withFallback(
        'color(display-p3 0.7804 0.3216 0.4863 / 0.20)',
        'rgba(233, 56, 125, 0.20)'
      ),
      _light: withFallback('color(display-p3 0.9804 0.9216 0.9412)', '#ffe9f0')
    },
    bgInteractive: {
      _dark: withFallback('color(display-p3 0.7819 0.3236 0.4876)', '#ea377d'),
      _light: 'accent.500'
    },
    bgPrimary: {
      _dark: withFallback('color(display-p3 0.1098 0.1098 0.1176)', '#1c1c1e'),
      _light: withFallback('color(display-p3 0.949 0.949 0.9686)', '#f2f2f7')
    },
    bgSecondary: {
      _dark: withFallback('color(display-p3 0.1584 0.1584 0.18)', '#28282f'),
      _light: withFallback('color(display-p3 0.949 0.949 0.9686)', '#f2f2f7')
    },
    border: {
      _dark: '#2c2c33',
      _light: withFallback('color(display-p3 0.949 0.949 0.9686)', '#f2f2f7')
    },
    graphGreen: withFallback(
      'color(display-p3 0.4745 0.7961 0.7725)',
      '#00d1c7'
    ),
    graphPurpleDark: withFallback(
      'color(display-p3 0.6784 0.5216 0.8824)',
      '#bd80ed'
    ),
    graphPurpleLight: withFallback(
      'color(display-p3 0.8238 0.7086 0.9736)',
      '#dfb1ff'
    ),
    graphPurpleOutline: withFallback(
      'color(display-p3 0.5922 0.2784 1)',
      '#a341ff'
    ),
    hover: {
      _dark: '#2c2c33',
      _light: withFallback('color(display-p3 0.949 0.949 0.9686)', '#f2f2f7')
    },
    textPrimary: {
      _dark: withFallback('color(display-p3 0.9882 0.9882 1)', '#fcfcff'),
      _light: withFallback('color(display-p3 0.0353 0.0706 0.149)', '#04122a')
    },
    textSecondary: {
      _dark: 'textSecondaryDark',
      _light: 'textSecondaryLight'
    }
  },
  shadows: {
    element: {
      _dark: 'joeDark',
      _light: 'joeLight'
    }
  }
}

const components: ThemeComponents = {
  Button: buttonTheme,
  Heading: {
    baseStyle: {
      fontWeight: 'semibold'
    }
  },
  Input: inputTheme,
  Menu: menuTheme,
  Modal: modalTheme,
  Popover: popoverTheme,
  Slider: slidersTheme,
  Stat: statTheme,
  Switch: switchTheme,
  Table: tableTheme,
  Tabs: tabsTheme,
  Tag: tagTheme,
  Text: {
    baseStyle: {
      color: 'textPrimary'
    }
  }
}

const fonts = {
  body: fontStyle,
  heading: fontStyle,
  html: fontStyle
}

const shadows = {
  joeDark: withFallback(
    '0px 2px 5px 0px color(display-p3 0 0 0 / 0.05)',
    '0px 2px 5px 0px rgba(0, 0, 0, 0.05)'
  ),
  joeLight: withFallback(
    '0px 2px 5px 0px color(display-p3 0 0 0 / 0.05)',
    '0px 2px 5px 0px rgba(0, 0, 0, 0.05)'
  )
}

export const chakraTheme = extendTheme({
  colors,
  components,
  fonts,
  semanticTokens,
  shadows,
  styles
})
