import {
  Badge,
  HStack,
  LinkBox,
  LinkOverlay,
  Skeleton,
  Td,
  Text,
  Tr
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import DoubleCurrencyLogo from 'components/DoubleCurrencyLogo'
import useChainId from 'hooks/useChainId'
import React, { useMemo } from 'react'
import { Farm } from 'types/farm'
import { getBestBribe } from 'utils/farm'
import { formattedNum } from 'utils/format'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'
import { formatUnits } from 'viem'

interface VoteTableRowProps {
  farm: Farm
  userVeMoeBalance?: bigint
}

const VoteTableRow = ({ farm, userVeMoeBalance }: VoteTableRowProps) => {
  const chainId = useChainId()

  const nativeCurrency = CNATIVE.onChain(chainId)
  const currencyX = isWrappedNativeCurrency(farm.reserves.token0.token, chainId)
    ? nativeCurrency
    : farm.reserves.token0
  const currencyY = isWrappedNativeCurrency(farm.reserves.token1.token, chainId)
    ? nativeCurrency
    : farm.reserves.token1

  const moePerDayEmittedToFarm = farm.moePerSecEmittedToFarm * 60 * 60 * 24
  const moePerDayEmittedToFarmUsd = farm.moePerSecEmittedToFarmUsd
    ? farm.moePerSecEmittedToFarmUsd * 60 * 60 * 24
    : undefined

  const nbOfActiveBribes = farm.activeBribes.length
  const fmtNbOfBribes =
    nbOfActiveBribes > 9
      ? t`9+ Rewards`
      : nbOfActiveBribes > 1
        ? t`${nbOfActiveBribes} Rewards`
        : nbOfActiveBribes > 0
          ? t`Reward`
          : undefined

  const binStep = Number(farm.reserves.binStep)
  const tag =
    binStep === 0
      ? 'Classic'
      : `${formattedNum(formatUnits(farm.reserves.baseFee * BigInt(100), 18), {
          places: 2
        })}%`

  const bestBribe = useMemo(() => getBestBribe(farm), [farm])

  const fmtRewardsPer1mVotesPerDay = bestBribe
    ? `${formattedNum(bestBribe.rewardsPer1mVotesPerDay, {
        places: 5
      })} ${bestBribe.tokenSymbol}`
    : '--'

  const fmtRewardsPer1mVotesPerDayUsd =
    bestBribe && bestBribe.tokenUsdPrice
      ? formattedNum(
          bestBribe.rewardsPer1mVotesPerDay * bestBribe.tokenUsdPrice,
          {
            usd: true
          }
        )
      : undefined

  return (
    <LinkBox
      as={Tr}
      transform="scale(1)"
      cursor="pointer"
      role="group"
      boxShadow="element"
      borderRadius="10px"
    >
      <Td borderTopLeftRadius="10px" borderBottomLeftRadius="10px">
        <LinkOverlay
          aria-label={`Link to vote page for ${farm.reserves.token0.symbol} / ${farm.reserves.token1.symbol}`}
          href={`/vote/${farm.pid}`}
        />
        <HStack>
          <DoubleCurrencyLogo
            address0={
              currencyX instanceof CNATIVE ? undefined : currencyX.token
            }
            address1={
              currencyY instanceof CNATIVE ? undefined : currencyY.token
            }
            symbol0={currencyX.symbol}
            symbol1={currencyY.symbol}
            boxSize={6}
          />
          <Text>{`${currencyX.symbol} / ${currencyY.symbol}`}</Text>
          <Badge rounded="full" px={2} textTransform="none">
            {tag}
          </Badge>
          {fmtNbOfBribes && (
            <Badge colorScheme="green" rounded="full" px={2}>
              {fmtNbOfBribes}
            </Badge>
          )}
        </HStack>
      </Td>
      <Td>
        <Text>{formattedNum(farm.votesPercent, { places: 2 })}%</Text>
      </Td>
      <Td>
        {farm.votes7dChange ? (
          <Text textColor={farm.votes7dChange >= 0 ? 'green.400' : 'red.400'}>
            {farm.votes7dChange >= 0
              ? `+${formattedNum(farm.votes7dChange, { places: 2 })}%`
              : `${formattedNum(farm.votes7dChange, { places: 2 })}%`}
          </Text>
        ) : (
          '--'
        )}
      </Td>
      <Td>
        <Text>
          {formattedNum(farm.totalWeightOnFarmPercent, { places: 2 })}%{' '}
        </Text>
      </Td>
      <Td>
        <HStack>
          <Text>{`${formattedNum(moePerDayEmittedToFarm)} MOE`}</Text>
          {moePerDayEmittedToFarmUsd ? (
            <Text textColor="textSecondary" fontWeight="normal">
              {formattedNum(moePerDayEmittedToFarmUsd, { usd: true })}
            </Text>
          ) : null}
        </HStack>
      </Td>
      <Td>
        <Skeleton isLoaded={!!bestBribe || farm.activeBribes.length === 0}>
          <HStack>
            <Text>{fmtRewardsPer1mVotesPerDay}</Text>
            {fmtRewardsPer1mVotesPerDayUsd ? (
              <Text textColor="textSecondary" fontWeight="normal">
                {fmtRewardsPer1mVotesPerDayUsd}
              </Text>
            ) : null}
          </HStack>
        </Skeleton>
      </Td>
      <Td borderTopRightRadius="10px" borderBottomRightRadius="10px">
        {farm.userVotesOnFarm > 0 && userVeMoeBalance ? (
          <Text>
            {formattedNum(
              Number(
                (farm.userVotesOnFarm * BigInt(10000)) / userVeMoeBalance
              ) / 100,
              {
                places: 2
              }
            )}
            %
          </Text>
        ) : (
          '--'
        )}
      </Td>
    </LinkBox>
  )
}

export default VoteTableRow
