import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { StableMoeRemittance } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

const useGetStableMoeRemittances = () => {
  const chainId = useChainId()

  const roundToNearest5Minutes = (timestamp: number) => {
    const msIn5Minutes = 5 * 60 * 1000
    return Math.round(timestamp / msIn5Minutes) * msIn5Minutes
  }

  const now = roundToNearest5Minutes(Date.now())
  const timestamp30dAgo = roundToNearest5Minutes(now - 30 * 24 * 60 * 60 * 1000)

  const fetchRemittances = useDexbarnGet<StableMoeRemittance[]>(
    `/v1/remittances/${getDexbarnChainParam(chainId)}`
  )

  return useQuery({
    queryFn: () =>
      fetchRemittances({
        params: {
          endTimestamp: Math.trunc(now / 1000),
          startTimestamp: Math.trunc(timestamp30dAgo / 1000)
        }
      }),
    queryKey: ['getStableMoeRemittances', chainId, now, timestamp30dAgo]
  })
}

export default useGetStableMoeRemittances
