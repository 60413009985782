import { Button, Center, SimpleGrid, Text, useToken } from '@chakra-ui/react'
import { LiquidityDistribution } from '@traderjoe-xyz/sdk-v2'
import { ReactComponent as BidAskLogo } from 'assets/distribution_bid_ask.svg'
import { ReactComponent as CurveLogo } from 'assets/distribution_curve.svg'
import { ReactComponent as SpotLogo } from 'assets/distribution_spot.svg'
import React from 'react'

const getDistributionImage = (
  distribution: LiquidityDistribution,
  color: string
): JSX.Element => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return <BidAskLogo style={{ color }} />
    case LiquidityDistribution.CURVE:
      return <CurveLogo style={{ color }} />
    case LiquidityDistribution.SPOT:
      return <SpotLogo style={{ color }} />
  }
}

const getDistributtionTitle = (distribution: LiquidityDistribution): string => {
  switch (distribution) {
    case LiquidityDistribution.BID_ASK:
      return 'Bid-Ask'
    case LiquidityDistribution.CURVE:
      return 'Curve'
    case LiquidityDistribution.SPOT:
      return 'Spot'
  }
}

interface DistributionOptionsProps {
  distribution: LiquidityDistribution
  onDistributionChange: (distribution: LiquidityDistribution) => void
}

const DistributionOptions = ({
  distribution,
  onDistributionChange
}: DistributionOptionsProps) => {
  const options = [
    LiquidityDistribution.SPOT,
    LiquidityDistribution.CURVE,
    LiquidityDistribution.BID_ASK
  ]

  const [accent500, textPrimary] = useToken('colors', [
    'bgInteractive',
    'textPrimary'
  ])

  return (
    <SimpleGrid columns={3} gap={2} w="full">
      {options.map((option, i) => {
        const isSelected = distribution === option
        return (
          <Button
            data-cy={`distribution-shape-${i}-button`}
            key={i}
            variant="unstyled"
            border="1px"
            borderColor="border"
            borderRadius="lg"
            h="100px"
            _hover={{ bg: 'hover' }}
            isActive={isSelected}
            _active={{ bg: 'bgHighlight', borderColor: 'bgInteractive' }}
            onClick={() => onDistributionChange(option)}
          >
            <Center flexDir="column">
              {getDistributionImage(
                option,
                isSelected ? accent500 : textPrimary
              )}
              <Text
                pt="10px"
                fontSize="sm"
                fontWeight="normal"
                color={isSelected ? 'bgInteractive' : 'textPrimary'}
              >
                {getDistributtionTitle(option)}
              </Text>
            </Center>
          </Button>
        )
      })}
    </SimpleGrid>
  )
}

export default DistributionOptions
