import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import { JOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import React from 'react'

interface StakedJoeAmountPanelProps {
  joeDepositedAmount?: string
}

const StakedJoeAmountPanel = ({
  joeDepositedAmount
}: StakedJoeAmountPanelProps) => {
  const chainId = useChainId()
  const joeToken = JOE_TOKEN[chainId]

  if (!joeToken) {
    return null
  }

  return (
    <CurrencyAmountsPanel
      boxProps={{
        bg: 'bgCard',
        borderRadius: { base: '10px', md: '20px' },
        boxShadow: 'element'
      }}
      title={t`Staked Balance`}
      tokens={[joeToken.address]}
      tokenAmounts={[Number(joeDepositedAmount)]}
      tokenSymbols={[joeToken.symbol]}
    />
  )
}

export default StakedJoeAmountPanel
