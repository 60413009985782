import { t } from '@lingui/macro'
import { MOE_MASTERCHEF_ADDRESS } from 'constants/moe'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { formatUnits, parseAbi } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseDepositToFarmProps {
  enabled: boolean
  farmName: string
  onSuccess: () => void
  pid: bigint
  amount?: bigint
}

const useDepositToFarm = ({
  amount,
  enabled,
  farmName,
  onSuccess,
  pid
}: UseDepositToFarmProps) => {
  const chainId = useChainId()
  const { isConnected } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: parseAbi(['function deposit(uint256 pid, uint256 amount) external']),
    address: MOE_MASTERCHEF_ADDRESS[chainId],
    args: amount ? [pid, amount] : undefined,
    functionName: 'deposit',
    query: { enabled: isConnected && !!amount && enabled, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const fmtAmount = amount ? formatUnits(amount, 18) : 0
        const description = t`Stake ${formattedNum(
          fmtAmount
        )} MoeLP in ${farmName} farm`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const deposit = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    deposit,
    isLoading: isPending || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success'
  }
}

export default useDepositToFarm
