import { ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react'
import { SubmenuItem } from 'constants/urls'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ThreeDotsIcon } from 'theme/icons'

interface SubmenuProps {
  items: SubmenuItem[]
}

const Submenu = ({ items }: SubmenuProps) => {
  const navigate = useNavigate()

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="Links to other pages"
        variant="ghost"
        color="textSecondary"
        _hover={{ bg: 'bgSecondary' }}
        _active={{ bg: 'bgSecondary', color: 'textPrimary' }}
      >
        <Center>
          <ThreeDotsIcon fill="textSecondary" />
        </Center>
      </MenuButton>
      <MenuList>
        {items.map((item, i) => {
          return item.external ? (
            <MenuItem
              key={i}
              h="48px"
              fontWeight="semibold"
              onClick={() => window.open(item.url, '_blank')}
            >
              <Flex gap={2} align="center" justify="space-between">
                {item.name}
                <ExternalLinkIcon />
              </Flex>
            </MenuItem>
          ) : (
            <MenuItem
              key={i}
              h="48px"
              fontWeight="semibold"
              onClick={() => navigate(`/${item.url}`)}
            >
              {item.name}
            </MenuItem>
          )
        })}
      </MenuList>
    </Menu>
  )
}

export default Submenu
