import { Box, SimpleGrid, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { formattedNum } from 'utils/format'

interface VoteWeightInfoGridProps {
  platformTotalVotes?: string
  userAvailableVeMoeBalance?: string
  userVeMoeBalance?: string
}

const VoteWeightInfoGrid = ({
  platformTotalVotes,
  userAvailableVeMoeBalance,
  userVeMoeBalance
}: VoteWeightInfoGridProps) => {
  const userRemainingVotes = userAvailableVeMoeBalance
    ? Number(userAvailableVeMoeBalance)
    : 0

  const userAllocatedVotes =
    userVeMoeBalance !== undefined
      ? Number(userVeMoeBalance) - userRemainingVotes
      : undefined

  const userAllocatedVotesPercentage =
    userVeMoeBalance !== undefined && userAllocatedVotes !== undefined
      ? (userAllocatedVotes / Number(userVeMoeBalance)) * 100
      : undefined

  const userShareOfAllocatedVotes =
    userAllocatedVotes !== undefined && platformTotalVotes
      ? (userAllocatedVotes / Number(platformTotalVotes)) * 100
      : undefined

  return (
    <SimpleGrid mt={10} columns={{ base: 2, md: 4 }} spacing={4}>
      <Box p={4} bg="bgCard" borderRadius="10px" boxShadow="element">
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Allocated vote weight:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="lg">
          {userAllocatedVotesPercentage !== undefined
            ? formattedNum(userAllocatedVotesPercentage, {
                places: 2
              }) + '%'
            : '--'}
        </Text>
      </Box>
      <Box p={4} bg="bgCard" borderRadius="10px" boxShadow="element">
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Remaining vote weight:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="lg">
          {userAllocatedVotesPercentage !== undefined
            ? formattedNum(100 - userAllocatedVotesPercentage, {
                places: 2
              }) + '%'
            : '--'}
        </Text>
      </Box>
      <Box p={4} bg="bgCard" borderRadius="10px" boxShadow="element">
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Your share of allocated vote weight:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="lg">
          {userShareOfAllocatedVotes !== undefined
            ? formattedNum(userShareOfAllocatedVotes, {
                places: 2
              }) + '%'
            : '--'}
        </Text>
      </Box>
    </SimpleGrid>
  )
}

export default VoteWeightInfoGrid
