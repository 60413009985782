import { useQuery } from '@tanstack/react-query'
import { MOE_MASTERCHEF_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { Farm as DexbarnFarm } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseGetDexbarnFarmProps {
  pid?: string
}

const useGetDexbarnFarm = ({ pid }: UseGetDexbarnFarmProps) => {
  const chainId = useChainId()

  const dexbarnFarmId = pid
    ? `${MOE_MASTERCHEF_ADDRESS[chainId].toLowerCase()}-${pid}`
    : undefined
  const fetchFarm = useDexbarnGet<DexbarnFarm>(
    `/v1/farms/${getDexbarnChainParam(chainId)}/${dexbarnFarmId}`
  )

  return useQuery({
    enabled: !!dexbarnFarmId,
    queryFn: () => fetchFarm(),
    queryKey: ['FarmQuery', dexbarnFarmId, chainId]
  })
}

export default useGetDexbarnFarm
