import { CNATIVE, Token } from '@traderjoe-xyz/sdk-core'
import { VeMoeRewarderAbi } from 'constants/abis/VeMoeRewarder'
import useChainId from 'hooks/useChainId'
import { toSdkChainId } from 'utils/chains'
import { getAddress, zeroAddress } from 'viem'
import { useBalance, useReadContracts } from 'wagmi'

interface UseGetRewarderDataProps {
  rewarderAddress?: string
}

const useGetRewarderData = ({ rewarderAddress }: UseGetRewarderDataProps) => {
  const chainId = useChainId()

  const checksummedRewarderAddress = rewarderAddress
    ? getAddress(rewarderAddress)
    : zeroAddress

  const { data, isLoading, refetch } = useReadContracts({
    allowFailure: true,
    contracts: [
      {
        abi: VeMoeRewarderAbi,
        address: checksummedRewarderAddress,
        chainId,
        functionName: 'owner'
      },
      {
        abi: VeMoeRewarderAbi,
        address: checksummedRewarderAddress,
        chainId,
        functionName: 'getRewarderParameter'
      },
      {
        abi: VeMoeRewarderAbi,
        address: checksummedRewarderAddress,
        chainId,
        functionName: 'isStopped'
      },
      {
        abi: VeMoeRewarderAbi,
        address: checksummedRewarderAddress,
        functionName: 'getRemainingReward'
      }
    ],
    query: {
      enabled: checksummedRewarderAddress !== zeroAddress,
      select: (data) => {
        const owner = data[0].result
        const rewarderParameters = data[1].result
        const isStopped = data[2].result
        const remainingReward = data[3].result

        return {
          isStopped,
          owner,
          remainingReward,
          rewarderParameters
        }
      }
    }
  })

  const rewardTokenAddress = data?.rewarderParameters?.[0]
  const isNative = rewardTokenAddress === zeroAddress

  const {
    data: rewarderTokenBalanceResult,
    isLoading: isLoadingRewarderTokenBalance,
    refetch: refetchRewarderTokenBalance
  } = useBalance({
    address: rewarderAddress ? getAddress(rewarderAddress) : undefined,
    chainId,
    token: isNative ? undefined : rewardTokenAddress
  })

  const rewardToken = isNative
    ? CNATIVE.onChain(chainId)
    : rewarderTokenBalanceResult && rewardTokenAddress
      ? new Token(
          toSdkChainId(chainId),
          rewardTokenAddress,
          rewarderTokenBalanceResult.decimals,
          rewarderTokenBalanceResult.symbol
        )
      : undefined

  return {
    isLoading: isLoading || isLoadingRewarderTokenBalance,
    isStopped: data?.isStopped,
    owner: data?.owner,
    refetch: () => {
      refetch()
      refetchRewarderTokenBalance()
    },
    remainingRewardAmount: data?.remainingReward,
    rewardToken,
    rewarderParameters: {
      endTimestamp: data?.rewarderParameters?.[3],
      lastUpdateTimestamp: data?.rewarderParameters?.[2],
      rewardPerSecond: data?.rewarderParameters?.[1]
    },
    rewarderTokenBalance: rewarderTokenBalanceResult
      ? {
          formatted: rewarderTokenBalanceResult.formatted,
          value: rewarderTokenBalanceResult.value
        }
      : undefined
  }
}

export default useGetRewarderData
