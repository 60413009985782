import { useSearchParams } from 'react-router-dom'

enum Tab {
  AutoPools = 'auto-pools',
  LBPools = 'lb-pools',
  MyPools = 'my-pools',
  Pools = 'pools'
}

const tabs = Object.values(Tab)

const tabToIndex = (tab: Tab): number => {
  switch (tab) {
    case Tab.Pools:
      return 0
    case Tab.LBPools:
      return 1
    case Tab.AutoPools:
      return 2
    case Tab.MyPools:
      return 3
  }
}

const indexToTab = (index: number): Tab => {
  switch (index) {
    case 0:
      return Tab.Pools
    case 1:
      return Tab.LBPools
    case 2:
      return Tab.AutoPools
    case 3:
      return Tab.MyPools
    default:
      return Tab.Pools
  }
}

const usePoolSearchParams = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab')
  const selectedTab: Tab =
    tab && tabs.includes(tab as Tab) ? (tab as Tab) : Tab.Pools

  return {
    selectedTab: tabToIndex(selectedTab),
    setSelectedTab: (index: number) =>
      setSearchParams({
        tab: indexToTab(index)
      })
  }
}

export default usePoolSearchParams
