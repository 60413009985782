import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import { JOE_STAKING, MOE_TOKEN_ADDRESS } from 'constants/moe'
import useClaim from 'hooks/stake/useClaim'
import useChainId from 'hooks/useChainId'
import React from 'react'

interface ClaimMoePanelProps {
  claimableMoeAmount: string
  onClaimSuccess: () => void
}

const ClaimMoePanel = ({
  claimableMoeAmount,
  onClaimSuccess
}: ClaimMoePanelProps) => {
  const chainId = useChainId()
  const moeToken = MOE_TOKEN_ADDRESS[chainId]

  const { claim, isLoading: isClaiming } = useClaim({
    enabled: Number(claimableMoeAmount) > 0,
    onSuccess: onClaimSuccess,
    stakingContractAddress: JOE_STAKING[chainId]
  })

  return (
    <CurrencyAmountsPanel
      boxProps={{
        bg: 'bgCard',
        borderRadius: { base: '10px', md: '20px' },
        boxShadow: 'element',
        'data-cy': 'claim-moe-panel'
      }}
      title={t`Claim MOE`}
      tokens={[moeToken]}
      tokenAmounts={[Number(claimableMoeAmount)]}
      tokenSymbols={['MOE']}
      bottomContent={
        <Web3Button
          data-cy="claim-moe-button"
          mt={4}
          size="xl"
          colorScheme="accent"
          variant="outline-bold"
          w="full"
          isDisabled={Number(claimableMoeAmount) === 0 || !claim}
          isLoading={isClaiming}
          loadingText={t`Claiming`}
          onClick={claim}
        >
          Claim
        </Web3Button>
      }
    />
  )
}

export default ClaimMoePanel
