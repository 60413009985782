import { useQuery } from '@tanstack/react-query'
import { VeMoeAbi } from 'constants/abis/VeMoe'
import { findLevelByVeMoeAmount } from 'constants/journey'
import { VE_MOE_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { getCurrentJourneyEpochS4 } from 'pages/MoeJourney/constants'
import { formatUnits } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

const useMoeJourneyPoints = () => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  const { data: veMoeBalance, isLoading: isLoadingVeMoeBalance } =
    useReadContract({
      abi: VeMoeAbi,
      address: VE_MOE_ADDRESS[chainId],
      args: account ? [account] : undefined,
      chainId,
      functionName: 'balanceOf',
      query: {
        select: (data) => ({ formatted: formatUnits(data, 18), raw: data })
      }
    })

  const epoch = getCurrentJourneyEpochS4()
  const epochStartTimestamp = epoch.epochStartDate.getTime() / 1000
  const fetchMultipliers = useDexbarnGet<{
    communityBonusMjt: string
    decreasedMoeStake: string
    hasDiscordRole: boolean
    increasedMoeStake: string
    isLoyal: boolean
    moeClaimed: string
    szn3BonusMjt: string
    userAddress: string
  }>(`/v1/user/mjt/${account}?startTimestamp=${epochStartTimestamp}`)

  const { data: userExtraTickets, isLoading: isLoadingExtraTickets } = useQuery(
    {
      enabled: !!account,
      queryFn: () => fetchMultipliers(),
      queryKey: ['GetUserMoeJourneyTickets', account, epochStartTimestamp]
    }
  )

  const veMoeBalanceNum = veMoeBalance ? Number(veMoeBalance.formatted) : 0
  const userVeMoeTier = findLevelByVeMoeAmount(veMoeBalanceNum)

  const moeClaimedPerTicket = 25
  const userTicketsForMoeClaimed = Math.round(
    Number(userExtraTickets?.moeClaimed || 0) / moeClaimedPerTicket
  )

  const increasedMoeStakePerTicket = 25
  const userNetMoeStaked =
    Number(userExtraTickets?.increasedMoeStake || 0) -
    Number(userExtraTickets?.decreasedMoeStake || 0)
  const userTicketsForStakedMoe =
    userNetMoeStaked > 0
      ? Math.round(userNetMoeStaked / increasedMoeStakePerTicket)
      : 0

  const userTotalTickets =
    Number(userVeMoeTier?.moeJourneyTickets || 0) +
    Number(userExtraTickets?.szn3BonusMjt || 0) +
    Number(userExtraTickets?.communityBonusMjt || 0) +
    userTicketsForMoeClaimed +
    userTicketsForStakedMoe

  return {
    isLoading: isLoadingVeMoeBalance || isLoadingExtraTickets,
    userExtraTickets,
    userNetMoeStaked,
    userTotalTickets,
    userVeMoeBalance: veMoeBalance,
    userVeMoeTier
  }
}

export default useMoeJourneyPoints
