import { PoolSortMethod } from 'hooks/pool/useSortedPools'
import { useMemo } from 'react'
import { VaultWithUserPosition } from 'types/vault'
import { getUserVaultBalanceUSD } from 'utils/vault'

interface UseSortedVaultsProps {
  isSortDescending: boolean
  sortMethod: PoolSortMethod
  vaults: VaultWithUserPosition[]
}

const useSortedVaults = ({
  isSortDescending,
  sortMethod,
  vaults
}: UseSortedVaultsProps) => {
  return useMemo(() => {
    let sortedVaults = vaults
    switch (sortMethod) {
      case PoolSortMethod.LIQUIDITY:
        sortedVaults = sortedVaults.sort(
          (a, b) => b.totalValueLockedUSD - a.totalValueLockedUSD
        )
        break
      case PoolSortMethod.FEE:
        sortedVaults = sortedVaults.sort((a, b) => b.feesUsd - a.feesUsd)
        break
      case PoolSortMethod.APR:
        sortedVaults = sortedVaults.sort((a, b) => b.apr1D - a.apr1D)
        break
      case PoolSortMethod.MY_FUNDS:
        sortedVaults = sortedVaults.sort(
          (a, b) =>
            (getUserVaultBalanceUSD(b) ?? 0) - (getUserVaultBalanceUSD(a) ?? 0)
        )
        break
    }
    return isSortDescending ? sortedVaults : sortedVaults.reverse()
  }, [vaults, sortMethod, isSortDescending])
}

export default useSortedVaults
