import {
  Box,
  Divider,
  Flex,
  Heading,
  HStack,
  Link,
  LinkProps,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import React from 'react'
import {
  CoinMarketCapIcon,
  DiscordIcon,
  GithubIcon,
  TwitterIcon
} from 'theme/icons'

import FooterSection from './FooterSection'

const SocialNetworkLink = (props: LinkProps) => (
  <Link
    isExternal
    display="flex"
    alignItems="center"
    justifyContent="center"
    borderRadius="10px"
    bg="bgAlternative"
    boxSize="40px"
    _hover={{ bg: 'bgAlternative', opacity: 0.8 }}
    _active={{ bg: 'bgAlternative' }}
    {...props}
  />
)

const Footer = () => {
  const year = new Date().getFullYear()
  return (
    <Box width="full" bgColor="bgPrimary">
      <Divider />
      <Box
        maxW={{ '2xl': '1600px', base: '1400px' }}
        margin="0 auto"
        p={4}
        pt={{ base: 4, md: 12 }}
        pb={{ base: '70px', md: 8 }}
      >
        <Flex
          flexWrap={{ base: 'wrap', md: 'nowrap' }}
          gap={{ base: '40px', md: '120px' }}
        >
          <Flex flexDir="column" justify="space-between" gap={8} flexShrink={0}>
            <VStack align="flex-start" spacing={2}>
              <Heading size="md" color="textSecondary">
                Merchant Moe
              </Heading>
              <Text fontSize="sm" color="textSecondary">
                © {year} Moetrade Ltd
              </Text>
            </VStack>

            <HStack spacing={4} flexWrap="wrap">
              <Link
                isExternal
                aria-label="Audits of Trader Joe's smart contracts"
                href="https://docs.merchantmoe.com/resources/audits"
                color="textSecondary"
                fontSize="sm"
              >
                <Trans>Security</Trans>
              </Link>
              <Link
                isExternal
                aria-label="Merchant Moe documentation"
                href="https://docs.merchantmoe.com/"
                color="textSecondary"
                fontSize="sm"
              >
                <Trans>Docs</Trans>
              </Link>
              <Link
                aria-label="Merchant Moe Privacy Policy"
                href="/privacy"
                color="textSecondary"
                fontSize="sm"
              >
                <Trans>Privacy</Trans>
              </Link>
              <Link
                aria-label="Merchant Moe Terms of Service"
                href="/tos"
                color="textSecondary"
                fontSize="sm"
              >
                <Trans>Terms of Service</Trans>
              </Link>
            </HStack>

            <HStack spacing={8}>
              <SocialNetworkLink
                aria-label="Merchant Moe on Discord"
                href="https://discord.com/invite/merchantmoe"
              >
                <DiscordIcon boxSize="28px" fill="#6E717A" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Merchant Moe on Twitter"
                href="https://twitter.com/merchantmoe_xyz"
              >
                <TwitterIcon boxSize="28px" fill="#6E717A" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Merchant Moe on CoinMarketCap"
                href="https://coinmarketcap.com/currencies/merchant-moe/"
              >
                <CoinMarketCapIcon boxSize="22px" fill="#6E717A" />
              </SocialNetworkLink>
              <SocialNetworkLink
                aria-label="Merchant Moe on Github"
                href="https://github.com/merchant-moe"
              >
                <GithubIcon boxSize="28px" fill="#6E717A" />
              </SocialNetworkLink>
            </HStack>
          </Flex>
          <SimpleGrid columns={3} w="full">
            <FooterSection
              title={t`MOE Token`}
              items={[
                {
                  href: 'https://www.coingecko.com/en/coins/moe-3',
                  name: 'Coingecko'
                },
                {
                  href: 'https://coinmarketcap.com/currencies/merchant-moe/',
                  name: 'CoinMarketCap'
                }
              ]}
            />
            <FooterSection
              title={t`Analytics`}
              items={[
                {
                  href: 'https://dexscreener.com/mantle/merchantmoe',
                  name: 'DEX Screener'
                },
                {
                  href: 'https://defillama.com/protocol/merchant-moe',
                  name: 'Defi Llama'
                }
              ]}
            />
            <FooterSection
              title={t`Business`}
              items={[
                {
                  href: 'https://forms.gle/9FvgjSEepJhFBApT7',
                  name: t`Apply for Partnership`
                },
                {
                  href: 'https://github.com/merchant-moe/moe-tokenlists',
                  name: t`Token Listing`
                },
                {
                  href: 'https://twitter.com/merchantmoe_xyz',
                  name: t`Contact Us`
                }
              ]}
            />
          </SimpleGrid>
        </Flex>
      </Box>
    </Box>
  )
}

export default Footer
