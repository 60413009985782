import { JOE_STAKING, JOE_STAKING_REWARDER } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { formatUnits, parseAbi } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

const joeStakingAbi = parseAbi([
  'function getDeposit(address account) external view returns (uint256)',
  'function getPendingReward(address account) external view returns (address, uint256)',
  'function getTotalDeposit() external view returns (uint256)',
  'function getRewarder() external view returns (address)'
])

const rewarderAbi = parseAbi([
  'function getRewarderParameter() external view returns (address token, uint256 rewardPerSecond, uint256 lastUpdateTimestamp, uint256 endTimestamp)'
])

export const useGetJoeStakingData = () => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  return useReadContracts({
    contracts: [
      {
        abi: joeStakingAbi,
        address: JOE_STAKING[chainId],
        args: account ? [account] : undefined,
        chainId,
        functionName: 'getDeposit'
      },
      {
        abi: joeStakingAbi,
        address: JOE_STAKING[chainId],
        args: account ? [account] : undefined,
        chainId,
        functionName: 'getPendingReward'
      },
      {
        abi: joeStakingAbi,
        address: JOE_STAKING[chainId],
        chainId,
        functionName: 'getTotalDeposit'
      },
      {
        abi: rewarderAbi,
        address: JOE_STAKING_REWARDER[chainId],
        chainId,
        functionName: 'getRewarderParameter'
      }
    ],
    query: {
      select: (data) => {
        const [deposit, pendingReward, totalDeposit, rewarderParameter] = data
        const claimableMoe = pendingReward.result?.[1]
        const moePerSecond = rewarderParameter.result?.[1]

        const now = Math.floor(Date.now() / 1000)
        const endTimestamp = rewarderParameter.result?.[3]
        const remainingRewardsAmount =
          moePerSecond && endTimestamp
            ? moePerSecond * (endTimestamp - BigInt(now))
            : undefined

        return {
          joeTotalDepositedAmount:
            totalDeposit.result !== undefined
              ? {
                  formatted: formatUnits(totalDeposit.result, 18),
                  value: totalDeposit.result
                }
              : undefined,
          moePerSecond:
            moePerSecond !== undefined
              ? {
                  formatted: formatUnits(moePerSecond, 18),
                  value: moePerSecond
                }
              : undefined,
          remainingRewardsAmount:
            remainingRewardsAmount !== undefined
              ? {
                  formatted: formatUnits(remainingRewardsAmount, 18),
                  value: remainingRewardsAmount
                }
              : undefined,
          userClaimableMoeAmount:
            claimableMoe !== undefined
              ? {
                  formatted: formatUnits(claimableMoe, 18),
                  value: claimableMoe
                }
              : undefined,
          userJoeDepositedAmount:
            deposit.result !== undefined
              ? {
                  formatted: formatUnits(deposit.result, 18),
                  value: deposit.result
                }
              : undefined
        }
      }
    }
  })
}
