import {
  Box,
  Center,
  Divider,
  Grid,
  Spinner,
  Tab as ChakraTab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import useGetLbRewarderData from 'hooks/pool/v2/useGetLbRewarderData'
import useLBPosition from 'hooks/pool/v2/useLBPosition'
import usePoolDetailV2Params from 'hooks/pool/v2/usePoolDetailV2Params'
import useChainId from 'hooks/useChainId'
import debounce from 'lodash.debounce'
import React, { useState } from 'react'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import AddRemoveLiquidityPanelV2 from './AddRemoveLiquidityPanelV2'
import LbRewarderPanel from './LbRewardsPanel'
import PoolDetailV2AnalyticsPanel from './PoolDetailV2AnalyticsPanel'
import PoolDetailV2Header from './PoolDetailV2Header'
import { useLBPairDataContext, usePoolDetailV2Context } from './state'
import UserEarnedFees from './UserEarnedFees'
import UserLiquidityChart from './UserLiquidityChart'

const PoolDetailV2 = () => {
  const { address: account, isConnected } = useAccount()
  const { binStep, lbPoolVersion } = usePoolDetailV2Params()
  const chainId = useChainId()

  enum Tab {
    LIQUIDITY = 0,
    ANALYTICS = 1
  }
  const [selectedTab, setSelectedTab] = useState<Tab>(Tab.LIQUIDITY)

  const {
    balance0,
    balance1,
    currency0,
    currency1,
    inversePriceRatios,
    isLoadingPoolV2,
    pool,
    refetchBalance0,
    refetchBalance1,
    togglePriceRatios
  } = usePoolDetailV2Context()
  const {
    activeBinId,
    baseFee,
    isLoading,
    lbPairAddress,
    refetchReservesAndActiveBinId,
    reserveX,
    reserveY
  } = useLBPairDataContext()

  const wrappedCurrency0 = wrappedCurrency(currency0, chainId)
  const wrappedCurrency1 = wrappedCurrency(currency1, chainId)

  const {
    isLoadingLBPosition: isLoadingUserLBPosition,
    pooledTokens0,
    pooledTokens1,
    refetchLBPosition: refetchUserLBPosition,
    userBalances
  } = useLBPosition({
    lbBinStep: Number(binStep),
    lbPairAddress: lbPairAddress,
    owner: account,
    token0: wrappedCurrency0,
    token1: wrappedCurrency1
  })

  const { data: lbRewarderData, refetch: refetchLbRewarderData } =
    useGetLbRewarderData({
      binStep: Number(binStep),
      currency0,
      currency1,
      ids: userBalances?.positions || [],
      pairAddress: lbPairAddress
    })
  const rewarderPid = lbRewarderData?.rewarderData.pid

  if (isLoading || !lbPairAddress || isLoadingPoolV2) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  // wait for the indexer to update the user's positions, then ask for a refetch
  const debouncedRefetchUserLBPosition = debounce(
    () => refetchUserLBPosition(),
    10000
  )
  const debouncedRefetchLbRewarderData = debounce(
    () => refetchLbRewarderData(),
    2000
  )

  return (
    <Box pt={4} w="full">
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailV2Header
        votePid={rewarderPid ? Number(rewarderPid) : undefined}
      />
      <Tabs
        colorScheme="accent"
        pos="relative"
        mt={{ base: 0, md: '-30px' }}
        w="full"
        index={selectedTab}
        onChange={setSelectedTab}
      >
        <Box overflowX="auto" overflowY="hidden">
          <TabList
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
            px={4}
          >
            <ChakraTab>Manage</ChakraTab>
            <ChakraTab>Analytics</ChakraTab>
          </TabList>
        </Box>
        <TabPanels w="full" minH="calc(100vh - 250px)">
          <TabPanel
            pb={32}
            pt={8}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
            w="full"
            px={4}
          >
            <Grid
              templateColumns={{
                base: 'minmax(0, 1fr)',
                xl: 'minmax(0, 1fr) minmax(0, 1fr)'
              }}
              gap={{ base: 4, md: 8 }}
              alignItems="flex-start"
            >
              <VStack spacing={4}>
                <Box
                  w="full"
                  bg="bgCard"
                  borderRadius="2xl"
                  boxShadow="element"
                >
                  <Box px={{ base: 4, md: 8 }} py={{ base: 4, md: 6 }}>
                    {activeBinId && binStep ? (
                      <UserLiquidityChart
                        currency0={currency0}
                        currency1={currency1}
                        binStep={binStep}
                        userBalances={userBalances}
                        activeBinId={activeBinId}
                        isLoadingUserBalances={isLoadingUserLBPosition}
                        isPriceRatioInversed={inversePriceRatios}
                        rewardedBins={lbRewarderData?.rewardedBins?.map(
                          (bin) => bin.binId
                        )}
                      />
                    ) : null}
                  </Box>
                  <Divider />
                  {wrappedCurrency0 && wrappedCurrency1 ? (
                    <CurrencyAmountsPanel
                      title={t`Deposit Balance`}
                      tokens={[
                        wrappedCurrency0.address,
                        wrappedCurrency1.address
                      ]}
                      tokenAmounts={[pooledTokens0 ?? 0, pooledTokens1 ?? 0]}
                      tokenSymbols={[currency0?.symbol, currency1?.symbol]}
                    />
                  ) : null}
                </Box>

                {lbRewarderData &&
                lbRewarderData.hasRewarder &&
                currency0 &&
                currency1 ? (
                  <LbRewarderPanel
                    hookRewarderAddress={
                      lbRewarderData.rewarderData.hooksParameters.hooks
                    }
                    rewards={lbRewarderData.rewards}
                    userClaimableRewards={lbRewarderData.userClaimableRewards}
                    onClaimSuccess={debouncedRefetchLbRewarderData}
                    currency0={currency0}
                    currency1={currency1}
                    userBinIds={userBalances?.positions}
                    inversePriceRatios={inversePriceRatios}
                  />
                ) : null}

                {isConnected && wrappedCurrency0 && wrappedCurrency1 ? (
                  <Box
                    w="full"
                    bg="bgCard"
                    borderRadius="2xl"
                    boxShadow="element"
                  >
                    <Box py={{ base: 4, md: 6 }} px={{ base: 4, md: 8 }}>
                      <UserEarnedFees
                        currency0={currency0}
                        currency1={currency1}
                        tokenAddrs={[
                          wrappedCurrency0.address,
                          wrappedCurrency1.address
                        ]}
                        lbPairAddress={lbPairAddress}
                        isPriceRatioInversed={inversePriceRatios}
                      />
                    </Box>
                  </Box>
                ) : null}
              </VStack>
              {currency0 && currency1 && lbPairAddress && lbPoolVersion ? (
                <Box bg="bgCard" borderRadius="2xl" boxShadow="element">
                  <AddRemoveLiquidityPanelV2
                    poolLiquidityUsd={pool?.liquidityUsd}
                    lbPairAddress={lbPairAddress}
                    currency0={currency0}
                    currency1={currency1}
                    balance0={balance0}
                    balance1={balance1}
                    binStep={binStep}
                    activeBinId={activeBinId}
                    rewardedRange={lbRewarderData?.rewardedRange}
                    userBalances={userBalances}
                    onAddLiquiditySuccess={() => {
                      refetchBalance0()
                      refetchBalance1()
                      debouncedRefetchUserLBPosition()
                      debouncedRefetchLbRewarderData()
                    }}
                    onRemoveLiquiditySuccess={() => {
                      refetchBalance0()
                      refetchBalance1()
                      debouncedRefetchUserLBPosition()
                      debouncedRefetchLbRewarderData()
                    }}
                    onRemoveLiquidityConfigError={() => {
                      refetchReservesAndActiveBinId()
                      refetchUserLBPosition()
                    }}
                    inversePriceRatios={inversePriceRatios}
                    togglePriceRatiosClicked={togglePriceRatios}
                  />
                </Box>
              ) : null}
            </Grid>
          </TabPanel>
          <TabPanel
            pb={32}
            pt={8}
            maxW={{ '2xl': '1600px', base: '1400px' }}
            margin="0 auto"
          >
            {currency0 && currency1 ? (
              <PoolDetailV2AnalyticsPanel
                lbPairAddress={lbPairAddress}
                activeBinId={activeBinId}
                baseFee={baseFee}
                isSelected={selectedTab === Tab.ANALYTICS}
                currency0={currency0}
                currency1={currency1}
                reserveX={reserveX}
                reserveY={reserveY}
                isPriceRatioInversed={inversePriceRatios}
                liquidityDepthTokenX={pool?.liquidityDepthTokenX}
                liquidityDepthTokenY={pool?.liquidityDepthTokenY}
              />
            ) : null}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  )
}

export default PoolDetailV2
