import {
  Box,
  Center,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr
} from '@chakra-ui/react'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useGetMoeJourneyWinnings from 'hooks/moeJourney/useGetMoeJourneyWinnings'
import React from 'react'
import { formattedNum } from 'utils/format'

const MoeJourneyRewardStats = () => {
  const { data = [], isLoading } = useGetMoeJourneyWinnings()

  if (data.length === 0 && !isLoading) {
    return null
  }

  return (
    <Box
      py={4}
      px={1}
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      boxShadow="element"
    >
      {isLoading ? (
        <Center h="full">
          <Spinner size="sm" />
        </Center>
      ) : (
        <TableContainer overflow="hidden">
          <Table variant="simple" w="full">
            <Thead>
              <Tr>
                <Th>#</Th>
                <Th isNumeric>MJT</Th>
                <Th
                  isNumeric
                  textDecor="underline"
                  textDecorationStyle="dotted"
                >
                  <TouchFriendlyTooltip label="Your displayed chance of winning a prize is based on your winning success rate over 100,000 simulations of the reward draw for each epoch.">
                    Winning Chance
                  </TouchFriendlyTooltip>
                </Th>
                <Th isNumeric>Draw Participants</Th>
              </Tr>
            </Thead>
            <Tbody>
              {data.map((item, index) => (
                <Tr key={index}>
                  <Td>Epoch {item.epoch}</Td>
                  <Td isNumeric>{formattedNum(item.mjt)} MJT</Td>
                  <Td isNumeric>
                    {item.winningChance > 0
                      ? `${(item.winningChance * 100).toFixed(2)}%`
                      : '--'}
                  </Td>
                  <Td isNumeric>{item.qualifiedUsers}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      )}
    </Box>
  )
}

export default MoeJourneyRewardStats
