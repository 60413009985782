import { Center, Heading, HStack, Image, Text, VStack } from '@chakra-ui/react'
import MoeJourneyTicketsLogo from 'assets/moeJourney/moe-journey-ticket.png'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React from 'react'
import { WarningIcon } from 'theme/icons'
import { formattedNum } from 'utils/format'

interface MJTicketsViewProps {
  hasDiscordRole: boolean
  isLoyal: boolean
  userTotalTickets: number
}

const MJTicketsView = ({
  hasDiscordRole,
  isLoyal,
  userTotalTickets
}: MJTicketsViewProps) => {
  return (
    <VStack
      p={{ base: 4, md: 6 }}
      bg="bgInteractive"
      borderRadius={{ base: '10px', md: '20px' }}
      boxShadow="element"
    >
      <Image src={MoeJourneyTicketsLogo} boxSize="220px" />

      <VStack spacing={1}>
        <HStack spacing={3}>
          <Heading size="lg" textColor="white" fontWeight="bold">
            Moe Journey Tickets
          </Heading>
          <TouchFriendlyTooltip label="Users who unstake or swap on another dex during an epoch will have their MJT nulled for that epoch.">
            <Center>
              <WarningIcon boxSize="16px" fill="white" mt={1} />
            </Center>
          </TouchFriendlyTooltip>
        </HStack>
        <Text fontSize="sm" textColor="white" opacity={0.8} textAlign="center">
          Your total collected MJT in an epoch will determine your chance at
          winning a share of 7000 MNT in the biweekly rewards draw. Each two
          week epoch will have 100 winners. To enter the draw, make a swap on
          Moe during the epoch and remain loyal (do not unstake MOE and do not
          swap on other Mantle DEX&apos;s).
        </Text>
      </VStack>

      <Center h="full" flexDir="column">
        <Text
          as={isLoyal && hasDiscordRole ? 'span' : 's'}
          fontWeight="bold"
          textColor="white"
          flexShrink={0}
          fontSize="3xl"
        >
          {formattedNum(userTotalTickets, { places: 0 })} Tickets
        </Text>
        {!isLoyal ? (
          <HStack>
            <WarningIcon fill="yellow.200" />
            <Text textColor="yellow.200" fontSize="sm">
              Your loyal journeymen status is not currently active. To earn this
              status during each epoch, swap loyally on Merchant Moe, do not
              unstake $MOE, AND make at least 1 swap on Moe during each epoch.
              [Status may take up to 24 hours to update]
            </Text>
          </HStack>
        ) : !hasDiscordRole ? (
          <HStack>
            <WarningIcon fill="yellow.200" />
            <Text textColor="yellow.200" fontSize="sm">
              You must claim the MJT role on Guild to be entered into reward
              draws.
            </Text>
          </HStack>
        ) : null}
      </Center>
    </VStack>
  )
}

export default MJTicketsView
