import { BoxProps, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { WarningIcon } from 'theme/icons'

interface Props {
  message: string
  boxProps?: BoxProps
}

const WarningOutlined = ({ boxProps, message }: Props) => {
  return (
    <Flex
      py={2}
      px={4}
      border="1px solid"
      borderColor="yellowBar"
      borderRadius="xl"
      gap={4}
      align="center"
      {...boxProps}
    >
      <WarningIcon boxSize={5} />
      <Text fontSize="sm">{message}</Text>
    </Flex>
  )
}

export default WarningOutlined
