import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Button,
  Divider,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import useChainId from 'hooks/useChainId'
import PoolTableNameColumn from 'pages/Pool/PoolTableNameColumn'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { Vault } from 'types/vault'
import { formattedNum } from 'utils/format'
import { getPoolDetailV2Url } from 'utils/poolUrl'

import VaultInfoStats from './VaultInfoStats'

interface VaultInfoProps {
  vault: Vault
}

const VaultInfo = ({ vault }: VaultInfoProps) => {
  const navigate = useNavigate()
  const chainId = useChainId()

  const navigateToPoolDetail = useCallback(() => {
    const url = getPoolDetailV2Url(
      vault.lbPair.version,
      vault.lbPair.binStep,
      chainId,
      vault.tokenX.address,
      vault.tokenY.address
    )
    navigate(url)
  }, [navigate, vault, chainId])

  return (
    <VStack
      bg="bgCard"
      align="flex-start"
      borderRadius="2xl"
      border="1px"
      borderColor="border"
      p={{ base: 4, md: 8 }}
      spacing={{ base: 4, md: 6 }}
    >
      <Heading size="md">
        <Trans>Vault Information</Trans>
      </Heading>
      <Text whiteSpace="pre-line" fontSize="sm">
        <Trans>
          Your access to and use of Auto-Pool Vaults through this interface is
          entirely at your own risk and could lead to substantial losses,
          including but not limited to impermanent loss and smart contract risk.
          Before participating, you should ensure that you fully understand
          these risks.
        </Trans>
        {'\n\n'}
        <Trans>
          Divergence Risk: Assets may experience significant divergent price
          movements during volatile conditions. This means your deposit is
          exposed to the price movements of both tokens in the pair, and your
          exposure to each token can also change. Volatile conditions may lead
          to increased impermanent/divergence loss.
        </Trans>
        {'\n\n'}
        <Trans>Important Notes:</Trans>
        {'\n'}
        <Trans>
          - Your capital is at risk and you may receive back less than you
          originally deposited.
        </Trans>
        {'\n'}
        <Trans>
          - All fees are auto-compounded and shared by all Auto Pool
          participants.
        </Trans>
        {'\n'}
        <Trans>
          - The automation fee is an annualized percentage rate applied to total
          assets during each rebalance.
        </Trans>
        {'\n'}
        <Trans>
          - You may deposit assets in any ratio, but you may withdraw only based
          on the ratio of the assets in the Auto Pool. The ratio of the assets
          may change as the Auto Pool performs its automated rebalancing
          strategies.
        </Trans>
        {'\n'}
        <Trans>
          - All withdrawals are queued until the next rebalance (at most 1
          hour).
        </Trans>
      </Text>
      <Divider />
      <SimpleGrid w="full" columns={{ base: 2, sm: 4 }} gap={4}>
        <VaultInfoStats
          title={t`Liquidity`}
          value={formattedNum(vault.totalValueLockedUSD, { usd: true })}
        />
        <VaultInfoStats
          title={t`Pct Change (24H)`}
          value={`${vault.apt1dPriceChange >= 0 ? '+' : ''}${formattedNum(
            vault.apt1dPriceChange,
            { places: 2 }
          )}%`}
          valueTextColor={vault.apt1dPriceChange >= 0 ? 'green.400' : 'red.500'}
        />
        <VaultInfoStats
          title={t`Fees (24H)`}
          value={formattedNum(vault.feesUsd, { usd: true })}
        />
        <VaultInfoStats
          title={t`Automation Fee`}
          value={`${vault.managementFeePct}%`}
          tooltipLabel={t`The automation fee is applied to each rebalance taken from the total assets within the Auto-Pool, which equates to an annual rate of ${vault.managementFeePct}%.`}
        />
      </SimpleGrid>
      <Divider />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        w="full"
        justify="space-between"
        gap={4}
      >
        <PoolTableNameColumn
          fees={`${vault.lbPair.baseFeePct}%`}
          token0={vault.tokenX}
          token1={vault.tokenY}
          points={vault.points}
        />
        <Button
          variant="outline-bold"
          rightIcon={<ArrowForwardIcon />}
          onClick={navigateToPoolDetail}
        >
          <Trans>Go to Pool Page</Trans>
        </Button>
      </Flex>
    </VStack>
  )
}

export default VaultInfo
