import { VeMoeAbi } from 'constants/abis/VeMoe'
import { VE_MOE_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { formatUnits } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseVeMoeUserBalanceProps {
  moeDepositedAmount?: bigint
}

const useVeMoeUserBalance = ({
  moeDepositedAmount
}: UseVeMoeUserBalanceProps = {}) => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  return useReadContracts({
    contracts: [
      {
        abi: VeMoeAbi,
        address: VE_MOE_ADDRESS[chainId],
        args: account ? [account] : undefined,
        chainId,
        functionName: 'balanceOf'
      },
      {
        abi: VeMoeAbi,
        address: VE_MOE_ADDRESS[chainId],
        chainId,
        functionName: 'getMaxVeMoePerMoe'
      },
      {
        abi: VeMoeAbi,
        address: VE_MOE_ADDRESS[chainId],
        chainId,
        functionName: 'getVeMoePerSecondPerMoe'
      },
      {
        abi: VeMoeAbi,
        address: VE_MOE_ADDRESS[chainId],
        args: account ? [account] : undefined,
        chainId,
        functionName: 'getTotalVotesOf'
      },
      {
        abi: VeMoeAbi,
        address: VE_MOE_ADDRESS[chainId],
        chainId,
        functionName: 'getTotalVotes'
      }
    ],
    query: {
      select: (data) => {
        const balance = data[0].result

        const maxVeMoePerMoe = data[1].result
        const maxTotalVeMoe =
          moeDepositedAmount !== undefined && maxVeMoePerMoe !== undefined
            ? (maxVeMoePerMoe * moeDepositedAmount) / BigInt(10) ** BigInt(18)
            : undefined

        const veMoePerSecondPerMoe = data[2].result
        const veMoePerDay =
          balance !== undefined && veMoePerSecondPerMoe !== undefined
            ? veMoePerSecondPerMoe * BigInt(60 * 60 * 24) * balance
            : undefined

        const userTotalVotes = data[3].result
        const userAvailableVeMoeBalance =
          balance !== undefined && userTotalVotes !== undefined
            ? balance - userTotalVotes
            : undefined

        const platformTotalVotes = data[4].result

        return {
          balance:
            balance !== undefined
              ? {
                  formatted: formatUnits(balance, 18),
                  value: balance
                }
              : undefined,
          maxTotalVeMoe:
            maxTotalVeMoe !== undefined
              ? {
                  formatted: formatUnits(maxTotalVeMoe, 18),
                  value: maxTotalVeMoe
                }
              : undefined,
          maxVeMoePerMoe:
            maxVeMoePerMoe !== undefined
              ? {
                  formatted: formatUnits(maxVeMoePerMoe, 18),
                  value: maxVeMoePerMoe
                }
              : undefined,
          platformTotalVotes:
            platformTotalVotes !== undefined
              ? {
                  formatted: formatUnits(platformTotalVotes, 18),
                  value: platformTotalVotes
                }
              : undefined,
          userAvailableVeMoeBalance:
            userAvailableVeMoeBalance !== undefined
              ? {
                  formatted: formatUnits(userAvailableVeMoeBalance, 18),
                  value: userAvailableVeMoeBalance
                }
              : undefined,
          veMoePerDay:
            veMoePerDay !== undefined
              ? {
                  formatted: formatUnits(veMoePerDay, 34),
                  value: veMoePerDay
                }
              : undefined,
          veMoePerSecondPerMoe:
            veMoePerSecondPerMoe !== undefined
              ? {
                  formatted: formatUnits(veMoePerSecondPerMoe, 18),
                  value: veMoePerSecondPerMoe
                }
              : undefined
        }
      }
    }
  })
}

export default useVeMoeUserBalance
