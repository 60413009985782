import { VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import useStakeToken from 'hooks/stake/useStakeToken'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React from 'react'
import { Hex } from 'viem'

interface StakeTokenPanelProps {
  stakingContractAddress: Hex
  token: Token
  bottomStackViewTopElement?: JSX.Element
  isPanelEnabled?: boolean
  onStakeSuccess?: () => void
}

const StakeTokenPanel = ({
  bottomStackViewTopElement,
  isPanelEnabled = true,
  onStakeSuccess,
  stakingContractAddress,
  token
}: StakeTokenPanelProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: token
  })

  const { data: tokenBalance, refetch: refetchMoeBalance } = useTokenBalance({
    token: token.address
  })
  const debouncedRefetchTokenBalance = debounce(() => refetchMoeBalance(), 2000)

  const isExceedingBalance = tokenBalance
    ? Number(amount) > Number(tokenBalance.formatted)
    : false

  const isAmountInvalid = isExceedingBalance || Number(amount) === 0

  const {
    approvalType,
    approve,
    isApproved,
    isApproving,
    isLoadingAllowance,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: amountBN,
    spender: stakingContractAddress,
    token: token.address,
    tokenSymbol: token.symbol
  })

  const isStakeDisabled = isAmountInvalid || !isApproved || !isPanelEnabled

  const { isLoading: isStaking, stake } = useStakeToken({
    amount: amountBN,
    enabled: !isStakeDisabled,
    onSuccess: () => {
      setAmount('')
      debouncedRefetchTokenBalance()
      onStakeSuccess?.()
    },
    stakingContractAddress,
    token
  })

  return (
    <VStack spacing={{ base: 4, md: 10 }}>
      <CurrencyInput
        data-cy="stake-token-input"
        heading={t`Amount to stake:`}
        headingProps={{ fontSize: 'sm' }}
        currency={token}
        currencyAddress={token.address}
        value={amount}
        onValueChange={setAmount}
        h="60px"
        balance={tokenBalance?.formatted}
        error={isExceedingBalance ? t`Not enough ${token.symbol}` : undefined}
      />

      <VStack w="full" spacing={4} align="flex-start">
        {bottomStackViewTopElement ? bottomStackViewTopElement : null}

        {!isApproved &&
        !isAmountInvalid &&
        isPanelEnabled &&
        !isLoadingAllowance &&
        !!approve ? (
          <ApproveTokenButton
            data-cy="stake-token-approve-button"
            amount={amount}
            currencySymbol={token.symbol}
            approvalType={approvalType}
            onApprovalTypeSelect={setApprovalType}
            isLoading={isApproving}
            onClick={approve}
          >
            <Trans>Approve {token.symbol}</Trans>
          </ApproveTokenButton>
        ) : null}

        <Web3Button
          data-cy="stake-token-button"
          size="xl"
          colorScheme="accent"
          variant="primary"
          w="full"
          isDisabled={isStakeDisabled || !stake}
          isLoading={isStaking}
          loadingText={t`Staking ${token.symbol}`}
          onClick={stake}
        >
          <Trans>Stake {token.symbol}</Trans>
        </Web3Button>
      </VStack>
    </VStack>
  )
}

export default StakeTokenPanel
