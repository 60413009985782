import { Flex, Text } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import CurrencyAmountsPanel from 'components/CurrencyAmountsPanel'
import Web3Button from 'components/Web3Button'
import React from 'react'
import { FormattedReward } from 'types/rewards'

interface ClaimRewardsProps {
  claimableRewards: FormattedReward[]
  handleClaimRewards: (() => void) | undefined
  isClaiming: boolean
}

const ClaimRewards = ({
  claimableRewards,
  handleClaimRewards,
  isClaiming
}: ClaimRewardsProps) => {
  return (
    <CurrencyAmountsPanel
      title={t`Claimable Rewards`}
      tokens={claimableRewards.map((reward) => reward.tokenAddress)}
      tokenAmounts={claimableRewards.map((reward) => reward.tokenAmount)}
      tokenSymbols={claimableRewards.map((reward) => reward.token?.symbol)}
      bottomContent={
        claimableRewards.length === 0 ? (
          <Flex
            flexDir="column"
            justifyContent="center"
            alignItems="center"
            h="160px"
          >
            <Text fontSize="sm" color="textSecondary" textAlign="center">
              You don&apos;t have any claimable rewards yet
            </Text>
          </Flex>
        ) : (
          <Web3Button
            variant="primary"
            colorScheme="accent"
            size="xl"
            w="full"
            mt={4}
            isDisabled={!handleClaimRewards}
            isLoading={isClaiming}
            loadingText="Claiming rewards"
            onClick={() => {
              handleClaimRewards?.()
            }}
          >
            Claim Rewards
          </Web3Button>
        )
      }
    />
  )
}

export default ClaimRewards
