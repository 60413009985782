import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

import { withFallback } from './chakra'

const baseStyle = defineStyle({
  borderRadius: { base: 'md', md: 'xl' }
})

const lg = defineStyle({
  borderRadius: { base: 'md', md: 'xl' },
  fontSize: 'md',
  fontWeight: 'bold',
  h: { base: '40px', md: '48px' },
  px: 4
})

const xl = defineStyle({
  borderRadius: { base: 'xl', md: '2xl' },
  fontSize: 'md',
  fontWeight: 'bold',
  h: { base: '48px', md: '54px' }
})

const primary = defineStyle(({ colorScheme: c }) => ({
  _active: {
    background: `${c}.600`
  },
  _dark: {
    borderTopColor:
      c === 'accent'
        ? withFallback('color(display-p3 0.851 0.4235 0.5765)', '#fcF5895')
        : `${c}.400`,
    boxShadow:
      c === 'accent'
        ? withFallback(
            '0px 20px 20px -15px color(display-p3 0.4958 0.124 0.257 / 0.50)',
            '0px 20px 20px -15px rgba(138, 18, 66, 0.50)'
          )
        : '0px 4px 8px rgba(0, 0, 0, 0.24)'
  },
  _disabled: {
    borderBottom: 0
  },
  _hover: {
    _disabled: {
      background: `${c}.500`
    },
    background: `${c}.600`,
    borderBottomColor: `${c}.700`
  },
  background: c === 'accent' ? 'bgInteractive' : `${c}.500`,
  border: '0px',
  borderRadius: '10px',
  borderTop: '3px solid',
  borderTopColor: `${c}.400`,
  boxShadow:
    c === 'accent'
      ? withFallback(
          '0px 20px 20px -15px color(display-p3 0.9294 0.502 0.6549 / 0.50)',
          ' 0px 20px 20px -15px rgba(254, 120, 168, 0.50)'
        )
      : '0px 20px 20px -15px rgba(0, 0, 0, 0.24)',
  color: 'white',
  fontSize: '16px'
}))

const outline = defineStyle({
  _hover: {
    bg: 'hover'
  },
  bg: 'transparent',
  border: '1px solid',
  borderColor: 'border'
})

const outlineBold = defineStyle({
  _dark: {
    _hover: { bg: 'bgSecondary' },
    bg: 'bgPrimary',
    borderColor: 'bgInteractive'
  },
  _hover: { bg: 'accent.100' },
  border: '2px solid',
  borderColor: 'bgInteractive',
  borderRadius: '10px'
})

export const buttonTheme = defineStyleConfig({
  baseStyle,
  sizes: { lg, xl },
  variants: { outline, 'outline-bold': outlineBold, primary }
})
