import { MerchantMoeChainId } from 'constants/chains'

export const STABLECOINS: {
  [chainId in MerchantMoeChainId]: string[]
} = {
  [MerchantMoeChainId.MANTLE]: [
    '0x09Bc4E0D864854c6aFB6eB9A9cdF58aC190D0dF9',
    '0x201EBa5CC46D216Ce6DC03F6a759e8E766e956aE',
    '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34',
    '0x5bE26527e817998A7206475496fDE1E68957c5A6',
    '0xab575258d37EaA5C8956EfABe71F4eE8F6397cF3'
  ],
  [MerchantMoeChainId.FUJI]: []
}

export const MAJOR_TOKENS: {
  [chainId in MerchantMoeChainId]: string[]
} = {
  [MerchantMoeChainId.MANTLE]: [
    // btc
    '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2',
    '0xC96dE26018A54D51c097160568752c4E3BD6C364',
    // eth
    '0xdEAddEaDdeadDEadDEADDEAddEADDEAddead1111',
    '0xcDA86A272531e8640cD7F1a92c01839911B90bb0',
    // mnt
    '0x78c1b0C915c4FAA5FffA6CAbf0219DA63d7f4cb8'
  ],
  [MerchantMoeChainId.FUJI]: []
}
