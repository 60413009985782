import { ChevronDownIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Text,
  useDisclosure,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import BackButton from 'components/BackButton'
import CurrencyLogo from 'components/CurrencyLogo'
import CurrencyPickerModal from 'components/CurrencyPickerModal'
import PageContainer from 'components/PageContainer'
import PairNameHeader from 'components/PairNameHeader'
import Web3Button from 'components/Web3Button'
import useGetLensFarm from 'hooks/farms/useGetLensFarm'
import useCreateRewarder, {
  RewarderType
} from 'hooks/rewarder/useCreateRewarder'
import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const CreateVoteRewards = () => {
  const { pid } = useParams()
  const navigate = useNavigate()

  // fetch farm
  const { data: farm, isLoading: isLoadingFarm } = useGetLensFarm({
    pid: pid ? BigInt(pid) : undefined
  })

  // pick reward token
  const [rewardToken, setRewardToken] = useState<Currency | undefined>()
  const {
    isOpen: isCurrencyPickerOpen,
    onClose: onCurrencyPickerClose,
    onOpen: onCurrencyPickerOpen
  } = useDisclosure()

  // create rewarder
  const { createRewarder, isLoading: isCreatingRewarder } = useCreateRewarder({
    currency: rewardToken,
    farmPid: farm?.pid,
    onSuccess: (rewarderAddress) => {
      if (!rewarderAddress) return
      navigate(`/vote/${pid}/rewarder/${rewarderAddress}`)
    },
    rewarderType: RewarderType.VeMoeRewarder
  })

  return (
    <PageContainer mb="100px">
      {isLoadingFarm ? (
        <Center minH="60vh">
          <Spinner />
        </Center>
      ) : farm ? (
        <>
          <CurrencyPickerModal
            isOpen={isCurrencyPickerOpen}
            onClose={onCurrencyPickerClose}
            onCurrencyChange={setRewardToken}
            canSelectUnverifiedToken={false}
          />

          <BackButton
            title={t`Back to vote detail`}
            ml={-3}
            aria-label="back to vote detail"
            onClick={() => navigate(`/vote/${pid}`)}
          />

          <PairNameHeader
            token0={{
              address: farm.reserves.token0.token,
              symbol: farm.reserves.token0.symbol
            }}
            token1={{
              address: farm.reserves.token1.token,
              symbol: farm.reserves.token1.symbol
            }}
          />

          <Center mt="50px">
            <Flex
              bg="bgCard"
              border="1px solid"
              borderColor="border"
              w="full"
              flexDir="column"
              gap={8}
              p={{ base: 4, md: 8 }}
              borderRadius="2xl"
              maxW="600px"
            >
              <VStack align="flex-start">
                <Heading size="md">
                  <Trans>Add Voting Rewards</Trans>
                </Heading>
              </VStack>

              <VStack w="full" align="flex-start">
                <Text fontSize="sm" textColor="textSecondary">
                  <Trans>
                    Select which token you would like to give as reward:
                  </Trans>
                </Text>
                <Button
                  w="full"
                  bg="bgSecondary"
                  size="lg"
                  borderRadius={12}
                  leftIcon={
                    rewardToken ? (
                      <CurrencyLogo
                        address={
                          rewardToken.isToken ? rewardToken.address : undefined
                        }
                        symbol={rewardToken.symbol}
                        boxSize={5}
                      />
                    ) : undefined
                  }
                  rightIcon={<ChevronDownIcon boxSize={6} />}
                  onClick={onCurrencyPickerOpen}
                >
                  <Text>{rewardToken?.symbol ?? t`Select token`}</Text>
                  <Spacer />
                </Button>
              </VStack>

              <Web3Button
                variant="primary"
                colorScheme="accent"
                size="xl"
                w="full"
                isDisabled={!rewardToken || !createRewarder}
                isLoading={isCreatingRewarder}
                onClick={createRewarder}
                loadingText={t`Creating rewarder`}
              >
                <Trans>Create Rewarder</Trans>
              </Web3Button>
            </Flex>
          </Center>
        </>
      ) : null}
    </PageContainer>
  )
}

export default CreateVoteRewards
