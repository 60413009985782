import { Token } from '@traderjoe-xyz/sdk-core'
import { EthenaLPStakingAbi } from 'constants/abis/EthenaLPStaking'
import { ETHENA_LP_STAKING_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { formatUnits, getAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface UseEthenaStakeParametersByTokenProps {
  lpToken: Token
}

const useEthenaStakeParametersByToken = ({
  lpToken
}: UseEthenaStakeParametersByTokenProps) => {
  const chainId = useChainId()

  return useReadContract({
    abi: EthenaLPStakingAbi,
    address: ETHENA_LP_STAKING_ADDRESS[chainId],
    args: [getAddress(lpToken.address)],
    chainId,
    functionName: 'stakeParametersByToken',
    query: {
      select: (data) => {
        return {
          cooldown: data[4],
          epoch: data[0],
          stakeLimit: {
            formatted: formatUnits(data[1], lpToken.decimals),
            value: data[1]
          },
          totalCoolingDown: {
            formatted: formatUnits(data[3], lpToken.decimals),
            value: data[3]
          },
          totalStaked: {
            formatted: formatUnits(data[2], lpToken.decimals),
            value: data[2]
          }
        }
      }
    }
  })
}

export default useEthenaStakeParametersByToken
