import { InputRightElement, Kbd, useOutsideClick } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import SearchPanel from 'components/SearchPanel'
import usePairsSearch from 'hooks/usePairsSearch'
import useTokensSearch from 'hooks/useTokensSearch'
import React, { useState } from 'react'

import SearchResults from './SearchResults'
import useSearchPanelKeyboardNavigation from './useSearchPanelKeyboardNavigation'

const HeaderSearchPanel = () => {
  const [query, setQuery] = useState('')
  const [focused, setFocused] = useState(false)
  const { data: pairs = [], isLoading: isLoadingPools } = usePairsSearch({
    enabled: focused,
    orderBy: 'volume',
    query
  })
  const { isLoading: isLoadingTokens, tokens } = useTokensSearch({
    enabled: focused,
    query
  })

  const {
    activeIndex: activeResultIndex,
    closePanel,
    inputRef,
    resultsContainerRef,
    setActiveIndex
  } = useSearchPanelKeyboardNavigation({
    focused,
    pairs,
    setFocused,
    tokens
  })

  const ref = React.useRef<HTMLDivElement | null>(null)
  useOutsideClick({ handler: closePanel, ref: ref })

  return (
    <SearchPanel
      resultsContainerRef={resultsContainerRef}
      searchPanelRef={ref}
      inputRef={inputRef}
      inputRightElement={
        !focused ? (
          <InputRightElement>
            <Kbd>/</Kbd>
          </InputRightElement>
        ) : undefined
      }
      placeholder={t`Search by symbol, name or address`}
      query={query}
      setQuery={setQuery}
      queryMinLength={2}
      focused={focused}
      setFocused={setFocused}
      searchResults={
        <SearchResults
          onMouseEnter={(index) => {
            setActiveIndex(index)
          }}
          activeResultIndex={activeResultIndex}
          pairs={pairs}
          tokens={tokens}
          isLoadingPools={isLoadingPools}
          isLoadingTokens={isLoadingTokens}
          onRowClick={() => {
            setQuery('')
            setFocused(false)
          }}
        />
      }
    />
  )
}

export default HeaderSearchPanel
