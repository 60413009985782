import { Box, Skeleton, Text, useToken } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import AnalyticsChart from 'components/AnalyticsChart'
import React from 'react'
import { formattedNum } from 'utils/format'

interface StakeTVLPanelProps {
  data: {
    amountStaked: number
    timestamp: number
  }[]
  isLoading: boolean
}

const StakeTVLPanel = ({ data, isLoading }: StakeTVLPanelProps) => {
  const [graphPurple, graphPurpleOutline] = useToken('colors', [
    'graphPurpleDark',
    'graphPurpleOutline'
  ])

  const fmtTVL =
    data.length > 0 ? formattedNum(data[data.length - 1].amountStaked) : '0'

  return (
    <Box
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      boxShadow="element"
      w="full"
    >
      <AnalyticsChart
        id="moe-staking-tvl"
        header={
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold" fontSize="2xl" minW="100px">
              {`${fmtTVL} MOE`}
            </Text>
          </Skeleton>
        }
        hideTab={true}
        tagline={t`TVL (Total Value Locked)`}
        tooltipTitle={t`TVL`}
        data={data.map((d) => ({
          date: d.timestamp,
          value: d.amountStaked
        }))}
        valueSymbol="MOE"
        usdValue={false}
        fill={graphPurple}
        stroke={graphPurpleOutline}
        h={{ base: '80px', md: '150px' }}
        headerFlexProps={{ mb: 0, pt: 4, px: 4 }}
      />
    </Box>
  )
}

export default StakeTVLPanel
