import { getWagmiConnectorV2 } from '@binance/w3w-wagmi-connector-v2'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  coinbaseWallet,
  injected,
  safe,
  walletConnect
} from '@wagmi/connectors'
import { chain } from 'constants/chains'
import { isRunningE2ETests, wagmiTestConfig } from 'utils/e2e'
import { http } from 'viem'
import { createConfig } from 'wagmi'

const walletConnectProjectId =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const isSafeInstalled =
  !(typeof window === 'undefined') && window?.parent !== window

const binanceWallet = getWagmiConnectorV2()

export const wagmiConfig = isRunningE2ETests
  ? wagmiTestConfig
  : createConfig({
      batch: { multicall: true },
      chains: [chain],
      connectors: isSafeInstalled
        ? [
            injected(),
            walletConnect({ projectId: walletConnectProjectId }),
            coinbaseWallet({ appName: 'Merchant Moe' }),
            binanceWallet(),
            safe()
          ]
        : [
            injected(),
            walletConnect({ projectId: walletConnectProjectId }),
            coinbaseWallet({ appName: 'Merchant Moe' }),
            binanceWallet()
          ],
      transports: {
        [ChainId.MANTLE]: http(),
        [ChainId.FUJI]: http()
      }
    })
