import { Heading, SimpleGrid, Skeleton, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { MOE_TOKEN_ADDRESS } from 'constants/moe'
import { JOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import React from 'react'
import { formattedNum } from 'utils/format'

interface JoeStakingAnalyticsPanelProps {
  isLoadingStakingData: boolean
  moePerSecond?: string
  remainingRewardsAmount?: string
  totalJoeStaked?: string
}

const JoeStakingAnalyticsPanel = ({
  isLoadingStakingData,
  moePerSecond,
  remainingRewardsAmount,
  totalJoeStaked
}: JoeStakingAnalyticsPanelProps) => {
  const chainId = useChainId()

  // get MOE and JOE token addresses
  const moeAddress = MOE_TOKEN_ADDRESS[chainId]
  const joeAddress = JOE_TOKEN[chainId].address

  // get MOE and JOE token prices
  const { data: tokenPriceResults } = useGetTokensUsdPrice({
    tokenAddresses: [moeAddress, joeAddress]
  })
  const moePrice = tokenPriceResults?.[moeAddress.toLowerCase()]
  const joePrice = tokenPriceResults?.[joeAddress.toLowerCase()]

  // calculate rewards vested percent
  const amountAtStart = 25_000_000
  const rewardsVestedPercent = remainingRewardsAmount
    ? ((amountAtStart - Number(remainingRewardsAmount)) * 100) / amountAtStart
    : 0

  // calculate APR
  const moePerDays = moePerSecond ? Number(moePerSecond) * 60 * 60 * 24 : 0
  const apr =
    moePrice && joePrice && totalJoeStaked
      ? ((moePerDays * 365 * moePrice) / (Number(totalJoeStaked) * joePrice)) *
        100
      : 0

  return (
    <VStack
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
      spacing={{ base: 4, md: 8 }}
      align="flex-start"
      w="full"
    >
      <Heading size="md">Analytics</Heading>

      <SimpleGrid columns={2} w="full" rowGap={8}>
        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Total JOE Staked</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">
              {formattedNum(totalJoeStaked || '0')} JOE
            </Heading>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>MOE emission</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">{formattedNum(moePerDays)} MOE / day</Heading>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>APR</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">
              {apr ? `${formattedNum(apr, { places: 2 })}%` : '--'}
            </Heading>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Rewards vested</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">
              {formattedNum(rewardsVestedPercent, { places: 2 })}%
            </Heading>
          </Skeleton>
        </VStack>
      </SimpleGrid>
    </VStack>
  )
}

export default JoeStakingAnalyticsPanel
