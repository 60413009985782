import { AddIcon } from '@chakra-ui/icons'
import {
  Button,
  Hide,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Show
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import React from 'react'
import { Link } from 'react-router-dom'

const CreateNewPoolMenu = () => {
  const versions = [
    {
      title: t`Manual`,
      version: 'v2'
    },
    {
      title: t`Classic AMM`,
      version: 'v1'
    }
  ]

  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="outline-bold"
        flexShrink={0}
        leftIcon={<AddIcon boxSize={3} />}
      >
        <Hide below="md">
          <Trans>Create New Pool</Trans>
        </Hide>
        <Show below="md">
          <Trans>Create Pool</Trans>
        </Show>
      </MenuButton>
      <MenuList>
        {versions.map(({ title, version }) => (
          <MenuItem
            key={version}
            as={Link}
            h="48px"
            to={`/pool/${version}/create`}
          >
            {title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  )
}

export default CreateNewPoolMenu
