export const VeMoeAbi = [
  {
    inputs: [
      {
        internalType: 'contract IMoeStaking',
        name: 'moeStaking',
        type: 'address'
      },
      {
        internalType: 'contract IMasterChef',
        name: 'masterChef',
        type: 'address'
      },
      {
        internalType: 'contract IRewarderFactory',
        name: 'rewarderFactory',
        type: 'address'
      },
      { internalType: 'uint256', name: 'maxVeMoePerMoe', type: 'uint256' }
    ],
    stateMutability: 'nonpayable',
    type: 'constructor'
  },
  {
    inputs: [],
    name: 'acceptOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'balanceOf',
    outputs: [{ internalType: 'uint256', name: 'veMoe', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256[]', name: 'pids', type: 'uint256[]' }],
    name: 'claim',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256[]', name: 'pids', type: 'uint256[]' }],
    name: 'emergencyUnsetBribes',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getAlpha',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: 'pid', type: 'uint256' }
    ],
    name: 'getBribesOf',
    outputs: [
      { internalType: 'contract IVeMoeRewarder', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'contract IVeMoeRewarder',
        name: 'bribe',
        type: 'address'
      },
      { internalType: 'uint256', name: 'pid', type: 'uint256' }
    ],
    name: 'getBribesTotalVotes',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getMasterChef',
    outputs: [
      { internalType: 'contract IMasterChef', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getMaxVeMoePerMoe',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getMoeStaking',
    outputs: [
      { internalType: 'contract IMoeStaking', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256[]', name: 'pids', type: 'uint256[]' }
    ],
    name: 'getPendingRewards',
    outputs: [
      {
        internalType: 'contract IERC20[]',
        name: 'tokens',
        type: 'address[]'
      },
      { internalType: 'uint256[]', name: 'pendingRewards', type: 'uint256[]' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getRewarderFactory',
    outputs: [
      { internalType: 'contract IRewarderFactory', name: '', type: 'address' }
    ],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTopPidsTotalVotes',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTopPoolIds',
    outputs: [{ internalType: 'uint256[]', name: '', type: 'uint256[]' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTotalVotes',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'getTotalVotesOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getTotalWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'getVeMoePerSecondPerMoe',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
    name: 'getVotes',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: 'pid', type: 'uint256' }
    ],
    name: 'getVotesOf',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
    name: 'getWeight',
    outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'initialOwner', type: 'address' }
    ],
    name: 'initialize',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
    name: 'isInTopPoolIds',
    outputs: [{ internalType: 'bool', name: '', type: 'bool' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'address', name: 'account', type: 'address' },
      { internalType: 'uint256', name: 'oldBalance', type: 'uint256' },
      { internalType: 'uint256', name: 'newBalance', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' },
      { internalType: 'uint256', name: '', type: 'uint256' }
    ],
    name: 'onModify',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'pendingOwner',
    outputs: [{ internalType: 'address', name: '', type: 'address' }],
    stateMutability: 'view',
    type: 'function'
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'pure',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256', name: 'alpha', type: 'uint256' }],
    name: 'setAlpha',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'pids', type: 'uint256[]' },
      {
        internalType: 'contract IVeMoeRewarder[]',
        name: 'bribes',
        type: 'address[]'
      }
    ],
    name: 'setBribes',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'uint256[]', name: 'pids', type: 'uint256[]' }],
    name: 'setTopPoolIds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'veMoePerSecondPerMoe',
        type: 'uint256'
      }
    ],
    name: 'setVeMoePerSecondPerMoe',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [{ internalType: 'address', name: 'newOwner', type: 'address' }],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    inputs: [
      { internalType: 'uint256[]', name: 'pids', type: 'uint256[]' },
      { internalType: 'int256[]', name: 'deltaAmounts', type: 'int256[]' }
    ],
    name: 'vote',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'alpha',
        type: 'uint256'
      }
    ],
    name: 'AlphaSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'pids',
        type: 'uint256[]'
      },
      {
        indexed: false,
        internalType: 'contract IVeMoeRewarder[]',
        name: 'bribes',
        type: 'address[]'
      }
    ],
    name: 'BribesSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'int256',
        name: 'deltaVeMoe',
        type: 'int256'
      }
    ],
    name: 'Claim',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint64',
        name: 'version',
        type: 'uint64'
      }
    ],
    name: 'Initialized',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferStarted',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address'
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address'
      }
    ],
    name: 'OwnershipTransferred',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'topPoolIds',
        type: 'uint256[]'
      }
    ],
    name: 'TopPoolIdsSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'uint256',
        name: 'veMoePerSecondPerMoe',
        type: 'uint256'
      }
    ],
    name: 'VeMoePerSecondPerMoeSet',
    type: 'event'
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: false,
        internalType: 'address',
        name: 'account',
        type: 'address'
      },
      {
        indexed: false,
        internalType: 'uint256[]',
        name: 'pids',
        type: 'uint256[]'
      },
      {
        indexed: false,
        internalType: 'int256[]',
        name: 'deltaVeAmounts',
        type: 'int256[]'
      }
    ],
    name: 'Vote',
    type: 'event'
  },
  { inputs: [], name: 'InvalidInitialization', type: 'error' },
  { inputs: [], name: 'Math__UnderOverflow', type: 'error' },
  { inputs: [], name: 'NotInitializing', type: 'error' },
  {
    inputs: [{ internalType: 'address', name: 'owner', type: 'address' }],
    name: 'OwnableInvalidOwner',
    type: 'error'
  },
  {
    inputs: [{ internalType: 'address', name: 'account', type: 'address' }],
    name: 'OwnableUnauthorizedAccount',
    type: 'error'
  },
  { inputs: [], name: 'VeMoe__CannotRenounceOwnership', type: 'error' },
  { inputs: [], name: 'VeMoe__CannotUnstakeWithVotes', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
    name: 'VeMoe__DuplicatePoolId',
    type: 'error'
  },
  {
    inputs: [
      { internalType: 'uint256', name: 'totalVeMoe', type: 'uint256' },
      { internalType: 'uint256', name: 'requiredVeMoe', type: 'uint256' }
    ],
    name: 'VeMoe__InsufficientVeMoe',
    type: 'error'
  },
  { inputs: [], name: 'VeMoe__InvalidAlpha', type: 'error' },
  { inputs: [], name: 'VeMoe__InvalidBribeAddress', type: 'error' },
  { inputs: [], name: 'VeMoe__InvalidCaller', type: 'error' },
  { inputs: [], name: 'VeMoe__InvalidLength', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
    name: 'VeMoe__InvalidPid',
    type: 'error'
  },
  { inputs: [], name: 'VeMoe__InvalidWeight', type: 'error' },
  {
    inputs: [{ internalType: 'uint256', name: 'pid', type: 'uint256' }],
    name: 'VeMoe__NoBribeForPid',
    type: 'error'
  },
  { inputs: [], name: 'VeMoe__TooManyPoolIds', type: 'error' }
] as const
