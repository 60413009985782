import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

export const menuListStyle = {
  _dark: {
    bg: 'bgCard',
    boxShadow: 'joeDark'
  },
  bg: 'bgCard',
  border: 0,
  borderRadius: '10px',
  boxShadow: 'element'
}

const baseStyle = defineStyle({
  button: {
    bg: 'bgCard',
    boxShadow: 'element'
  },
  item: {
    _dark: {
      _hover: { bg: 'bgCard' }
    },
    _hover: { bg: 'bgPrimary' },
    bg: 'bgCard',
    borderRadius: '10px',
    px: 2
  },
  list: {
    ...menuListStyle,
    display: 'flex',
    flexDir: 'column',
    gap: 0.5,
    p: 2
  }
})

export const menuTheme = defineStyleConfig({
  baseStyle
})
