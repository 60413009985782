import {
  Center,
  Heading,
  SimpleGrid,
  Spinner,
  Text,
  VStack
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import BackButton from 'components/BackButton'
import PageContainer from 'components/PageContainer'
import { REWARDER_FACTORY_ADDRESS } from 'constants/moe'
import useGetLensFarm from 'hooks/farms/useGetLensFarm'
import useGetRewarderData from 'hooks/rewarder/useGetRewarderData'
import useChainId from 'hooks/useChainId'
import debounce from 'lodash.debounce'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getAddress, parseAbi } from 'viem'
import { useAccount, useReadContract } from 'wagmi'

import RewarderInfoPanel from './RewarderInfoPanel'
import SetRewarderParametersPanel from './SetRewarderParametersPanel'
import StopRewarderPanel from './StopRewarderPanel'
import TransferTokensPanel from './TransferTokensPanel'
import VoteRewardsSteps from './VoteRewardsSteps'

const EditVoteRewards = () => {
  const { pid, rewarderAddress } = useParams()
  const navigate = useNavigate()

  // fetch farm
  const { data: farm, isLoading: isLoadingFarm } = useGetLensFarm({
    pid: pid ? BigInt(pid) : undefined
  })

  // fetch rewarder data
  const {
    isLoading,
    isStopped,
    owner,
    refetch: refetchRewarderData,
    remainingRewardAmount,
    rewarderParameters,
    rewarderTokenBalance,
    rewardToken
  } = useGetRewarderData({ rewarderAddress })
  const debouncedRefetchRewarderData = debounce(refetchRewarderData, 2000)

  // check if connected user is owner of the rewarder
  const { address: account } = useAccount()
  const isOwner = owner?.toLowerCase() === account?.toLowerCase()

  if (isLoading) {
    return (
      <Center h="90vh">
        <Spinner />
      </Center>
    )
  }

  if (!farm || !rewardToken || !rewarderAddress) {
    return null
  }

  return (
    <PageContainer mb="100px">
      {isLoadingFarm ? (
        <Center minH="60vh">
          <Spinner />
        </Center>
      ) : farm ? (
        <>
          <BackButton
            title={t`Back to vote detail`}
            ml={-3}
            aria-label="back to vote detail"
            onClick={() => navigate(`/vote/${pid}`)}
          />

          <VStack mt={2} align="flex-start">
            <Heading size="lg">
              <Trans>Add Voting Rewards</Trans>
            </Heading>
            <Text fontSize="sm" textColor="textSecondary">
              <Trans>
                Add Voting Rewards to attract votes to your pool. Pools with
                enough liquidity and votes will open a MOE farm.
              </Trans>
            </Text>
          </VStack>

          <VoteRewardsSteps />

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            spacing={{ base: '50px', xl: '125px' }}
            mt={{ base: 8, md: '70px' }}
            alignItems="flex-start"
          >
            <VStack spacing={8}>
              <TransferTokensPanel
                rewarderAddress={rewarderAddress}
                rewardToken={rewardToken}
                onTransferSuccess={debouncedRefetchRewarderData}
                isOwner={isOwner}
                isStopped={isStopped}
                maxWithdrawableAmount={
                  remainingRewardAmount || rewarderTokenBalance?.value
                }
              />
              {!isStopped ? (
                <SetRewarderParametersPanel
                  rewardToken={rewardToken}
                  remainingAmountInRewarder={
                    remainingRewardAmount || rewarderTokenBalance?.value
                  }
                  rewarderAddress={rewarderAddress}
                  onSetParametersSuccess={debouncedRefetchRewarderData}
                  isOwner={isOwner}
                />
              ) : null}
              {!isStopped ? (
                <StopRewarderPanel
                  isOwner={isOwner}
                  rewarderAddress={rewarderAddress}
                />
              ) : null}
            </VStack>

            {rewardToken ? (
              <RewarderInfoPanel
                farm={farm}
                rewardToken={rewardToken}
                rewarderTokenBalance={rewarderTokenBalance?.formatted}
                rewarderParameters={rewarderParameters}
                owner={owner}
                isStopped={isStopped}
              />
            ) : null}
          </SimpleGrid>
        </>
      ) : null}
    </PageContainer>
  )
}

const EditVoteRewardsWithRedirect = () => {
  const chainId = useChainId()
  const { rewarderAddress } = useParams()

  // make sure rewarderAddress is a valid rewarder
  const { data: rewarderType = BigInt(0), isLoading } = useReadContract({
    abi: parseAbi([
      'function getRewarderType(address rewarder) external view returns (uint256)'
    ]),
    address: REWARDER_FACTORY_ADDRESS[chainId],
    args: rewarderAddress ? [getAddress(rewarderAddress)] : undefined,
    chainId,
    functionName: 'getRewarderType'
  })

  if (isLoading) {
    return (
      <Center minH="90vh">
        <Spinner />
      </Center>
    )
  }

  if (rewarderType !== BigInt(2)) {
    return (
      <Center minH="90vh">
        <Text textColor="textSecondary">Rewarder not found</Text>
      </Center>
    )
  }

  return <EditVoteRewards />
}

export default EditVoteRewardsWithRedirect
