import UnstakeTokenPanel from 'components/UnstakeTokenPanel'
import { JOE_STAKING } from 'constants/moe'
import { JOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import React from 'react'

interface UnstakeJoePanelProps {
  onUnstakeSuccess: () => void
  joeDepositedAmount?: string
}

const UnstakeJoePanel = ({
  joeDepositedAmount,
  onUnstakeSuccess
}: UnstakeJoePanelProps) => {
  const chainId = useChainId()
  const joeToken = JOE_TOKEN[chainId]

  return (
    <UnstakeTokenPanel
      token={joeToken}
      stakingContractAddress={JOE_STAKING[chainId]}
      depositedAmount={joeDepositedAmount}
      onUnstakeSuccess={onUnstakeSuccess}
    />
  )
}

export default UnstakeJoePanel
