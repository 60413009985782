import { t } from '@lingui/macro'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import { erc20Abi, getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

import useWaitForTransactionReceipt from './useWaitForTransactionReceipt'

interface UseTransferTokenProps {
  enabled: boolean
  recipient: string
  amount?: bigint
  onSuccess?: () => void
  token?: string
}

const useTransferToken = ({
  amount,
  enabled,
  onSuccess,
  recipient,
  token
}: UseTransferTokenProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: erc20Abi,
    address: token ? getAddress(token) : undefined,
    args: amount ? [getAddress(recipient), amount] : undefined,
    chainId,
    functionName: 'transfer',
    query: {
      enabled: enabled && walletChain?.id === chainId && !!token && !!amount,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Transferred tokens`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const transferTokens = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isWaitingForTransaction || isPending,
    isSuccess,
    transferTokens
  }
}

export default useTransferToken
