import { VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import CopyableError from 'components/CopyableError'
import CurrencyInput from 'components/CurrencyInput'
import MaxButton from 'components/MaxButton'
import WarningOutlined from 'components/WarningOutlined'
import Web3Button from 'components/Web3Button'
import useWithdrawTokensFromEthena from 'hooks/ethena/useWithdrawTokensFromEthena'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { BaseError } from 'wagmi'

interface WithdrawPanelProps {
  token: Token
  onWithdrawSuccess?: () => void
  userCooldownStartTimestamp?: number
  userCoolingDownAmount?: {
    formatted: string
    value: bigint
  }
}

const WithdrawPanel = ({
  onWithdrawSuccess,
  token,
  userCooldownStartTimestamp,
  userCoolingDownAmount
}: WithdrawPanelProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: token
  })

  const now = Math.floor(Date.now() / 1000)
  const cooldown = 21 * 24 * 60 * 60 // TODO: fetch from contract?

  const countdownTimestamp = userCooldownStartTimestamp
    ? cooldown + userCooldownStartTimestamp - now
    : undefined
  const countdown = countdownTimestamp
    ? {
        days: Math.floor(countdownTimestamp / (60 * 60 * 24)),
        hours: Math.floor((countdownTimestamp % (60 * 60 * 24)) / (60 * 60))
      }
    : undefined

  const canWithdraw = userCooldownStartTimestamp
    ? cooldown + userCooldownStartTimestamp <= now
    : false

  const isExceedingBalance = userCoolingDownAmount
    ? Number(userCoolingDownAmount.formatted) < Number(amount)
    : false

  const isWithdrawButtonDisabled =
    !userCoolingDownAmount || isExceedingBalance || !amountBN || !canWithdraw

  const {
    error: withdrawError,
    isLoading: isWithdrawing,
    withdraw
  } = useWithdrawTokensFromEthena({
    amount: amountBN,
    enabled: !isWithdrawButtonDisabled,
    onSuccess: () => {
      onWithdrawSuccess?.()
    },
    tokenAddress: token.address
  })

  return (
    <VStack align="flex-start" spacing={4}>
      <CurrencyInput
        currency={token}
        currencyAddress={token.address}
        value={amount}
        onValueChange={setAmount}
        balance={userCoolingDownAmount?.formatted}
        error={isExceedingBalance ? t`Not enough ${token?.symbol}` : undefined}
        rightElement={
          userCoolingDownAmount ? (
            <MaxButton
              borderRadius={12}
              balance={userCoolingDownAmount.formatted}
              onClick={() => setAmount(userCoolingDownAmount.formatted)}
            />
          ) : undefined
        }
      />

      {canWithdraw ? null : countdown ? (
        <WarningOutlined
          message={`Tokens will be available to withdraw in ${
            countdown.days > 0
              ? `${countdown.days} days and ${countdown.hours} hours`
              : `${countdown.hours} hours`
          }`}
          boxProps={{ h: '48px', w: 'full' }}
        />
      ) : (
        <WarningOutlined
          message="Tokens will be available to withdraw 21 days after unlocking."
          boxProps={{ h: '48px', w: 'full' }}
        />
      )}

      <Web3Button
        variant="primary"
        colorScheme="accent"
        size="xl"
        w="full"
        isDisabled={isWithdrawButtonDisabled || !withdraw}
        onClick={withdraw}
        isLoading={isWithdrawing}
        loadingText={t`Withdrawing APT`}
      >
        Withdraw APT
      </Web3Button>

      {withdrawError ? (
        <CopyableError
          error={withdrawError.message}
          summary={(withdrawError as BaseError).shortMessage}
        />
      ) : null}
    </VStack>
  )
}

export default WithdrawPanel
