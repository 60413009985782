import {
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React, { useEffect, useRef, useState } from 'react'

interface ScrollLockModalProps {
  htmlContent: string | TrustedHTML
  isOpen: boolean
  onClickAccept: () => void
  onClose: () => void
}

const ScrollLockModal = ({
  htmlContent,
  isOpen,
  onClickAccept,
  onClose
}: ScrollLockModalProps) => {
  const [canClose, setCanClose] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const updateButtonState = () => {
    if (!contentRef.current) return

    const { clientHeight, scrollHeight, scrollTop } = contentRef.current
    const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100
    const isNotScrollable = scrollHeight <= clientHeight

    setCanClose(isAtBottom || isNotScrollable)
  }

  useEffect(() => {
    if (isOpen) {
      setCanClose(false)
      // Check if content is not scrollable when modal opens
      setTimeout(updateButtonState, 100) // Timeout ensures the content is rendered
    }
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} onClose={() => {}} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Trans>Terms & Conditions</Trans>
        </ModalHeader>
        <ModalBody
          data-cy="scroll-lock-modal-content"
          ref={contentRef}
          onScroll={updateButtonState}
          overflowY="auto"
          maxHeight="70svh"
          dangerouslySetInnerHTML={{ __html: htmlContent }}
        />

        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            <Trans>Close</Trans>
          </Button>

          <Button
            data-cy="scroll-lock-modal-accept-button"
            colorScheme="accent"
            mr={3}
            onClick={() => {
              onClickAccept()
              onClose()
            }}
            isDisabled={!canClose}
          >
            <Trans>I read and accept</Trans>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ScrollLockModal
