import { t } from '@lingui/macro'
import { VeMoeAbi } from 'constants/abis/VeMoe'
import { VE_MOE_ADDRESS } from 'constants/moe'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { formattedNum } from 'utils/format'
import { formatUnits } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseVoteForFarmProps {
  enabled: boolean
  onSuccess: () => void
  pid: bigint
  deltaAmount?: bigint
}

const useVoteForFarm = ({
  deltaAmount,
  enabled,
  onSuccess,
  pid
}: UseVoteForFarmProps) => {
  const chainId = useChainId()
  const { isConnected } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: VeMoeAbi,
    address: VE_MOE_ADDRESS[chainId],
    args: deltaAmount ? [[pid], [deltaAmount]] : undefined,
    functionName: 'vote',
    query: {
      enabled: isConnected && !!deltaAmount && enabled,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const fmtDeltaAmount = deltaAmount ? formatUnits(deltaAmount, 18) : 0
        const description = t`Vote for farm #${pid.toString()} with ${formattedNum(
          fmtDeltaAmount
        )} veMOE`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const voteForFarm = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    isLoading: isPending || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success',
    voteForFarm
  }
}

export default useVoteForFarm
