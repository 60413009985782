import { Button, ButtonProps, useDisclosure } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import ConnectModal from 'components/ConnectModal'
import { chain } from 'constants/chains'
import useSwitchNetwork from 'hooks/useSwitchNetwork'
import React from 'react'
import { useAccount } from 'wagmi'

const Web3Button = (props: ButtonProps) => {
  const { chain: walletChain, isConnected } = useAccount()
  const { isOpen, onClose, onOpen } = useDisclosure()

  const { switchChain } = useSwitchNetwork()

  return isConnected && chain.id !== walletChain?.id ? (
    <Button
      {...props}
      leftIcon={undefined}
      rightIcon={undefined}
      isDisabled={false}
      onClick={() => switchChain?.({ chainId: chain.id })}
    >
      {t`Switch to ${chain.name}`}
    </Button>
  ) : isConnected ? (
    <Button {...props} />
  ) : (
    <>
      <ConnectModal isOpen={isOpen} onOpen={onOpen} onClose={onClose} />

      <Button {...props} isDisabled={false} onClick={onOpen}>
        <Trans>Connect Wallet</Trans>
      </Button>
    </>
  )
}

export default Web3Button
