import { Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import PageContainer from 'components/PageContainer'
import useGetMoeStakingAnalytics from 'hooks/stake/useGetMoeStakingAnalytics'
import useGetStableMoeRemittances from 'hooks/stake/useGetStableMoeRemittances'
import useGetUserMoeDepositedAmount from 'hooks/stake/useGetUserMoeDepositedAmount'
import debounce from 'lodash.debounce'
import React from 'react'

import ClaimMoeStakingRewardsPanel from './ClaimMoeStakingRewardsPanel'
import StakedMoeBalancePanel from './StakedMoeBalancePanel'
import StakeInfoPanel from './StakeInfoPanel'
import StakeMoePanels from './StakeMoePanels'
import StakeOverviewPanel from './StakeOverviewPanel'
import StakeRemittancesTable from './StakeRemittancesTable'
import StakeTVLPanel from './StakeTVLPanel'
import VeMoeBalancePanel from './VeMoeBalancePanel'

const Stake = () => {
  const { data: userMoeDepositedAmount, refetch: refetchMoeDepositedAmount } =
    useGetUserMoeDepositedAmount()
  const debouncedRefetchMoeDepositedAmount = debounce(
    () => refetchMoeDepositedAmount(),
    2000
  )

  const { data: tvlData = [], isLoading: isLoadingTvlData } =
    useGetMoeStakingAnalytics()
  const { data: remittancesPast30d = [], isLoading: isLoadingRemittances } =
    useGetStableMoeRemittances()

  return (
    <PageContainer mb="100px">
      <VStack align="flex-start">
        <Heading>
          <Trans>Stake MOE</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>
            Stake MOE to simultaneously accrue voting power (veMOE) and earn a
            share of protocol revenue. Yield from protocol fee sharing will be
            claimable on this page. Head to the Vote page to see your accrued
            veMOE and use it to boost farms.
          </Trans>
        </Text>
      </VStack>

      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        mt="50px"
        spacing={{ base: '50px', xl: '125px' }}
        alignItems="flex-start"
      >
        <VStack spacing={10}>
          <StakeMoePanels
            moeDepositedAmount={userMoeDepositedAmount?.formatted}
            onStakeSuccess={debouncedRefetchMoeDepositedAmount}
            onUnstakeSuccess={debouncedRefetchMoeDepositedAmount}
          />
          <StakedMoeBalancePanel
            moeDepositedAmount={userMoeDepositedAmount?.formatted || '0'}
          />
          <ClaimMoeStakingRewardsPanel />
          <VeMoeBalancePanel
            moeDepositedAmount={userMoeDepositedAmount?.value}
          />
        </VStack>
        <VStack spacing={10}>
          <StakeOverviewPanel
            totalMoeStaked={tvlData[tvlData.length - 1]?.amountStaked}
            remittances30d={remittancesPast30d}
            isLoading={isLoadingRemittances || isLoadingTvlData}
          />
          <StakeTVLPanel data={tvlData} isLoading={isLoadingTvlData} />
          <StakeInfoPanel />
          <StakeRemittancesTable
            remittances={remittancesPast30d.slice(0, 15)}
            isLoading={isLoadingRemittances}
          />
        </VStack>
      </SimpleGrid>
    </PageContainer>
  )
}

export default Stake
