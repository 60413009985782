import { ArrowBackIcon } from '@chakra-ui/icons'
import { Button, ButtonProps, Text } from '@chakra-ui/react'
import React from 'react'

interface BackButtonProps {
  title: string
}

const BackButton = ({ title, ...props }: BackButtonProps & ButtonProps) => {
  return (
    <Button
      variant="ghost"
      size="sm"
      leftIcon={<ArrowBackIcon />}
      color="textSecondary"
      {...props}
    >
      <Text textColor="textSecondary">{title}</Text>
    </Button>
  )
}

export default BackButton
