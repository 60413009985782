import { useQuery } from '@tanstack/react-query'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { useAccount } from 'wagmi'

interface MoeJourneyWinning {
  epoch: number
  mjt: number
  mntWon: number
  qualifiedUsers: number
  userAddress: string
  winningChance: number
}

const useGetMoeJourneyWinnings = () => {
  const account = useAccount()

  const fetchWinnings = useDexbarnGet<MoeJourneyWinning[]>(
    `/v1/user/mjt/${account.address}/winnings`
  )

  return useQuery({
    enabled: !!account.address,
    queryFn: fetchWinnings,
    queryKey: ['moeJourneyWinnings', account.address]
  })
}

export default useGetMoeJourneyWinnings
