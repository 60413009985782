import { Image, ImageProps } from '@chakra-ui/react'
import AvaxLogo from 'assets/avalanche.svg'
import MantleLogo from 'assets/mantle.png'
import PlaceholderLogo from 'assets/token-logo-placeholder.svg'
import React from 'react'
import { getAddress, isAddress } from 'viem'

export const getTokenLogoURL = (address: string) =>
  `https://raw.githubusercontent.com/merchant-moe/moe-tokenlists/main/logos/${address}/logo.png`

interface CurrencyLogoProps {
  address?: string
  symbol?: string
}

const CurrencyLogo = ({
  address,
  symbol,
  ...props
}: CurrencyLogoProps & ImageProps) => {
  if (symbol === 'AVAX') {
    return <Image alt="avax-logo" src={AvaxLogo} {...props} />
  }
  if (symbol === 'MNT') {
    return <Image alt="mantle-logo" src={MantleLogo} {...props} />
  }
  if (!address || !isAddress(address)) {
    return (
      <Image
        alt="placeholder-logo"
        borderRadius="full"
        bg="white"
        src={PlaceholderLogo}
        {...props}
      />
    )
  }
  return (
    <Image
      alt={`${symbol}-token-logo`}
      src={getTokenLogoURL(getAddress(address))}
      borderRadius="full"
      fallback={
        <Image
          alt="placeholder-token-logo"
          src={PlaceholderLogo}
          borderRadius="full"
          bg="white"
          {...props}
        />
      }
      fallbackStrategy="onError"
      {...props}
    />
  )
}

export default CurrencyLogo
