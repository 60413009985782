import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { LBRouterV21ABI } from '@traderjoe-xyz/sdk-v2'
import { LB_ROUTER_V22_ADDRESS } from 'constants/moe'
import useActiveChain from 'hooks/useActiveChain'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useEffect, useMemo } from 'react'
import { TradeBestPath } from 'types/trade'
import { formattedNum } from 'utils/format'
import { getConfigWithGasLimitIncreased } from 'utils/gas'
import { BaseError, getAddress } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

import useSwapCallArguments from './useSwapCallArguments'

interface UseSwapCallArgumentsProps {
  allowedSlippage: number
  enabled: boolean
  isExactIn: boolean
  onSwapSuccess: () => void
  currencyIn?: Currency
  currencyOut?: Currency
  trade?: TradeBestPath
}

export const useSwap = ({
  allowedSlippage,
  currencyIn,
  currencyOut,
  enabled,
  isExactIn,
  onSwapSuccess,
  trade
}: UseSwapCallArgumentsProps) => {
  const chainId = useChainId()
  const { chain: walletChain } = useAccount()
  const { nativeCurrency } = useActiveChain()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()
  const { defaultParameters, feeOnTransferParameters } = useSwapCallArguments({
    allowedSlippage,
    currencyIn,
    currencyOut,
    isExactIn,
    trade
  })

  const routerAddress = getAddress(LB_ROUTER_V22_ADDRESS[chainId])
  const {
    data: defaultConfig,
    error: defaultPrepareWriteError,
    isError: isDefaultConfigError,
    isFetching: isFetchingDefaultConfig
  } = useSimulateContract({
    abi: LBRouterV21ABI as any,
    address: routerAddress,
    args: defaultParameters?.args as any,
    functionName: defaultParameters?.methodName,
    query: {
      enabled: !!defaultParameters && enabled && walletChain?.id === chainId,
      gcTime: 0
    },
    value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
  })

  const {
    data: feeOnTransferConfig,
    error: feeOnTransferError,
    isFetching: isFetchingFeeOnTransferConfig
  } = useSimulateContract({
    abi: LBRouterV21ABI as any,
    address: routerAddress,
    args: feeOnTransferParameters?.args as any,
    functionName: feeOnTransferParameters?.methodName,
    query: {
      enabled:
        !!feeOnTransferParameters && enabled && walletChain?.id === chainId,
      gcTime: 0
    },
    value: feeOnTransferParameters
      ? BigInt(feeOnTransferParameters.value)
      : BigInt(0)
  })

  const transactionSummary = useMemo(() => {
    if (!trade) return ''
    const inputSymbol = currencyIn?.isNative
      ? nativeCurrency?.symbol
      : currencyIn?.symbol
    const outputSymbol = currencyOut?.isNative
      ? nativeCurrency?.symbol
      : currencyOut?.symbol
    const inputAmount = trade.amountIn.formatted
    const outputAmount = trade.amountOut.formatted
    return t`Swap ${formattedNum(
      inputAmount
    )} ${inputSymbol} for ${formattedNum(outputAmount)} ${outputSymbol}`
  }, [trade, nativeCurrency, currencyIn, currencyOut])

  const {
    data: hash,
    isPending,
    reset,
    writeContractAsync
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        addRecentTransaction({ description: transactionSummary, hash })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const config = getConfigWithGasLimitIncreased({
    config: isDefaultConfigError ? feeOnTransferConfig : defaultConfig,
    percentageIncrease: 10
  })

  // force send the transaction when the simulation fails still works
  // https://github.com/wevm/wagmi/discussions/2661
  const writeArgsFallback = defaultParameters
    ? {
        abi: LBRouterV21ABI,
        address: routerAddress,
        args: defaultParameters.args,
        functionName: defaultParameters.methodName,
        value: defaultParameters ? BigInt(defaultParameters.value) : BigInt(0)
      }
    : undefined

  const swapAsync = config?.request
    ? () => writeContractAsync(config.request)
    : writeArgsFallback
      ? () => writeContractAsync(writeArgsFallback as any)
      : undefined

  const { isLoading: isWaitingForTransaction } = useWaitForTransactionReceipt({
    hash,
    onTransactionSuccess: onSwapSuccess
  })

  useEffect(() => {
    reset()
  }, [chainId, reset])

  const error: { message: string; summary: string } | undefined =
    useMemo(() => {
      if (
        isFetchingDefaultConfig ||
        isFetchingFeeOnTransferConfig ||
        Boolean(config?.request) ||
        !defaultPrepareWriteError ||
        !feeOnTransferError
      ) {
        return undefined
      }

      const defaultErrorSummary = defaultPrepareWriteError
        ? (defaultPrepareWriteError as BaseError).shortMessage
        : undefined
      const feeOnTransferErrorSummary = feeOnTransferError
        ? (feeOnTransferError as BaseError).shortMessage
        : undefined
      const isErrorDueToSlippageTooLow =
        defaultPrepareWriteError?.message?.includes(
          'LBRouter__InsufficientAmountOut'
        ) ||
        feeOnTransferError?.message?.includes('LBRouter__InsufficientAmountOut')

      const error = defaultPrepareWriteError || feeOnTransferError
      const errorSummary = defaultErrorSummary || feeOnTransferErrorSummary

      if (!error) {
        return undefined
      }

      return isErrorDueToSlippageTooLow
        ? {
            message: error.message,
            summary: t`Error: the slippage is too low for this trade.`
          }
        : {
            message: error.message,
            summary: errorSummary || ''
          }
    }, [
      defaultPrepareWriteError,
      feeOnTransferError,
      isFetchingDefaultConfig,
      isFetchingFeeOnTransferConfig,
      config?.request
    ])

  return {
    error,
    isSwapping: isPending || isWaitingForTransaction,
    swapAsync
  }
}
