import {
  Button,
  Center,
  Flex,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Text,
  Thead,
  Tr
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import useSortedPools, { PoolSortMethod } from 'hooks/pool/useSortedPools'
import { useCallback, useState } from 'react'
import React from 'react'
import { Pool } from 'types/pool'

import { poolsListHeaders } from './constants'
import PoolTableHeader from './PoolTableHeader'
import PoolTableRow from './PoolTableRow'

export interface PoolsTableProps {
  isFetchingPage: boolean
  isLoadingPools: boolean
  pools: Pool[]
  query: string
  setSortMethod: (sortMethod: PoolSortMethod) => void
  sortMethod: PoolSortMethod
  hasNextPage?: boolean
  onLoadMoreClick?: () => void
}

const PoolsTable = ({
  hasNextPage,
  isFetchingPage,
  isLoadingPools,
  onLoadMoreClick,
  pools,
  query,
  setSortMethod,
  sortMethod
}: PoolsTableProps) => {
  const [isSortDescending, setIsSortDescending] = useState<boolean>(true)
  const sortedPools = useSortedPools({
    isSortDescending,
    pools,
    sortMethod
  })

  const onHeaderClick = useCallback(
    (method?: PoolSortMethod) => {
      if (!method) return
      sortMethod !== method
        ? setSortMethod(method)
        : setIsSortDescending((previous) => !previous)
    },
    [sortMethod, setSortMethod]
  )

  return (
    <Flex flexDir="column" gap={4}>
      {isLoadingPools ? (
        <Center mt={8}>
          <Spinner />
        </Center>
      ) : sortedPools.length > 0 ? (
        <TableContainer>
          <Table
            variant="card"
            style={{ borderCollapse: 'separate', borderSpacing: '0 8px' }}
          >
            <Thead>
              <Tr>
                {poolsListHeaders.map((header, i) => (
                  <PoolTableHeader
                    key={i}
                    selectedSortMethod={sortMethod}
                    isSortDescending={isSortDescending}
                    {...header}
                    onClick={() => onHeaderClick(header.sortMethod)}
                  />
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {sortedPools.map((pool) => (
                <PoolTableRow key={pool.pairAddress} pool={pool} />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Center mt="80px">
          <Text textColor="textSecondary">
            <Trans>No liquidity pools available</Trans>
          </Text>
        </Center>
      )}
      {!isLoadingPools &&
      query === '' &&
      sortedPools.length > 0 &&
      hasNextPage ? (
        <Button
          variant="outline-bold"
          alignSelf="center"
          w="160px"
          isLoading={isFetchingPage}
          loadingText={t`Loading`}
          onClick={onLoadMoreClick}
        >
          <Trans>Load More</Trans>
        </Button>
      ) : null}
    </Flex>
  )
}

export default PoolsTable
