import { DexbarnFarmBribe } from './dexbarn'
import { Farm as LensFarm } from './dexlens'

// interface used by the UI
export interface Farm extends LensFarm {
  activeBribes: {
    bribeId: string
    farmId: string
    rewardsPer1mVotesPerDay: number
    rewardsPerDay: number
    tokenSymbol: string
    tokenUsdPrice?: number
  }[]
  farmApr: number
  isLbRewarder: boolean
  liquidityUsd: string
  moePerSecEmittedToFarm: number
  totalWeightOnFarmPercent: number
  votesPercent: number
  moePerSecEmittedToFarmUsd?: number
  votes7dChange?: number
}

export type FarmSortMethod = 'liquidity' | 'rewards' | 'apr'

export enum RewarderStatus {
  ENDED = 'Ended',
  LIVE = 'Live',
  PRELAUNCH = 'Pre-launch',
  STOPPED = 'Stopped'
}

export interface FarmBribe extends DexbarnFarmBribe {
  rewarderStatus: RewarderStatus
  bribeTotalVotes?: bigint
  isStopped?: boolean
}
