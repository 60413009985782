import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { LBPool as LBDexbarnPool } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertDexbarnLBPoolToPool } from 'utils/poolV2'

interface UsePoolV2Props {
  address?: string
}

const usePoolV2 = ({ address }: UsePoolV2Props) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchPool = useDexbarnGet<LBDexbarnPool>(
    `/v1/lb/pools/${chain}/${address}`
  )
  const result = useQuery<LBDexbarnPool>({
    enabled: !!address,
    queryFn: () => fetchPool(),
    queryKey: ['PoolV2Query', chain, address],
    staleTime: 10 * (60 * 1000) // 10 mins
  })

  return {
    ...result,
    data: result.data ? convertDexbarnLBPoolToPool(result.data) : undefined
  }
}

export default usePoolV2
