import { t } from '@lingui/macro'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { getAddress, parseAbi } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseBatchClaimRewardsProps {
  enabled: boolean
  onSuccess: () => void
  pids: bigint[]
  rewarderAddress: string
}

const useBatchClaimRewards = ({
  enabled,
  onSuccess,
  pids,
  rewarderAddress
}: UseBatchClaimRewardsProps) => {
  const chainId = useChainId()
  const { isConnected } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: parseAbi(['function claim(uint256[] memory pids) external']),
    address: getAddress(rewarderAddress),
    args: [pids],
    chainId,
    functionName: 'claim',
    query: { enabled: isConnected && pids.length > 0 && enabled, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const description = t`Claimed rewards`
        addRecentTransaction({
          description,
          hash
        })
        addTransactionToast({ description, hash })
      }
    }
  })

  const batchClaimRewards = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { data: receipt, isLoading: isWaitingForTransaction } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    batchClaimRewards,
    isLoading: isPending || isWaitingForTransaction,
    isSuccess: receipt?.status === 'success'
  }
}

export default useBatchClaimRewards
