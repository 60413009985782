import { CloseIcon } from '@chakra-ui/icons'
import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerHeader,
  Flex,
  IconButton,
  Input
} from '@chakra-ui/react'
import SearchResults from 'components/HeaderSearchPanel/SearchResults'
import usePairsSearch from 'hooks/usePairsSearch'
import useTokensSearch from 'hooks/useTokensSearch'
import React, { useState } from 'react'

interface MobileSearchPanelProps {
  isOpen: boolean
  onClose: () => void
}

const MobileSearchPanel = ({
  isOpen,
  onClose
}: MobileSearchPanelProps): JSX.Element => {
  const [query, setQuery] = useState('')
  const { data: pairs = [], isLoading: isLoadingPools } = usePairsSearch({
    orderBy: 'volume',
    query
  })
  const { isLoading: isLoadingTokens, tokens } = useTokensSearch({
    query
  })

  return (
    <Drawer placement="right" isOpen={isOpen} onClose={onClose} size="full">
      <DrawerContent p={0} bg="bgPrimary">
        <DrawerHeader py={6} px={4}>
          <Flex
            justify="space-between"
            align="center"
            borderRadius={32}
            px="1rem"
            gap="1rem"
            h="3rem"
            bg="white"
            boxShadow="element"
          >
            <Input
              variant="unstyled"
              h="full"
              minH="40px"
              w="full"
              border={0}
              borderRadius={0}
              _focus={{ outline: 'none' }}
              placeholder="Search by symbol, name or address"
              onChange={(e) => setQuery(e.target.value)}
            />
            <IconButton
              aria-label="close search"
              icon={<CloseIcon boxSize="10px" />}
              rounded="full"
              bg="bgPrimary"
              size="sm"
              onClick={onClose}
            />
          </Flex>
        </DrawerHeader>
        <DrawerBody p={0}>
          <SearchResults
            pairs={pairs}
            tokens={tokens}
            isLoadingPools={isLoadingPools}
            isLoadingTokens={isLoadingTokens}
            onRowClick={onClose}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export default MobileSearchPanel
