import { useQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { DexbarnUserRewarder } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { useAccount } from 'wagmi'

const useGetUserRewarders = ({ enabled }: { enabled: boolean }) => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  const fetchUserRewarders = useDexbarnGet<DexbarnUserRewarder[]>(
    `/v1/user/bribes/${account}/${getDexbarnChainParam(chainId)}?isActive=false`
  )

  return useQuery({
    enabled: enabled && !!account,
    queryFn: fetchUserRewarders,
    queryKey: ['UserRewardersQuery', account, chainId]
  })
}

export default useGetUserRewarders
