import { Box, IconButton, VStack } from '@chakra-ui/react'
import { CurrencyInputProps } from 'components/CurrencyInput'
import React from 'react'
import { ArrowUpDownIcon } from 'theme/icons'

import TradeCurrencyInput from './TradeCurrencyInput'

interface TradeCurrencyInputsProps {
  bottomContent: JSX.Element
  inputCurrencyProps: CurrencyInputProps
  onChangeSwapDirectionClick: () => void
  outputCurrencyProps: CurrencyInputProps
  isChangeSwapDirectionDisabled?: boolean
}

const TradeCurrencyInputs = ({
  bottomContent,
  inputCurrencyProps,
  isChangeSwapDirectionDisabled,
  onChangeSwapDirectionClick,
  outputCurrencyProps
}: TradeCurrencyInputsProps) => {
  return (
    <VStack pos="relative" overflow="hidden" w="full" spacing={4}>
      <VStack pos="relative" w="full" spacing={3}>
        <Box bg="bgSecondary" p={4} borderRadius="10px" w="full">
          <TradeCurrencyInput
            data-cy="trade-currency-input"
            dataCyInputGroup="trade-currency-input-group"
            {...inputCurrencyProps}
          />
        </Box>
        <IconButton
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          pos="absolute"
          data-cy="change-swap-direction-button"
          bg="bgCard"
          size="lg"
          borderRight="10px"
          boxShadow="element"
          icon={<ArrowUpDownIcon />}
          aria-label="change swap direction"
          isDisabled={isChangeSwapDirectionDisabled}
          onClick={onChangeSwapDirectionClick}
        />
        <Box bg="bgSecondary" p={4} borderRadius="10px" w="full">
          <TradeCurrencyInput
            data-cy="trade-currency-output"
            dataCyInputGroup="trade-currency-output-group"
            {...outputCurrencyProps}
          />
        </Box>
      </VStack>
      {bottomContent}
    </VStack>
  )
}

export default TradeCurrencyInputs
