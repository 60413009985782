import { Box, Text, VStack } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import useActiveChain from 'hooks/useActiveChain'
import useChainId from 'hooks/useChainId'
import useKeyPress from 'hooks/useKeyPress'
import React, { useState } from 'react'
import { TooltipProps } from 'recharts'
import {
  NameType,
  ValueType
} from 'recharts/types/component/DefaultTooltipContent'
import { formattedNum } from 'utils/format'
import { ZERO_FRACTION } from 'utils/numbers'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

type LBPairDistributionChartTooltipProps = TooltipProps<ValueType, NameType>

const LBPairDistributionChartTooltip = ({
  active,
  payload
}: LBPairDistributionChartTooltipProps) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()

  const [priceDecimals, setPriceDecimals] = useState(10)
  useKeyPress({
    onUp: () => setPriceDecimals((prev) => (prev === 18 ? 10 : 18)),
    targetKey: 'g'
  })

  const bin = payload?.[0]?.payload
  const price = bin?.price
  const amountX = bin?.amountX
  const amountY = bin?.amountY
  const noLiquidity = bin?.liquidity === 0

  const showAmounts = amountX?.toExact() > 0 || amountY?.toExact() > 0

  const isToken0WrappedNativeCurrency = isWrappedNativeCurrency(
    amountX?.token.address,
    chainId
  )
  const isToken1WrappedNativeCurrency = isWrappedNativeCurrency(
    amountY?.token.address,
    chainId
  )

  const symbolA = isToken0WrappedNativeCurrency
    ? nativeCurrency.symbol
    : amountX?.token.symbol
  const symbolB = isToken1WrappedNativeCurrency
    ? nativeCurrency.symbol
    : amountY?.token.symbol

  return active && payload && payload.length && !noLiquidity ? (
    <Box
      p={4}
      borderRadius="xl"
      backdropFilter="blur(8px)"
      bg="rgba(245, 245, 255, 0.48)"
      boxShadow="0px 4px 40px rgba(0, 0, 0, 0.16)"
      _dark={{
        bg: 'rgba(53, 58, 108, 0.48)',
        boxShadow: '0px 4px 40px rgba(0, 0, 0, 0.4)'
      }}
    >
      <VStack spacing={0} align="flex-start" mb={showAmounts ? 4 : 0}>
        <Text
          fontSize="sm"
          color="textSecondary"
        >{t`Price (${symbolA} / ${symbolB})`}</Text>
        <Text fontWeight="semibold">
          {price
            ? formattedNum(price, {
                allowDecimalsOver1000: true,
                allowSmallDecimals: true,
                places: priceDecimals
              })
            : undefined}
        </Text>
      </VStack>
      <VStack spacing={4} align="flex-start">
        {amountX && amountX.greaterThan(ZERO_FRACTION) ? (
          <VStack spacing={0} align="flex-start">
            <Text fontSize="sm" color="textSecondary">
              {symbolA}
            </Text>
            <Text fontWeight="semibold">
              {formattedNum(amountX.toExact(), {
                allowDecimalsOver1000: true,
                allowSmallDecimals: true,
                places:
                  amountX.token.decimals >= priceDecimals
                    ? priceDecimals
                    : amountX.token.decimals
              })}
            </Text>
          </VStack>
        ) : null}
        {amountY && amountY.greaterThan(ZERO_FRACTION) ? (
          <VStack spacing={0} align="flex-start">
            <Text fontSize="sm" color="textSecondary">
              {symbolB}
            </Text>
            <Text fontWeight="semibold">
              {formattedNum(amountY.toExact(), {
                allowDecimalsOver1000: true,
                allowSmallDecimals: true,
                places:
                  amountY.token.decimals >= priceDecimals
                    ? priceDecimals
                    : amountX.token.decimals
              })}
            </Text>
          </VStack>
        ) : null}
      </VStack>
    </Box>
  ) : null
}

export default LBPairDistributionChartTooltip
