import { LiquidityDistribution } from '@traderjoe-xyz/sdk-v2'

export const DEFAULT_DISTRIBUTION = LiquidityDistribution.SPOT

export const getDefaultBinRange = (activeBinId: number | undefined) => {
  return activeBinId ? [activeBinId - 25, activeBinId + 25] : undefined
}

export type AddLiquidityState = {
  amount0: string
  amount1: string
  distribution: LiquidityDistribution
  activeBinId?: number
  binRange?: number[]
}

export type AddLiquidityAction =
  | { payload: string; type: 'set_amount0' }
  | { payload: string; type: 'set_amount1' }
  | {
      type: 'set_bin_range'
      payload?: number[]
    }
  | { type: 'set_default_bin_range' }
  | {
      payload: LiquidityDistribution
      type: 'set_distribution'
    }
  | { type: 'set_active_bin_id'; payload?: number }
  | { type: 'reset' }

export const addLiquidityStateReducer = (
  state: AddLiquidityState,
  action: AddLiquidityAction
): AddLiquidityState => {
  switch (action.type) {
    case 'set_amount0':
      return {
        ...state,
        amount0: action.payload
      }
    case 'set_amount1':
      return {
        ...state,
        amount1: action.payload
      }
    case 'set_distribution':
      return {
        ...state,
        distribution: action.payload
      }
    case 'set_bin_range': {
      const range = action.payload
      const activeBinId = state.activeBinId
      return {
        ...state,
        amount0:
          activeBinId && range && range[1] < activeBinId ? '0' : state.amount0,
        amount1:
          activeBinId && range && activeBinId < range[0] ? '0' : state.amount1,
        binRange: range
      }
    }
    case 'set_default_bin_range':
      return {
        ...state,
        binRange: getDefaultBinRange(state.activeBinId)
      }
    case 'set_active_bin_id':
      return {
        ...state,
        activeBinId: action.payload,
        binRange: getDefaultBinRange(action.payload)
      }
    case 'reset':
      return {
        ...state,
        amount0: '',
        amount1: '',
        binRange: getDefaultBinRange(state.activeBinId)
      }
  }
}
