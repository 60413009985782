import { LBPairV21ABI } from '@traderjoe-xyz/sdk-v2'
import { UserPositionRowProps } from 'pages/Pool/UserPositions/UserPositionRow'
import { UserPoolPosition } from 'types/pool'
import { UserLBPosition } from 'types/poolV2'
import { getPoolPoints } from 'utils/points'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

import useGetBatchLbRewarderData from './v2/useGetBatchLbRewarderData'

interface UseUserPositionsRowsProps {
  lbPositions: UserLBPosition[]
  positions: UserPoolPosition[]
}

const useUserPositionsRows = ({
  lbPositions,
  positions
}: UseUserPositionsRowsProps) => {
  // fetch active bin for v2 pools
  const { data: activeBinResults } = useReadContracts({
    contracts: lbPositions.map((position) => ({
      abi: LBPairV21ABI,
      address: getAddress(position.poolAddress),
      functionName: 'getActiveId'
    }))
  })

  // active bin per pool address
  const activeBinPerPool = activeBinResults?.reduce(
    (acc, result, i) => {
      if (result && result.status === 'success') {
        const activeId = result.result as number
        acc[lbPositions[i].poolAddress] = Number(activeId)
      }
      return acc
    },
    {} as { [poolAddress: string]: number }
  )

  // get lb rewards
  const { data: rewarderDataPerPairAddress } = useGetBatchLbRewarderData({
    pairs: lbPositions.map((lbPosition) => ({
      binStep: lbPosition.lbBinStep,
      currency0Decimals: lbPosition.tokenX.decimals,
      currency1Decimals: lbPosition.tokenY.decimals,
      ids: lbPosition.binIds.map((binId) => binId.toString()),
      pairAddress: lbPosition.poolAddress
    }))
  })

  // convert v1 positions
  const v1Rows: UserPositionRowProps[] = positions.map((position) => {
    const totalAmountUsd =
      (Number(position.amountX.formatted) +
        position.farmStakedAmounts.amountX) *
        position.tokenX.priceUsd +
      (Number(position.amountY.formatted) +
        position.farmStakedAmounts.amountY) *
        position.tokenY.priceUsd

    return {
      farmStakedAmounts: {
        amountX: position.farmStakedAmounts.amountX,
        amountY: position.farmStakedAmounts.amountY
      },
      feePct: 0.3,
      isPoolMigrated: false,
      points: getPoolPoints(
        position.tokenX.address,
        position.tokenY.address,
        'v1'
      ),
      poolAddress: position.pairId,
      tokenX: {
        ...position.tokenX,
        address: position.tokenX.address,
        amount: Number(position.amountX.formatted),
        decimals: position.tokenX.decimals
      },
      tokenY: {
        ...position.tokenY,
        address: position.tokenY.address,
        amount: Number(position.amountY.formatted),
        decimals: position.tokenY.decimals
      },
      totalAmountUsd
    }
  })

  // convert v2 positions
  const v2Rows: UserPositionRowProps[] = lbPositions.map((position) => {
    const activeBinId = activeBinPerPool?.[position.poolAddress]
    const lbRewards = rewarderDataPerPairAddress?.[position.poolAddress].rewards

    return {
      feePct: position.lbBaseFeePct,
      isOutOfPosition: activeBinId
        ? !position.binIds.includes(activeBinId)
        : false,
      isPoolMigrated: position.status === 'old',
      lbBinStep: position.lbBinStep,
      lbPoolVersion: 'v22',
      points: getPoolPoints(
        position.tokenX.address,
        position.tokenY.address,
        'v2'
      ),
      poolAddress: position.poolAddress,
      rewards: lbRewards,
      tokenX: {
        ...position.tokenX,
        amount: Number(position.totalAmountX.formatted)
      },
      tokenY: {
        ...position.tokenY,
        amount: Number(position.totalAmountY.formatted)
      },
      totalAmountUsd:
        Number(position.totalAmountX.formatted) * position.tokenX.priceUsd +
        Number(position.totalAmountY.formatted) * position.tokenY.priceUsd
    }
  })

  const allPositions = v1Rows.concat(v2Rows)

  // sort by total amount in usd
  return allPositions.sort(
    (a, b) => (b.totalAmountUsd ?? 0) - (a.totalAmountUsd ?? 0)
  )
}

export default useUserPositionsRows
