import { UserPoolPosition } from 'types/pool'
import { UserLBPosition } from 'types/poolV2'
import { VaultWithUserPosition } from 'types/vault'
import { isAddress } from 'viem'

interface UseUserPositionSearchProps {
  lbPositions: UserLBPosition[]
  poolV1Positions: UserPoolPosition[]
  query: string
  userVaults: VaultWithUserPosition[]
}

const useUserPositionSearch = ({
  lbPositions,
  poolV1Positions,
  query,
  userVaults
}: UseUserPositionSearchProps) => {
  const lowercasedQuery = query.toLowerCase()
  if (isAddress(query)) {
    return {
      lbPositions: lbPositions.filter(
        (position) =>
          position.tokenX.address.toLowerCase().startsWith(lowercasedQuery) ||
          position.tokenY.address.toLowerCase().startsWith(lowercasedQuery) ||
          position.poolAddress.toLowerCase().startsWith(lowercasedQuery)
      ),
      poolV1Positions: poolV1Positions.filter(
        (position) =>
          position.tokenX.address.toLowerCase().startsWith(lowercasedQuery) ||
          position.tokenY.address.toLowerCase().startsWith(lowercasedQuery) ||
          position.pairId.toLowerCase().startsWith(lowercasedQuery)
      ),
      vaults: userVaults.filter(
        (vault) =>
          vault.tokenX.address?.toLowerCase().startsWith(lowercasedQuery) ||
          vault.tokenY.address?.toLowerCase().startsWith(lowercasedQuery) ||
          vault.id.toLowerCase().startsWith(lowercasedQuery)
      )
    }
  }
  return {
    lbPositions: lbPositions.filter(
      (position) =>
        position.pairName.toLowerCase().includes(lowercasedQuery) ||
        position.tokenX.symbol.toLowerCase().includes(lowercasedQuery) ||
        position.tokenY.symbol.toLowerCase().includes(lowercasedQuery)
    ),
    poolV1Positions: poolV1Positions.filter(
      (position) =>
        position.tokenX.symbol.toLowerCase().startsWith(lowercasedQuery) ||
        position.tokenY.symbol.toLowerCase().startsWith(lowercasedQuery)
    ),
    vaults: userVaults.filter(
      (vault) =>
        vault.tokenX.symbol.toLowerCase().startsWith(lowercasedQuery) ||
        vault.tokenY.symbol.toLowerCase().startsWith(lowercasedQuery)
    )
  }
}

export default useUserPositionSearch
