import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { Farm } from 'types/dexlens'

import FarmStakePanel from './FarmStakePanel'
import FarmUnstakePanel from './FarmUnstakePanel'

interface FarmStakePanelsProps {
  farm: Farm
  onStakeSuccess: () => void
  onUnstakeSuccess: () => void
}

const FarmStakePanels = (props: FarmStakePanelsProps) => {
  return (
    <VStack
      w="full"
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
      spacing={10}
      align="flex-start"
    >
      <Tabs w="full" variant="solid-rounded" isFitted>
        <TabList>
          <Tab data-cy="farm-stake-tab">
            <Trans>Stake</Trans>
          </Tab>
          <Tab data-cy="farm-unstake-tab">
            <Trans>Unstake</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt={10}>
            <FarmStakePanel {...props} />
          </TabPanel>
          <TabPanel p={0} pt={10}>
            <FarmUnstakePanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

export default FarmStakePanels
