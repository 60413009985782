import { VeMoeAbi } from 'constants/abis/VeMoe'
import { VE_MOE_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useReadContracts } from 'wagmi'

interface UseGetBribesTotalVotesProps {
  bribes?: {
    contractAddress: string
    farmPid: bigint
  }[]
}

const useGetBribesTotalVotes = ({ bribes }: UseGetBribesTotalVotesProps) => {
  const chainId = useChainId()

  return useReadContracts({
    contracts: bribes?.map((bribe) => ({
      abi: VeMoeAbi,
      address: VE_MOE_ADDRESS[chainId],
      args: [bribe.contractAddress, bribe.farmPid],
      chainId,
      functionName: 'getBribesTotalVotes'
    })),
    query: {
      enabled: !!bribes && bribes.length > 0,
      select: (data) => {
        return data.map((d) =>
          d.status === 'success' ? (d.result as bigint | undefined) : undefined
        )
      }
    }
  })
}

export default useGetBribesTotalVotes
