import { keepPreviousData, useQuery } from '@tanstack/react-query'
import { LiquidityHelperAbi } from 'constants/abis/LiquidityHelper'
import { LIQUIDITY_HELPER_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { getDexbarnChainParam } from 'utils/chains'
import { convertLBPositionsToUserLBPositions } from 'utils/poolV2'
import { getAddress } from 'viem'
import { useReadContracts } from 'wagmi'

interface UseLBPairBalancesProps {
  enabled?: boolean
  lbBinStep?: number
  lbPairAddress?: string
  owner?: string
  token0Decimals?: number
  token1Decimals?: number
}

const useLBPairBalances = ({
  enabled = true,
  lbBinStep,
  lbPairAddress,
  owner,
  token0Decimals,
  token1Decimals
}: UseLBPairBalancesProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)
  const fetchUserBinIds = useDexbarnGet<number[]>(
    `/v1/lb/user/bin-ids/${owner?.toLowerCase()}/${chain}/${lbPairAddress?.toLowerCase()}`
  )

  const {
    data: bins,
    isFetching: isFetchingBins,
    refetch: refetchBins
  } = useQuery<number[]>({
    enabled: !!lbPairAddress && !!owner && enabled,
    placeholderData: keepPreviousData,
    queryFn: () => fetchUserBinIds(),
    queryKey: ['UserBinIds', chain, owner, lbPairAddress]
  })

  // we get the amounts and liquidities from the chain directly
  const binIds = bins?.map((binId) => BigInt(binId))
  const args =
    owner && binIds && lbPairAddress
      ? ([getAddress(lbPairAddress), getAddress(owner), binIds] as const)
      : undefined
  const {
    data: amountsAndLiquidities,
    isFetching: isFetchingAmountsAndLiquidities,
    refetch: refetchAmountsAndLiquidities
  } = useReadContracts({
    contracts: [
      {
        abi: LiquidityHelperAbi,
        address: getAddress(LIQUIDITY_HELPER_ADDRESS[chainId]),
        args,
        chainId,
        functionName: 'getAmountsOf'
      },
      {
        abi: LiquidityHelperAbi,
        address: getAddress(LIQUIDITY_HELPER_ADDRESS[chainId]),
        args,
        chainId,
        functionName: 'getSharesOf'
      }
    ],
    query: {
      enabled:
        !!lbPairAddress && !!owner && !!binIds && binIds.length > 0 && enabled
    }
  })

  const amounts = amountsAndLiquidities?.[0].result
  const liquidities = amountsAndLiquidities?.[1].result
  const positions =
    bins &&
    amounts &&
    liquidities &&
    token0Decimals &&
    token1Decimals &&
    lbBinStep
      ? convertLBPositionsToUserLBPositions({
          amounts: { amountsX: amounts[0], amountsY: amounts[1] },
          binIds: bins,
          lbBinStep,
          liquidities,
          token0Decimals,
          token1Decimals
        })
      : undefined

  return {
    data: positions,
    isFetching: isFetchingBins || isFetchingAmountsAndLiquidities,
    refetch: () => {
      refetchBins()
      refetchAmountsAndLiquidities()
    }
  }
}

export default useLBPairBalances
