import { MoeRouterAbi } from 'constants/abis/MoeRouter'
import { MOE_ROUTER } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import useGetTransactionDeadline from 'hooks/useGetTransactionDeadline'
import { getAddress } from 'viem'
import { useAccount, useSimulateContract } from 'wagmi'

interface UseAddLiquidityConfigProps {
  amountADesired?: bigint
  amountAMin?: bigint
  amountBDesired?: bigint
  amountBMin?: bigint
  enabled?: boolean
  tokenA?: `0x${string}`
  tokenB?: `0x${string}`
}

const useAddLiquidityConfig = ({
  amountADesired,
  amountAMin,
  amountBDesired,
  amountBMin,
  enabled,
  tokenA,
  tokenB
}: UseAddLiquidityConfigProps) => {
  const chainId = useChainId()
  const { address: account } = useAccount()

  const getTransactionDeadline = useGetTransactionDeadline()
  const deadline = getTransactionDeadline()

  return useSimulateContract({
    abi: MoeRouterAbi,
    address: getAddress(MOE_ROUTER[chainId]),
    args:
      tokenA &&
      tokenB &&
      account &&
      deadline &&
      amountADesired &&
      amountAMin &&
      amountBDesired &&
      amountBMin
        ? [
            tokenA,
            tokenB,
            amountADesired,
            amountBDesired,
            amountAMin,
            amountBMin,
            account,
            deadline
          ]
        : undefined,
    functionName: 'addLiquidity',
    query: {
      enabled:
        !!account &&
        !!deadline &&
        !!amountADesired &&
        !!amountBDesired &&
        !!tokenA &&
        !!tokenB &&
        enabled,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })
}

export default useAddLiquidityConfig
