import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

import StakeJoePanel from './StakeJoePanel'
import UnstakeJoePanel from './UnstakeJoePanel'

interface StakeJoePanelsProps {
  onStakeSuccess: () => void
  onUnstakeSuccess: () => void
  joeDepositedAmount?: string
}

const StakeJoePanels = ({
  joeDepositedAmount,
  onStakeSuccess,
  onUnstakeSuccess
}: StakeJoePanelsProps) => {
  return (
    <VStack
      w="full"
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
      spacing={{ base: 4, md: 10 }}
      align="flex-start"
    >
      <Heading size="md">
        <Trans>Stake JOE</Trans>
      </Heading>

      <Tabs w="full" variant="solid-rounded" isFitted>
        <TabList>
          <Tab data-cy="stake-joe-tab">
            <Trans>Stake</Trans>
          </Tab>
          <Tab data-cy="unstake-joe-tab">
            <Trans>Unstake</Trans>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel p={0} pt={{ base: 4, md: 10 }}>
            <StakeJoePanel onStakeSuccess={onStakeSuccess} />
          </TabPanel>
          <TabPanel p={0} pt={{ base: 4, md: 10 }}>
            <UnstakeJoePanel
              joeDepositedAmount={joeDepositedAmount}
              onUnstakeSuccess={onUnstakeSuccess}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </VStack>
  )
}

export default StakeJoePanels
