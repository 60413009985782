import {
  Box,
  Center,
  Flex,
  Grid,
  Heading,
  HStack,
  Spacer,
  Text
} from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { CNATIVE, Currency, Token } from '@traderjoe-xyz/sdk-core'
import { PoolVersion } from '@traderjoe-xyz/sdk-v2'
import AddToWalletButton from 'components/AddToWalletButton'
import ApproveTokenButton from 'components/ApproveTokenButton'
import CopyableError from 'components/CopyableError'
import MaxButton from 'components/MaxButton'
import PageHelmet from 'components/PageHelmet'
import SlippageSettingsPicker from 'components/SlippageSettingsPicker'
import TradingViewChart from 'components/TradingViewChart'
import Web3Button from 'components/Web3Button'
import { LB_ROUTER_V22_ADDRESS } from 'constants/moe'
import { TRADE_HELMET_DESCRIPTION, TRADE_HELMET_TITLE } from 'constants/swap'
import useGetBestTrade from 'hooks/swap/useGetBestTrade'
import { useSwap } from 'hooks/swap/useSwap'
import useWrapUnwrapNativeCurrency from 'hooks/swap/useWrapUnwrapNativeCurrency'
import useApproveSpenderIfNeeded from 'hooks/useApproveSpenderIfNeeded'
import useChainId from 'hooks/useChainId'
import useGetTokensUsdPrice from 'hooks/useGetTokensPriceUsd'
import useSdkCurrencies from 'hooks/useSdkCurrencies'
import { useTokenBalance } from 'hooks/useTokenBalance'
import debounce from 'lodash.debounce'
import React, { useEffect } from 'react'
import { useState } from 'react'
import { useCallback } from 'react'
import { useMemo } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom'
import { useIsSafeModeEnabled, useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { tradeSwap } from 'utils/measure'
import { tryParseAmount } from 'utils/swap'
import {
  getCurrencyAddress,
  wrappedCurrency,
  wrappedCurrencyAmount
} from 'utils/wrappedCurrency'
import { useAccount } from 'wagmi'

import TradeCurrencyInputs from './TradeCurrencyInputs'
import TradeCurrencyShortcuts from './TradeCurrencyShortcuts'
import TradeDetails from './TradeDetails'
import TradeRefetchView from './TradeRefetchView'

const Trade = () => {
  const { isConnected } = useAccount()
  const location = useLocation()
  const chainId = useChainId()

  // Decode search params
  const [searchParams] = useSearchParams()
  const inputCurrencyAddr = searchParams.get('inputCurrency')
  const outputCurrencyAddr = searchParams.get('outputCurrency')
  const paramAddresses = useMemo(
    () => [inputCurrencyAddr, outputCurrencyAddr],
    [inputCurrencyAddr, outputCurrencyAddr]
  )
  const {
    tokens: [inputCurrencyParam, outputCurrencyParam]
  } = useSdkCurrencies({
    addresses: paramAddresses
  })

  // Input/output currencies
  const [isExactIn, setIsExactIn] = useState(true)
  const [selectedInputCurrency, setInputCurrency] = useState<
    Currency | undefined
  >(CNATIVE.onChain(chainId))
  const [selectedOutputCurrency, setOutputCurrency] = useState<
    Currency | undefined
  >()

  const inputCurrency = useMemo(
    () => selectedInputCurrency ?? inputCurrencyParam,
    [selectedInputCurrency, inputCurrencyParam]
  )
  const outputCurrency = useMemo(
    () => selectedOutputCurrency ?? outputCurrencyParam,
    [selectedOutputCurrency, outputCurrencyParam]
  )

  // Addresses
  const inputCurrencyAddress = getCurrencyAddress(inputCurrency)
  const outputCurrencyAddress = getCurrencyAddress(outputCurrency)

  // User balances
  const inputBalance = useTokenBalance({
    token: inputCurrencyAddress
  })
  const debouncedRefetchInputBalance = debounce(
    () => inputBalance.refetch(),
    2000
  )
  const outputBalance = useTokenBalance({
    token:
      outputCurrency instanceof Token
        ? (outputCurrency as Token).address
        : undefined
  })
  const debouncedRefetchOutputBalance = debounce(
    () => outputBalance.refetch(),
    2000
  )

  // User input
  const [typedValue, setTypedValue] = useState('')
  const userTypedCurrency = isExactIn ? inputCurrency : outputCurrency
  const typedTokenAmount = useMemo(
    () =>
      wrappedCurrencyAmount(
        tryParseAmount(typedValue, userTypedCurrency),
        chainId
      ),
    [typedValue, userTypedCurrency, chainId]
  )

  // Get token USD prices
  const inputWrappedCurrencyAddress = wrappedCurrency(inputCurrency, chainId)
    ?.address
  const outputWrappedCurrencyAddress = wrappedCurrency(outputCurrency, chainId)
    ?.address
  const { data: tokenPrices } = useGetTokensUsdPrice({
    tokenAddresses:
      inputWrappedCurrencyAddress && outputWrappedCurrencyAddress
        ? [inputWrappedCurrencyAddress, outputWrappedCurrencyAddress]
        : []
  })
  const inputCurrencyUsdPrice =
    tokenPrices?.[inputWrappedCurrencyAddress?.toLowerCase() || '']
  const outputCurrencyUsdPrice =
    tokenPrices?.[outputWrappedCurrencyAddress?.toLowerCase() || '']

  // Fetch trades
  const refetchInterval = 30000 // 30 seconds
  const [isRefreshQuoteEnabled, setIsRefreshQuoteEnabled] = useState(true)
  const {
    data: tradeBestPath,
    isFetching: isFetchingBestPath,
    lastFetchTime,
    refetch: refetchTradeBestPath,
    routerType
  } = useGetBestTrade({
    inputCurrency,
    isExactIn,
    outputCurrency,
    refetchInterval: isRefreshQuoteEnabled ? refetchInterval : 0,
    typedTokenAmount
  })
  const isLiquidityInsufficient =
    !isFetchingBestPath && !tradeBestPath && !!typedValue

  // Get amounts usd prices
  const inputAmountUsdPrice =
    tradeBestPath && inputCurrencyUsdPrice
      ? Number(tradeBestPath.amountIn.formatted) * inputCurrencyUsdPrice
      : undefined
  const outputAmountUsdPrice =
    tradeBestPath && outputCurrencyUsdPrice
      ? Number(tradeBestPath.amountOut.formatted) * outputCurrencyUsdPrice
      : undefined
  const priceImpactInUsd =
    inputAmountUsdPrice && outputAmountUsdPrice
      ? ((outputAmountUsdPrice - inputAmountUsdPrice) / inputAmountUsdPrice) *
        100
      : undefined

  // Slippage
  const {
    slippageSettings: { swapV2: lbSlippage, v1: normalSlippage }
  } = useSlippageSettings()
  const allowedSlippage = useMemo(() => {
    if (tradeBestPath) {
      // use low slippage if only v2 pools are used in the trade
      const isAllV2Pools = tradeBestPath.path.every(
        (path) => path.version !== PoolVersion.V1
      )
      if (isAllV2Pools) {
        return Math.trunc(lbSlippage * 100)
      }
    }
    return Math.trunc(normalSlippage * 100)
  }, [tradeBestPath, normalSlippage, lbSlippage])

  // Price impact
  const { isSafeModeEnabled } = useIsSafeModeEnabled()
  const priceImpact = priceImpactInUsd
    ? priceImpactInUsd >= 0
      ? 0
      : Math.abs(priceImpactInUsd)
    : tradeBestPath
      ? Number(tradeBestPath.priceImpact.toFixed(2))
      : undefined
  const isPriceImpactHigh = priceImpact ? priceImpact >= 5 : false

  // Input error
  const hasEnoughInputCurrency =
    inputBalance.data && tradeBestPath
      ? Number(inputBalance.data.formatted) >=
        Number(tradeBestPath.amountIn.formatted)
      : true

  // Approval
  const {
    approvalType,
    approveAsync,
    isApproved,
    isApproving,
    reset: resetApproval,
    setApprovalType
  } = useApproveSpenderIfNeeded({
    amount: tradeBestPath?.amountIn.value,
    spender: LB_ROUTER_V22_ADDRESS[chainId],
    token: inputCurrencyAddress,
    tokenSymbol: inputCurrency?.symbol
  })

  // Approve click handler
  const onApproveClick = approveAsync
    ? async () => {
        setIsRefreshQuoteEnabled(false)
        try {
          await approveAsync()
        } finally {
          setIsRefreshQuoteEnabled(true)
        }
      }
    : undefined

  // Refresh state on swap success
  const onSwapSuccess = useCallback(() => {
    resetApproval()
    setTypedValue('')
    setIsRefreshQuoteEnabled(true)
    debouncedRefetchInputBalance()
    debouncedRefetchOutputBalance()
  }, [
    debouncedRefetchInputBalance,
    debouncedRefetchOutputBalance,
    resetApproval
  ])

  // Reset approval on currency change
  useEffect(() => {
    resetApproval()
  }, [inputCurrency, resetApproval])

  // Swap
  const isSwapEnabled =
    hasEnoughInputCurrency &&
    !isFetchingBestPath &&
    (isApproved || (inputCurrency?.isNative ?? false))
  const {
    error: swapError,
    isSwapping,
    swapAsync
  } = useSwap({
    allowedSlippage,
    currencyIn: inputCurrency,
    currencyOut: outputCurrency,
    enabled: isSwapEnabled,
    isExactIn,
    onSwapSuccess,
    trade: tradeBestPath
  })

  // Swap click handler
  const onSwapClick = async () => {
    setIsRefreshQuoteEnabled(false)
    try {
      await swapAsync?.()
      tradeSwap(
        inputCurrency?.symbol,
        outputCurrency?.symbol,
        tradeBestPath?.path,
        routerType
      )
    } catch {
      setIsRefreshQuoteEnabled(true)
    }
  }

  // Wrap / Unwrap
  const {
    isLoading: isLoadingWrapUnwrap,
    state: wrapUnwrapState,
    write: wrapUnwrap
  } = useWrapUnwrapNativeCurrency({
    amount: typedTokenAmount?.toExact(),
    currency0: inputCurrency,
    currency1: outputCurrency,
    onSuccess: onSwapSuccess
  })

  // Update inputs and currencies when chain changes
  useEffect(() => {
    setInputCurrency(!!inputCurrencyAddr ? undefined : CNATIVE.onChain(chainId))
    setOutputCurrency(undefined)
    setTypedValue('')
  }, [chainId, inputCurrencyAddr])

  // Action handlers
  const onChangeSwapDirectionClick = useCallback(() => {
    const newInputCurrency = outputCurrency
    const newOutputCurrency = inputCurrency
    setIsExactIn((curr) => !curr)
    setInputCurrency(newInputCurrency)
    setOutputCurrency(newOutputCurrency)
  }, [inputCurrency, outputCurrency])

  return (
    <Flex
      mt={{ base: 6, md: 10 }}
      mb="200px"
      justify="center"
      minH="calc(100vh - 400px)"
    >
      <PageHelmet
        title={TRADE_HELMET_TITLE}
        description={TRADE_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <Grid
        gap={{ base: 4, lg: 8 }}
        w="full"
        maxW={{ '2xl': '1600px', base: '1300px' }}
        alignItems="flex-start"
        templateColumns={{
          base: 'minmax(0, 1fr)',
          lg: 'minmax(0, 7fr) minmax(0, 5fr)'
        }}
        px={{ base: 4, xl: 0 }}
      >
        {inputCurrency && outputCurrency ? (
          <Box order={{ base: 1, md: 0 }} mt={{ base: 4, md: 0 }}>
            <Box
              w="full"
              h="full"
              border="1px solid"
              borderColor="border"
              borderRadius={{ base: 'md', md: '2xl' }}
              overflow="hidden"
              bg="white"
              _dark={{ bg: '#151822' }}
              p={1}
            >
              <TradingViewChart
                inputCurrency={inputCurrency}
                outputCurrency={outputCurrency}
              />
            </Box>
          </Box>
        ) : (
          <Box />
        )}
        <Flex
          width="full"
          flexDir="column"
          gap={4}
          bg="bgCard"
          p={4}
          borderRadius={{ base: 'lg', md: '2xl' }}
        >
          <HStack>
            <Heading size="md">
              <Trans>Swap</Trans>
            </Heading>

            <Spacer />

            {!!tradeBestPath ? (
              <TradeRefetchView
                isRefreshQuoteEnabled={isRefreshQuoteEnabled}
                lastFetchTime={lastFetchTime}
                refetchInterval={refetchInterval}
                onRefetchClick={refetchTradeBestPath}
              />
            ) : null}
            <SlippageSettingsPicker
              type="swap"
              iconButtonProps={{
                'aria-label': 'open settings',
                bg: 'bgSecondary',
                border: 0,
                borderRadius: '10px',
                boxShadow: 'none',
                size: 'md'
              }}
            />
          </HStack>

          <TradeCurrencyInputs
            inputCurrencyProps={{
              amountUsd: inputAmountUsdPrice
                ? '~' +
                  formattedNum(inputAmountUsdPrice, {
                    allowDecimalsOver1000: true,
                    places: 2,
                    usd: true
                  })
                : undefined,
              balance: inputBalance.data?.formatted,
              currency: inputCurrency,
              currencyAddress: inputCurrencyAddress,
              heading: t`From`,
              isDisabled: !isExactIn && isFetchingBestPath,
              onCurrencyChange: (currency) => {
                const cAddress = currency.isToken ? currency.address : undefined
                const outputCurrencyAddress = outputCurrency?.isToken
                  ? outputCurrency.address
                  : undefined
                currency.symbol === outputCurrency?.symbol &&
                cAddress === outputCurrencyAddress
                  ? onChangeSwapDirectionClick()
                  : setInputCurrency(currency)
              },
              onValueChange: (value) => {
                setIsExactIn(true)
                setTypedValue(value)
              },
              rightElement: inputCurrency ? (
                <MaxButton
                  data-cy="trade-currency-input-max-button"
                  borderRadius="full"
                  bg="transparent"
                  fontSize="sm"
                  balance={inputBalance.data?.formatted}
                  onClick={() => {
                    setIsExactIn(true)
                    setTypedValue(inputBalance.data?.formatted ?? '')
                  }}
                />
              ) : (
                <TradeCurrencyShortcuts
                  otherSelectedCurrency={outputCurrency}
                  onCurrencyClick={setInputCurrency}
                />
              ),
              value: wrapUnwrapState
                ? typedValue
                : isExactIn
                  ? typedValue
                  : tradeBestPath?.amountIn.formatted ?? '',
              wrapRightElementIfNeeded: !inputCurrency
            }}
            outputCurrencyProps={{
              amountUsd:
                outputAmountUsdPrice && priceImpactInUsd
                  ? `~${formattedNum(outputAmountUsdPrice, {
                      allowDecimalsOver1000: true,
                      places: 2,
                      usd: true
                    })} (${priceImpactInUsd.toFixed(2)}%)`
                  : undefined,
              balance: outputBalance.data?.formatted,
              currency: outputCurrency,
              currencyAddress: outputCurrencyAddress,
              heading: t`To`,
              isDisabled: isExactIn && isFetchingBestPath,
              onCurrencyChange: (currency) => {
                const cAddress = currency.isToken ? currency.address : undefined
                const inputCurrencyAddress = inputCurrency?.isToken
                  ? inputCurrency.address
                  : undefined

                currency.symbol === inputCurrency?.symbol &&
                inputCurrencyAddress === cAddress
                  ? onChangeSwapDirectionClick()
                  : setOutputCurrency(currency)
              },
              onValueChange: (value) => {
                setIsExactIn(false)
                setTypedValue(value)
              },
              rightElement: !outputCurrency ? (
                <TradeCurrencyShortcuts
                  otherSelectedCurrency={inputCurrency}
                  onCurrencyClick={setOutputCurrency}
                />
              ) : undefined,
              value: wrapUnwrapState
                ? typedValue
                : isExactIn
                  ? tradeBestPath?.amountOut.formatted ?? ''
                  : typedValue,
              wrapRightElementIfNeeded: !outputCurrency
            }}
            onChangeSwapDirectionClick={onChangeSwapDirectionClick}
            isChangeSwapDirectionDisabled={isFetchingBestPath || isSwapping}
            bottomContent={
              <Box w="full">
                {tradeBestPath ? (
                  <TradeDetails
                    trade={tradeBestPath}
                    allowedSlippage={allowedSlippage}
                    isExactIn={isExactIn}
                  />
                ) : null}
                <Flex flexDir="column" pt={4} gap={4}>
                  {onApproveClick &&
                  !isApproved &&
                  hasEnoughInputCurrency &&
                  !isFetchingBestPath ? (
                    <ApproveTokenButton
                      data-cy="approve-button"
                      amount={tradeBestPath?.amountIn.formatted}
                      currencySymbol={inputCurrency?.symbol}
                      approvalType={approvalType}
                      onApprovalTypeSelect={setApprovalType}
                      isLoading={isApproving}
                      onClick={onApproveClick}
                    />
                  ) : null}
                  {wrapUnwrapState && !!wrapUnwrap ? (
                    <Web3Button
                      data-cy="wrap-unwrap-button"
                      variant="primary"
                      colorScheme="accent"
                      w="full"
                      size="xl"
                      isLoading={isLoadingWrapUnwrap}
                      loadingText={wrapUnwrapState}
                      onClick={() => wrapUnwrap?.()}
                    >
                      {wrapUnwrapState}
                    </Web3Button>
                  ) : (
                    <Web3Button
                      data-cy="swap-button"
                      variant="primary"
                      colorScheme={
                        (isPriceImpactHigh && isConnected) || swapError
                          ? 'red'
                          : 'accent'
                      }
                      w="full"
                      size="xl"
                      isLoading={isFetchingBestPath || isSwapping}
                      loadingText={
                        isFetchingBestPath
                          ? t`Fetching best trade`
                          : t`Waiting for confirmation`
                      }
                      isDisabled={
                        !typedTokenAmount ||
                        !isSwapEnabled ||
                        isLiquidityInsufficient ||
                        (isPriceImpactHigh && isSafeModeEnabled)
                      }
                      onClick={onSwapClick}
                    >
                      {isLiquidityInsufficient ? (
                        <Trans>Insufficient liquidity for this trade</Trans>
                      ) : hasEnoughInputCurrency ? (
                        isPriceImpactHigh ? (
                          isSafeModeEnabled ? (
                            <Trans>Safe Mode Activated</Trans>
                          ) : (
                            <Trans>Swap Anyway</Trans>
                          )
                        ) : swapError ? (
                          <Trans>Swap Anyway</Trans>
                        ) : (
                          <Trans>Swap</Trans>
                        )
                      ) : (
                        <Trans>Not enough {inputCurrency?.symbol}</Trans>
                      )}
                    </Web3Button>
                  )}

                  {swapError ? (
                    <CopyableError
                      textProps={{ mt: -2 }}
                      summary={swapError.summary}
                      error={swapError.message}
                    />
                  ) : null}

                  {isPriceImpactHigh && isSafeModeEnabled ? (
                    <Text
                      fontSize="sm"
                      textColor="textSecondary"
                      textAlign="center"
                      mt={-2}
                    >
                      <Trans>
                        Safe mode prevents high price impact trade. It can be
                        accessed in Settings.
                      </Trans>
                    </Text>
                  ) : null}
                </Flex>
              </Box>
            }
          />

          {outputCurrency && outputCurrency.isToken ? (
            <Center w="full">
              <AddToWalletButton token={outputCurrency} />
            </Center>
          ) : null}
        </Flex>
      </Grid>
    </Flex>
  )
}

export default Trade
