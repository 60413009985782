import { InputProps } from '@chakra-ui/react'
import CurrencyInput, { CurrencyInputProps } from 'components/CurrencyInput'
import React from 'react'

const TradeCurrencyInput = (props: CurrencyInputProps & InputProps) => (
  <CurrencyInput
    border={0}
    borderRadius="10px"
    p={0}
    _focus={{ border: 0, boxShadow: 'none' }}
    fontSize="22px"
    placeholder="0.0"
    _placeholder={{ color: 'textSecondary' }}
    currencyPickerButtonProps={{
      _dark: { _hover: { bg: 'hover' }, bg: 'bgPrimary' },
      bg: 'white',
      borderRadius: '10px',
      boxShadow: 'element',
      px: 4,
      size: 'lg'
    }}
    headingProps={{
      fontSize: 'sm',
      mb: '-20px',
      zIndex: 1
    }}
    {...props}
  />
)

export default TradeCurrencyInput
