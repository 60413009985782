import { JoePairABI, Pair } from '@traderjoe-xyz/sdk'
import { Token, TokenAmount } from '@traderjoe-xyz/sdk-core'
import useChainId from 'hooks/useChainId'
import { toSdkChainId } from 'utils/chains'
import { getAddress } from 'viem'
import { useReadContract } from 'wagmi'

interface UsePairProps {
  pairAddress?: string
  token0Info?: {
    address: string
    decimals: number
    symbol?: string
  }
  token1Info?: {
    address: string
    decimals: number
    symbol?: string
  }
}

const usePair = ({ pairAddress, token0Info, token1Info }: UsePairProps) => {
  const chainId = useChainId()

  const { data: reserves, ...result } = useReadContract({
    abi: JoePairABI,
    address: pairAddress ? getAddress(pairAddress) : undefined,
    chainId,
    functionName: 'getReserves',
    query: { enabled: !!pairAddress }
  })
  const reserve0 = reserves?.[0]
  const reserve1 = reserves?.[1]

  const token0 = token0Info
    ? new Token(
        toSdkChainId(chainId),
        getAddress(token0Info.address),
        token0Info.decimals,
        token0Info.symbol
      )
    : undefined
  const tokenAmount0 =
    reserve0 !== undefined && token0
      ? new TokenAmount(token0, reserve0.toString())
      : undefined

  const token1 = token1Info
    ? new Token(
        toSdkChainId(chainId),
        getAddress(token1Info.address),
        token1Info.decimals,
        token1Info.symbol
      )
    : undefined
  const tokenAmount1 =
    reserve1 !== undefined && token1
      ? new TokenAmount(token1, reserve1.toString())
      : undefined

  return {
    ...result,
    data:
      tokenAmount0 && tokenAmount1
        ? new Pair(tokenAmount0, tokenAmount1, toSdkChainId(chainId))
        : undefined
  }
}

export default usePair
