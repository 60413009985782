import { ChainId } from '@traderjoe-xyz/sdk-core'

import { MerchantMoeChainId } from './chains'

interface StrategyInfo {
  name: string
  vault: string
}
export const DISABLED_VAULTS: {
  [chainId in MerchantMoeChainId]: string[]
} = {
  [MerchantMoeChainId.FUJI]: [],
  [MerchantMoeChainId.MANTLE]: []
}

export const STRATEGIES_INFO: {
  [chainId in MerchantMoeChainId]: {
    [address: string]: StrategyInfo | undefined
  }
} = {
  [ChainId.FUJI]: {},
  [ChainId.MANTLE]: {}
}

// these auto-pools will use emergencyWithdraw instead of queueWithdrawal
export const CLOSED_AUTO_POOLS: {
  [chainId in MerchantMoeChainId]: string[]
} = {
  [MerchantMoeChainId.MANTLE]: [],
  [MerchantMoeChainId.FUJI]: []
}

export const HIDDEN_VAULTS: {
  [chainId in MerchantMoeChainId]: string[]
} = {
  [MerchantMoeChainId.FUJI]: [],
  [MerchantMoeChainId.MANTLE]: [
    '0xc8019b320e4a428d3060524a61f6cfab55828530'.toLowerCase()
  ]
}
