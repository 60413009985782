import { sliderAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  sliderAnatomy.keys
)

const baseStyle = {
  filledTrack: {
    background: 'bgHighlight'
  },
  thumb: {
    background: 'bgInteractive',
    // border: '4px solid',
    // borderColor: 'white',
    boxSize: 7,
    dropShadow: '0px 0px 16px -4px rgba(0, 0, 0, 0.32)'
  },
  track: {
    _dark: { background: 'bgSecondary' },
    background: 'accent.100',
    h: 4
  }
}

export const slidersTheme = defineMultiStyleConfig({
  baseStyle
})
