import { ChainId } from '@traderjoe-xyz/sdk-core'
import {
  avalancheFuji as wagmiAvalancheFuji,
  Chain,
  mantle as wagmiMantle
} from 'wagmi/chains'

const isTestnet = process.env.REACT_APP_IS_TESTNET === 'true'

interface JoeChain {
  slug: string
}
const mantle: Chain & JoeChain = {
  ...wagmiMantle,
  slug: 'mantle'
}

const fuji: Chain & JoeChain = {
  ...wagmiAvalancheFuji,
  slug: 'fuji'
}

export const chain = isTestnet ? fuji : mantle

export enum MerchantMoeChainId {
  MANTLE = ChainId.MANTLE,
  FUJI = ChainId.FUJI
}
