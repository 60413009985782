import {
  Center,
  HStack,
  SimpleGrid,
  Spinner,
  Tag,
  VStack
} from '@chakra-ui/react'
import PageContainer from 'components/PageContainer'
import PairNameHeader from 'components/PairNameHeader'
import PoolNavigationButtons from 'components/PoolNavigationButtons'
import useGetLensFarm from 'hooks/farms/useGetLensFarm'
import useGetUserVoteBribeData from 'hooks/farms/useGetUserVoteBribeData'
import debounce from 'lodash.debounce'
import React from 'react'
import { useParams } from 'react-router-dom'

import BribesPanel from './BribesPanel'
import VoteForFarmPanels from './VoteForFarmPanels'

const VoteDetail = () => {
  const { pid } = useParams()

  const {
    data: farm,
    isLoading: isLoadingFarm,
    refetch: refetchFarm
  } = useGetLensFarm({
    pid: pid ? BigInt(pid) : undefined
  })
  const debouncedRefetchFarm = debounce(() => refetchFarm(), 2000)

  const {
    data: userVoteBribeData,
    isLoading: isLoadingUserVoteBribeData,
    refetch: refetchUserVoteBribeData
  } = useGetUserVoteBribeData({ pid: farm?.pid })
  const debouncedRefetchUserVoteBribeData = debounce(
    () => refetchUserVoteBribeData(),
    2000
  )

  const binStep = Number(farm?.reserves.binStep)
  const tag = binStep === 0 ? 'v1' : `${binStep}bps`

  return (
    <PageContainer mb="100px">
      {isLoadingFarm ? (
        <Center minH="70vh">
          <Spinner />
        </Center>
      ) : farm ? (
        <>
          <VStack align="flex-start" spacing={0}>
            <PoolNavigationButtons
              token0={{
                address: farm.reserves.token0.token,
                symbol: farm.reserves.token0.symbol
              }}
              token1={{
                address: farm.reserves.token1.token,
                symbol: farm.reserves.token1.symbol
              }}
              votePid={Number(farm.pid)}
              mb={2}
              binStep={
                farm.reserves.binStep
                  ? Number(farm.reserves.binStep)
                  : undefined
              }
            />

            <HStack spacing={4}>
              <PairNameHeader
                token0={{
                  address: farm.reserves.token0.token,
                  symbol: farm.reserves.token0.symbol
                }}
                token1={{
                  address: farm.reserves.token1.token,
                  symbol: farm.reserves.token1.symbol
                }}
              />

              <Tag variant="outline">{tag}</Tag>
            </HStack>
          </VStack>

          <SimpleGrid
            columns={{ base: 1, md: 2 }}
            mt="50px"
            spacing={{ base: '50px', xl: '125px' }}
            alignItems="flex-start"
          >
            <VoteForFarmPanels
              farm={farm}
              isUserRequiredToEmergencyUnsetBribe={
                userVoteBribeData?.rewarder?.isEnded || false
              }
              onAddVoteSuccess={debouncedRefetchFarm}
              onRemoveVoteSuccess={debouncedRefetchFarm}
            />
            <BribesPanel
              farm={farm}
              userBribeContractAddress={userVoteBribeData?.bribeContractAddress}
              userBribePendingReward={
                userVoteBribeData?.userPendingAmount?.formatted
              }
              onUserBribeChanged={debouncedRefetchUserVoteBribeData}
              isLoadingUserBribe={isLoadingUserVoteBribeData}
            />
          </SimpleGrid>
        </>
      ) : null}
    </PageContainer>
  )
}

export default VoteDetail
