import { Box, Grid, Heading, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'

const VoteRewardsSteps = () => {
  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr',
        xl: '1fr 1fr 1.2fr'
      }}
      gap={4}
      pt={{ base: 8, md: '50px' }}
    >
      <Box borderRadius="10px" boxShadow="element" bg="bgCard" px={4} py={3}>
        <Text textColor="textSecondary" fontSize="sm">
          <Trans>Step 1</Trans>
        </Text>
        <Heading size="md" mt="10px">
          <Trans>Add Rewards</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>
            Deposit token rewards to attract users to vote for your pool.
          </Trans>
        </Text>
      </Box>
      <Box borderRadius="10px" boxShadow="element" bg="bgCard" px={4} py={3}>
        <Text textColor="textSecondary" fontSize="sm">
          <Trans>Step 2</Trans>
        </Text>
        <Heading size="md" mt="10px">
          <Trans>Get Votes</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>
            veMOE holders will vote for your pool to earn yield from your
            supplied rewards.
          </Trans>
        </Text>
      </Box>
      <Box borderRadius="10px" boxShadow="element" bg="bgCard" px={4} py={3}>
        <Text textColor="textSecondary" fontSize="sm">
          <Trans>Step 3</Trans>
        </Text>
        <Heading size="md" mt="10px">
          <Trans>Unlock Farm</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          Farms are periodically updated. Pools with the top liquidity and votes
          will be whitelisted to receive MOE farms.
        </Text>
      </Box>
    </Grid>
  )
}

export default VoteRewardsSteps
