import { Box, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import MoeJourneyBanner from 'assets/moeJourney/moe-journey-banner.jpg'
import PageContainer from 'components/PageContainer'
import useMoeJourneyPoints from 'hooks/moeJourney/useMoeJourneyPoints'
import React from 'react'

import MJPMultiplierView from './MJPMultiplierView'
import MJTicketsProgress from './MJTicketsProgress'
import MJTicketsView from './MJTicketsView'
import MoeJourneyCards from './MoeJourneyCards'
import MoeJourneyCountdown from './MoeJourneyCountdown'
import MoeJourneyRewardReveal from './MoeJourneyRewardReveal'
import MoeJourneyRewardStats from './MoeJourneyRewardStats'
import MoeJourneyVeMoeTierProgress from './MoeJourneyVeMoeTierProgress'

const MoeJourney = () => {
  const {
    userExtraTickets,
    userNetMoeStaked,
    userTotalTickets,
    userVeMoeBalance
  } = useMoeJourneyPoints()

  return (
    <PageContainer mb="100px">
      <Box
        pos="relative"
        borderRadius="2xl"
        h={{ base: '200px', md: '400px' }}
        w="full"
        background={`linear-gradient(0deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 100%), url(${MoeJourneyBanner})`}
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        <VStack
          alignSelf="bottom"
          pos="absolute"
          left={0}
          right={0}
          bottom={{ base: 4, md: 8 }}
          margin="auto"
        >
          <Heading
            size={{ base: 'xl', md: '3xl' }}
            textColor="white"
            fontWeight="bold"
          >
            <Trans>Moe&apos;s Journey SZN4</Trans>
          </Heading>
          <Heading
            size={{ base: 'md', md: 'xl' }}
            textColor="white"
            fontWeight="bold"
          >
            <Trans>Hot Moe Summer</Trans>
          </Heading>
          <MoeJourneyCountdown />
        </VStack>
      </Box>

      <VStack align="flex-start" mt={{ base: '40px', md: '60px' }} spacing={4}>
        <VStack align="flex-start" spacing={1}>
          <Heading size="lg">
            <Trans>Get started</Trans>
          </Heading>
          <Text fontSize="sm">
            <Trans>
              Join Moe&#39;s Journey and start earning Moe Journey Tickets to
              earn chances to win a draw for bonus MNT rewards.
            </Trans>
          </Text>
        </VStack>
        <MoeJourneyCards
          hasDiscordRole={userExtraTickets?.hasDiscordRole || false}
        />
      </VStack>

      <VStack align="flex-start" mt={{ base: '40px', md: '60px' }} spacing={4}>
        <VStack align="flex-start" spacing={1}>
          <Heading size="lg">
            <Trans>Track progress</Trans>
          </Heading>
          <Text fontSize="sm">
            <Trans>
              Collect MJT by staking MOE, and boost your tickets by unlocking
              bonuses. Track your staking tier and bonuses for the current epoch
              below. MJT bonuses will reset at the start of each two-week epoch.
              Swap loyally on Moe and keep $MOE staked to qualify.
            </Trans>{' '}
            <Box
              as="span"
              textColor="accent.500"
              textDecor="underline"
              cursor="pointer"
              onClick={() =>
                window.open(
                  'https://merchantmoe.substack.com/p/moes-journey-szn4-hot-moe-summer',
                  '_blank'
                )
              }
            >
              Read more.
            </Box>
          </Text>
        </VStack>

        <Box w="full" mt={2}>
          <SimpleGrid columns={{ base: 1, lg: 2 }} gap={4}>
            <MoeJourneyVeMoeTierProgress
              userVeMoeBalance={Number(userVeMoeBalance?.formatted || '0')}
            />
            <MJTicketsView
              userTotalTickets={userTotalTickets}
              isLoyal={userExtraTickets ? userExtraTickets.isLoyal : true}
              hasDiscordRole={
                userExtraTickets ? userExtraTickets.hasDiscordRole : true
              }
            />
          </SimpleGrid>

          <SimpleGrid
            mt={4}
            columns={{ base: 1, md: 2 }}
            gap={4}
            alignItems="flex-start"
          >
            <MJPMultiplierView
              title="SZN3 Participant Bonus"
              subtitle={`${userExtraTickets?.szn3BonusMjt || 0} MJT`}
              description="Collect a recurring MJT bonus if your wallet participated in SZN3 of Moe’s Journey."
            />
            <MJPMultiplierView
              title="Community Participant Bonus"
              subtitle={`${userExtraTickets?.communityBonusMjt || 0} MJT`}
              description="Collect various amounts of MJT by completing quests, attending community calls, and other community activities."
            />
            <MJPMultiplierView
              title="Increase MOE Stake Bonus"
              subtitle="1 MJT per 25 MOE staked after epoch begins"
              description="Stake additional MOE during an epoch to collect bonus MJT. The more you increase your stake, the more tickets you collect. To qualify for this bonus, you MUST have reached veMOE Staking Tier 1 BEFORE the current epoch begins. "
              bottomElement={<MJTicketsProgress amount={userNetMoeStaked} />}
            />
            <MJPMultiplierView
              title="Methamorphosis MDMA Bonus"
              subtitle="1 MJT per 25 MOE claimed"
              description="Provide liquidity to Methamorphosis LB Pools and claim MOE rewards via MDMA. The more MOE you claim, the more bonus MJT you collect."
              bottomElement={
                <MJTicketsProgress
                  amount={Number(userExtraTickets?.moeClaimed || 0)}
                />
              }
            />
          </SimpleGrid>
        </Box>
      </VStack>

      <VStack align="flex-start" mt={{ base: '40px', md: '60px' }} spacing={4}>
        <VStack align="flex-start" spacing={1}>
          <Heading size="lg">
            <Trans>Rewards</Trans>
          </Heading>
          <Text fontSize="sm">
            Reveal the results of the MJT Rewards Draw and claim any MNT
            winnings.
          </Text>
        </VStack>

        <SimpleGrid columns={{ base: 1, md: 2 }} gap={4} w="full">
          <MoeJourneyRewardReveal />
          <MoeJourneyRewardStats />
        </SimpleGrid>
      </VStack>
    </PageContainer>
  )
}

export default MoeJourney
