import { Box, Center, Grid, Spinner } from '@chakra-ui/react'
import { WNATIVE } from '@traderjoe-xyz/sdk-core'
import PageHelmet from 'components/PageHelmet'
import { POOL_HELMET_DESCRIPTION, POOL_HELMET_TITLE } from 'constants/pool'
import usePairUserBalance from 'hooks/pool/v1/usePairUserBalance'
import usePoolV1LiquidityPanel from 'hooks/pool/v1/usePoolV1LiquidityPanel'
import useChainId from 'hooks/useChainId'
import debounce from 'lodash.debounce'
import React from 'react'
import { useLocation, useParams } from 'react-router-dom'

import AddRemoveLiquidityPanel from './AddRemoveLiquidityPanel'
import PoolDetailBalancesPanelV1 from './PoolDetailBalancesPanelV1'
import PoolDetailHeader from './PoolDetailHeader'
import PoolDetailOverview from './PoolDetailOverview'

const PoolDetail = () => {
  const { token0Address, token1Address } = useParams()
  const location = useLocation()
  const chainId = useChainId()

  const {
    isLoading,
    onToken0Select,
    onToken1Select,
    pair,
    pool,
    token0,
    token1
  } = usePoolV1LiquidityPanel({
    token0Address,
    token1Address
  })

  const {
    data: userBalanceData,
    isLoading: isLoadingUserBalance,
    refetchUserBalance
  } = usePairUserBalance({
    address: pool?.pairAddress,
    pair
  })
  const debouncedRefetchUserBalance = debounce(() => refetchUserBalance(), 4000)

  if (isLoading || !pool) {
    return (
      <Center minH="100vh">
        <Spinner />
      </Center>
    )
  }

  return (
    <Box pt={4}>
      <PageHelmet
        title={POOL_HELMET_TITLE}
        description={POOL_HELMET_DESCRIPTION}
        url={location.pathname}
      />
      <PoolDetailHeader
        address={pool?.pairAddress}
        token0={{
          address: token0?.isToken ? token0?.address : WNATIVE[chainId].address,
          symbol: token0?.symbol
        }}
        token1={{
          address: token1?.isToken ? token1?.address : WNATIVE[chainId].address,
          symbol: token1?.symbol
        }}
        tag="Classic AMM"
        slippageSettingsPickerType="poolV1"
        px={{ base: 4, md: 0 }}
        h={{ base: '180px', md: '110px' }}
        settingsBottom={4}
        farmPid={pool.farmPid}
        points={pool.points}
      />
      {pool && pair && token0 && token1 ? (
        <Box bg="bgPrimary" minH="calc(100vh - 250px)" pb={24}>
          <Grid
            maxW={{ '2xl': '1600px', base: '1300px' }}
            margin="0 auto"
            alignItems="flex-start"
            templateColumns={{
              base: 'minmax(0, 1fr)',
              lg: 'minmax(0, 1fr) 600px'
            }}
            pt={4}
            px={{ base: 4, md: 0 }}
            gap={8}
          >
            <PoolDetailOverview
              pool={pool}
              reserveX={pair.reserve0.toFixed()}
              reserveY={pair.reserve1.toFixed()}
            />
            <Center flexDir="column" gap={4}>
              {userBalanceData ? (
                <Box
                  w="full"
                  bg="bgCard"
                  borderRadius="2xl"
                  boxShadow="element"
                >
                  <AddRemoveLiquidityPanel
                    pool={pool}
                    pair={pair}
                    token0={token0}
                    token1={token1}
                    onToken0Select={onToken0Select}
                    onToken1Select={onToken1Select}
                    userBalanceData={userBalanceData}
                    onAddLiquiditySuccess={debouncedRefetchUserBalance}
                    onRemoveLiquiditySuccess={debouncedRefetchUserBalance}
                  />
                </Box>
              ) : null}
              <Box w="full" bg="bgCard" borderRadius="2xl" boxShadow="element">
                {userBalanceData ? (
                  <PoolDetailBalancesPanelV1
                    userBalanceData={userBalanceData}
                    isLoadingUserBalance={isLoadingUserBalance}
                  />
                ) : null}
              </Box>
            </Center>
          </Grid>
        </Box>
      ) : null}
    </Box>
  )
}

export default PoolDetail
