import { t } from '@lingui/macro'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { Hex, parseAbi } from 'viem'
import { useAccount, useSimulateContract, useWriteContract } from 'wagmi'

interface UseClaimLbRewardsProps {
  userBinIds: number[]
  enabled?: boolean
  hookRewarderAddress?: Hex
  onSuccess?: () => void
}

const useClaimLbRewards = ({
  enabled = true,
  hookRewarderAddress,
  onSuccess,
  userBinIds
}: UseClaimLbRewardsProps) => {
  const chainId = useChainId()
  const { address: account, chain: walletChain } = useAccount()
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const { data: config } = useSimulateContract({
    abi: parseAbi([
      'function claim(address user, uint256[] calldata ids) external'
    ]),
    address: hookRewarderAddress,
    args: account ? [account, userBinIds.map((id) => BigInt(id))] : undefined,
    chainId,
    functionName: 'claim',
    query: {
      enabled: enabled && walletChain?.id === chainId && !!account,
      gcTime: 0
    },
    value: BigInt(0) as any // workaround for safe app
  })

  const {
    data: hash,
    isPending,
    writeContract
  } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary = t`Claimed rewards`
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({ description: transactionSummary, hash })
      }
    }
  })

  const claim = config?.request
    ? () => writeContract(config.request)
    : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash,
      onTransactionSuccess: onSuccess
    })

  return {
    claim,
    isLoading: isWaitingForTransaction || isPending,
    isSuccess
  }
}

export default useClaimLbRewards
