import { ExternalLinkIcon } from '@chakra-ui/icons'
import { Flex, HStack, Link, SimpleGrid, Text } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import PairNameHeader from 'components/PairNameHeader'
import { format } from 'date-fns'
import useActiveChain from 'hooks/useActiveChain'
import React from 'react'
import { Farm } from 'types/dexlens'
import { shortenAddress } from 'utils/addresses'
import { formattedNum } from 'utils/format'
import { formatUnits, Hex } from 'viem'

interface RewarderInfoPanelProps {
  farm: Farm
  rewardToken: Currency
  isStopped?: boolean
  owner?: Hex
  rewarderParameters?: {
    endTimestamp: bigint | undefined
    lastUpdateTimestamp: bigint | undefined
    rewardPerSecond: bigint | undefined
  }
  rewarderTokenBalance?: string
}

const RewarderInfoPanel = ({
  farm,
  isStopped,
  owner,
  rewarderParameters,
  rewarderTokenBalance,
  rewardToken
}: RewarderInfoPanelProps) => {
  const { blockExplorers } = useActiveChain()

  const startDate =
    rewarderParameters && rewarderParameters.lastUpdateTimestamp
      ? new Date(Number(rewarderParameters.lastUpdateTimestamp) * 1000)
      : undefined

  const endDate =
    rewarderParameters && rewarderParameters.endTimestamp
      ? new Date(Number(rewarderParameters.endTimestamp) * 1000)
      : undefined

  const isStarted = startDate && startDate.getTime() < Date.now()
  const isEnded = endDate && endDate.getTime() < Date.now()

  const rewardPerSecond =
    rewarderParameters && rewarderParameters.rewardPerSecond
      ? Number(
          formatUnits(rewarderParameters.rewardPerSecond, rewardToken.decimals)
        )
      : 0

  return (
    <Flex
      bg="bgCard"
      border="1px solid"
      borderColor="border"
      w="full"
      flexDir="column"
      gap={8}
      p={{ base: 4, md: 8 }}
      borderRadius="2xl"
      boxShadow="element"
    >
      <PairNameHeader
        boxSize={5}
        size="md"
        token0={{
          address: farm.reserves.token0.token,
          symbol: farm.reserves.token0.symbol
        }}
        token1={{
          address: farm.reserves.token1.token,
          symbol: farm.reserves.token1.symbol
        }}
      />

      <SimpleGrid columns={2} spacing={2}>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Status:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          {isStopped ? (
            <Trans>Stopped</Trans>
          ) : isEnded ? (
            <Trans>Ended</Trans>
          ) : rewarderParameters ? (
            <Trans>Active</Trans>
          ) : (
            <Trans>Not configured</Trans>
          )}
        </Text>

        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Reward token:</Trans>
        </Text>
        <HStack spacing={1}>
          <CurrencyLogo symbol={rewardToken.symbol} boxSize={5} />
          <Text fontWeight="semibold" fontSize="sm">
            {rewardToken.symbol}
          </Text>
        </HStack>

        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Token amount in rewarder:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          {rewarderTokenBalance ? formattedNum(rewarderTokenBalance) : '--'}{' '}
          {rewardToken?.symbol}
        </Text>

        {!isStarted ? (
          <Text fontSize="sm" textColor="textSecondary">
            <Trans>Start date:</Trans>
          </Text>
        ) : null}
        {!isStarted ? (
          <Text fontWeight="semibold" fontSize="sm">
            {startDate ? format(startDate, 'Pppp') : '--'}
          </Text>
        ) : null}

        <Text fontSize="sm" textColor="textSecondary">
          <Trans>End date:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          {endDate ? format(endDate, 'Pppp') : '--'}
        </Text>

        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Emission:</Trans>
        </Text>
        <Text fontWeight="semibold" fontSize="sm">
          <Trans>
            {formattedNum(rewardPerSecond * 60 * 60 * 24)} {rewardToken.symbol}{' '}
            / day
          </Trans>
        </Text>

        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Owner:</Trans>
        </Text>
        <Link
          isExternal
          href={`${blockExplorers?.default.url}/address/${owner}`}
          w="fit-content"
        >
          <HStack>
            <Text fontWeight="semibold" fontSize="sm">
              {shortenAddress(owner)}
            </Text>
            <ExternalLinkIcon />
          </HStack>
        </Link>
      </SimpleGrid>
    </Flex>
  )
}

export default RewarderInfoPanel
