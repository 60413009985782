import {
  Box,
  Button,
  ButtonProps,
  Center,
  Heading,
  Spinner,
  Text
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import LBPairDistributionChart from 'components/LBPairDistributionChart'
import React from 'react'
import { LBPairDistribution } from 'types/poolV2'

interface LBPairDistributionSectionProps {
  data: LBPairDistribution[]
  isLoading: boolean
  title: string
  activeBinId?: number
  currency0?: Currency
  currency1?: Currency
  headingActionButtonProps?: ButtonProps
  onHeadingActionButtonClick?: () => void
}

const LBPairDistributionSection = ({
  activeBinId,
  currency0,
  currency1,
  data,
  headingActionButtonProps,
  isLoading,
  onHeadingActionButtonClick,
  title
}: LBPairDistributionSectionProps) => {
  if (!activeBinId) {
    return null
  }

  return data.length === 0 || isLoading ? (
    <Box w="full">
      <Heading size="md" mb={2}>
        {title}
      </Heading>
      <Center h="216px">
        {isLoading ? (
          <Spinner />
        ) : (
          <Text fontSize="sm" color="textSecondary">
            <Trans>There is no liquidity deployed to the pool</Trans>
          </Text>
        )}
      </Center>
    </Box>
  ) : (
    <LBPairDistributionChart
      title={title}
      data={data}
      currency0={currency0}
      currency1={currency1}
      headingRightElement={
        headingActionButtonProps ? (
          <Button
            {...headingActionButtonProps}
            data-cy="lb-pair-distribution-heading-action-button"
            onClick={() => onHeadingActionButtonClick?.()}
          />
        ) : undefined
      }
    />
  )
}

export default LBPairDistributionSection
