import StakeTokenPanel from 'components/StakeTokenPanel'
import { MOE_STAKING } from 'constants/moe'
import { MOE_TOKEN } from 'constants/tokens'
import useChainId from 'hooks/useChainId'
import React from 'react'

interface StakeMoePanelProps {
  onStakeSuccess?: () => void
}

const StakeMoePanel = ({ onStakeSuccess }: StakeMoePanelProps) => {
  const chainId = useChainId()
  const moeToken = MOE_TOKEN[chainId]

  return (
    <StakeTokenPanel
      token={moeToken}
      stakingContractAddress={MOE_STAKING[chainId]}
      onStakeSuccess={onStakeSuccess}
    />
  )
}

export default StakeMoePanel
