import { InfoOutlineIcon } from '@chakra-ui/icons'
import {
  Box,
  Heading,
  HStack,
  SimpleGrid,
  Skeleton,
  Text,
  VStack
} from '@chakra-ui/react'
import { t } from '@lingui/macro'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import useVeMoeUserBalance from 'hooks/farms/useVeMoeUserBalance'
import React from 'react'
import { formattedNum } from 'utils/format'
import { useAccount } from 'wagmi'

interface VeMoeBalancePanelProps {
  moeDepositedAmount?: bigint
}

const VeMoeBalancePanel = ({ moeDepositedAmount }: VeMoeBalancePanelProps) => {
  const { isConnected } = useAccount()
  const { data, isLoading } = useVeMoeUserBalance({ moeDepositedAmount })

  if (!isConnected) {
    return null
  }

  return (
    <Box
      w="full"
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
    >
      <VStack align="flex-start" spacing={1}>
        <Heading size="md">veMOE Balance</Heading>
        <Text fontSize="sm">Vote for farms to increase reward emissions</Text>
      </VStack>

      <SimpleGrid columns={3} pt={{ base: 4, md: 6 }}>
        <VStack spacing={0} align="flex-start">
          <Text fontSize="sm" textColor="textSecondary">
            Balance
          </Text>
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold">
              {data?.balance !== undefined
                ? formattedNum(data.balance.formatted)
                : '--'}{' '}
              veMOE
            </Text>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <TouchFriendlyTooltip
            label={
              data?.maxVeMoePerMoe
                ? t`You can't have more than ${formattedNum(
                    data.maxVeMoePerMoe.formatted
                  )} veMOE per MOE`
                : undefined
            }
            isDisabled={!data?.maxVeMoePerMoe}
          >
            <HStack spacing={1}>
              <Text fontSize="sm" textColor="textSecondary">
                Max Balance
              </Text>
              <InfoOutlineIcon color="textSecondary" boxSize={3} />
            </HStack>
          </TouchFriendlyTooltip>
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold">
              {data?.maxTotalVeMoe !== undefined
                ? formattedNum(data.maxTotalVeMoe.formatted, { places: 2 })
                : '--'}{' '}
              veMOE
            </Text>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text fontSize="sm" textColor="textSecondary">
            Total Emission
          </Text>
          <Skeleton isLoaded={!isLoading}>
            <Text fontWeight="semibold">
              {data?.veMoePerDay !== undefined
                ? formattedNum(data.veMoePerDay.formatted, { places: 2 })
                : '--'}{' '}
              veMOE / day
            </Text>
          </Skeleton>
        </VStack>
      </SimpleGrid>
    </Box>
  )
}

export default VeMoeBalancePanel
