import { Flex, Heading } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import Web3Button from 'components/Web3Button'
import useStopRewarder from 'hooks/rewarder/useStopRewarder'
import React from 'react'

interface StopRewarderPanelProps {
  isOwner: boolean
  rewarderAddress: string
}

const StopRewarderPanel = ({
  isOwner,
  rewarderAddress
}: StopRewarderPanelProps) => {
  const { isLoading, stopRewarder } = useStopRewarder({
    enabled: isOwner,
    rewarderAddress
  })

  return (
    <Flex
      bg="bgCard"
      border="1px solid"
      borderColor="border"
      w="full"
      flexDir="column"
      gap={8}
      p={{ base: 4, md: 8 }}
      borderRadius="2xl"
      boxShadow="element"
    >
      <Heading size="md">
        <Trans>Stop Rewarder</Trans>
      </Heading>

      <Web3Button
        variant="primary"
        colorScheme="red"
        size="xl"
        w="full"
        isLoading={isLoading}
        onClick={stopRewarder}
        loadingText={t`Stopping rewarder`}
        isDisabled={!stopRewarder}
      >
        {isOwner ? (
          <Trans>Stop rewarder</Trans>
        ) : (
          <Trans>You are not the contract owner</Trans>
        )}
      </Web3Button>
    </Flex>
  )
}

export default StopRewarderPanel
