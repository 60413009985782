import { useQuery } from '@tanstack/react-query'
import { TokenInfo } from 'types/tokensList'

const useFetchTokensList = (
  { enabled }: { enabled: boolean } = { enabled: true }
) => {
  const traderJoeListUrl =
    'https://raw.githubusercontent.com/merchant-moe/moe-tokenlists/main/tokens.json'

  return useQuery<{ tokens: TokenInfo[] }, Error>({
    enabled,
    gcTime: 10 * 60 * 1000, // 10 mins
    queryFn: () => fetch(traderJoeListUrl).then((res) => res.json()),
    queryKey: ['TraderJoeTokensListV2Query']
  })
}

export default useFetchTokensList
