import { useCallback } from 'react'
import { useAppDispatch, useAppSelector } from 'state/hooks'

import {
  setAreAirdropTermsAccepted,
  setAreTermsAccepted,
  setIsAirdropCitizenDisclaimerAccepted,
  setIsDedicatedRPC,
  setIsRemoveLiquidityInNativeCurrencyEnabled,
  setIsSafeModeEnabled,
  setIsUKDisclaimerHidden,
  setOriginCountryCode,
  setSlippage,
  setTransactionDeadline
} from './actions'
import { initialSlippageSettings, SlippageType } from './reducer'

const useSlippageSettings = () => {
  const dispatch = useAppDispatch()
  const slippageSettings = useAppSelector(
    (state) => state.settings.slippageSettings
  )
  const updateSlippage = useCallback(
    (type: SlippageType, value: string) => {
      dispatch(
        setSlippage({
          type,
          value: value
            ? Math.trunc(parseFloat(value) * 100) / 100
            : initialSlippageSettings[type]
        })
      )
    },
    [dispatch]
  )
  return { slippageSettings, updateSlippage }
}

const useUserTransactionDeadline = () => {
  const userTransactionDeadline = useAppSelector(
    (state) => state.settings.transactionDeadline
  )

  const dispatch = useAppDispatch()
  const setUserTransactionDeadline = useCallback(
    (value: string) => {
      dispatch(setTransactionDeadline(value ? parseFloat(value) : undefined))
    },
    [dispatch]
  )

  return { setUserTransactionDeadline, userTransactionDeadline }
}

const useIsSafeModeEnabled = () => {
  const dispatch = useAppDispatch()
  const isSafeModeEnabled = useAppSelector(
    (state) => state.settings.isSafeModeEnabled
  )
  const updateIsSafeModeEnabled = useCallback(
    (isEnabled: boolean) => {
      dispatch(setIsSafeModeEnabled(isEnabled))
    },
    [dispatch]
  )
  return { isSafeModeEnabled, updateIsSafeModeEnabled }
}

const useIsDedicatedRPC = () => {
  const dispatch = useAppDispatch()
  const isDedicatedRPCSelected = useAppSelector(
    (state) => state.settings.isDedicatedRPC
  )
  const updateDedicatedRPC = useCallback(
    (isEnabled: boolean) => {
      dispatch(setIsDedicatedRPC(isEnabled))
    },
    [dispatch]
  )
  return { isDedicatedRPCSelected, updateDedicatedRPC }
}

const useAreTermsAccepted = () => {
  const dispatch = useAppDispatch()
  const areTermsAccepted = useAppSelector(
    (state) => state.settings.areTermsAccepted
  )
  const updateAreTermsAccepted = useCallback(
    (areAccepted: boolean) => {
      dispatch(setAreTermsAccepted(areAccepted))
    },
    [dispatch]
  )
  return { areTermsAccepted, updateAreTermsAccepted }
}

const useAreAirdropTermsAccepted = () => {
  const dispatch = useAppDispatch()
  const areAirdropTermsAccepted = useAppSelector(
    (state) => state.settings.areAirdropTermsAccepted
  )
  const updateAreAirdropTermsAccepted = useCallback(
    (areAccepted: boolean) => {
      dispatch(setAreAirdropTermsAccepted(areAccepted))
    },
    [dispatch]
  )
  return { areAirdropTermsAccepted, updateAreAirdropTermsAccepted }
}

const useIsAirdropCitizenDisclaimerAccepted = () => {
  const dispatch = useAppDispatch()
  const isAirdropCitizenDisclaimerAccepted = useAppSelector(
    (state) => state.settings.isAirdropCitizenDisclaimerAccepted
  )
  const updateIsAirdropCitizenDisclaimerAccepted = useCallback(
    (isAccepted: boolean) => {
      dispatch(setIsAirdropCitizenDisclaimerAccepted(isAccepted))
    },
    [dispatch]
  )
  return {
    isAirdropCitizenDisclaimerAccepted,
    updateIsAirdropCitizenDisclaimerAccepted
  }
}

const useIsRemoveLiquidityInNativeCurrencyEnabled = () => {
  const dispatch = useAppDispatch()
  const isRemoveLiquidityInNativeCurrencyEnabled = useAppSelector(
    (state) => state.settings.isRemoveLiquidityInNativeCurrencyEnabled
  )
  const updateIsRemoveLiquidityInNativeCurrencyEnabled = useCallback(
    (isEnabled: boolean) => {
      dispatch(setIsRemoveLiquidityInNativeCurrencyEnabled(isEnabled))
    },
    [dispatch]
  )
  return {
    isRemoveLiquidityInNativeCurrencyEnabled,
    updateIsRemoveLiquidityInNativeCurrencyEnabled
  }
}

const useOriginCountry = () => {
  const dispatch = useAppDispatch()
  const originCountryCode = useAppSelector(
    (state) => state.settings.originCountryCode
  )
  const updateOriginCountryCode = useCallback(
    (originCountryCode: string) => {
      dispatch(setOriginCountryCode(originCountryCode))
    },
    [dispatch]
  )
  return { originCountryCode, updateOriginCountryCode }
}

const useSetUKDisclaimerHidden = () => {
  const dispatch = useAppDispatch()
  const isUKDisclaimerHidden = useAppSelector(
    (state) => state.settings.isUKDisclaimerHidden
  )
  const setUKDisclaimerHidden = useCallback(
    (isHidden: boolean) => {
      dispatch(setIsUKDisclaimerHidden(isHidden))
    },
    [dispatch]
  )
  return { isUKDisclaimerHidden, setUKDisclaimerHidden }
}

export {
  useAreAirdropTermsAccepted,
  useAreTermsAccepted,
  useIsAirdropCitizenDisclaimerAccepted,
  useIsDedicatedRPC,
  useIsRemoveLiquidityInNativeCurrencyEnabled,
  useIsSafeModeEnabled,
  useOriginCountry,
  useSetUKDisclaimerHidden,
  useSlippageSettings,
  useUserTransactionDeadline
}
