import { RepeatIcon } from '@chakra-ui/icons'
import { Box, CircularProgress, IconButton } from '@chakra-ui/react'
import { t } from '@lingui/macro'
import TouchFriendlyTooltip from 'components/TouchFriendlyTooltip'
import React, { useEffect, useState } from 'react'

interface TradeRefetchViewProps {
  isRefreshQuoteEnabled: boolean
  lastFetchTime: number
  onRefetchClick: () => void
  refetchInterval: number
}

const TradeRefetchView = ({
  isRefreshQuoteEnabled,
  lastFetchTime,
  onRefetchClick,
  refetchInterval
}: TradeRefetchViewProps) => {
  const [progress, setProgress] = useState(100)

  useEffect(() => {
    if (!isRefreshQuoteEnabled) return

    const intervalId = setInterval(() => {
      const currentTime = Date.now()
      const elapsedTime = currentTime - lastFetchTime
      const remainingTime = refetchInterval - elapsedTime
      const newProgress = (remainingTime / refetchInterval) * 100

      setProgress(newProgress > 0 ? newProgress : 0)
    }, 100)

    return () => clearInterval(intervalId)
  }, [lastFetchTime, isRefreshQuoteEnabled, refetchInterval])

  return (
    <Box
      position="relative"
      display="inline-block"
      w="fit-content"
      opacity={isRefreshQuoteEnabled ? 1 : 0.5}
    >
      <CircularProgress
        value={progress}
        max={100}
        color="accent.500"
        thickness="2px"
        size="48px"
        trackColor="border"
      />
      <TouchFriendlyTooltip
        label={t`Quote will auto-refresh every 30 seconds.`}
      >
        <IconButton
          isDisabled={!isRefreshQuoteEnabled}
          borderRadius="full"
          bg="bgSecondary"
          variant="ghost"
          aria-label="Refresh"
          icon={<RepeatIcon boxSize={5} />}
          _hover={{ bg: 'hover' }}
          onClick={onRefetchClick}
          position="absolute"
          margin="auto"
          left={0}
          right={0}
          top={0}
          bottom={0}
          boxSize={10}
          size="sm"
        />
      </TouchFriendlyTooltip>
    </Box>
  )
}

export default TradeRefetchView
