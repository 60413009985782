import {
  Flex,
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import PageContainer from 'components/PageContainer'
import React, { useState } from 'react'

import FarmPendingRewards from './FarmPendingRewards'
import FarmsTable from './FarmsTable'

export enum FarmsTab {
  ALL,
  MY_FARMS
}

const Farms = () => {
  const [tab, setTab] = useState<FarmsTab>(FarmsTab.ALL)

  return (
    <PageContainer mb="100px">
      <VStack align="flex-start">
        <Heading>
          <Trans>Farms</Trans>
        </Heading>
        <Text fontSize="sm" textColor="textSecondary">
          <Trans>Stake your LP pool tokens and earn MOE rewards</Trans>
        </Text>
      </VStack>

      <Tabs
        variant="solid-rounded"
        mt={{ base: 8, md: 10 }}
        index={tab}
        onChange={(index) => setTab(index)}
      >
        <Flex
          flexDir={{ base: 'column-reverse', md: 'row' }}
          justify="space-between"
          align={{ base: 'flex-start', md: 'flex-end' }}
          gap={4}
        >
          <TabList bg="bgCard" boxShadow="element" w="fit-content">
            <Tab>
              <Trans>All Farms</Trans>
            </Tab>
            <Tab>
              <Trans>My Farms</Trans>
            </Tab>
          </TabList>

          <FarmPendingRewards />
        </Flex>

        <TabPanels>
          <TabPanel p={0}>
            <FarmsTable tab="all" />
          </TabPanel>
          <TabPanel p={0}>
            <FarmsTable tab="my_farms" />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageContainer>
  )
}

export default Farms
