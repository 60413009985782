import { useQuery } from '@tanstack/react-query'
import { MoeLensAbi } from 'constants/abis/MoeLens'
import { MOE_LENS_ADDRESS } from 'constants/moe'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { DexbarnFarmPosition } from 'types/dexbarn'
import { Farm as LensFarm } from 'types/dexlens'
import { getDexbarnChainParam } from 'utils/chains'
import { getFarmPidFromDexbarnFarmId } from 'utils/farm'
import { zeroAddress } from 'viem'
import { useAccount, useReadContracts } from 'wagmi'

interface UseUserFarmsPositionsProps {
  enabled?: boolean
}

const useUserFarmPositions = (
  { enabled }: UseUserFarmsPositionsProps = { enabled: true }
) => {
  const { address: account } = useAccount()
  const chainId = useChainId()

  // fetch user farm positions
  const fetchFarmPositions = useDexbarnGet<DexbarnFarmPosition[]>(
    `/v1/user/farm-positions/${account}/${getDexbarnChainParam(chainId)}`
  )
  const { data: farmPositions } = useQuery({
    enabled: !!account && enabled,
    queryFn: () => fetchFarmPositions(),
    queryKey: ['UserFarmPositionsQuery', account, chainId]
  })

  // get user farm staked amounts
  return useReadContracts({
    contracts: farmPositions?.map((farmPosition) => ({
      abi: MoeLensAbi,
      address: MOE_LENS_ADDRESS[chainId],
      args: [
        getFarmPidFromDexbarnFarmId(farmPosition.farmId),
        account || zeroAddress
      ],
      chainId,
      functionName: 'getFarmDataAt'
    })),
    query: {
      enabled:
        !!account && enabled && farmPositions && farmPositions.length > 0,
      select: (data) => {
        return data
          .map((result) => result.result as LensFarm | undefined)
          .filter(Boolean) as LensFarm[]
      }
    }
  })
}

export default useUserFarmPositions
