import { InfiniteData, useInfiniteQuery } from '@tanstack/react-query'
import useChainId from 'hooks/useChainId'
import { useDexbarnGet } from 'hooks/useDexbarn'
import { VaultRecentActivity } from 'types/dexbarn'
import { getDexbarnChainParam } from 'utils/chains'

interface UseVaultRecentActivitiesProps {
  vaultAddress: string
  pageSize?: number
}

const useVaultRecentActivities = ({
  pageSize = 20,
  vaultAddress
}: UseVaultRecentActivitiesProps) => {
  const chainId = useChainId()
  const chain = getDexbarnChainParam(chainId)

  const fetchRecentActivities = useDexbarnGet(
    `/v1/lb/vaults/${chain}/${vaultAddress}/recent-activity`
  )
  return useInfiniteQuery<VaultRecentActivity[]>({
    getNextPageParam: (lastPage, allPages) =>
      lastPage.length % pageSize === 0 && lastPage.length !== 0
        ? allPages.length + 1
        : undefined,
    initialPageParam: 1,
    queryFn: ({ pageParam }) =>
      fetchRecentActivities({
        params: {
          pageNum: pageParam,
          pageSize
        }
      }),
    queryKey: ['VaultRecentActivityQuery', chain, vaultAddress, pageSize],
    select: convertData
  })
}

export default useVaultRecentActivities

const convertData = (
  data: InfiniteData<VaultRecentActivity[]>
): InfiniteData<VaultRecentActivity[]> => {
  return {
    ...data,
    pages: data.pages.map((page) =>
      page.map((activity) => ({
        ...activity,
        deposits: activity.deposits.map((deposit) => ({
          ...deposit,
          amountX: BigInt(deposit.amountX),
          amountY: BigInt(deposit.amountY)
        })),
        withdrawals: activity.withdrawals.map((withdrawal) => ({
          ...withdrawal,
          amountX: BigInt(withdrawal.amountX),
          amountY: BigInt(withdrawal.amountY)
        }))
      }))
    )
  }
}
