import { Heading, HStack } from '@chakra-ui/react'
import { CNATIVE } from '@traderjoe-xyz/sdk-core'
import CurrencyLogo from 'components/CurrencyLogo'
import useChainId from 'hooks/useChainId'
import React from 'react'
import { isWrappedNativeCurrency } from 'utils/wrappedCurrency'

interface PairNameHeaderProps {
  token0: {
    symbol: string
    address?: string
  }
  token1: {
    symbol: string
    address?: string
  }
  boxSize?: number
  size?: 'md' | 'lg'
}

const PairNameHeader = ({
  boxSize = 7,
  size = 'lg',
  token0,
  token1
}: PairNameHeaderProps) => {
  const chainId = useChainId()

  const nativeCurrency = CNATIVE.onChain(chainId)
  const isToken0WrappedNative = isWrappedNativeCurrency(token0.address, chainId)
  const isToken1WrappedNative = isWrappedNativeCurrency(token1.address, chainId)

  return (
    <HStack>
      <HStack>
        <CurrencyLogo
          address={isToken0WrappedNative ? undefined : token0.address}
          symbol={isToken0WrappedNative ? nativeCurrency.symbol : token0.symbol}
          boxSize={boxSize}
        />
        <Heading data-cy="pair-name-heading-currencyX" size={size}>
          {isToken0WrappedNative ? nativeCurrency.symbol : token0.symbol}
        </Heading>
      </HStack>
      <Heading size={size}>/</Heading>
      <HStack>
        <CurrencyLogo
          address={isToken1WrappedNative ? undefined : token1.address}
          symbol={isToken1WrappedNative ? nativeCurrency.symbol : token1.symbol}
          boxSize={boxSize}
        />
        <Heading data-cy="pair-name-heading-currencyY" size={size}>
          {isToken1WrappedNative ? nativeCurrency.symbol : token1.symbol}
        </Heading>
      </HStack>
    </HStack>
  )
}

export default PairNameHeader
