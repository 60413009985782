import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import { VaultABI } from '@traderjoe-xyz/sdk-v2'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useTransactionToast from 'hooks/useTransactionToast'
import useWaitForTransactionReceipt from 'hooks/useWaitForTransactionReceipt'
import { useMemo } from 'react'
import { formattedNum } from 'utils/format'
import { formatUnits, getAddress } from 'viem'
import { useSimulateContract, useWriteContract } from 'wagmi'

interface UseVaultDepositProps {
  vaultAddress: string
  amount0?: bigint
  amount1?: bigint
  currency0?: Currency
  currency1?: Currency
  enabled?: boolean
  onSuccess?: () => void
}

const useVaultDeposit = ({
  amount0,
  amount1,
  currency0,
  currency1,
  enabled = true,
  onSuccess,
  vaultAddress
}: UseVaultDepositProps) => {
  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isUsingNativeCurrency = currency0?.isNative || currency1?.isNative
  const args = useMemo(
    () =>
      amount0 !== undefined &&
      amount1 !== undefined &&
      (amount0 > BigInt(0) || amount1 > BigInt(0))
        ? ([amount0, amount1] as const)
        : undefined,
    [amount0, amount1]
  )
  const _enabled = enabled && !!args && !!currency0 && !!currency1

  const { data: depositNativeConfig } = useSimulateContract({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args,
    functionName: 'depositNative',
    query: { enabled: _enabled && isUsingNativeCurrency, gcTime: 0 },
    value: (currency0?.isNative ? amount0 : amount1) ?? BigInt(0)
  })

  const { data: depositConfig } = useSimulateContract({
    abi: VaultABI,
    address: getAddress(vaultAddress),
    args,
    functionName: 'deposit',
    query: { enabled: _enabled && !isUsingNativeCurrency, gcTime: 0 },
    value: BigInt(0) as any // workaround for safe app
  })

  const { data, isPending, writeContract } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        const transactionSummary =
          amount0 !== undefined &&
          amount1 !== undefined &&
          currency0 &&
          currency1
            ? t`Deposit ${formattedNum(
                formatUnits(amount0, currency0.decimals)
              )} ${currency0.symbol} and ${formattedNum(
                formatUnits(amount1, currency1.decimals)
              )} ${currency1.symbol}`
            : undefined
        if (!transactionSummary) return
        addRecentTransaction({
          description: transactionSummary,
          hash: hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash: hash
        })
      }
    }
  })

  const deposit = isUsingNativeCurrency
    ? depositNativeConfig?.request
      ? () => writeContract(depositNativeConfig?.request)
      : undefined
    : depositConfig?.request
      ? () => writeContract(depositConfig?.request)
      : undefined

  const { isLoading: isWaitingForTransaction, isSuccess } =
    useWaitForTransactionReceipt({
      hash: data,
      onTransactionSuccess: onSuccess
    })

  return {
    deposit,
    isLoading: isWaitingForTransaction || isPending,
    isSuccess
  }
}

export default useVaultDeposit
