import { t } from '@lingui/macro'
import { Currency } from '@traderjoe-xyz/sdk-core'
import {
  Bin,
  LBRouterV21ABI,
  LiquidityDistributionParams
} from '@traderjoe-xyz/sdk-v2'
import { LB_ROUTER_V22_ADDRESS } from 'constants/moe'
import useActiveChain from 'hooks/useActiveChain'
import useAddRecentTransaction from 'hooks/useAddRecentTransaction'
import useChainId from 'hooks/useChainId'
import useGetTransactionDeadline from 'hooks/useGetTransactionDeadline'
import useTransactionToast from 'hooks/useTransactionToast'
import { useMemo } from 'react'
import { useSlippageSettings } from 'state/settings/hooks'
import { formattedNum } from 'utils/format'
import { wrappedCurrency } from 'utils/wrappedCurrency'
import { formatUnits, getAddress } from 'viem'
import { useAccount, useWriteContract } from 'wagmi'

export interface UseAddLiquidityV2Props {
  activeBinId?: bigint
  amount0?: bigint
  amount1?: bigint
  binStep?: number
  currency0?: Currency
  currency1?: Currency
  distributionParams?: LiquidityDistributionParams
}

const useAddLiquidityV2 = ({
  activeBinId,
  amount0,
  amount1,
  binStep,
  currency0,
  currency1,
  distributionParams
}: UseAddLiquidityV2Props) => {
  const chainId = useChainId()
  const { nativeCurrency } = useActiveChain()
  const { address: account } = useAccount()
  const { slippageSettings } = useSlippageSettings()
  const getTransactionDeadline = useGetTransactionDeadline()

  const addRecentTransaction = useAddRecentTransaction()
  const addTransactionToast = useTransactionToast()

  const isCurrency0Native = currency0?.symbol === nativeCurrency.symbol
  const isCurrency1Native = currency1?.symbol === nativeCurrency.symbol
  const isOneCurrencyNative = isCurrency0Native || isCurrency1Native

  const token0Address = wrappedCurrency(currency0, chainId)?.address
  const token1Address = wrappedCurrency(currency1, chainId)?.address

  const allowedPriceSlippage = slippageSettings.liquidityPriceV2 * 100
  const priceSlippage = allowedPriceSlippage / 10000
  const idSlippage = binStep
    ? Bin.getIdSlippageFromPriceSlippage(priceSlippage, binStep)
    : 0

  const allowedAmountsSlippage =
    10000 - slippageSettings.liquidityAmountV2 * 100
  const amountMin0 =
    amount0 !== undefined
      ? (amount0 * BigInt(allowedAmountsSlippage)) / BigInt(10000)
      : undefined
  const amountMin1 =
    amount1 !== undefined
      ? (amount1 * BigInt(allowedAmountsSlippage)) / BigInt(10000)
      : undefined

  const { deltaIds, distributionX, distributionY } = distributionParams || {}

  const deadline = getTransactionDeadline()
  const addLiquidityArgs =
    token0Address &&
    token1Address &&
    binStep &&
    amount0 !== undefined &&
    amount1 !== undefined &&
    amountMin0 !== undefined &&
    amountMin1 !== undefined &&
    deadline &&
    account &&
    activeBinId &&
    deltaIds &&
    distributionX &&
    distributionY
      ? ([
          {
            activeIdDesired: activeBinId,
            amountX: amount0,
            amountXMin: amountMin0,
            amountY: amount1,
            amountYMin: amountMin1,
            binStep: BigInt(binStep),
            deadline,
            deltaIds: deltaIds.map((id) => BigInt(id)),
            distributionX,
            distributionY,
            idSlippage: BigInt(idSlippage),
            refundTo: account,
            to: account,
            tokenX: getAddress(token0Address),
            tokenY: getAddress(token1Address)
          }
        ] as const)
      : undefined

  const transactionSummary = useMemo(
    () =>
      amount0 !== undefined && amount1 !== undefined && currency0 && currency1
        ? t`Add ${formattedNum(
            formatUnits(amount0, currency0.decimals)
          )} ${currency0?.symbol} and ${formattedNum(
            formatUnits(amount1, currency1.decimals)
          )} ${currency1?.symbol}`
        : undefined,
    [currency0, currency1, amount0, amount1]
  )

  const { error, isPending, reset, writeContractAsync } = useWriteContract({
    mutation: {
      onSuccess: (hash) => {
        if (!transactionSummary) return
        addRecentTransaction({
          description: transactionSummary,
          hash
        })
        addTransactionToast({
          description: transactionSummary,
          hash
        })
      }
    }
  })

  const addLiquidity = addLiquidityArgs
    ? () =>
        writeContractAsync({
          abi: LBRouterV21ABI,
          address: getAddress(LB_ROUTER_V22_ADDRESS[chainId]),
          args: addLiquidityArgs,
          functionName: isOneCurrencyNative
            ? 'addLiquidityNATIVE'
            : 'addLiquidity',
          value: (isCurrency0Native && amount0
            ? amount0
            : isCurrency1Native && amount1
              ? amount1
              : BigInt(0)) as any
        })
    : undefined

  return {
    addLiquidity,
    isLoading: isPending,
    resetAddLiquidity: reset,
    writeError: error
  }
}

export default useAddLiquidityV2
