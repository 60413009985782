import useGetBribesTotalVotes from 'hooks/farms/useGetBribesTotalVotes'
import useIsRewarderStopped from 'hooks/rewarder/useIsRewarderStopped'
import debounce from 'lodash.debounce'
import { useMemo, useState } from 'react'
import { DexbarnFarmBribe } from 'types/dexbarn'
import { FarmBribe, RewarderStatus } from 'types/farm'
import { getFarmPidFromDexbarnFarmId } from 'utils/farm'

enum FarmBribeTab {
  ACTIVE,
  ENDED
}

export enum FarmBribeSortType {
  CREATED_AT = 'Start date',
  REWARDS = 'Rewards'
}

interface Props {
  dexbarnFarmBribes: DexbarnFarmBribe[]
}

const useSortedFarmBribes = ({ dexbarnFarmBribes }: Props) => {
  const {
    data: bribesTotalVotes,
    isLoading: isLoadingBribesTotalVotes,
    refetch: refetchBribesTotalVotes
  } = useGetBribesTotalVotes({
    bribes: dexbarnFarmBribes.map((bribe) => ({
      contractAddress: bribe.bribeId,
      farmPid: BigInt(getFarmPidFromDexbarnFarmId(bribe.farmId))
    }))
  })
  const debouncedRefetchBribesTotalVotes = debounce(
    () => refetchBribesTotalVotes(),
    2000
  )

  const { data: isStoppedArray, isLoading: isLoadingIsStoppedArray } =
    useIsRewarderStopped({
      rewarderAddresses: dexbarnFarmBribes.map((bribe) => bribe.bribeId)
    })

  const farmBribes: FarmBribe[] = useMemo(() => {
    const now = Date.now() / 1000

    return dexbarnFarmBribes.map((bribe, i) => {
      const isStopped = isStoppedArray?.[i]

      const status =
        isStopped || bribe.startTimestamp === bribe.endTimestamp
          ? RewarderStatus.STOPPED
          : bribe.startTimestamp > now
            ? RewarderStatus.PRELAUNCH
            : bribe.startTimestamp < now && bribe.endTimestamp > now
              ? RewarderStatus.LIVE
              : RewarderStatus.ENDED

      return {
        ...bribe,
        bribeTotalVotes: bribesTotalVotes?.[i],
        isStopped,
        rewarderStatus: status
      }
    })
  }, [dexbarnFarmBribes, isStoppedArray, bribesTotalVotes])

  const [selectedTab, setSelectedTab] = useState<FarmBribeTab>(
    FarmBribeTab.ACTIVE
  )
  const [sortType, setSortType] = useState<FarmBribeSortType>(
    FarmBribeSortType.CREATED_AT
  )

  const visibleFarmBribes = useMemo(() => {
    const bribes = farmBribes.filter((bribe) => {
      switch (selectedTab) {
        case FarmBribeTab.ACTIVE:
          return (
            bribe.rewarderStatus === RewarderStatus.LIVE ||
            bribe.rewarderStatus === RewarderStatus.PRELAUNCH
          )
        case FarmBribeTab.ENDED:
          return (
            bribe.rewarderStatus === RewarderStatus.ENDED ||
            bribe.rewarderStatus === RewarderStatus.STOPPED
          )
      }
    })

    // TODO: sort from backend
    return bribes.sort((a, b) => {
      switch (sortType) {
        case FarmBribeSortType.CREATED_AT:
          return b.startTimestamp - a.startTimestamp
        case FarmBribeSortType.REWARDS:
          // TODO: shoudl use USD value
          return parseFloat(b.rewardsPerSecond) - parseFloat(a.rewardsPerSecond)
      }
    })
  }, [selectedTab, farmBribes, sortType])

  return {
    debouncedRefetchBribesTotalVotes,
    farmBribes: visibleFarmBribes,
    isLoading: isLoadingBribesTotalVotes || isLoadingIsStoppedArray,
    selectedTab,
    setSelectedTab,
    setSortType,
    sortType
  }
}

export default useSortedFarmBribes
