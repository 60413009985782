import { Text, VStack } from '@chakra-ui/react'
import { t, Trans } from '@lingui/macro'
import { Token } from '@traderjoe-xyz/sdk-core'
import CurrencyInput from 'components/CurrencyInput'
import Web3Button from 'components/Web3Button'
import useUnstakeToken from 'hooks/stake/useUnstakeToken'
import useCurrencyInputAmount from 'hooks/useCurrencyInputAmount'
import React from 'react'
import { Hex } from 'viem'

interface UnstakeTokenPanelProps {
  stakingContractAddress: Hex
  token: Token
  depositedAmount?: string
  footerText?: string
  onUnstakeSuccess?: () => void
}

const UnstakeTokenPanel = ({
  depositedAmount,
  footerText,
  onUnstakeSuccess,
  stakingContractAddress,
  token
}: UnstakeTokenPanelProps) => {
  const { amount, amountBN, setAmount } = useCurrencyInputAmount({
    currency: token
  })

  const isExceedingBalance = depositedAmount
    ? Number(amount) > Number(depositedAmount)
    : false

  const isDisabled =
    !depositedAmount || isExceedingBalance || Number(amount) === 0

  const {
    isLoading: isUnstaking,
    prepareContractWriteError,
    unstake
  } = useUnstakeToken({
    amount: amountBN,
    enabled: !isDisabled,
    onSuccess: () => {
      setAmount('')
      onUnstakeSuccess?.()
    },
    stakingContractAddress,
    token
  })

  // MOE staking error when the user is voting for farms
  const userFacingErrorMsg = prepareContractWriteError?.message.includes(
    '0xfe73bb25'
  )
    ? t`You can not unstake MOE when you are voting for farms. Tip: you must unstake any veMOE from voting before you may unstake your MOE.`
    : undefined

  return (
    <VStack spacing={10}>
      <CurrencyInput
        data-cy="unstake-token-input"
        heading={t`Amount to unstake:`}
        headingProps={{ fontSize: 'sm' }}
        currency={token}
        currencyAddress={token?.address}
        value={amount}
        onValueChange={setAmount}
        h="60px"
        balance={depositedAmount}
        error={isExceedingBalance ? t`Not enough ${token.symbol}` : undefined}
      />

      <VStack w="full">
        <Web3Button
          data-cy="unstake-token-button"
          size="xl"
          colorScheme="accent"
          variant="primary"
          w="full"
          isDisabled={isDisabled || !unstake}
          onClick={unstake}
          isLoading={isUnstaking}
          loadingText={t`Unstaking ${token.symbol}`}
        >
          <Trans>Unstake {token.symbol}</Trans>
        </Web3Button>

        {userFacingErrorMsg ? (
          <Text
            data-cy="unstake-token-error-text"
            fontSize="sm"
            textColor="red.500"
            textAlign="center"
          >
            {userFacingErrorMsg}
          </Text>
        ) : footerText ? (
          <Text fontSize="sm" textColor="textSecondary" textAlign="center">
            {footerText}
          </Text>
        ) : null}
      </VStack>
    </VStack>
  )
}

export default UnstakeTokenPanel
